import React from "react";
import { Button } from "reactstrap";
import validationSchema from "./NotificationForm.schema";
import { Formik, Form, Field } from "formik";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import FormInputField from "common/components/formFields/FormInputField";
import FormDatePickerField from "common/components/formFields/FormDatePickerField";

const NotificationPopup = ({ sendHandler, cancelHandler }) => {
  const { t } = useTranslation("notifications");

  const onSubmit = (values, form) => {
    sendHandler(form, values);
  };

  return (
    <Formik
      initialValues={{
        title: "",
        message: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form noValidate className="category-form">
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={t("addNotificationTitle")}
            onConfirm={onSubmit}
            onCancel={cancelHandler}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            showCancel
            btnSize=""
            customButtons={
              <React.Fragment>
                <Button type="button" onClick={cancelHandler} color="danger">
                  {t("common:button.cancel")}
                </Button>
                <Button type="submit" color="success">
                  {t("common:button.send")}
                </Button>
              </React.Fragment>
            }
          >
            <Field
              id="notification_timestamp"
              name="notification_timestamp"
              component={FormDatePickerField}
              label={t("sendDate")}
              past={false}
              timeIntervals={5}
            />
            <Field
              required
              fullWidth
              id="title"
              type="text"
              name="title"
              label={t("titleField")}
              component={FormInputField}
            />
            <Field
              required
              fullWidth
              id="message"
              type="textarea"
              name="message"
              label={t("message")}
              component={FormInputField}
              className="notification-message"
            />
          </ReactBSAlert>
        </Form>
      )}
    </Formik>
  );
};

export default NotificationPopup;
