import i18n from "i18n/i18n";
import _ from "lodash";

export const constantToSelect = (data, translationNamespace) => {
  const result = [];
  Object.keys(data).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(data, key) && data[key] != null) {
      const label = !_.isEmpty(translationNamespace)
        ? i18n.t(`${translationNamespace}.${data[key]}`)
        : data[key];

      result.push({
        value: data[key],
        label,
      });
    }
  });
  return result;
};
