import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { REACT_APP_REDUX_DEV_TOOLS_ENABLED } from "constants/env";
import RootReducer from "./RootReducer";

export const prepareStore = (preloadedState) => {
  return createStore(
    RootReducer,
    preloadedState,
    compose(
      applyMiddleware(thunkMiddleware),
      REACT_APP_REDUX_DEV_TOOLS_ENABLED && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );
};
