export default {
  //auth
  LOGIN: "/login",
  //categories
  CATEGORIES: "/categories",
  // products
  PRODUCTS: "/products",
  ADD_PRODUCT: "/products/add",
  EDIT_PRODUCT: "/products/:id",
  //Marketplace
  MARKETPLACE: "/marketplace",
  // properties
  PROPERTIES: "/properties",
  // users
  USERS: "/users",
  ADD_USER: "/users/add",
  EDIT_USER: "/users/:id",
  // links
  LINKS: "/links",
  // brands
  BRANDS: "/brands",
  // notifications
  NOTIFICATIONS: "/notifications",
  // settings
  SETTINGS: "/settings",
  // error
  NOT_FOUND: "/notfound",
  // add partner
  ADD_PARTNER: "/partners/add",
  // analytics dashboard
  ANALYTICS_DASHBOARD: "/dashboard",
  // totems
  TOTEMS: "/totems",
  TOTEMS_EDIT: "/totems/:id",
  TOTEM_BANNERS: "/banners/:id",
  // apps,
  APPS: "/applications",
  // autocut
  AUTOCUT: "/autocut",
  AUTOCUT_REPORT: "/autocut/report",
};
