import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

const NotFound = () => {
  const { t } = useTranslation("error");

  return (
    <div className="content">
      <Col md="12">
        <Row className="center-flex-content">
          <div className="icon-lg-container">
            <i className="icon-lg nc-icon nc-glasses-2" />
            <span className="eye-span eye-span-1" />
            <span className="eye-span eye-span-2" />
            <span className="mouth-span">(</span>
          </div>
          <span className="not-found-info">{t("notFound")}</span>
        </Row>
      </Col>
    </div>
  );
};

export default NotFound;
