import { PriceRegex } from "common/utils/ProductUtils";
import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    published: Yup.bool()
      .required("common:validator.required")
      .test(
        "published_version",
        "common:validator.cannotPublish",
        function (value) {
          const publishedVersions = this.parent?.versions?.filter(
            (v) => v.published === true
          );
          if (value && publishedVersions.length === 0) return false;
          return true;
        }
      ),
    name: Yup.string()
      .required("common:validator.required")
      .max(200, "common:validator.max200Chars"),
    brand: Yup.object().shape({
      value: Yup.string().nullable(),
    }),
    type: Yup.object().shape({
      value: Yup.string().required("common:validator.required"),
    }),
    category: Yup.object().shape({
      value: Yup.string().nullable(),
    }),
    versions: Yup.array()
      .of(
        Yup.object().shape({
          published: Yup.bool().required("common:validator.required"),
          name: Yup.string()
            .required("common:validator.required")
            .max(200, "common:validator.max200Chars"),
          code: Yup.string().nullable().max(50, "common:validator.max50Chars"),
          sku: Yup.string().nullable().max(50, "common:validator.max50Chars"),
          price: Yup.string()
            .nullable()
            .matches(PriceRegex, "common:validator.priceFormat"),
          specialPrice: Yup.string()
            .nullable()
            .matches(PriceRegex, "common:validator.priceFormat"),
          pageLink: Yup.string()
            .max(200, "common:validator.max200Chars")
            .nullable(),
        })
      )
      .required("product:versionsRequired"),
  });
};
