import i18n from "i18n/i18n";

const typesToSelect = (typeList) => {
  const result = [];
  typeList.forEach((type) =>
    result.push({
      code: type.type_name,
      label: i18n.t(`types:${type.type_name}`),
      value: type.id,
    })
  );
  return result;
};

export { typesToSelect };
