import React from "react";
import { Button } from "reactstrap";

const PropertyGroupItem = ({ group, editHandler, deleteHandler }) => {
  return (
    <div className="property-group">
      <div className="property-group-header">
        <div>
          <div className="property-group-title">{group.name}</div>
          {/* currently properties not related to categories */}
          {/* <div className="property-group-category">
            {group?.category?.label}
          </div> */}
        </div>
        <div>
          <Button className="btn btn-info icon-button" onClick={editHandler}>
            <i className="nc-icon nc-ruler-pencil" />
          </Button>
          <Button
            className="btn btn-danger icon-button"
            onClick={deleteHandler}
          >
            <i className="nc-icon nc-simple-remove" />
          </Button>
        </div>
      </div>
      {group?.properties &&
        group.properties.map((property) => (
          <div key={property.name} className="property-item">
            {property.name}
          </div>
        ))}
    </div>
  );
};

export default PropertyGroupItem;
