import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    totemActive: Yup.boolean().required("common:validator.required"),
    totemName: Yup.string().max(50, "common:validator.max50Chars").nullable(),
    productNameFontReversed: Yup.boolean().required(
      "common:validator.required"
    ),
    qrCodes: Yup.boolean().required("common:validator.required"),
    qrCodesText: Yup.string()
      .max(200, "common:validator.max200Chars")
      .nullable(),
  });
};
