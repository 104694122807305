import ThreeJSModes from "constants/ThreeJSModes";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { MaterialType } from "../../constants/MaterialType";
import ProductTypes from "../../constants/ProductTypes";

let productObject;

const getRotation = (mode) => {
  switch (mode) {
    case ThreeJSModes.LINES_FRONT_MODE:
      return [0, 0, 0];
    case ThreeJSModes.LINES_SIDE_MODE:
      return [0, Math.PI / 2, 0];
    default:
      return [0.1, 0, 0];
  }
};
const getParams = (productType, mode) => {
  switch (productType) {
    case ProductTypes.HAT:
      return {
        color: 0xcccccc,
        rotation: [0.8, -0.3, 0],
        position: [-1, -3, -30],
        roughness: 1,
      };
    default:
      return {
        color: 0xe5e5e5,
        rotation: getRotation(mode),
        position: [0, 0, 0],
        roughness: 0,
      };
  }
};

const displayObject = (
  scene,
  sceneTop,
  modelObj,
  modelFbx,
  earRotationWeb,
  textures = {},
  objectScale,
  productType,
  mode
) =>
  new Promise((resolve, reject) => {
    const objLoader = new OBJLoader();
    const fbxLoader = new FBXLoader();
    const params = getParams(productType, mode);

    if (
      mode === ThreeJSModes.LINES_FRONT_MODE ||
      mode === ThreeJSModes.LINES_SIDE_MODE
    ) {
      const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
      const points1 = [];
      points1.push(new THREE.Vector3(-30, 0, 0));
      points1.push(new THREE.Vector3(30, 0, 0));
      const points2 = [];
      points2.push(new THREE.Vector3(0, -30, 0));
      points2.push(new THREE.Vector3(0, 30, 0));
      const geometry1 = new THREE.BufferGeometry().setFromPoints(points1);
      const geometry2 = new THREE.BufferGeometry().setFromPoints(points2);
      const line1 = new THREE.Line(geometry1, material.clone());
      const line2 = new THREE.Line(geometry2, material.clone());

      sceneTop.add(line1);
      sceneTop.add(line2);
    }
    if (modelFbx) {
      fbxLoader.load(
        modelFbx,
        async (object) => {
          productObject = object;

          const textureLoader = new THREE.TextureLoader();

          let textureLinks = {
            ...textures,
            env: process.env.PUBLIC_URL + "/textures/env.jpg",
          };

          let texturesMap = {
            albedo: null,
            metallic: null,
            normal: null,
            env: null,
          };

          let loadedTextures = {
            albedo: textureLinks.albedo == null,
            metallic: textureLinks.metallic == null,
            normal: textureLinks.normal == null,
            env: false,
          };
          let regularMaterial;
          let invisibleMaterial;

          const loadTextures = () =>
            Object.keys(textureLinks).forEach((texture) => {
              if (texture in texturesMap) {
                texturesMap[texture] = textureLoader.load(
                  textureLinks[texture],
                  () => {
                    loadedTextures[texture] = true;
                  }
                );
              }
            });

          const prepareMaterials = () => {
            regularMaterial = new THREE.MeshStandardMaterial({
              color: params.color,
              map: texturesMap.albedo,
              envMap: texturesMap.env,
              metalnessMap: texturesMap.metallic,
              metalness: 0.2,
              normalMap: texturesMap.normal,
              transparent: true,
              roughness: params.roughness,
            });

            invisibleMaterial = new THREE.MeshLambertMaterial({
              map: texturesMap.albedo,
              emissive: 0x333333,
              envMap: texturesMap.env,
              transparent: true,
              reflectivity: 0.4,
              depthWrite: false,
            });
            texturesMap.env.mapping = THREE.EquirectangularReflectionMapping;
            texturesMap.env.encoding = THREE.sRGBEncoding;
          };

          if (productObject) {
            loadTextures();
            prepareMaterials();
            regularMaterial.needsUpdate = true;
            invisibleMaterial.needUpdate = true;
            productObject.traverse((child) => {
              if (child.name === "ear_left" || child.name === "left") {
                child.rotation.z = THREE.Math.degToRad(-earRotationWeb);
              }
              if (child.name === "ear_right" || child.name === "right") {
                child.rotation.z = THREE.Math.degToRad(earRotationWeb);
              }
              if (child.isMesh) {
                if (child.material.length > 0) {
                  for (let i = 0; i < child.material.length; i++) {
                    if (child.material[i].name === MaterialType.GLASS) {
                      child.material[i] = invisibleMaterial;
                    } else {
                      child.material[i] = regularMaterial;
                    }
                  }
                } else if (child instanceof THREE.Mesh && child.material) {
                  if (child.material.name === MaterialType.GLASS) {
                    child.material = invisibleMaterial;
                    child.renderOrder = 2;
                  } else {
                    child.material = regularMaterial;
                    child.renderOrder = 1;
                  }
                }
              }
            });

            productObject.rotation.set(...params.rotation);
            productObject.position.set(...params.position);
            productObject.scale.set(objectScale, objectScale, objectScale);
            scene.add(productObject);
          }

          const isLoaded = async () => {
            if (
              !loadedTextures.albedo ||
              !loadedTextures.metallic ||
              !loadedTextures.normal ||
              !loadedTextures.env
            ) {
              return setTimeout(isLoaded, 1000);
            } else {
              resolve();
            }
          };
          await isLoaded();
        },
        () => {},
        (err) => {
          reject(err);
        }
      );
    } else if (!modelFbx && modelObj) {
      objLoader.load(
        modelObj,
        async (object) => {
          productObject = object;

          const textureLoader = new THREE.TextureLoader();

          let textureLinks = {
            ...textures,
            env: process.env.PUBLIC_URL + "/textures/env.jpg",
          };

          let texturesMap = {
            albedo: null,
            metallic: null,
            normal: null,
            env: null,
          };

          let loadedTextures = {
            albedo: textureLinks.albedo == null,
            metallic: textureLinks.metallic == null,
            normal: textureLinks.normal == null,
            env: false,
          };

          Object.keys(textureLinks).forEach((texture) => {
            if (texture in texturesMap) {
              texturesMap[texture] = textureLoader.load(
                textureLinks[texture],
                () => {
                  loadedTextures[texture] = true;
                }
              );
            }
          });

          texturesMap.env.mapping = THREE.EquirectangularReflectionMapping;
          texturesMap.env.encoding = THREE.sRGBEncoding;

          const regularMaterial = new THREE.MeshStandardMaterial({
            color: params.color,
            map: texturesMap.albedo,
            envMap: texturesMap.env,
            metalnessMap: texturesMap.metallic,
            metalness: 0.2,
            normalMap: texturesMap.normal,
            transparent: true,
            roughness: params.roughness,
          });

          const invisibleMaterial = new THREE.MeshLambertMaterial({
            map: texturesMap.albedo,
            emissive: 0x333333,
            envMap: texturesMap.env,
            transparent: true,
            reflectivity: 0.3,
            depthWrite: false,
          });

          productObject.traverse((child) => {
            if (child instanceof THREE.Mesh) {
              if (child.material.name === MaterialType.GLASS) {
                child.material = invisibleMaterial;
                child.renderOrder = 2;
              } else {
                child.material = regularMaterial;
                child.renderOrder = 1;
              }
            }
          });

          productObject.rotation.set(...params.rotation);
          productObject.position.set(...params.position);
          productObject.scale.set(objectScale, objectScale, objectScale);
          scene.add(productObject);

          const isLoaded = async () => {
            if (
              !loadedTextures.albedo ||
              !loadedTextures.metallic ||
              !loadedTextures.normal ||
              !loadedTextures.env
            ) {
              return setTimeout(isLoaded, 2000);
            } else {
              resolve();
            }
          };

          await isLoaded();
        },
        () => {},
        (err) => {
          reject(err);
        }
      );
    }
  });

const deleteObject = (scene) => {
  scene.remove(productObject);
  if (productObject.geometry) productObject.geometry.dispose();
  if (productObject.material) productObject.material.dispose();
  productObject = undefined;
};

const reloadObject = async (
  scene,
  sceneTop,
  modelObj,
  modelFbx,
  earRotationWeb,
  textures = {},
  objectScale,
  productType,
  mode
) => {
  if (productObject != null) deleteObject(scene);
  await displayObject(
    scene,
    sceneTop,
    modelObj,
    modelFbx,
    earRotationWeb,
    textures,
    objectScale,
    productType,
    mode
  );
};

export { reloadObject };
