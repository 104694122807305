import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { login } from "auth/AuthActions";
import { showAlert } from "common/components/alert/AlertActions";
import { REACT_APP_GOOGLE_CLIENT_ID } from "constants/env";
import { useTranslation } from "react-i18next";
import AlertVariant from "common/components/alert/AlertVariant";

const LoginButton = ({ login, showAlert }) => {
  const { t } = useTranslation("account");
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const handleCallbackResponse = async (res) => {
    if (res && res.credential) {
      login(res.credential);
    } else {
      showAlert(t("error:loginFailed"), AlertVariant.DANGER);
    }
  };

  useEffect(() => {
    if (scriptLoaded) return undefined;

    const initializeGoogle = () => {
      if (!window.google || scriptLoaded) return;

      setScriptLoaded(true);
      window.google.accounts.id.initialize({
        client_id: REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCallbackResponse,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signInDiv"),
        { size: "large", shape: "pill" }
      );
    };

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGoogle;
    script.async = true;
    script.id = "google-client-script";
    document.head.appendChild(script);
  }, [scriptLoaded]);

  return <div id="signInDiv"></div>;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ login, showAlert }, dispatch);
};
export default connect(null, mapDispatchToProps)(LoginButton);
