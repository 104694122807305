import React from "react";
import PropTypes from "prop-types";
import moment from "../../moment/moment";
import { Button } from "reactstrap";

const NotificationTableRow = ({ item, deleteHandler, showDeleteLoader }) => {
  const now = new Date().toISOString();
  const notificationTimestamp = new Date(
    item.notification_timestamp
  ).toISOString();
  const showDelete = now < notificationTimestamp;

  return (
    <>
      <td style={{ width: "20%" }}>
        {moment(item.notification_timestamp).format("L, LT")}
      </td>
      <td style={{ width: "20%" }}>{item.notification_title}</td>
      <td
        style={{ width: "45%", maxWidth: "400px", overflowWrap: "break-word" }}
      >
        {item.notification_message}
      </td>
      <td style={{ width: "10%" }}>
        {item?.onesignal_id ? item?.onesignal_data?.successful : "-"}
      </td>
      <td style={{ width: "10%" }}>
        {item?.onesignal_id ? item?.onesignal_data?.failed ?? "" : "-"}
      </td>
      <td align="center">
        {showDelete && (
          <Button
            className="btn btn-danger icon-button"
            onClick={() => deleteHandler(item.id)}
            disabled={showDeleteLoader}
          >
            <i className="nc-icon nc-simple-remove" />
          </Button>
        )}
      </td>
    </>
  );
};

NotificationTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default NotificationTableRow;
