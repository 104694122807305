import React from "react";
import { Col, Row } from "reactstrap";
import { Formik } from "formik";
import LinkSearchForm from "./LinkSearchForm";

const LinkSearch = ({ onSearchCallback }) => {
  const onSubmit = (values, { setSubmitting }) => {
    onSearchCallback({
      linkSearch: values.linkSearch,
      productId: values.product.value || null,
      offset: 0,
    });
    setSubmitting(false);
  };

  return (
    <Row>
      <Col md="12">
        <Formik
          initialValues={{
            linkSearch: "",
            product: { label: "", value: "" },
          }}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => {
            return <LinkSearchForm />;
          }}
        </Formik>
      </Col>
    </Row>
  );
};

export default LinkSearch;
