import React from "react";
import { Button, Col } from "reactstrap";
import validationSchema from "./PropertyGroupForm.schema";
import { Formik, Form, Field, FieldArray } from "formik";
import FormInputField from "common/components/formFields/FormInputField";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const initialValues = {
  name: "",
  category: null,
  properties: [],
};

const PropertyGroupPopup = ({
  group,
  addHandler,
  editHandler,
  cancelHandler,
  // categories,
}) => {
  const { t } = useTranslation("property");
  const [deletedPropertyIds, setDeletedPropertyIds] = useState([]);

  // currently properties not related to categories
  // const [categoryList, setCategoryList] = useState([]);

  // useEffect(() => {
  //   setCategoryList(categories);
  // }, [categories]);

  const propertyDeleteHandler = (prop) => {
    if (!prop.id) return;
    setDeletedPropertyIds((deleted) => [...deleted, prop.id]);
  };
  const onSubmit = (values, form) => {
    if (values.id) {
      editHandler({
        ...values,
        categoryId: values.category?.value,
        deletedPropertyIds,
      });
    } else {
      addHandler({
        ...values,
        categoryId: values.category?.value,
      });
    }
    form.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={group || initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(form) => (
        <Form noValidate className="property-form">
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={group ? t("editPropertyTitle") : t("addPropertyTitle")}
            onConfirm={onSubmit}
            onCancel={cancelHandler}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            showCancel
            btnSize=""
            customButtons={
              <React.Fragment>
                <Button type="button" onClick={cancelHandler} color="danger">
                  {t("common:button.cancel")}
                </Button>
                <Button type="submit" color="success">
                  {t("common:button.save")}
                </Button>
              </React.Fragment>
            }
          >
            <Field
              required
              fullWidth
              id="name"
              type="text"
              name="name"
              label={t("groupName")}
              component={FormInputField}
            />
            {/* currently properties not related to categories */}
            {/* <Field
              fullWidth
              id="category"
              name="category"
              label={t("category")}
              options={categoryList}
              component={FormSelectField}
            /> */}
            <FieldArray
              name="properties"
              render={(arrayHelpers) => (
                <div className="mb-2">
                  {form.values?.properties && (
                    <>
                      {form.values.properties.map((prop, index) => (
                        /* eslint-disable react/no-array-index-key */
                        <div
                          className="property-form-input-container"
                          key={index}
                        >
                          <Col>
                            <Field
                              id={`properties[${index}].name`}
                              name={`properties[${index}].name`}
                              label={t("propertyName")}
                              component={FormInputField}
                              //noStyling
                            />
                          </Col>
                          <Button
                            className="btn btn-danger icon-button"
                            onClick={() => {
                              propertyDeleteHandler(
                                form.values.properties[index]
                              );
                              arrayHelpers.remove(index);
                            }}
                          >
                            <i className="nc-icon nc-simple-remove" />
                          </Button>
                        </div>
                      ))}
                    </>
                  )}
                  <Button
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        name: "",
                      })
                    }
                    color="info"
                    className="mr-3"
                  >
                    <i className="nc-icon nc-simple-add button-add-icon" />
                    {t("addProperty")}
                  </Button>
                </div>
              )}
            />
          </ReactBSAlert>
        </Form>
      )}
    </Formik>
  );
};

export default PropertyGroupPopup;
