import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RouterPaths from "constants/RouterPaths";
import { prepareUrl } from "common/utils/PrepareUrl";

const UserTableRow = ({ item, number }) => {
  const { t } = useTranslation("user");
  return (
    <>
      <td>{number}</td>
      <td>{item.user_email}</td>
      <td>{t(`statusOptions.${item.user_active}`)}</td>
      <td>{t(`roleOptions.${item.user_role}`)}</td>
      <td className="text-right">
        <Link
          to={prepareUrl(RouterPaths.EDIT_USER, { id: item.id })}
          className="btn btn-info table-icon-button"
        >
          <i className="nc-icon nc-ruler-pencil" />
        </Link>
      </td>
    </>
  );
};

export default UserTableRow;
