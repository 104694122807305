import React from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Col, Row } from "reactstrap";

const LineChart = ({ data, title }) => {
  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
      },
    },
    scales: {
      yAxes: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 10,
      },
    },
  };

  return (
    <Row>
      <Col>
        <h2 className="analytics-chart-heading">{title}</h2>
        <Line options={options} data={data} />
      </Col>
    </Row>
  );
};

export default LineChart;
