import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import { GET_TYPES_SUCCESS } from "./TypeReducer";

export const getTypes = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.TYPES)
    .then((res) => {
      dispatch({
        type: GET_TYPES_SUCCESS,
        payload: {
          types: res?.data?.body,
        },
      });
    })
    .catch((err) => dispatch(handleError(err)));
};
