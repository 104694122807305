import axios from "axios";
import { parseFormErrors, scrollToError } from "common/utils/FormUtils";
import { REACT_APP_BACKEND_URL } from "constants/env";
import i18n from "i18n/i18n";
import history from "browserHistory";
import RouterPaths from "constants/RouterPaths";
import { AUTH_TOKEN, PARTNER } from "auth/AuthConstants";

import { LOGOUT } from "auth/AuthReducer";
import { showAlert } from "../common/components/alert/AlertActions";
import AlertVariant from "../common/components/alert/AlertVariant";

export const externalApiClient = axios.create({
  timeout: 60000,
});

export const apiClient = axios.create({
  baseURL: `${REACT_APP_BACKEND_URL}`,
  timeout: 120000,
});

export const handleError =
  (err, form = null) =>
  (dispatch) => {
    let errorMessage;
    switch (err?.response?.status) {
      case 404:
        history.push(RouterPaths.NOT_FOUND);
        break;

      case 401:
      case 403:
        errorMessage =
          err?.response?.data?.message ||
          err?.response?.data?.body ||
          "unauthorized";
        dispatch(
          showAlert(
            i18n.t([
              `common:backendErrors.${errorMessage}`,
              "common:backendErrors.unspecific",
            ]),
            AlertVariant.DANGER
          )
        );
        localStorage.removeItem(AUTH_TOKEN);
        sessionStorage.removeItem(PARTNER);
        setAuthorizationHeader(null, () => {});
        dispatch({ type: LOGOUT });
        break;

      case 400:
        if (form && err?.response?.data?.errors) {
          form.setErrors(parseFormErrors(err?.response?.data?.errors));
          scrollToError(err?.response?.data?.errors);
        } else {
          dispatch(
            showAlert(
              i18n.t("common:backendErrors.unspecific"),
              AlertVariant.DANGER
            )
          );
        }
        break;
      case 409:
      case 418:
      case 422:
      case 500: {
        errorMessage = err?.response?.data || err?.response?.statusText;

        const message =
          typeof errorMessage === "object"
            ? i18n
                .t([
                  `common:backendErrors.${errorMessage.code}`,
                  "common:backendErrors.unspecific",
                ])
                .replace(/\$field\$/gi, errorMessage.field)
                .replace(/\$value\$/gi, errorMessage.value)
            : i18n.t([
                `common:backendErrors.${errorMessage}`,
                "common:backendErrors.unspecific",
              ]);

        dispatch(showAlert(message, AlertVariant.DANGER));
        break;
      }
      default:
        dispatch(
          showAlert(
            i18n.t(
              err.code === "ECONNABORTED"
                ? "common:checkConnection"
                : "common:backendErrors.unspecific"
            ),
            err.code === "ECONNABORTED"
              ? AlertVariant.DANGER_LASTING
              : AlertVariant.DANGER
          )
        );
        break;
    }
  };

export const setAuthorizationHeader = (token) => {
  if (token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete apiClient.defaults.headers.common.Authorization;
  }
};

export const setPartnerHeader = (partnerId) => {
  if (partnerId != null) {
    apiClient.defaults.headers.common["partner-id"] = partnerId;
  } else {
    delete apiClient.defaults.headers.common["partner-id"];
  }
};
