import { totemStatus } from "constants/TotemStatuses";
import i18n from "i18n/i18n";

const checkTotemStatus = (totem) => {
  if (
    new Date().toISOString() >
    new Date(totem.totem_expiration_date).toISOString()
  ) {
    return totemStatus.EXPIRED;
  } else if (!totem.totem_deactivation_date && totem.totem_active) {
    return totemStatus.ACTIVE;
  } else if (
    totem.totem_deactivation_date &&
    new Date(totem.totem_last_activity).toISOString() <
      new Date(totem.totem_deactivation_date).toISOString()
  ) {
    return totemStatus.INACTIVE_PENDING;
  } else if (
    (!totem.totem_active &&
      new Date(totem.totem_last_activity).toISOString() >
        new Date(totem.totem_deactivation_date).toISOString()) ||
    new Date(totem.totem_last_activity).toISOString() >
      new Date(totem.totem_expiration_date).toISOString()
  ) {
    return totemStatus.INACTIVE_CONFIRMED;
  }
};

const getTotemStatus = (totem) => {
  const status = checkTotemStatus(totem);
  return i18n.t(`totems:totemStatus.${status}`);
};

const getTotemStatusTooltip = (totem) => {
  const status = checkTotemStatus(totem);
  return i18n.t(`totems:tooltips.${status}`);
};

const checkIfTotemActive = (totem) => {
  return (
    !(
      totem.totem_deactivation_date &&
      new Date(totem.totem_last_activity).toISOString() >
        new Date(totem.totem_deactivation_date).toISOString()
    ) &&
    !(
      new Date().toISOString() >
      new Date(totem.totem_expiration_date).toISOString()
    )
  );
};

const totemsToSelect = (totemList) => {
  const result = [];
  totemList.forEach((totem) =>
    result.push({
      label: totem.totem_name || "-",
      value: totem.totem_id,
    })
  );
  return result;
};
export {
  getTotemStatus,
  getTotemStatusTooltip,
  checkIfTotemActive,
  totemsToSelect,
};
