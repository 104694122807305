import React, { useEffect } from "react";
import _ from "lodash";
import { dataURLtoBlob } from "common/utils/FileUtils";
import { initThreeApp } from "../3D/ThreeApp";
import { reloadObject } from "../3D/Object3D";
import ThreeJSModes from "constants/ThreeJSModes";

const ThumbnailMaker = ({
  size,
  objectScale,
  fileUrls,
  index,
  onThumbReady,
  productType,
  values,
  generate,
}) => {
  let threeApp;

  const makeThumbnail = async () => {
    try {
      const canvasElement = document.getElementById(
        `thumbnail-canvas-${index}`
      );

      const modelObjLink = fileUrls.model;
      const modelFbxLink = fileUrls.modelFbx;

      const textureLinks = {
        albedo: fileUrls.albedo,
        metallic: fileUrls.metallic,
        normal: fileUrls.normal,
      };

      Object.keys(textureLinks).forEach(
        (k) => textureLinks[k] === null && delete textureLinks[k]
      );

      if (!threeApp) {
        threeApp = await initThreeApp(
          canvasElement,
          productType,
          ThreeJSModes.THUMBNAIL_MODE
        );
      }

      await reloadObject(
        threeApp.scene,
        threeApp.sceneTop,
        modelObjLink,
        modelFbxLink,
        values.earRotationWeb,
        textureLinks,
        objectScale,
        productType,
        ThreeJSModes.THUMBNAIL_MODE
      );

      const data = canvasElement.toDataURL();
      const blob = dataURLtoBlob(data);
      if (typeof onThumbReady === "function") {
        onThumbReady(blob);
      }
    } catch {
      if (typeof onThumbReady === "function") {
        onThumbReady(null);
      }
    }
  };

  useEffect(() => {
    if (!generate) return;
    if (
      (!(fileUrls.model || fileUrls.modelFbx) ||
        values.thumbFileNew ||
        (!_.isEmpty(values.thumbLink) && !values.thumbFileDeleted)) &&
      typeof onThumbReady === "function"
    ) {
      onThumbReady(null);
      return;
    }
    makeThumbnail();
    // eslint-disable-next-line
  }, [generate]);

  if (!fileUrls.model && !fileUrls.modelFbx) {
    return null;
  }

  return (
    <canvas
      id={`thumbnail-canvas-${index}`}
      className="thumbnail-canvas-hidden"
      width={size.width}
      height={size.height}
    ></canvas>
  );
};

export default ThumbnailMaker;
