const HexListRegex = /^[0-9a-f]{10}(;[0-9a-f]{10})*;?$/i;
const PriceRegex = /^(([1-9][0-9]{0,9})|0)(.[0-9]{2})?$/;

const productsToSelect = (productList) => {
  const result = [];
  productList.forEach((product) =>
    result.push({
      label:
        product.product_name !== product.product_name_owner
          ? `${product.product_name} (${product.product_name_owner})`
          : product.product_name,
      value: product.id,
      versions: product.versions?.map((version) => {
        return {
          label:
            version.version_name !== version.version_name_owner
              ? `${version.version_name} (${version.version_name_owner})`
              : version.version_name,
          value: version.partners_version_id,
        };
      }),
    })
  );
  return result;
};

module.exports = {
  HexListRegex,
  PriceRegex,
  productsToSelect,
};
