import { fromJS } from "immutable";

export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const GET_DATA_ERROR = "GET_DATA_ERROR";

const initialData = fromJS({
  data: null,
  status: null,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_DATA_SUCCESS:
      return state.merge({
        data: { data: action.payload.chart_data },
        status: action.payload.status_progress,
      });
    case GET_DATA_ERROR:
      return state.merge({
        data: null,
        status: action.payload.status_progress,
      });
    default:
      return state;
  }
};
