import React from "react";
import { Button } from "reactstrap";

const CategoryItem = ({ category, editHandler }) => {
  return (
    <div className="category-list-item">
      <div className="category-list-item-text">{category.category_name}</div>
      <Button className="btn btn-info icon-button" onClick={editHandler}>
        <i className="nc-icon nc-ruler-pencil" />
      </Button>
    </div>
  );
};

export default CategoryItem;
