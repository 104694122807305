import _ from "lodash";

const versionFilenames = [
  "thumbFile",
  "normalFile",
  "albedoFile",
  "metallicFile",
  "frontFile",
  "lensesFile",
  "leftFile",
  "rightFile",
];

const totemFileNames = [
  "logoFile",
  "topImageFile",
  "middleImageFile",
  "bottomImageFile",
];

const settingsFileNames = ["fbxFile"];

const prepareProductFormData = (product) => {
  const formData = new FormData();
  const productData = _.cloneDeep(product);

  formData.append("modelFile", productData.modelFile);
  delete productData.modelFile;

  formData.append("modelFileFbx", productData.modelFileFbx);
  delete productData.modelFileFbx;

  if (productData.photoFiles) {
    productData.photoFiles.forEach((p, index) => {
      formData.append(`photos[${index}]`, p);
    });
  }
  delete productData.photoFiles;

  productData.versions.forEach((version, index) => {
    versionFilenames.forEach((filename) => {
      if (version[filename]) {
        formData.append(`versions[${index}].${filename}`, version[filename]);
        delete version[filename];
      }
    });
  });

  formData.append("productData", JSON.stringify(productData));
  return formData;
};

const prepareCategoryFormData = (category) => {
  const formData = new FormData();
  const categoryData = _.cloneDeep(category);

  formData.append("photoFile", categoryData.photoFile);
  delete categoryData.photoFile;

  formData.append("categoryData", JSON.stringify(categoryData));
  return formData;
};

const prepareBannerFormData = (banner) => {
  const formData = new FormData();
  const bannerData = _.cloneDeep(banner);

  formData.append("file", bannerData.file);
  delete bannerData.file;

  formData.append("bannerData", JSON.stringify(bannerData));
  return formData;
};

const prepareSettingsFormData = (settings) => {
  const formData = new FormData();
  const settingsData = _.cloneDeep(settings);

  if (settingsData.effects) {
    settingsData.effects.forEach((effect, index) => {
      settingsFileNames.forEach((filename) => {
        if (effect[filename]) {
          formData.append(`effects[${index}].${filename}`, effect[filename]);
          delete effect[filename];
        }
      });
    });
  }

  if (settingsData.newEffects) {
    settingsData.newEffects.forEach((effect, index) => {
      settingsFileNames.forEach((filename) => {
        if (effect[filename]) {
          formData.append(`newEffects[${index}].${filename}`, effect[filename]);
          delete effect[filename];
        }
      });
    });
  }

  if (settingsData.newEffects) {
    settingsData.updateEffects.forEach((effect, index) => {
      settingsFileNames.forEach((filename) => {
        if (effect[filename]) {
          formData.append(
            `updateEffects[${index}].${filename}`,
            effect[filename]
          );
          delete effect[filename];
        }
      });
    });
  }
  formData.append("partnerData", JSON.stringify(settingsData));
  return formData;
};

const prepareTotemFormData = (totem) => {
  const formData = new FormData();
  const totemData = _.cloneDeep(totem);

  totemFileNames.forEach((filename) => {
    if (totemData[filename]) {
      formData.append(`${filename}`, totemData[filename]);
      delete totemData[filename];
    }
  });

  formData.append("totemData", JSON.stringify(totemData));
  return formData;
};

export {
  prepareProductFormData,
  prepareCategoryFormData,
  prepareBannerFormData,
  prepareTotemFormData,
  prepareSettingsFormData,
};
