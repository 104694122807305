import React from "react";
import { Button } from "reactstrap";
import validationSchema from "./BrandForm.schema";
import { Formik, Form, Field } from "formik";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import FormInputField from "common/components/formFields/FormInputField";

const BrandPopup = ({
  brand,
  newBrand,
  addHandler,
  editHandler,
  cancelHandler,
}) => {
  const { t } = useTranslation("brands");

  const onSubmit = (values, form) => {
    if (newBrand) {
      addHandler(values.brandName, form);
    } else {
      editHandler(brand.id, values.brandName, form);
    }
    form.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        brandName: brand.brand_name,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form noValidate className="category-form">
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={newBrand ? t("addBrandTitle") : t("editBrandTitle")}
            onConfirm={onSubmit}
            onCancel={cancelHandler}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            showCancel
            btnSize=""
            customButtons={
              <React.Fragment>
                <Button type="button" onClick={cancelHandler} color="danger">
                  {t("common:button.cancel")}
                </Button>
                <Button type="submit" color="success">
                  {t("common:button.save")}
                </Button>
              </React.Fragment>
            }
          >
            <Field
              required
              fullWidth
              id="brandName"
              type="text"
              name="brandName"
              label={t("name")}
              component={FormInputField}
            />
          </ReactBSAlert>
        </Form>
      )}
    </Formik>
  );
};

export default BrandPopup;
