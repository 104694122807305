import { fromJS } from "immutable";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

const initialData = fromJS({
  userList: null,
  user: null,
  meta: {
    offset: 0,
  },
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return state.merge({
        userList: action.payload.users,
        meta: action.payload.meta,
        user: null,
      });
    case GET_USER_SUCCESS:
      return state.merge({
        user: action.payload.user,
      });
    default:
      return state;
  }
};
