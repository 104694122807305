import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import { GET_MARKETPLACE_PRODUCTS_SUCCESS } from "./MarketplaceReducer";
import {
  parseProductCategories,
  categoriesToSelect,
} from "common/utils/CategoryUtils";
import { getCategories } from "components/products/ProductActions";

export const getMarketplaceProducts = (params) => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.MARKETPLACE_PRODUCTS, { params })
    .then((res) => {
      const products = res?.data?.body;
      return dispatch(getCategories()).then((categories) => {
        const parsedCategories = categoriesToSelect(categories);
        const parsedProducts = parseProductCategories(
          products,
          parsedCategories
        );
        dispatch({
          type: GET_MARKETPLACE_PRODUCTS_SUCCESS,
          payload: {
            products: parsedProducts,
            meta: res?.data?.meta,
          },
        });
        return res?.data?.body;
      });
    })
    .catch((err) => dispatch(handleError(err)));
};

export const assignProductFromMarketplace =
  (productId, search) => (dispatch) => {
    return apiClient
      .post(ApiEndpoints.MARKETPLACE_PRODUCTS_ASSIGN, { productId })
      .then(() => {
        dispatch(getMarketplaceProducts(search));
        return true;
      })
      .catch((err) => {
        dispatch(handleError(err));
        return false;
      });
  };
