import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import { connect } from "react-redux";
import { Field, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import FormInputField from "common/components/formFields/FormInputField";
import FileExtension from "constants/FileExtension";
import _ from "lodash";
import FormDropzoneField from "common/components/formFields/FormDropzoneField";
import FormSwitchField from "common/components/formFields/FormSwitchField";
import Input from "reactstrap/lib/Input";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import { showAlert } from "common/components/alert/AlertActions";
import { bindActionCreators } from "redux";
import AlertVariant from "common/components/alert/AlertVariant";
import ExtendedUserRoles from "constants/ExtendedUserRoles";
import { isMainPartner } from "common/utils/PartnerUtils";
import Wizard from "components/wizard2d/Wizard";

const Product2dVersionForm = ({
  id,
  index,
  arrayHelpers,
  deleteHandler,
  assignHandler,
  onFileDrop,
  form,
  formSaving,
  partner,
  product,
  showAlert,
  showTryOn,
  user,
  isAssigned,
  fromMarketplace,
  fromOtherPartner,
}) => {
  const { t } = useTranslation("product");
  const { values } = useFormikContext();
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const [fileUrls, setFileUrls] = useState({
    front: null,
    lenses: null,
    left: null,
    right: null,
  });

  const [thumbPreviewSize, setThumbPreviewSize] = useState({
    width: 0,
    height: 0,
  });

  const [globalWebSettings, setGlobalWebSettings] = useState({
    aspectRatioWeb: 1,
    positionYWeb: 0,
    positionZWeb: 0,
    rotationWeb: 0,
  });

  const deleteVersion = () => {
    if (
      (arrayHelpers.form.values?.versions?.filter((v) => v.isAssigned === true))
        .length === 1
    ) {
      setDeleteError(t("versionsRequired"));
    } else {
      if (!fromMarketplace && !fromOtherPartner) {
        arrayHelpers.remove(index);
      }
      if (deleteHandler) deleteHandler(values.versions[index]);
    }
  };

  useEffect(() => {
    if (partner) {
      setGlobalWebSettings({
        aspectRatioWeb: partner.aspect_ratio_web,
        positionYWeb: partner.position_y_web,
        positionZWeb: partner.position_z_web,
        rotationWeb: partner.rotation_web,
      });
    }
  }, [partner]);

  useEffect(() => {
    setShowFiles(false);
  }, [product]);

  useEffect(() => {
    if (!partner?.thumb_file_width || !partner?.thumb_file_height) {
      return;
    }
    if (partner.thumb_file_height < 150) {
      setThumbPreviewSize({
        width: partner.thumb_file_width,
        height: partner.thumb_file_height,
      });
      return;
    }
    const height = 150;
    const width =
      (partner.thumb_file_width * height) / partner.thumb_file_height;
    setThumbPreviewSize({ width, height });
  }, [partner]);

  const versionValues = values.versions[index];
  const { frontFile, lensesFile, leftFile, rightFile } = versionValues;

  useEffect(() => {
    const oldUrls = { ...fileUrls };
    setFileUrls({
      front: frontFile ? URL.createObjectURL(frontFile) : null,
      lenses: lensesFile ? URL.createObjectURL(lensesFile) : null,
      left: leftFile ? URL.createObjectURL(leftFile) : null,
      right: rightFile ? URL.createObjectURL(rightFile) : null,
    });

    if (oldUrls.front) URL.revokeObjectURL(oldUrls.front);
    if (oldUrls.lenses) URL.revokeObjectURL(oldUrls.lenses);
    if (oldUrls.left) URL.revokeObjectURL(oldUrls.left);
    if (oldUrls.right) URL.revokeObjectURL(oldUrls.right);

    return () => {
      if (fileUrls.front) URL.revokeObjectURL(fileUrls.front);
      if (fileUrls.lenses) URL.revokeObjectURL(fileUrls.lenses);
      if (fileUrls.left) URL.revokeObjectURL(fileUrls.left);
      if (fileUrls.right) URL.revokeObjectURL(fileUrls.right);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frontFile, lensesFile, leftFile, rightFile]);

  useEffect(() => {
    if (
      !_.isEmpty(values.versions[index].thumbLink) ||
      !_.isEmpty(thumbnailUrl)
    ) {
      setThumbnailUrl(values.versions[index].thumbLink);
    }
    if (values.versions.length > 1) {
      setDeleteError("");
    }
  }, [values, index, thumbnailUrl]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(id);
    showAlert(t("version.copyClipboard"), AlertVariant.SUCCESS);
  };

  const loadTryOn = () => {
    if (
      document.getElementById("load-arlab") &&
      document.getElementById("arlab")
    )
      return;

    if (window.arlab?.q) window.arlab.q = [];

    const script = document.createElement("script");
    script.id = "load-arlab";
    script.async = true;
    script.innerHTML = `
      (function (w,d,s,o,f,js,fjs) {
        w['arlab-site-widgets']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
        if (d.getElementById(o)) return;
        js = d.createElement(s); 
        fjs = d.getElementsByTagName(s)[0];
        js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
      }(window, document, 'script', 'arlab', 'https://connect.ar-labs.io/v2/widget.js'));
      arlab('init', { apiKey:'b5f30e56-6a08-4418-b08c-7bdef2d0dd80', language: 'en' })
      arlab('panel', { clickedIndex: ${index}})
    `;
    document.head.appendChild(script);
  };

  const [showFiles, setShowFiles] = useState(null);
  const [showWizard, setShowWizard] = useState(false);
  const choosePreparedFiles = () => setShowFiles(true);
  const startWizard = () => {
    setShowWizard(true);
  };

  const onWizardFilesReady = (front, frontWithLenses, left, right) => {
    if (front?.type === "image/png") {
      front.path = "front.png";
      onFileDrop(
        `versions[${index}].frontFile`,
        `versions[${index}].frontLink`,
        [front]
      );
    }
    if (frontWithLenses?.type === "image/png") {
      frontWithLenses.path = "frontWithLenses.png";
      onFileDrop(
        `versions[${index}].lensesFile`,
        `versions[${index}].lensesLink`,
        [frontWithLenses]
      );
    }
    if (left?.type === "image/png") {
      left.path = "left.png";
      onFileDrop(`versions[${index}].leftFile`, `versions[${index}].leftLink`, [
        left,
      ]);
    }
    if (right?.type === "image/png") {
      right.path = "right.png";
      onFileDrop(
        `versions[${index}].rightFile`,
        `versions[${index}].rightLink`,
        [right]
      );
    }
    setShowFiles(true);
    setShowWizard(false);
  };

  return (
    <Row>
      <Col md="12">
        <Col xs="12">
          <Row>
            <Col>
              <h5
                className="pt-4 mb-3"
                style={{
                  color:
                    !isAssigned && (fromMarketplace || fromOtherPartner)
                      ? "#e1e1e1"
                      : "#252422",
                }}
              >{`${t("version.header")} ${index + 1}`}</h5>
            </Col>
          </Row>
        </Col>
        <Row>
          <Col md="6" xs="12">
            {!fromMarketplace && !fromOtherPartner && (
              <Field
                id={`versions[${index}].published`}
                name={`versions[${index}].published`}
                label={
                  partner?.totem_active
                    ? t("version.publishedApp")
                    : t("version.published")
                }
                onChangeHandler={(value) => {
                  if (value && !form.values.published) {
                    form.setFieldValue("published", true);
                  }
                }}
                onText={t("common:button.yes").toUpperCase()}
                offText={t("common:button.no").toUpperCase()}
                component={FormSwitchField}
                multiple={partner?.totem_active}
                disabled={formSaving}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12">
            {id && (
              <>
                <label>ID</label>
                <InputGroup>
                  <Input
                    disabled
                    type="text"
                    value={id}
                    className="copy-to-clipboard-input"
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      onClick={copyToClipboard}
                      size="sm"
                      className="copy-to-clipboard-icon"
                      color="neutral"
                    >
                      <i className="nc-icon nc-single-copy-04" />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </>
            )}
          </Col>
          <Col md="6" xs="12">
            {!fromMarketplace &&
              !fromOtherPartner &&
              values.versions[index].modificationTime && (
                <Field
                  fullWidth
                  disabled
                  id={`versions[${index}].modificationTime`}
                  type="text"
                  name={`versions[${index}].modificationTime`}
                  label={t("version.modificationTime")}
                  component={FormInputField}
                />
              )}
          </Col>
        </Row>
        <div>
          <div className="product-thumb-file-container">
            <>
              {!_.isEmpty(thumbnailUrl) ? (
                <img
                  className="product-thumb-file"
                  src={thumbnailUrl}
                  alt="Version thumb"
                  width={thumbPreviewSize.width}
                  height={thumbPreviewSize.height}
                />
              ) : (
                <div
                  className="product-thumb-file-preview"
                  style={{
                    width: thumbPreviewSize.width,
                    height: thumbPreviewSize.height,
                    lineHeight: `${thumbPreviewSize.height}px`,
                  }}
                >
                  {t("version.thumbPreview")}
                </div>
              )}
            </>
            <div
              className={
                (user.role !== ExtendedUserRoles.SUPERADMIN && !showTryOn) ||
                !frontFile ||
                !lensesFile ||
                !leftFile ||
                !rightFile
                  ? "d-none"
                  : "webfit-wrapper"
              }
            >
              <div
                className="webfit"
                id={`webfit-${index}`}
                front-url={fileUrls.front}
                lenses-url={fileUrls.lenses}
                left-url={fileUrls.left}
                right-url={fileUrls.right}
                scale={globalWebSettings.aspectRatioWeb * values.aspectRatioWeb}
                rotation={globalWebSettings.rotationWeb + values.rotationWeb}
                position-y={
                  globalWebSettings.positionYWeb + values.positionYWeb
                }
                position-z={
                  globalWebSettings.positionZWeb + values.positionZWeb
                }
                is-2d="true"
              />
              <div id={`webfit-${index}-modal`} />
              <Button onClick={loadTryOn}>TRY ON</Button>
              <div className="webfit-warning">{t("tryOnWarning")}</div>
            </div>
          </div>
          <Field
            required
            fullWidth
            id={`versions[${index}].name`}
            type="text"
            name={`versions[${index}].name`}
            label={t("version.name")}
            component={FormInputField}
            disabled={
              formSaving ||
              (!isAssigned && (fromMarketplace || fromOtherPartner))
            }
          />
          <Field
            fullWidth
            id={`versions[${index}].pageLink`}
            type="text"
            name={`versions[${index}].pageLink`}
            label={t("version.pageLink")}
            component={FormInputField}
            disabled={
              formSaving ||
              (!isAssigned && (fromMarketplace || fromOtherPartner))
            }
          />
          {!fromMarketplace && !fromOtherPartner && (
            <FormDropzoneField
              id={`versions[${index}].thumbFile`}
              name={`versions[${index}].thumbFile`}
              label={t("version.thumbFile", {
                x: partner?.thumb_file_width || 0,
                y: partner?.thumb_file_height || 0,
              })}
              acceptedExtension={FileExtension.PNG}
              handleDrop={(file) =>
                onFileDrop(
                  `versions[${index}].thumbFile`,
                  `versions[${index}].thumbLink`,
                  file
                )
              }
              handleDelete={() =>
                onFileDrop(
                  `versions[${index}].thumbFile`,
                  `versions[${index}].thumbLink`,
                  null
                )
              }
              existingFile={
                !_.isEmpty(values.versions[index].thumbLink) &&
                !values.versions[index].thumbFileDeleted
                  ? t("fileUploaded")
                  : ""
              }
              disabled={formSaving}
            />
          )}

          {!fromMarketplace && !fromOtherPartner && showFiles && (
            <>
              <FormDropzoneField
                id={`versions[${index}].frontFile`}
                name={`versions[${index}].frontFile`}
                label={t("version.frontFile")}
                acceptedExtension={FileExtension.PNG}
                handleDrop={(file) =>
                  onFileDrop(
                    `versions[${index}].frontFile`,
                    `versions[${index}].frontLink`,
                    file
                  )
                }
                handleDelete={() =>
                  onFileDrop(
                    `versions[${index}].frontFile`,
                    `versions[${index}].frontLink`,
                    null
                  )
                }
                existingFile={frontFile?.path}
                noInfo={!values.versions[index].frontFileNew}
                disabled={formSaving}
              />
              <FormDropzoneField
                id={`versions[${index}].lensesFile`}
                name={`versions[${index}].lensesFile`}
                label={t("version.lensesFile")}
                acceptedExtension={FileExtension.PNG}
                handleDrop={(file) =>
                  onFileDrop(
                    `versions[${index}].lensesFile`,
                    `versions[${index}].lensesLink`,
                    file
                  )
                }
                handleDelete={() =>
                  onFileDrop(
                    `versions[${index}].lensesFile`,
                    `versions[${index}].lensesLink`,
                    null
                  )
                }
                existingFile={lensesFile?.path}
                noInfo={!values.versions[index].lensesFileNew}
                disabled={formSaving}
              />
              <FormDropzoneField
                id={`versions[${index}].leftFile`}
                name={`versions[${index}].leftFile`}
                label={t("version.leftFile")}
                acceptedExtension={FileExtension.PNG}
                handleDrop={(file) =>
                  onFileDrop(
                    `versions[${index}].leftFile`,
                    `versions[${index}].leftLink`,
                    file
                  )
                }
                handleDelete={() =>
                  onFileDrop(
                    `versions[${index}].leftFile`,
                    `versions[${index}].leftLink`,
                    null
                  )
                }
                existingFile={leftFile?.path}
                noInfo={!values.versions[index].leftFileNew}
                disabled={formSaving}
              />
              <FormDropzoneField
                id={`versions[${index}].rightFile`}
                name={`versions[${index}].rightFile`}
                label={t("version.rightFile")}
                acceptedExtension={FileExtension.PNG}
                handleDrop={(file) =>
                  onFileDrop(
                    `versions[${index}].rightFile`,
                    `versions[${index}].rightLink`,
                    file
                  )
                }
                handleDelete={() =>
                  onFileDrop(
                    `versions[${index}].rightFile`,
                    `versions[${index}].rightLink`,
                    null
                  )
                }
                existingFile={rightFile?.path}
                noInfo={!values.versions[index].rightFileNew}
                disabled={formSaving}
              />
              {window.innerWidth < 1240 && (
                <div style={{ color: "red" }}>
                  <b>Important!</b> Adding and Editing Wizard is unvailable on
                  tablet and mobile devices.
                </div>
              )}
            </>
          )}
          {!fromMarketplace && !fromOtherPartner && (
            <>
              {window.innerWidth >= 1240 && (
                <Button
                  onClick={startWizard}
                  disabled={formSaving || form.isSubmitting}
                >
                  {t("version.useWizard")}
                </Button>
              )}
              {!showFiles && (
                <Button
                  onClick={choosePreparedFiles}
                  disabled={formSaving || form.isSubmitting}
                >
                  {t("version.usePreparedFiles")}
                </Button>
              )}
              {showWizard && (
                <Wizard
                  onSave={onWizardFilesReady}
                  onBack={() => {
                    setShowFiles(false);
                    setShowWizard(false);
                  }}
                />
              )}
            </>
          )}
        </div>
        {(fromMarketplace || fromOtherPartner) && (
          <Row style={{ height: "25px" }} />
        )}
        {!fromOtherPartner && (
          <Row>
            <Col fullwidth="true">
              {!isAssigned && fromMarketplace ? (
                <Button
                  type="button"
                  onClick={() => {
                    if (assignHandler) assignHandler(values.versions[index]);
                  }}
                  color="info"
                  style={{ float: "right", marginTop: "0px" }}
                  disabled={formSaving || form.isSubmitting}
                >
                  {t("common:button.add")}
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={deleteVersion}
                  color="danger"
                  style={{ float: "right", marginTop: "0px" }}
                  disabled={formSaving || form.isSubmitting}
                >
                  {t("common:button.delete")}
                </Button>
              )}
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <label style={{ color: "red", float: "right" }}>
              {deleteError}
            </label>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = function ({ properties, partners, auth, products }) {
  return {
    propertyGroups: properties.get("propertyGroupList") ?? [],
    partner: partners.get("partner") ?? null,
    user: auth.get("user") ?? null,
    product: products.get("product"),
    fromMarketplace:
      products.get("product")?.from_main_partner &&
      !isMainPartner(partners.get("partner")),
    fromOtherPartner: products.get("product")?.from_other_partner,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showAlert }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product2dVersionForm);
