import React, { useState, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const CropImage = ({ src, modifications, onCropReady, imgRef, style }) => {
  const [crop, setCrop] = useState(modifications.crop);

  const { scale, rotation, position } = modifications;

  useEffect(() => {
    setCrop(modifications.crop);
  }, [modifications]);

  return (
    <div className="App" style={style}>
      <ReactCrop
        crop={crop}
        aspect={1}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        onComplete={onCropReady}
      >
        <img
          ref={imgRef}
          alt="Crop me"
          src={src}
          style={{
            transform: `scale(${scale / 1}) rotate(${
              rotation / 1
            }deg) translateY(${
              (position.y * (imgRef?.current?.height || 0)) / 1024
            }px) translateX(${
              (position.x * (imgRef?.current?.width || 0)) / 1024
            }px) `,
          }}
        />
      </ReactCrop>
    </div>
  );
};

export default CropImage;
