import React, { useEffect, useState } from "react";
import { Row, Col, CardSubtitle } from "reactstrap";
import { useFormikContext } from "formik";
import FileExtension from "constants/FileExtension";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import welcomeScreenGlasses from "assets/img/welcomeScreenTotemGlasses.png";
import welcomeScreenCredits from "assets/img/welcomeScreenTotemCredits.png";

import FormDropzoneField from "common/components/formFields/FormDropzoneField";

const TotemConfigurationWelcomeScreenForm = ({ onFileDrop }) => {
  const { values } = useFormikContext();
  const [backgroundColor, setBackgroundColor] = useState(null);
  const { t } = useTranslation("totems");

  useEffect(() => {
    setBackgroundColor(
      !values.backgroundColor
        ? "white"
        : `rgb(${values.backgroundColor?.r}, ${values.backgroundColor?.g}, ${values.backgroundColor?.b})`
    );
  }, [values.backgroundColor]);

  return (
    <Col className="totem-config-section">
      <Row>
        <CardSubtitle>
          <h5 className="totem-config-subtitle-text">
            {t("welcomeScreen.title")}
          </h5>
        </CardSubtitle>
      </Row>
      <Row>
        <Col sm="6" xs="12">
          <FormDropzoneField
            id={"topImageFile"}
            name={"topImageFile"}
            label={t("welcomeScreen.topImageFile")}
            acceptedExtension={FileExtension.PNG}
            handleDrop={(file) =>
              onFileDrop(`topImageFile`, `topImageLink`, file)
            }
            handleDelete={() =>
              onFileDrop(`topImageFile`, `topImageLink`, null)
            }
            existingFile={
              !_.isEmpty(values.topImageLink) && !values.topImageFileDeleted
                ? t("fileUploaded")
                : ""
            }
          />
          <FormDropzoneField
            id={"middleImageFile"}
            name={"middleImageFile"}
            label={t("welcomeScreen.middleImageFile")}
            acceptedExtension={FileExtension.PNG}
            handleDrop={(file) =>
              onFileDrop(`middleImageFile`, `middleImageLink`, file)
            }
            handleDelete={() =>
              onFileDrop(`middleImageFile`, `middleImageLink`, null)
            }
            existingFile={
              !_.isEmpty(values.middleImageLink) &&
              !values.middleImageFileDeleted
                ? t("fileUploaded")
                : ""
            }
          />
          <FormDropzoneField
            id={"bottomImageFile"}
            name={"bottomImageFile"}
            label={t("welcomeScreen.bottomImageFile")}
            acceptedExtension={FileExtension.PNG}
            handleDrop={(file) =>
              onFileDrop(`bottomImageFile`, `bottomImageLink`, file)
            }
            handleDelete={() =>
              onFileDrop(`bottomImageFile`, `bottomImageLink`, null)
            }
            existingFile={
              !_.isEmpty(values.bottomImageLink) &&
              !values.bottomImageFileDeleted
                ? t("fileUploaded")
                : ""
            }
          />
        </Col>
        <Col sm="6" xs="12">
          <div className="welcome-screen-container">
            <div
              style={{ height: "58px", backgroundColor: "#d1d1d1" }}
              className="welcome-screen-custom-preview"
            >
              {values.topImageLinkNew ||
              (values.topImageLink && !values.topImageFileDeleted) ? (
                <img
                  style={{ backgroundColor: backgroundColor }}
                  className="welcome-screen-preview"
                  src={
                    values.topImageLinkNew
                      ? values.topImageLinkNew
                      : values.topImageLink
                  }
                  alt=""
                  height="58px"
                  width="158px"
                />
              ) : (
                <p className="welcome-screen-custom-preview-text">1080x400</p>
              )}
            </div>
            <div
              style={{ height: "73px", backgroundColor: "#a9a9a9" }}
              className="welcome-screen-custom-preview"
            >
              {values.middleImageLinkNew ||
              (values.middleImageLink && !values.middleImageFileDeleted) ? (
                <img
                  style={{ backgroundColor: backgroundColor }}
                  className="welcome-screen-preview"
                  src={
                    values.middleImageLinkNew
                      ? values.middleImageLinkNew
                      : values.middleImageLink
                  }
                  alt=""
                  height="73px"
                  width="158px"
                />
              ) : (
                <p className="welcome-screen-custom-preview-text">1080x500</p>
              )}
            </div>
            <div
              style={{ height: "90px" }}
              className="welcome-screen-custom-preview"
            >
              <img
                className="welcome-screen-preview"
                src={welcomeScreenGlasses}
                alt=""
                height="90px"
                width="158px"
              />
            </div>
            <div
              style={{ height: "40px", backgroundColor: "#ececec" }}
              className="welcome-screen-custom-preview"
            >
              {values.bottomImageLinkNew ||
              (values.bottomImageLink && !values.bottomImageFileDeleted) ? (
                <img
                  style={{ backgroundColor: backgroundColor }}
                  className="welcome-screen-preview"
                  src={
                    values.bottomImageLinkNew
                      ? values.bottomImageLinkNew
                      : values.bottomImageLink
                  }
                  alt=""
                  height="40px"
                  width="158px"
                />
              ) : (
                <p className="welcome-screen-custom-preview-text">1080x270</p>
              )}
            </div>
            <div
              style={{ height: "19px" }}
              className="welcome-screen-custom-preview"
            >
              <img
                className="welcome-screen-preview"
                src={welcomeScreenCredits}
                alt=""
                height="19px"
                width="158px"
              />
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default TotemConfigurationWelcomeScreenForm;
