import React, { useState, useEffect } from "react";
import { Field, getIn } from "formik";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Button, Label } from "reactstrap";
import { useDropzone } from "react-dropzone";

const AcceptedFile = ({ filename, removeHandler, removeButton, disabled }) => {
  return (
    <div className="file-dropzone-list-container">
      {removeButton && filename && (
        <Button
          className="file-dropzone-list-button"
          onClick={removeHandler}
          disabled={disabled}
        >
          <i className="nc-icon nc-simple-remove" />
        </Button>
      )}
      <div className="file-dropzone-list">{filename}</div>
    </div>
  );
};

const FormDropzoneFieldInner = ({
  form,
  field,
  acceptedExtension,
  handleDrop,
  handleDelete,
  label,
  existingFile,
  removeButton = true,
  multi = false,
  disabled,
  noInfo = false,
}) => {
  const { t } = useTranslation();
  const [uploadedFiles, setUploadedFiles] = useState(
    existingFile ? [{ path: existingFile }] : []
  );
  const [error, setError] = useState({ file: "", code: "" });

  const onDrop = (files) => {
    if (_.isEmpty(files)) handleDelete();
    else handleDrop(files);
  };

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: acceptedExtension,
      multiple: multi,
      onDrop,
    });

  useEffect(() => {
    if (_.isEmpty(acceptedFiles) || !_.isEmpty(existingFile)) {
      setUploadedFiles(existingFile ? [{ path: existingFile }] : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingFile, acceptedFiles]);

  const getFilenamesLabel = (files) => {
    if (_.isEmpty(files)) return "";

    if (!multi) {
      return files[0].path;
    }
    if (files.length <= 3) {
      return files.map((f) => f.path).join(", ");
    }
    return `${files
      .slice(0, 3)
      .map((f) => f.path)
      .join(", ")} ${t("common:dropzone.andOthers", {
      count: files.length - 3,
    })}`;
  };

  useEffect(() => {
    setError({ file: "", code: "" });
    if (!_.isEmpty(acceptedFiles)) {
      setUploadedFiles((uploaded) => {
        if (uploaded?.length === 1 && !(uploaded[0] instanceof File)) {
          return [...acceptedFiles];
        }
        return [...uploaded, ...acceptedFiles];
      });
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (!_.isEmpty(fileRejections)) {
      setError({
        code: fileRejections[0].errors[0].code,
        file: fileRejections[0].file.name,
      });
    }
  }, [fileRejections]);

  const removeHandler = () => {
    setUploadedFiles([]);
    onDrop([]);
  };

  const formError = getIn(form.errors, field.name) || form.errors[field.name];
  const fieldTouched = getIn(form.touched, field.name);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }}>
        <Label>{label}</Label>
        <div
          {...getRootProps({ className: "file-dropzone" })}
          disabled={form.isSubmitting || disabled}
        >
          <input name={field.name} {...getInputProps()} />
          <div className="file-dropzone-text">
            {t("common:dropzone.mainText")}
          </div>
        </div>
        <div className="file-dropzone-error">
          {error.code && (
            <label style={{ color: "red" }}>
              {error.file + ": " + t(`common:dropzone.errors.${error.code}`)}
            </label>
          )}
          {(form.submitCount > 0 || fieldTouched) && formError && (
            <label style={{ color: "red", minHeight: "30px" }}>
              {t(formError)}
            </label>
          )}
        </div>
        {((form.submitCount === 0 && !fieldTouched) || !formError) && (
          <AcceptedFile
            filename={getFilenamesLabel(noInfo ? [] : uploadedFiles)}
            removeHandler={removeHandler}
            removeButton={removeButton}
            disabled={form.isSubmitting || disabled}
          />
        )}
      </div>
    </div>
  );
};

const FormDropzoneField = (props) => {
  return <Field {...props} component={FormDropzoneFieldInner} />;
};

export default FormDropzoneField;
