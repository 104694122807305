import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CommonTable from "common/components/CommonTable";
import { Card, CardHeader, CardBody, CardTitle, Col, Button } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { addBrand, deleteBrand, editBrand, getBrands } from "./BrandActions";
import BrandTableRow from "./BrandTableRow";
import BrandPopup from "./BrandPopup";

const BrandList = ({
  partner,
  brands,
  getBrands,
  addBrand,
  editBrand,
  deleteBrand,
}) => {
  const { t } = useTranslation("brands");
  const [brandData, setBrandData] = useState({ elements: [] });
  const ref = useRef();
  const [brandPopup, setBrandPopup] = useState({
    link: null,
    show: false,
    new: null,
  });
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    link: null,
  });

  useEffect(() => {
    ref.current.scrollIntoView({ block: "end" });
  }, []);

  useEffect(() => {
    getBrands();
  }, [getBrands, partner]);

  useEffect(() => {
    setBrandData({ elements: brands });
  }, [brands]);

  const addBrandHandler = (brandName, form) => {
    addBrand(brandName, form).then((id) => {
      if (!id) return;
      getBrands();
      setBrandPopup({ brand: null, show: false, new: null });
    });
  };

  const editBrandHandler = (id, brandName, form) => {
    editBrand(id, brandName, form).then((ok) => {
      if (!ok) return;
      getBrands();
      setBrandPopup({ brand: null, show: false, new: null });
    });
  };

  const onDeleteConfirmed = () => {
    const brand = deleteAlert.brand;
    deleteBrand(brand.id).then((ok) => {
      if (!ok) return;
      getBrands();
      setDeleteAlert({ show: false, brand: null });
    });
  };

  const headers = [
    {
      label: t("number"),
    },
    {
      label: t("name"),
    },
    {
      label: t("actions"),
      className: "text-right",
    },
  ];

  return (
    <div className="content" ref={ref}>
      {brandPopup?.show && (
        <BrandPopup
          brand={brandPopup.brand}
          newBrand={brandPopup.new}
          addHandler={addBrandHandler}
          editHandler={editBrandHandler}
          cancelHandler={() =>
            setBrandPopup({ brand: null, show: false, new: null })
          }
        />
      )}
      {deleteAlert.show && (
        <ReactBSAlert
          warning
          showCancel
          cancelBtnText={t("common:button.cancel")}
          confirmBtnText={t("common:button.delete")}
          confirmBtnBsStyle="danger"
          title={t("deleteAlertTitle")}
          onConfirm={onDeleteConfirmed}
          onCancel={() => setDeleteAlert({ show: false, group: null })}
          focusCancelBtn
        />
      )}
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("title")}</h4>
              <Button
                className="btn btn-info"
                onClick={() =>
                  setBrandPopup({ brand: {}, show: true, new: true })
                }
              >
                {t("common:button.add")}
              </Button>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CommonTable
              headers={headers}
              data={brandData}
              pagination={false}
              rowComponent={BrandTableRow}
              offset={0}
              total={brands?.length || 0}
              rowComponentProps={{
                editHandler: (brand) =>
                  setBrandPopup({ show: true, brand: brand, new: false }),
                deleteHandler: (brand) =>
                  setDeleteAlert({ show: true, brand: brand }),
                isMainPartner: partner?.main_partner,
              }}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ brands, partners }) => {
  return {
    brands: brands.get("brandList") ?? [],
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBrands,
      addBrand,
      editBrand,
      deleteBrand,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BrandList);
