import React from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { categoriesOptions } from "constants/SearchOptions";
import { statusOptions } from "constants/SearchOptions";
import { ownershipOptions } from "constants/SearchOptions";
import ProductSearchForm from "./ProductSearchForm";
import { useState } from "react";
import { useEffect } from "react";

const ProductSearch = ({ onSearchCallback }) => {
  const { t, i18n } = useTranslation("product");
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({
      productName: "",
      category: { label: t("search.all"), value: categoriesOptions.ALL },
      status: { label: t("search.all"), value: statusOptions.ALL },
      owner: { label: t("search.all"), value: ownershipOptions.ALL },
    });
    i18n.on("languageChanged", () => {
      setInitialValues({
        productName: "",
        category: { label: t("search.all"), value: categoriesOptions.ALL },
        status: { label: t("search.all"), value: statusOptions.ALL },
        owner: { label: t("search.all"), value: ownershipOptions.ALL },
      });
    });
    return () => {
      i18n.off("languageChanged", () => {});
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values, { setSubmitting }) => {
    onSearchCallback({
      productNameSearch: values.productName,
      categorySearch:
        values.category.value === categoriesOptions.ALL
          ? null
          : values.category.value,
      publishedSearch:
        values.status.value === statusOptions.ALL
          ? null
          : values.status.value === statusOptions.PUBLISHED,
      otherPartnerSearch:
        values.owner.value === ownershipOptions.ALL
          ? null
          : values.owner.value === ownershipOptions.OTHER_PARTNER,
      offset: 0,
    });
    setSubmitting(false);
  };

  return (
    <Row>
      <Col md="12">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => {
            return <ProductSearchForm />;
          }}
        </Formik>
      </Col>
    </Row>
  );
};

export default ProductSearch;
