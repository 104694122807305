export const setingsFields = {
  productName: {
    id: "productName",
    label: "name",
  },
  productAspectRatioAndroid: {
    id: "productAspectRatioAndroid",
    label: "aspectRatioAndroid",
  },
  productAspectRatioIos: {
    id: "productAspectRatioIos",
    label: "aspectRatioIos",
  },
  productAspectRatioWeb: {
    id: "productAspectRatioWeb",
    label: "aspectRatioWeb",
  },
  productPositionYAndroid: {
    id: "productPositionYAndroid",
    label: "positionYAndroid",
  },
  productPositionYIos: {
    id: "productPositionYIos",
    label: "positionYIos",
  },
  productPositionYWeb: {
    id: "productPositionYWeb",
    label: "positionYWeb",
  },
  productPositionZAndroid: {
    id: "productPositionZAndroid",
    label: "positionZAndroid",
  },
  productPositionZIos: {
    id: "productPositionZIos",
    label: "positionZIos",
  },
  productPositionZWeb: {
    id: "productPositionZWeb",
    label: "positionZWeb",
  },
  productRotationAndroid: {
    id: "productRotationAndroid",
    label: "rotationAndroid",
  },
  productRotationIos: {
    id: "productRotationIos",
    label: "rotationIos",
  },
  productRotationWeb: {
    id: "productRotationWeb",
    label: "rotationWeb",
  },
  productEarRotationWeb: {
    id: "productEarRotationWeb",
    label: "earRotationWeb",
  },
  productModelFileFbxUploaded: {
    id: "productModelFileFbxUploaded",
    label: "modelFileFbx",
    component: "FormCheckboxField",
  },
  productModelFileUploaded: {
    id: "productModelFileUploaded",
    label: "modelFileObj",
    component: "FormCheckboxField",
  },
  productPublished: {
    id: "productPublished",
    label: "published",
    component: "FormCheckboxField",
  },
  productHasNonstandardGlass: {
    id: "productHasNonstandardGlass",
    label: "hasNonstandardGlassLog",
    component: "FormCheckboxField",
  },
  productFilesZipLink: {
    id: "productFilesZipLink",
    label: null,
    name: "messages.filesZipLinkUpdateSuccess",
  },
  productPhotosZipLink: {
    id: "productPhotosZipLink",
    label: null,
    name: "messages.photosZipLinkUpdateSuccess",
  },
  productBrand: {
    id: "productBrand.brand_name",
    label: "brand",
  },
  productCategory: {
    id: "productCategory.category_name",
    label: "category",
  },
  versionName: {
    id: "versionName",
    label: "version.name",
  },
  versionCode: {
    id: "versionCode",
    label: "version.code",
  },
  versionOrder: {
    id: "versionOrder",
    label: "version.order",
  },
  versionPrice: {
    id: "versionPrice",
    label: "version.price",
    suffix: "partner_currency",
  },
  versionPublished: {
    id: "versionPublished",
    label: "version.published",
    component: "FormCheckboxField",
  },
  versionShopUrl: {
    id: "versionShopUrl",
    label: "version.pageLink",
  },
  versionSku: {
    id: "versionSku",
    label: "version.sku",
  },
  versionSpecialPrice: {
    id: "versionSpecialPrice",
    label: "version.specialPrice",
    suffix: "partner_currency",
  },
  versionTextureAlbedoUploaded: {
    id: "versionTextureAlbedoUploaded",
    label: "version.albedoFile",
  },
  versionTextureMetallicUploaded: {
    id: "versionTextureMetallicUploaded",
    label: "modelFileFbx",
  },
  versionTextureNormalUploaded: {
    id: "versionTextureNormalUploaded",
    label: "version.normalFile",
  },
  versionThumbLink: {
    id: "versionThumbLink",
    label: "version.thumb",
  },
  versionThumbSmallLink: {
    id: "versionThumbSmallLink",
    label: "version.thumb",
  },
  versionFrontFile: {
    id: "versionFrontFile",
    label: "version.frontFile",
  },
  versionLensesFile: {
    id: "versionLensesFile",
    label: "version.lensesFile",
  },
  versionLeftFile: {
    id: "versionLeftFile",
    label: "version.leftFile",
  },
  versionRightFile: {
    id: "versionRightFile",
    label: "version.rightFile",
  },
  productUseMetallicGlass: {
    id: "productUseMetallicGlass",
    label: "hasMetallicGlassLog",
    component: "FormCheckboxField",
  },
  productType: {
    id: "productType.type_name",
    label: "type",
  },
  property: {
    id: "property.value.label",
    label: "property_",
  },
};
