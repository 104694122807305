import React from "react";
import { Button } from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import FormInputField from "common/components/formFields/FormInputField";
import { useTranslation } from "react-i18next";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { showAlert } from "common/components/alert/AlertActions";
import AlertVariant from "common/components/alert/AlertVariant";
import { updateMinVersion } from "./ApplicationsActions";

const AppsMinVersionForm = ({ minVersion, updateMinVersion, showAlert }) => {
  const { t } = useTranslation("apps");

  const onSubmit = (values, form) => {
    updateMinVersion(values.minVersion).then((ok) => {
      if (ok) {
        showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
      }
      form.setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={{
        minVersion,
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form noValidate>
          <Row>
            <Col md={6}>
              <Field
                required
                id="minVersion"
                type="text"
                name="minVersion"
                label={t("minVersion")}
                component={FormInputField}
              />
            </Col>
            <Col md={6} style={{ alignItems: "center", display: "flex" }}>
              <Button type="submit" color="success">
                {t("common:button.save")}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMinVersion,
      showAlert,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AppsMinVersionForm);
