import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import AlertVariant from "common/components/alert/AlertVariant";
import { showAlert } from "common/components/alert/AlertActions";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { bindActionCreators } from "redux";
import { getTotem, getTotems, copyTotem } from "./TotemsActions";
import connect from "react-redux/es/connect/connect";
import { Formik, Form, Field } from "formik";
import FormSelectField from "common/components/formFields/FormSelectField";
import { totemsToSelect } from "common/utils/TotemUtils";

const CopyTotemPopup = ({
  totems,
  currentTotem,
  partner,
  copyTotem,
  cancelHandler,
  showAlert,
  getTotems,
  getTotem,
}) => {
  const { t } = useTranslation("totems");
  const [totemOptions, setTotemOptions] = useState([]);

  useEffect(() => {
    getTotems();
  }, [getTotems, partner]);

  useEffect(() => {
    setTotemOptions(totemsToSelect(totems));
  }, [totems]);

  const onSubmit = (values) => {
    copyTotem(values.totem.value, currentTotem.totem_id).then((ok) => {
      if (ok) {
        showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
        getTotem(currentTotem.totem_id);
      }
      cancelHandler();
    });
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={{
        totem: { label: "", value: null },
      }}
    >
      {() => (
        <Form noValidate>
          <ReactBSAlert
            style={{ display: "block", width: "600px" }}
            title={t("copySettings")}
            onCancel={cancelHandler}
            onConfirm={onSubmit}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            showCancel
            btnSize=""
            customButtons={
              <>
                <Button type="button" onClick={cancelHandler} color="danger">
                  {t("common:button.cancel")}
                </Button>
                <Button type="submit" color="success">
                  {t("common:button.save")}
                </Button>
              </>
            }
          >
            <div style={{ marginLeft: 30, marginRight: 30 }}>
              {t("copyInfo")}
            </div>
            <div
              style={{
                marginLeft: 30,
                marginRight: 30,
                marginBottom: 30,
                color: "red",
              }}
            >
              {t("copyWarning")}
            </div>
            <Field
              required
              fullWidth
              id="totem"
              name="totem"
              label={"Totem"}
              options={totemOptions}
              component={FormSelectField}
            />
          </ReactBSAlert>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ totems, partners }) => {
  return {
    totems: totems.get("totemList") ?? [],
    partner: partners.get("partner") ?? null,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      copyTotem,
      showAlert,
      getTotems,
      getTotem,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CopyTotemPopup);
