import React from "react";
import { NavLink } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "auth/AuthActions";
import { showAlert } from "common/components/alert/AlertActions";

const LogoutButton = ({ className, logout, showAlert, ...props }) => {
  const signOut = () => {
    logout();
  };

  return (
    <NavLink className={className} onClick={signOut}>
      {props.children}
    </NavLink>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout, showAlert }, dispatch);

export default connect(null, mapDispatchToProps)(LogoutButton);
