import { fromJS } from "immutable";

export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_IMPORT_DATA_SUCCESS = "GET_IMPORT_DATA_SUCCESS";
export const UPDATE_IMPORT_DATA_SUCCESS = "UPDATE_IMPORT_DATA_SUCCESS";
export const GET_VISUAL_REPORT_SUCCESS = "GET_VISUAL_REPORT_SUCCESS";

const initialData = fromJS({
  report: null,
  visualReport: null,
  importData: null,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_REPORT_SUCCESS:
      return state.merge({
        report: action.payload.report,
      });
    case GET_VISUAL_REPORT_SUCCESS:
      return state.merge({
        visualReport: action.payload.importData,
      });
    case GET_IMPORT_DATA_SUCCESS:
      return state.merge({
        importData: action.payload.importData,
      });
    case UPDATE_IMPORT_DATA_SUCCESS:
      return state.merge({
        importData: action.payload.importData,
      });
    default:
      return state;
  }
};
