/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import RouterPaths from "constants/RouterPaths";
import ExtendedUserRoles from "constants/ExtendedUserRoles";
import i18n from "i18n/i18n";
import SettingsLabels from "constants/SettingsLabels";

const sidebarRoutes = () => [
  {
    path: RouterPaths.ANALYTICS_DASHBOARD,
    name: i18n.t("analyticsDashboard:title"),
    icon: "nc-icon nc-chart-bar-32",
    permission: ExtendedUserRoles.USER,
    requiredSettings: [
      [SettingsLabels.MOBILE_ACTIVE, SettingsLabels.WEB_ACTIVE],
      [SettingsLabels.ANALYTICS_DASHBOARD_ACTIVE],
    ],
    disabledSettings: [],
  },
  {
    path: RouterPaths.PRODUCTS,
    name: i18n.t("product:title"),
    icon: "nc-icon nc-shop",
    permission: ExtendedUserRoles.USER,
    requiredSettings: [],
    disabledSettings: [],
  },
  {
    path: RouterPaths.MARKETPLACE,
    name: i18n.t("marketplace:title"),
    icon: "nc-icon nc-shop",
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.MARKETPLACE_VISIBLE]],
    disabledSettings: [],
  },
  {
    path: RouterPaths.CATEGORIES,
    name: i18n.t("category:title"),
    icon: "nc-icon nc-bullet-list-67",
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.CATEGORIES_VISIBLE]],
    disabledSettings: [],
  },
  {
    path: RouterPaths.PROPERTIES,
    name: i18n.t("property:title"),
    icon: "nc-icon nc-tag-content",
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.MOBILE_ACTIVE]],
    disabledSettings: [],
  },
  {
    path: RouterPaths.LINKS,
    name: i18n.t("links:sidebar"),
    icon: "nc-icon nc-globe",
    permission: ExtendedUserRoles.USER,
    requiredSettings: [
      [SettingsLabels.WEB_ACTIVE, SettingsLabels.QRCODES_ACTIVE],
    ],
    disabledSettings: [],
  },
  {
    path: RouterPaths.BRANDS,
    name: i18n.t("brands:title"),
    icon: "nc-icon nc-box",
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.MOBILE_ACTIVE]],
    disabledSettings: [],
  },
  {
    path: RouterPaths.NOTIFICATIONS,
    name: i18n.t("notifications:title"),
    icon: "nc-icon nc-bell-55",
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.NOTIFICATIONS_ACTIVE]],
    disabledSettings: [],
  },
  {
    path: RouterPaths.TOTEMS,
    name: i18n.t("totems:title"),
    icon: "nc-icon nc-tv-2",
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.TOTEM_ACTIVE]],
    disabledSettings: [],
  },
  {
    path: RouterPaths.AUTOCUT,
    name: i18n.t("autocut:title"),
    icon: "nc-icon nc-scissors",
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.AUTOCUT_ACTIVE]],
    disabledSettings: [],
  },
  {
    path: RouterPaths.USERS,
    name: i18n.t("user:title"),
    icon: "nc-icon nc-badge",
    permission: ExtendedUserRoles.ADMIN,
    requiredSettings: [],
    disabledSettings: [],
  },
  {
    path: RouterPaths.SETTINGS,
    name: i18n.t("settings:title"),
    icon: "nc-icon nc-settings-gear-65",
    permission: ExtendedUserRoles.USER,
    requiredSettings: [],
    disabledSettings: [],
  },
  {
    path: RouterPaths.ADD_PARTNER,
    name: i18n.t("addPartner:title"),
    icon: "nc-icon nc-single-02",
    permission: ExtendedUserRoles.SUPERADMIN,
    requiredSettings: [],
    disabledSettings: [],
  },
  {
    path: RouterPaths.APPS,
    name: i18n.t("apps:sidebar"),
    icon: "nc-icon nc-app",
    permission: ExtendedUserRoles.SUPERADMIN,
    requiredSettings: [],
    disabledSettings: [],
  },
];

export default sidebarRoutes;
