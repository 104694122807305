import React from "react";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";

const ProductList = ({ productList, title }) => {
  return (
    <Row>
      <Col>
        <h2 className="product-list-heading">{title}</h2>
        <ListGroup>
          {productList.map((product) => {
            return (
              <ListGroupItem className="product-list-item">
                {product}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </Col>
    </Row>
  );
};

export default ProductList;
