const EditorTools = {
  SMART_PLUS: "SMART_PLUS",
  SMART_MINUS: "SMART_MINUS",
  PLUS: "PLUS",
  MINUS: "MINUS",
  ERASER: "ERASER",
};

const EditMode = {
  NONE: "NONE",
  FRAME: "FRAME",
  FRAME_WITH_LENSES: "FRAME_WITH_LENSES",
};

const EditorToolBrushes = {
  SMART_PLUS: {
    color: "#17ad53",
    operation: "source-over",
  },
  SMART_MINUS: {
    color: "#a10f0d",
    operation: "source-over",
  },
  PLUS: {
    color: "#60e841",
    operation: "source-over",
  },
  MINUS: {
    color: "#ff2f0f",
    operation: "source-over",
  },
  ERASER: {
    color: "#b3b3b3",
    operation: "destination-out",
  },
};

const TO_RADIANS = Math.PI / 180;

export { EditMode, EditorToolBrushes, EditorTools, TO_RADIANS };
