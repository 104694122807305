import { fromJS } from "immutable";

export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const MOVE_PRODUCT_TO_MAIN_PARTNER_SUCCESS =
  "MOVE_PRODUCT_TO_MAIN_PARTNER_SUCCESS";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const GET_PRODUCT_CATEGORIES_SUCCESS = "GET_PRODUCT_CATEGORIES_SUCCESS";
export const GET_CATEGORY_PROPERTY_GROUPS_SUCCESS =
  "GET_CATEGORY_PROPERTY_GROUPS_SUCCESS";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_PRODUCT_LOG_SUCCESS = "GET_PRODUCTS_LOGS_SUCCESS";

const initialData = fromJS({
  categoryList: null,
  productList: null,
  productLog: null,
  allProductList: null,
  propertyGroupList: null,
  meta: null,
  product: null,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return state.merge({
        productList: action.payload.products,
        meta: action.payload.meta,
        product: null,
        propertyGroupList: [],
      });
    case GET_ALL_PRODUCT_SUCCESS:
      return state.merge({
        allProductList: action.payload.products,
      });
    case GET_PRODUCT_SUCCESS:
      return state.merge({
        product: action.payload.product,
      });
    case GET_PRODUCT_CATEGORIES_SUCCESS:
      return state.merge({
        categoryList: action.payload.categoryList,
      });
    case GET_CATEGORY_PROPERTY_GROUPS_SUCCESS:
      return state.merge({
        propertyGroupList: action.payload.propertyGroups,
      });
    case GET_PRODUCT_LOG_SUCCESS:
      return state.merge({
        productLog: action.payload.productLog,
      });
    default:
      return state;
  }
};
