import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import CommonTable from "common/components/CommonTable";
import { Card, CardHeader, CardBody, CardTitle, Col } from "reactstrap";
import { Link } from "react-router-dom";
import RouterPaths from "constants/RouterPaths";
import UserTableRow from "./UserTableRow";
import { getUsers } from "./UserActions";

const USER_PAGE_LIMIT = 20;

const UserList = ({ users, usersMeta, partner, getUsers }) => {
  const { t } = useTranslation("user");
  const [usersData, setUsersData] = useState({ elements: [] });
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    getUsers(offset, USER_PAGE_LIMIT);
  }, [getUsers, offset, partner]);

  useEffect(() => {
    setUsersData({ elements: users });
  }, [users]);

  const headers = [
    {
      label: t("number"),
    },
    {
      label: t("email"),
    },
    {
      label: t("status"),
    },
    {
      label: t("role"),
    },
    {
      label: t("actions"),
      className: "text-right",
    },
  ];

  return (
    <div className="content">
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("title")}</h4>
              <Link to={RouterPaths.ADD_USER} className="btn btn-info">
                {t("common:button.add")}
              </Link>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CommonTable
              headers={headers}
              data={usersData}
              rowComponent={UserTableRow}
              offset={offset}
              onOffsetChange={setOffset}
              total={Number(usersMeta.count) ?? 0}
              pageSize={USER_PAGE_LIMIT}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ users, partners }) => {
  return {
    users: users.get("userList") ?? [],
    usersMeta: users.get("meta"),
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUsers }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
