import React from "react";
import PropTypes from "prop-types";
import Button from "reactstrap/lib/Button";
import { useTranslation } from "react-i18next";

const TotemVersionTableRow = ({
  item,
  deleteVersionHandler,
  changeActiveVersion,
}) => {
  const { t } = useTranslation("totems");
  const tdWidth = `calc(50% - 115px)`;
  return (
    <>
      <td style={{ width: 130, minWidth: 130 }}>
        {item.product_thumb_link ? (
          <img alt="thumb" src={item.product_thumb_link} height={40} />
        ) : (
          t("notAdded")
        )}
      </td>
      <td style={{ width: tdWidth }}>
        <div>{item.version_name}</div>
        {item.version_name_owner !== item.version_name && (
          <small>{item.version_name_owner}</small>
        )}
      </td>
      <td style={{ width: tdWidth }}>
        <div>{item.product_name}</div>
        {item.product_name_owner !== item.product_name && (
          <small>{item.product_name_owner}</small>
        )}
      </td>
      <td style={{ width: 150 }}>
        <Button
          type="button"
          className={`btn-sm  ${
            item?.version_active ? "btn-success" : "btn-neutral"
          }`}
          style={{
            width: "116px",
            background: item?.version_active ? "#6bd098" : "#e4e4e4",
          }}
          onClick={() => changeActiveVersion(item)}
        >
          {t(`statusOptions.${item?.version_active}`)}
        </Button>
      </td>
      <td className="text-center" style={{ width: 50, minWidth: 50 }}>
        <Button
          type="button"
          className="btn btn-danger icon-button"
          onClick={() => deleteVersionHandler(item)}
        >
          <i className="nc-icon nc-simple-remove" />
        </Button>
      </td>
    </>
  );
};

TotemVersionTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TotemVersionTableRow;
