import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import AlertVariant from "common/components/alert/AlertVariant";
import { showAlert } from "common/components/alert/AlertActions";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { bindActionCreators } from "redux";
import { addTotemVersion } from "../TotemsActions";
import connect from "react-redux/es/connect/connect";
import validationSchema from "./TotemVersionForm.schema";
import { Formik, Form, Field } from "formik";
import FormSelectField from "common/components/formFields/FormSelectField";
import { productsToSelect } from "common/utils/ProductUtils";
import { getAllProductsWithVersions } from "components/products/ProductActions";

const AddTotemVersionPopup = ({
  totem,
  products,
  partner,
  cancelHandler,
  addTotemVersion,
  getAllProductsWithVersions,
  showAlert,
}) => {
  const { t } = useTranslation("totems");
  const [productOptions, setProductOptions] = useState([]);
  const [versionOptions, setVersionOptions] = useState([]);

  useEffect(() => {
    getAllProductsWithVersions();
  }, [getAllProductsWithVersions, partner]);

  useEffect(() => {
    const productList = productsToSelect(products);
    setProductOptions(productList);
  }, [products]);

  const onSubmit = (values, form) => {
    const data = {
      totem_id: totem,
      version_id: values.version.value,
    };

    addTotemVersion(data, form).then((formCorrect) => {
      if (formCorrect) {
        showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
      }
    });
    cancelHandler();
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        version: { label: "", value: null },
        product: { label: "", value: null },
      }}
    >
      {(form) => (
        <Form noValidate className="totem-version-form">
          <ReactBSAlert
            style={{ display: "block", width: "500px" }}
            title={t("totem")}
            onCancel={cancelHandler}
            onConfirm={onSubmit}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            showCancel
            btnSize=""
            customButtons={
              <>
                <Button type="button" onClick={cancelHandler} color="danger">
                  {t("common:button.cancel")}
                </Button>
                <Button type="submit" color="success">
                  {t("common:button.save")}
                </Button>
              </>
            }
          >
            <Field
              required
              fullWidth
              id="product"
              name="product"
              label={t("productName")}
              options={productOptions}
              onChangeHandler={(option) => {
                form.setFieldValue("version", { label: "", value: "" });
                setVersionOptions(option.versions);
              }}
              component={FormSelectField}
            />
            <Field
              required
              fullWidth
              id="version"
              name="version"
              label={t("versionName")}
              options={versionOptions}
              component={FormSelectField}
            />
          </ReactBSAlert>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ products, partners }) => {
  return {
    products: products.get("allProductList") ?? [],
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addTotemVersion,
      getAllProductsWithVersions,
      showAlert,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTotemVersionPopup);
