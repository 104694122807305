import React, { useEffect, useState, useRef, useCallback } from "react";
import { Col } from "reactstrap";
import io from "socket.io-client";
import { REACT_APP_EDITOR_BACKEND_WS_URL } from "constants/env";
import { useTranslation } from "react-i18next";
import EditorCanvas from "./EditorCanvas";

const Editor = ({
  inputFile,
  maskFile,
  onBack,
  onNext,
  nextText,
  hintText,
  photoTitle,
}) => {
  const { t } = useTranslation("wizard");

  const [isFirstConnection, setIsFirstConnection] = useState(true);
  const [mask, setMask] = useState(maskFile);
  const [maskHistory, setMaskHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const maskRef = React.useRef(mask);
  const socketRef = useRef();

  useEffect(() => {
    maskRef.current = mask;
  });

  // Socket functions
  const onInitFinished = useCallback(
    (response) => {
      if (response?.error) {
        onBack();
        return;
      }
      if (response?.data) {
        setMask({ src: response.data });
        setMaskHistory([{ src: response.data }]);
        setIsFirstConnection(false);
      }
      setLoading(false);
    },
    [onBack]
  );

  const onEditFinished = (response, save = false) => {
    if (response?.error) return;
    if (response?.data) {
      setMask({ src: response.data });
      if (save) {
        setMaskHistory((masks) => {
          masks.push({ src: response.data });
          return masks.slice(-5);
        });
      }
    }
  };

  const init = useCallback(() => {
    setLoading(true);
    if (!maskRef.current?.src) {
      socketRef.current.emit("init", inputFile.src, onInitFinished);
    } else {
      socketRef.current.emit(
        "reconnect",
        inputFile.src,
        maskRef.current.src,
        isFirstConnection,
        onInitFinished
      );
    }
  }, [inputFile.src, onInitFinished, isFirstConnection]);

  const edit = useCallback((x, y, brushRadius, tool, save = false) => {
    socketRef.current.emit("edit", x, y, brushRadius, tool, (res) =>
      onEditFinished(res, save)
    );
  }, []);

  const undo = useCallback(() => {
    setMaskHistory((masks) => {
      if (masks.length > 1) {
        masks.pop();
        const previous = masks.slice(-1)[0].src;
        socketRef.current.emit("undo", previous, onEditFinished);
      }
      return masks;
    });
  }, []);

  const onSocketConnected = useCallback(() => {
    if (!inputFile?.src) return;
    init();
  }, [init, inputFile]);

  const onSocketDisconnected = () => {
    setLoading(true);
  };

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(REACT_APP_EDITOR_BACKEND_WS_URL);
    }
    socketRef.current.on("connect", onSocketConnected);
    socketRef.current.on("disconnect", onSocketDisconnected);
    return () => {
      socketRef.current.off("connect");
      socketRef.current.off("disconnect");
    };
  }, [onSocketConnected]);

  useEffect(() => {
    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  // End of socket functions

  return (
    <div className="editor-container">
      <Col md="12" style={{ height: "100%" }}>
        <div className="editor-header">
          <button type="button" className="editor-back-button" onClick={onBack}>
            {t("common:button.back")}
          </button>
          <button
            type="button"
            className="editor-next-button"
            onClick={() => onNext(maskRef.current.src)}
          >
            {nextText}
          </button>
        </div>
        <EditorCanvas
          onEdit={edit}
          onUndo={undo}
          isUndoPossible={maskHistory.length > 1}
          backgroundImg={inputFile?.src}
          maskImg={mask?.src}
          loading={loading}
          hintText={hintText}
          photoTitle={photoTitle}
        />
      </Col>
    </div>
  );
};

export default Editor;
