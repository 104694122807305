import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Formik, Form, Field } from "formik";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import FormSelectField from "common/components/formFields/FormSelectField";
import { productsToSelect } from "common/utils/ProductUtils";
import FormInputField from "common/components/formFields/FormInputField";
import validationSchema from "./LinkForm.schema";

const LinkPopup = ({
  link,
  newLink,
  addHandler,
  editHandler,
  cancelHandler,
  products,
  domainOptions,
}) => {
  const { t } = useTranslation("links");
  const [productOptions, setProductOptions] = useState([]);
  const [versionOptions, setVersionOptions] = useState([]);
  const [fbxInfo, setFbxInfo] = useState();

  const checkFbx = (id) => {
    const product = products.find((p) => p.id === id);
    if (product?.options?.fbx === false && product?.options?.["2d"] === false) {
      setFbxInfo(t("fbxDoesNotExist"));
    } else {
      setFbxInfo();
    }
  };

  useEffect(() => {
    const productList = productsToSelect(products);
    setProductOptions(productList);
    if (link.product_id) {
      const product = productList.find((p) => p.value === link.product_id);
      if (product) {
        setVersionOptions(product.versions);
        checkFbx(product.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, link]);

  const onSubmit = (values, form) => {
    if (newLink) {
      addHandler(
        form,
        values.version.value,
        values.domain.value,
        values.webLinkUrl?.trim()
      );
    } else {
      editHandler(
        form,
        link.web_link_id,
        values.version.value,
        values.domain.value,
        values.webLinkUrl?.trim()
      );
    }
    form.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        version: {
          label: link.partners_version_name ?? "",
          value: link.partners_version_id ?? "",
        },
        product: {
          label: link.product_name ?? "",
          value: link.product_id ?? "",
        },
        domain: { label: link.domain_name ?? "", value: link.domain_id ?? "" },
        webLinkUrl: link.web_link_url,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(form) => (
        <Form noValidate className="category-form">
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={newLink ? t("addLinkTitle") : t("editLinkTitle")}
            onConfirm={onSubmit}
            onCancel={cancelHandler}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            showCancel
            btnSize=""
            customButtons={
              <>
                <Button type="button" onClick={cancelHandler} color="danger">
                  {t("common:button.cancel")}
                </Button>
                <Button type="submit" color="success">
                  {t("common:button.save")}
                </Button>
              </>
            }
          >
            <Field
              required
              fullWidth
              id="product"
              name="product"
              label={t("product")}
              options={productOptions}
              onChangeHandler={(option) => {
                form.setFieldValue("version", { label: "", value: "" });
                setVersionOptions(option.versions);
                checkFbx(option.value);
              }}
              component={FormSelectField}
            />
            <Field
              required
              fullWidth
              id="version"
              name="version"
              label={t("version")}
              options={versionOptions}
              component={FormSelectField}
            />
            <Field
              required
              fullWidth
              id="domain"
              name="domain"
              label={t("domain")}
              options={domainOptions}
              component={FormSelectField}
            />
            <Field
              required
              fullWidth
              id="webLinkUrl"
              type="text"
              name="webLinkUrl"
              label={t("webLinkUrl")}
              component={FormInputField}
            />
            {fbxInfo && <div className="warning-label">{fbxInfo}</div>}
          </ReactBSAlert>
        </Form>
      )}
    </Formik>
  );
};

export default LinkPopup;
