export const currencies = {
  PLN: "ZŁ",
  USD: "$",
  EUR: "€",
  GBP: "£",
};

export const isPrefix = {
  ZŁ: false,
  $: true,
  "€": false,
  "£": true,
};
