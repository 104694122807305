import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import { GET_BRANDS_SUCCESS } from "./BrandReducer";

export const getBrands = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.BRANDS)
    .then((res) => {
      dispatch({
        type: GET_BRANDS_SUCCESS,
        payload: {
          brands: res?.data?.body,
        },
      });
    })
    .catch((err) => dispatch(handleError(err)));
};

export const addBrand = (brandName, form) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.BRANDS, { brandName })
    .then((res) => {
      return res?.data?.id;
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const editBrand = (id, brandName, form) => (dispatch) => {
  return apiClient
    .put(`${ApiEndpoints.BRANDS}${id}`, { brandName })
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const deleteBrand = (id) => (dispatch) => {
  return apiClient
    .delete(`${ApiEndpoints.BRANDS}${id}`)
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};
