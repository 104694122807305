import { TO_RADIANS } from "./Constants";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const resizeImageToSquare = (file, size, callback) => {
  let startX;
  let startY;
  let newWidth;
  let newHeight;

  if (file.width < file.height) {
    newHeight = size;
    newWidth = (newHeight * file.width) / file.height;
    startY = 0;
    startX = (size - newWidth) / 2;
  } else {
    newWidth = size;
    newHeight = (newWidth * file.height) / file.width;
    startX = 0;
    startY = (size - newHeight) / 2;
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = size;
  canvas.height = size;

  const image = new Image();
  image.onload = function () {
    ctx.fillStyle = "rgba(255, 255, 255, 0)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, startX, startY, newWidth, newHeight);
    const dataUrl = canvas.toDataURL();
    file.src = dataUrl;
    file.width = size;
    file.height = size;

    callback(file);
  };
  const url = URL.createObjectURL(file);
  image.src = url;
};

const renderCanvas = (canvas, image, size, mask, modifications) => {
  if (!canvas || !image || !image.naturalWidth) return;
  const { scale, rotation, position } = modifications;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("No 2d context");
  }

  canvas.width = size;
  canvas.height = size;

  const rotateRads = rotation ? rotation * TO_RADIANS : 0;

  const centerX = canvas.width / 2;
  const centerY = canvas.height / 2;

  ctx.save();

  // Move the center of image to the origin (0,0)
  ctx.translate(centerX, centerY);
  // Rotate and scale
  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);
  // Move back and set the correct position
  ctx.translate(-centerX + position.x, -centerY + position.y);

  ctx.globalCompositeOperation = "source-over";
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  if (mask) {
    ctx.globalCompositeOperation = "destination-out";
    ctx.drawImage(mask, 0, 0, canvas.width, canvas.height);
  }

  ctx.restore();
};

export { toBase64, resizeImageToSquare, renderCanvas };
