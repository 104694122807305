import { fromJS } from "immutable";

export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";

const initialData = fromJS({
  categoryList: null,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return state.merge({
        categoryList: action.payload.categoryList,
      });
    default:
      return state;
  }
};
