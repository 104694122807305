import { fromJS } from "immutable";

export const GET_PROPERTY_GROUPS_SUCCESS = "GET_PROPERTY_GROUPS_SUCCESS";
export const ADD_PROPERTY_GROUP_SUCCESS = "ADD_PROPERTY_GROUP_SUCCESS";
export const EDIT_PROPERTY_GROUP_SUCCESS = "EDIT_PROPERTY_GROUP_SUCCESS";
export const DELETE_PROPERTY_GROUP_SUCCESS = "DELETE_PROPERTY_GROUP_SUCCESS";
export const GET_PROPERTY_CATEGORIES_SUCCESS =
  "GET_PROPERTY_CATEGORIES_SUCCESS";

const initialData = fromJS({
  propertyGroupList: null,
  categoryList: null,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_PROPERTY_GROUPS_SUCCESS:
      return state.merge({
        propertyGroupList: action.payload.propertyGroups,
      });
    case GET_PROPERTY_CATEGORIES_SUCCESS:
      return state.merge({
        categoryList: action.payload.categoryList,
      });
    default:
      return state;
  }
};
