import React from "react";
import { Table } from "reactstrap";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TablePagination from "./TablePagination";

const CommonTable = ({
  data,
  headers,
  rowComponent: RowComponent,
  rowComponentProps = {},
  pagination = true,
  offset,
  pageSize,
  onOffsetChange,
  total,
  keyProperty = "id",
}) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Table responsive>
        <thead className="text-primary">
          {!isEmpty(data.elements) && (
            <tr>
              {headers.map((header) => (
                <th key={header.label} className={header.className}>
                  {header.label}
                </th>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          {!isEmpty(data.elements) &&
            data.elements.map((item, index) => (
              <tr key={`${item[keyProperty]}-${index}`}>
                <RowComponent
                  number={offset + index + 1}
                  item={item}
                  index={index}
                  isFirst={offset + index === 0}
                  isLast={offset + index + 1 === total}
                  {...rowComponentProps}
                />
              </tr>
            ))}
          {isEmpty(data.elements) && (
            <tr>
              <td colSpan={headers.length}>
                <span>{t("noData")}</span>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {pagination && (
        <div className="mt-2">
          {!isEmpty(data.elements) && (
            <TablePagination
              total={total}
              offset={offset}
              pageSize={pageSize}
              onOffsetChange={(offset) => onOffsetChange(offset)}
            />
          )}
        </div>
      )}
    </>
  );
};

CommonTable.defaultProps = {
  offset: 0,
  onOffsetChange: () => {},
  pageSize: 10,
};

CommonTable.propTypes = {
  data: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  rowComponent: PropTypes.any.isRequired,
  offset: PropTypes.number,
  onOffsetChange: PropTypes.func,
  pageSize: PropTypes.number,
  total: PropTypes.number.isRequired,
};

export default CommonTable;
