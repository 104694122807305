import React from "react";
import {
  FormGroup,
  Input,
  Label,
  Button,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import PropTypes from "prop-types";
import { Field, getIn } from "formik";
import { useTranslation } from "react-i18next";
import AlertVariant from "../alert/AlertVariant";
import { showAlert } from "../alert/AlertActions";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";

export const FormCopyToClipboardFieldType = {
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  TIME: "time",
};

const FormCopyToClipboardFieldInner = ({
  label,
  placeholder,
  type,
  form,
  field,
  disabled = true,
  showAlert,
}) => {
  const { t } = useTranslation();

  const value = ![null, undefined].includes(getIn(form.values, field.name))
    ? getIn(form.values, field.name)
    : "";

  const element = (
    <div>
      <InputGroup>
        <Input
          className="copy-to-clipboard-input"
          name={field.name}
          placeholder={placeholder}
          type={type}
          value={value}
          disabled={disabled}
          onChange={({ target }) => {
            form.setFieldValue(field.name, target.value);
          }}
        />
        <InputGroupAddon addonType="append">
          <Button
            size="sm"
            className="copy-to-clipboard-icon"
            color="neutral"
            onClick={() => {
              navigator.clipboard.writeText(value);
              showAlert(t("copyToClipboard"), AlertVariant.SUCCESS);
            }}
          >
            <i className="nc-icon nc-single-copy-04" />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }}>
        <Label>{label}</Label>
        <FormGroup>{element}</FormGroup>
      </div>
    </div>
  );
};

const FormCopyToClipboardField = (props) => {
  return <Field {...props} component={FormCopyToClipboardFieldInner} />;
};

FormCopyToClipboardField.defaultProps = {
  label: "",
  type: FormCopyToClipboardFieldType.TEXT,
  placeholder: "",
  disabled: true,
};

FormCopyToClipboardField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showAlert }, dispatch);

export default connect(null, mapDispatchToProps)(FormCopyToClipboardField);
