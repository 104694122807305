import { fromJS } from "immutable";

export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";

const initialData = fromJS({
  brandList: null,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_BRANDS_SUCCESS:
      return state.merge({
        brandList: action.payload.brands,
      });
    default:
      return state;
  }
};
