import React, { useEffect } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Field, getIn } from "formik";
import { useTranslation } from "react-i18next";
import Switch from "react-bootstrap-switch";

const FormSwitchFieldInner = (props) => {
  const {
    label,
    form,
    field,
    onChangeHandler,
    disabled,
    defaultValue,
    onText,
    offText,
    color,
    formGroupClass,
    multiple,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (!getIn(form.values, field.name) && defaultValue) {
      form.setFieldValue(field.name, defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const value = getIn(form.values, field.name);

  return (
    <Row>
      <Col className={multiple ? "form-multiple-switch-col" : ""}>
        {label && (
          <Label className={multiple ? "form-multiple-switch-label" : ""}>
            {label}
          </Label>
        )}
        <FormGroup className={formGroupClass} name={field.name}>
          <Switch
            defaultValue={false}
            onColor={color}
            onText={onText}
            offColor={color}
            offText={offText}
            disabled={form.isSubmitting || disabled}
            onChange={(e, value) => {
              form.setFieldValue(field.name, value);
              onChangeHandler(value);
            }}
            value={value}
          />
          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: "red", width: "100%" }}>{t(error)}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormSwitchField = (props) => {
  return <Field {...props} component={FormSwitchFieldInner} />;
};

FormSwitchField.defaultProps = {
  onChangeHandler: () => {},
  label: null,
  disabled: false,
  color: "success",
  defaultValue: false,
  formGroupClass: "form-group-switch",
  multiple: false,
};

FormSwitchField.propTypes = {
  label: PropTypes.string,
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  onText: PropTypes.string.isRequired,
  offText: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  formGroupClass: PropTypes.string,
  multiple: PropTypes.bool,
};

export default FormSwitchField;
