import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Card, CardHeader, CardBody, CardTitle, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AlertVariant from "common/components/alert/AlertVariant";
import { showAlert } from "common/components/alert/AlertActions";
import { updatePartner } from "../partners/PartnerActions";
import ValidationSchema from "./SettingsForm.schema";
import SettingsForm from "./SettingsForm";
import { typesToSelect } from "common/utils/TypeUtils";
import { createPrivacyLink } from "common/utils/PartnerUtils";
import { isPrefix } from "constants/SettingsCurrencies";

const Settings = ({ currentUser, partner, updatePartner, showAlert }) => {
  const { t } = useTranslation("settings");
  const [initialValues, setInitialValues] = useState({});
  const [newTypes, setNewTypes] = useState([]);
  const [deletedTypes, setDeletedTypes] = useState([]);
  const [showSaveLoader, setShowSaveLoader] = useState(false);

  const resetState = () => {
    setNewTypes([]);
    setDeletedTypes([]);
    setShowSaveLoader(false);
    document
      .getElementById("scrollableWrapper")
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    document.getElementById("scrollableWrapper").scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (partner) {
      const partnerValues = {
        id: partner.id,
        effects: partner?.effects?.map((e) => {
          return {
            id: e.id,
            name: e.effect_name,
            active: e.effect_active,
            fbxLink: e.effect_link,
          };
        }),
        types: typesToSelect(partner.types),
        partnerTypes: typesToSelect(
          partner.types.filter((type) => type.type_show === true)
        ),
        domains: partner?.domains?.map((e) => {
          return {
            domain_id: e.domain_id,
            domain_url: e.domain_url,
          };
        }),
        appLinks: partner?.app_links?.map((e) => {
          return {
            id: e.app_link_id,
            url: e.app_link_url,
            text: e.app_link_text,
          };
        }),
        name: partner.partner_name,
        partnerAPIKey: partner.partner_api_key,
        partnerAppKey: partner.app_api_key,
        partnerWebAppKey: partner.webapp_api_key,
        privacyPolicyLink: partner.partner_privacy_policy_link,
        privacyPolicyContents: partner.partner_privacy_policy_contents,
        privacyPolicyFromEditor:
          _.isEmpty(partner.partner_privacy_policy_link) ||
          partner.partner_privacy_policy_link ===
            createPrivacyLink(partner.partner_app_prefix)
            ? true
            : false,
        bundleId: partner?.app_bundle_id,
        packageName: partner?.app_package_name,
        panelLanguage: {
          label: partner.panel_language,
          value: partner.panel_language,
        },
        partnerAppStoreLink: partner.partner_app_store_link,
        partnerPlayStoreLink: partner.partner_play_store_link,
        partnerDefaultUrl: partner.partner_default_url,
        partnerAppPrefix: partner.partner_app_prefix,
        webActive: partner.web_active,
        qrcodesActive: partner.qrcodes_active,
        mobileActive: partner.mobile_active,
        totemActive: partner.totem_active,
        autocutActive: partner.autocut_active,
        categoriesVisible: partner.categories_visible,
        organizingProducts: partner.organizing_products,
        versionsGrouped: partner.versions_grouped,
        thumbObjectScale: partner.thumb_object_scale,
        rotationIOS: partner.rotation_ios,
        rotationAndroid: partner.rotation_android,
        rotationWeb: partner.rotation_web,
        aspectRatioIOS: partner.aspect_ratio_ios,
        aspectRatioAndroid: partner.aspect_ratio_android,
        aspectRatioWeb: partner.aspect_ratio_web,
        positionYIOS: partner.position_y_ios,
        positionYAndroid: partner.position_y_android,
        positionYWeb: partner.position_y_web,
        positionZIOS: partner.position_z_ios,
        positionZAndroid: partner.position_z_android,
        positionZWeb: partner.position_z_web,
        partnerCurrency: {
          label: partner.partner_currency,
          value: partner.partner_currency,
        },
        notificationsActive: partner.notifications_active,
        notificationsLimit: partner.notifications_limit,
        notificationsAppId: partner.notifications_app_id,
        notificationsKey: partner.notifications_key,
        pdMeasurementActive: partner.pd_measurement_active,
        googleAnalyticsActive: partner.google_analytics_active,
        googleAnalyticsKey: partner.google_analytics_key,
        analyticsDashboardActive: partner.analytics_dashboard_active,
        marketplaceVisible: partner.marketplace_visible,
        numberOfTotems: partner.number_of_totems,
        numberOfTotemsActive: partner.number_of_totems_active,
        totemsApiKey: partner.totems_api_key,
        totemsLicenseKey: partner.totems_license_key,
        totemsLicenseExpirationDate: partner.totems_license_expiration_date
          ? new Date(partner.totems_license_expiration_date)
          : null,
        webRequestsLimit: partner.web_requests_usage.web_requests_limit,
        webUsageRemaindersActive:
          partner.web_requests_usage.web_usage_remainders_active,
      };
      setInitialValues(partnerValues);
    }
  }, [partner, t]);

  const onSubmit = (values, form) => {
    setShowSaveLoader(true);

    if (values.privacyPolicyFromEditor)
      form.setFieldValue(
        "privacyPolicyLink",
        createPrivacyLink(values.partnerAppPrefix)
      );

    const updateDomains = [];
    const newDomains = [];
    const initialDomains = initialValues.domains;

    if (values && values.domains && values.domains.length > 0) {
      values.domains.map((domain) => {
        const oldDomain = domain.domain_id
          ? initialDomains.find((item) => item.domain_id === domain.domain_id)
          : null;
        if (oldDomain) {
          if (domain.domain_url !== oldDomain.domain_url) {
            updateDomains.push(domain);
          }
        } else {
          if (domain.domain_url && domain.domain_url.length > 0) {
            newDomains.push(domain);
          }
        }
        return true;
      });
    }

    const updateAppLinks = [];
    const newAppLinks = [];
    const initialAppLinks = initialValues.appLinks;

    if (values && values.appLinks && values.appLinks.length > 0) {
      values.appLinks.map((link) => {
        const oldAppLink = link.id
          ? initialAppLinks.find((item) => item.id === link.id)
          : null;
        if (oldAppLink) {
          if (link.url !== oldAppLink.url || link.text !== oldAppLink.text) {
            updateAppLinks.push(link);
          }
        } else {
          if (
            link.url &&
            link.url.length > 0 &&
            link.text &&
            link.text.length > 0
          ) {
            newAppLinks.push(link);
          }
        }
        return true;
      });
    }

    const updateEffects = [];
    const newEffects = [];
    const initialEffects = initialValues.effects;

    if (values && values.effects && values.effects?.length > 0) {
      values.effects.map((effect) => {
        const oldEffect = effect.id
          ? initialEffects.find((item) => item.id === effect.id)
          : null;
        if (oldEffect) {
          if (
            effect.name !== oldEffect.name ||
            effect.active !== oldEffect.active ||
            effect.fbxFile ||
            effect.fbxFileDeleted
          ) {
            updateEffects.push(effect);
          }
        } else if (effect.name && effect.name.length > 0) {
          newEffects.push(effect);
        }
        return true;
      });
    }

    const editedPartnerSettings = {
      id: values.id,
      privacyPolicyLink: values.privacyPolicyFromEditor
        ? createPrivacyLink(values.partnerAppPrefix)
        : values.privacyPolicyLink,
      privacyPolicyContents: values.privacyPolicyContents,
      partnerAppKey: values.partnerAppKey,
      partnerAppStoreLink: values.partnerAppStoreLink,
      partnerPlayStoreLink: values.partnerPlayStoreLink,
      partnerDefaultUrl: values.partnerDefaultUrl,
      partnerAppPrefix: values.partnerAppPrefix,
      bundleId: values.bundleId || null,
      packageName: values.packageName || null,
      panelLanguage: values.panelLanguage.value,
      webActive: values.webActive,
      qrcodesActive: values.qrcodesActive,
      mobileActive: values.mobileActive,
      totemActive: values.totemActive,
      autocutActive: values.autocutActive,
      categoriesVisible: values.categoriesVisible,
      organizingProducts: values.organizingProducts,
      versionsGrouped: values.versionsGrouped,
      thumbObjectScale: values.thumbObjectScale,
      rotationIOS: values.rotationIOS,
      rotationAndroid: values.rotationAndroid,
      rotationWeb: values.rotationWeb,
      aspectRatioIOS: values.aspectRatioIOS,
      aspectRatioAndroid: values.aspectRatioAndroid,
      aspectRatioWeb: values.aspectRatioWeb,
      positionYIOS: values.positionYIOS,
      positionYAndroid: values.positionYAndroid,
      positionYWeb: values.positionYWeb,
      positionZIOS: values.positionZIOS,
      positionZAndroid: values.positionZAndroid,
      positionZWeb: values.positionZWeb,
      partnerCurrency: values.partnerCurrency.value,
      notificationsActive: values.notificationsActive,
      notificationsLimit: values.notificationsLimit,
      notificationsAppId: values.notificationsAppId,
      notificationsKey: values.notificationsKey,
      pdMeasurementActive: values.pdMeasurementActive,
      currencyAsPrefix: isPrefix[values.partnerCurrency.value],
      googleAnalyticsActive: values.googleAnalyticsActive,
      googleAnalyticsKey: values.googleAnalyticsKey,
      analyticsDashboardActive: values.analyticsDashboardActive,
      marketplaceVisible: values.marketplaceVisible,
      numberOfTotems: values.numberOfTotems,
      totemsLicenseExpirationDate: values.totemsLicenseExpirationDate
        ? new Date(values.totemsLicenseExpirationDate)
            .toISOString()
            .replace("T", " ")
        : null,
      webRequestsUsage: {
        web_requests_limit: values.webRequestsLimit,
        web_usage_remainders_active: values.webUsageRemaindersActive,
      },
      newEffects,
      updateEffects,
      partnerTypesNew: newTypes,
      partnerTypesDeleted: deletedTypes,
      updateDomains,
      newDomains,
      updateAppLinks,
      newAppLinks,
    };

    updatePartner(values.id, editedPartnerSettings, form, currentUser).then(
      (formCorrect) => {
        form.setSubmitting(false);
        setShowSaveLoader(false);
        if (formCorrect) {
          showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
          resetState();
        }
      }
    );
  };

  const onTypeAdd = (type) => {
    setNewTypes((newTypes) => [...newTypes, type.value]);
    setDeletedTypes(deletedTypes.filter((t) => t !== type.value));
    return;
  };

  const onTypeRemove = (type) => {
    setDeletedTypes((deletedTypes) => [...deletedTypes, type.value]);
    setNewTypes(newTypes.filter((t) => t !== type.value));
    return;
  };

  return (
    <div className="content">
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("title")}</h4>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={ValidationSchema}
              enableReinitialize
            >
              {() => (
                <SettingsForm
                  currentUser={currentUser}
                  partner={partner}
                  selectTypeHandler={onTypeAdd}
                  deselectTypeHandler={onTypeRemove}
                  showSaveLoader={showSaveLoader}
                />
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = function ({ partners, auth }) {
  return {
    partner: partners.get("partner"),
    currentUser: auth.get("user"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePartner,
      showAlert,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Settings));
