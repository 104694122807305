import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";

const CLOSEST_PAGES = 5;

const TablePagination = ({ onOffsetChange, offset, pageSize, total }) => {
  const prevEnabled = offset !== 0;
  const nextEnabled = offset + pageSize < total;

  const currentPage = Math.ceil(offset / pageSize || 0);
  const pages = _.range(total / pageSize);

  const closestPages =
    pages.size < CLOSEST_PAGES
      ? pages
      : pages.filter(
          (page) => Math.abs(currentPage - page) <= CLOSEST_PAGES / 2
        );
  const hasPreviousPages = pages.some((page) => page < _.min(closestPages));
  const hasNextPages = pages.some((page) => page > _.max(closestPages));

  return (
    <Pagination>
      <PaginationItem disabled={!prevEnabled}>
        <PaginationLink
          onClick={() => onOffsetChange(offset - pageSize)}
          type="button"
        >
          &laquo;
        </PaginationLink>
      </PaginationItem>
      {hasPreviousPages && <span>...</span>}
      {closestPages.map((page, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <PaginationItem active={page === currentPage} key={index}>
          <PaginationLink
            onClick={() => onOffsetChange(page * pageSize)}
            type="button"
          >
            {page + 1}
          </PaginationLink>
        </PaginationItem>
      ))}
      {hasNextPages && <span>...</span>}
      <PaginationItem disabled={!nextEnabled}>
        <PaginationLink
          onClick={() => onOffsetChange(offset + pageSize)}
          type="button"
        >
          &raquo;
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

TablePagination.defaultProps = {
  offset: 0,
  pageSize: 10,
  total: 0,
};

TablePagination.propTypes = {
  onOffsetChange: PropTypes.func.isRequired,
  offset: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
};

export default TablePagination;
