import React, { useEffect } from "react";
import { Col, FormGroup, Label, Row, Button, ButtonGroup } from "reactstrap";
import PropTypes from "prop-types";
import { Field, getIn } from "formik";
import { useTranslation } from "react-i18next";

const FormButtonGroupFieldInner = (props) => {
  const { label, form, field, disabled, onText, offText, defaultValue } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (!getIn(form.values, field.name) && defaultValue) {
      form.setFieldValue(field.name, defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const value = getIn(form.values, field.name);

  return (
    <Row>
      <Col>
        <Label>{label}</Label>
        <FormGroup name={field.name}>
          <ButtonGroup>
            <Button
              className="form-button-group-button"
              outline
              size="sm"
              disabled={form.isSubmitting || disabled}
              color="success"
              onClick={() => form.setFieldValue(field.name, !value)}
              active={value}
            >
              {onText}
            </Button>
            <Button
              className="form-button-group-button"
              outline
              size="sm"
              disabled={form.isSubmitting || disabled}
              color="success"
              onClick={() => form.setFieldValue(field.name, !value)}
              active={!value}
            >
              {offText}
            </Button>
          </ButtonGroup>

          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: "red", width: "100%" }}>{t(error)}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormButtonGroupField = (props) => {
  return <Field {...props} component={FormButtonGroupFieldInner} />;
};

FormButtonGroupField.defaultProps = {
  disabled: false,
  defaultValue: false,
};

FormButtonGroupField.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
  onText: PropTypes.string.isRequired,
  offText: PropTypes.string.isRequired,
};

export default FormButtonGroupField;
