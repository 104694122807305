import React from "react";
import ButtonWithHold from "./ButtonWithHold";
// number range input
const NumberRangeInput = ({
  label,
  iconStart,
  iconEnd,
  value,
  startDisabled,
  endDisabled,
  actionStart,
  longActionStart,
  longActionEnd,
  actionEnd,
  className = "number-range-input-container",
}) => {
  return (
    <div className={className}>
      <p className="label">{label}</p>
      <div className="number-range-input">
        <ButtonWithHold
          icon={iconStart}
          disabled={startDisabled}
          action={actionStart}
          longAction={longActionStart}
        />
        <div className="number-range-input-value">{value}</div>
        <ButtonWithHold
          icon={iconEnd}
          disabled={endDisabled}
          action={actionEnd}
          longAction={longActionEnd}
        />
      </div>
    </div>
  );
};

export default NumberRangeInput;
