import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

const BrandTableRow = ({
  item,
  number,
  editHandler,
  deleteHandler,
  isMainPartner,
}) => {
  const { t } = useTranslation("brands");
  return (
    <>
      <td>{number}</td>
      <td>{item.brand_name}</td>
      <td className="text-right list-action-column">
        {item.from_main_partner && !isMainPartner ? (
          <span className="global-label">{t("global")}</span>
        ) : (
          <div>
            <Button
              className="btn btn-info icon-button"
              onClick={() => editHandler(item)}
            >
              <i className="nc-icon nc-ruler-pencil" />
            </Button>
            <Button
              className="btn btn-danger icon-button"
              onClick={() => deleteHandler(item)}
            >
              <i className="nc-icon nc-simple-remove" />
            </Button>
          </div>
        )}
      </td>
    </>
  );
};

BrandTableRow.propTypes = {
  number: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  editHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
};

export default BrandTableRow;
