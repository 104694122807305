import React, { useEffect, useState } from "react";
import { FormGroup, Label } from "reactstrap";
import { Field, getIn } from "formik";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Select from "react-select";
import PropTypes from "prop-types";

const FormMultiselectFieldInner = ({
  label,
  placeholder = "",
  form,
  field,
  options,
  disabled = false,
  defaultValue = [],
  selectOptionHandler,
  deselectOptionHandler,
  warningText,
}) => {
  const { t } = useTranslation();
  const [selectOptions, setSelectOptions] = useState(options);

  useEffect(() => {
    if (!getIn(form.values, field.name)) {
      form.setFieldValue(field.name, defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectOptions(options);
  }, [options]);

  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const values = getIn(form.values, field.name) ?? [];

  const handleChange = (newValues, actionMeta) => {
    switch (actionMeta.action) {
      case "select-option": //Selecting an option from the list
        if (selectOptionHandler) selectOptionHandler(actionMeta.option);
        break;
      case "deselect-option": //Deselecting an option from the list
      case "remove-value": //Removing a selected option with the remove button
      case "pop-value": //Removing options using backspace
        if (deselectOptionHandler)
          deselectOptionHandler(actionMeta.removedValue);
        break;
      case "clear": //Removing all selected options with the clear button
        if (deselectOptionHandler)
          values.forEach((v) => deselectOptionHandler(v));
        break;
      default:
        break;
    }

    form.setFieldValue(field.name, newValues);
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }}>
        <Label>{label}</Label>
        <FormGroup>
          <Select
            isMulti
            name={field.name}
            value={values}
            onChange={handleChange}
            options={selectOptions.sort((a, b) =>
              a.label.localeCompare(b.label)
            )}
            disabled={form.isSubmitting || disabled}
            placeholder={placeholder}
            noOptionsMessage={() => t("common:select.noOptions")}
            isDisabled={form.isSubmitting || disabled}
            className={form.isSubmitting || disabled ? "selectDisabled" : ""}
          />
          {!_.isEmpty(warningText) && (
            <label style={{ color: "orange" }}>{warningText}</label>
          )}
          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: "red" }}>
              {t(error.value ? error.value : error)}
            </label>
          )}
        </FormGroup>
      </div>
    </div>
  );
};

const FormMultiselectField = (props) => {
  return <Field {...props} component={FormMultiselectFieldInner} />;
};

FormMultiselectField.defaultProps = {
  placeholder: "",
  disabled: false,
  defaultValue: [],
  options: [],
  selectOptionHandler: () => {},
  deselectOptionHandler: () => {},
  newOptionLabel: "",
  newOptionHandler: () => {},
};

FormMultiselectField.propTypes = {
  label: PropTypes.string.isRequired,
  newOptionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  defaultValue: PropTypes.array,
  newOptionHandler: PropTypes.func,
  selectOptionHandler: PropTypes.func,
  deselectOptionHandler: PropTypes.func,
};

export default FormMultiselectField;
