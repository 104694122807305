import { REACT_APP_API_URL } from "constants/env";
import { MAIN_PARTNER_NAME } from "constants/PartnerType";

const PRIVACY_LINK = "/privacy/";

const isMainPartner = (partner) => {
  if (partner && partner?.partner_name === MAIN_PARTNER_NAME) return true; //we are currently using the panel of ARLabs in SuperAdmin mode
  return false; //we are using the panel of another company
};

const createPrivacyLink = (prefix) => {
  return `${REACT_APP_API_URL}${PRIVACY_LINK}${prefix}`;
};

export { isMainPartner, createPrivacyLink };
