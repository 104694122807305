import { CLOSE_ALERT, SHOW_ALERT } from "./AlertReducer";
import AlertVariant from "./AlertVariant";

export const showAlert =
  (text, variant, duration = 4000) =>
  (dispatch) => {
    dispatch(closeAlert());
    dispatch({
      type: SHOW_ALERT,
      payload: {
        text,
        variant,
        duration,
      },
    });
    if (duration && variant !== AlertVariant.DANGER_LASTING) {
      setTimeout(() => dispatch(closeAlert()), duration);
    }
  };

export const closeAlert = () => (dispatch) => dispatch({ type: CLOSE_ALERT });
