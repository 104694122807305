import { combineReducers } from "redux";
import AuthReducer, { LOGOUT } from "../auth/AuthReducer";
import AlertReducer from "../common/components/alert/AlertReducer";
import ProductReducer from "components/products/ProductReducer";
import BrandReducer from "components/brands/BrandReducer";
import UserReducer from "components/users/UserReducer";
import CategoryReducer from "components/categories/CategoryReducer";
import PartnerReducer from "components/partners/PartnerReducer";
import PropertiesReducer from "components/properties/PropertyGroupReducer";
import LinkReducer from "components/links/LinkReducer";
import TypeReducer from "components/types/TypeReducer";
import NotificationReducer from "components/notifications/NotificationReducer";
import DashboardReducer from "components/analyticsDashboard/DashboardReducer";
import { SET_CURRENT_PARTNER } from "components/partners/PartnerReducer";
import TotemsReducer from "components/totems/TotemsReducer";
import MarketplaceReducer from "components/marketplace/MarketplaceReducer";
import ApplicationsReducer from "components/applications/ApplicationsReducer";
import AutocutReducer from "components/autocut/AutocutReducer";

const appReducer = combineReducers({
  auth: AuthReducer,
  alert: AlertReducer,
  brands: BrandReducer,
  products: ProductReducer,
  users: UserReducer,
  categories: CategoryReducer,
  partners: PartnerReducer,
  properties: PropertiesReducer,
  links: LinkReducer,
  types: TypeReducer,
  notifications: NotificationReducer,
  dashboard: DashboardReducer,
  totems: TotemsReducer,
  marketplace: MarketplaceReducer,
  apps: ApplicationsReducer,
  autocut: AutocutReducer,
});

export default (state, action) => {
  if (state && action.type === SET_CURRENT_PARTNER) {
    return appReducer(
      {
        ...state,
        brands: undefined,
        products: undefined,
        users: undefined,
        categories: undefined,
        properties: undefined,
        links: undefined,
        types: undefined,
        notifications: undefined,
        dashboard: undefined,
        totems: undefined,
        marketplace: undefined,
      },
      action
    );
  }
  return appReducer(
    action.type === LOGOUT
      ? {
          alert: state.alert,
        }
      : state,
    action
  );
};
