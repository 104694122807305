import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import {
  GET_USERS_SUCCESS,
  GET_USER_SUCCESS,
  ADD_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  DELETE_USER_SUCCESS,
} from "./UserReducer";

export const getUsers = (offset, limit) => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.USERS, { params: { offset, limit } })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_USERS_SUCCESS,
          payload: {
            users: res?.data?.body,
            meta: res?.data?.meta,
          },
        });
      }
    })
    .catch((err) => dispatch(handleError(err)));
};

export const getUser = (id) => (dispatch) => {
  return apiClient
    .get(`${ApiEndpoints.USERS}${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_USER_SUCCESS,
          payload: {
            user: res?.data?.body,
          },
        });
      }
    })
    .catch((err) => dispatch(handleError(err)));
};

export const addUser = (user, form) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.USERS, { ...user })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: ADD_USER_SUCCESS,
        });
      }
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const editUser = (user, form) => (dispatch) => {
  return apiClient
    .put(`${ApiEndpoints.USERS}${user.id}`, { ...user })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: EDIT_USER_SUCCESS,
        });
      }
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const deleteUser = (id) => (dispatch) => {
  return apiClient
    .delete(`${ApiEndpoints.USERS}${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_USER_SUCCESS,
        });
      }
    })
    .catch((err) => dispatch(handleError(err)));
};
