import { apiClient, handleError, setPartnerHeader } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import { PARTNER } from "auth/AuthConstants";
import { GET_USER_PARTNERS_SUCCESS, SET_USER_ROLE } from "auth/AuthReducer";
import { prepareSettingsFormData } from "common/utils/PrepareFormData";
import { SET_CURRENT_PARTNER } from "./PartnerReducer";

export const setCurrentPartner = (partner) => (dispatch, getState) => {
  sessionStorage.setItem(PARTNER, partner?.id);
  dispatch({
    type: SET_CURRENT_PARTNER,
    payload: {
      partner,
    },
  });
  const user = getState().auth.get("user");
  dispatch({
    type: SET_USER_ROLE,
    payload: {
      ...user,
      role: partner?.user_role,
    },
  });
  setPartnerHeader(partner?.id);
};

export const setCurrentPartnerById = (partnerId) => (dispatch, getState) => {
  const partners = getState().auth.get("partners") ?? [];
  const actPartner = getState().partners.get("partner") ?? [];
  const partner = partners.find((p) => p.id === partnerId);
  if (partner && partner !== actPartner) {
    dispatch(setCurrentPartner(partner));
  }
};

export const updatePartner = (id, values, form, currentUser) => (dispatch) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const data = prepareSettingsFormData(values);

  return apiClient
    .put(`${ApiEndpoints.PARTNERS}${id}`, data, config)
    .then(() => {
      return dispatch(getUserPartners(currentUser.id));
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const createPartner = (values, form) => (dispatch, getState) => {
  const user = getState().auth.get("user") ?? {};
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const data = prepareSettingsFormData(values);
  return apiClient
    .post(ApiEndpoints.PARTNERS, data, config)
    .then((res) => {
      return dispatch(getUserPartners(user.id)).then((resPartners) => {
        if (resPartners) {
          return res?.data?.body;
        }
        return false;
      });
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const getUserPartners = (userId) => (dispatch) => {
  return apiClient
    .get(`${ApiEndpoints.LOGIN}partners/${userId}`)
    .then((res) => {
      if (!res?.data?.body) return false;
      dispatch({
        type: GET_USER_PARTNERS_SUCCESS,
        payload: res.data.body,
      });
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};
