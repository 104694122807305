import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { clearToken } from "../AuthActions";
import LoginButton from "common/components/googleAuth/LoginButton";
import logo from "assets/img/logo.png";
import CreateGoogleAcc from "common/components/CreateGoogleAcc/CreateGoogleAcc";

const Login = () => {
  useEffect(() => {
    clearToken();
    document.body.classList.toggle("login-page");
    return () => {
      document.body.classList.toggle("login-page");
    };
  }, []);

  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto login-container" lg="4" md="6">
            <img src={logo} alt="ARLabs logo" className="login-logo-img" />
            <LoginButton />
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto login-container">
            <CreateGoogleAcc />
          </Col>
        </Row>
      </Container>
      <div className="full-page-background background-color" />
    </div>
  );
};

export default Login;
