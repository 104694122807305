import React from "react";

const EditorCanvasTool = ({
  onClick,
  text,
  icon,
  outlined,
  color,
  selected,
}) => {
  let border = outlined ? `1px solid ${color}` : "none";
  border = selected ? "2px solid black" : border;

  return (
    <button
      type="button"
      className="editor-canvas-tool"
      onClick={onClick}
      style={{
        border,
        background: outlined ? "white" : color,
        color: outlined ? "black" : "white",
      }}
    >
      {icon}
      <div style={{ flexGrow: 1, textTransform: "uppercase" }}>{text}</div>
    </button>
  );
};

export default EditorCanvasTool;
