import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import RouterPaths from "constants/RouterPaths";
import { changeProductsOrder, getProducts } from "./ProductActions";
import ProductTableRow from "./ProductTableRow";
import ProductSearch from "./search/ProductSearch";
import CommonDragableTable from "common/components/CommonDragableTable";
import CommonTable from "common/components/CommonTable";
import CommonLoader from "common/components/loader/CommonLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import ExtendedUserRoles from "constants/ExtendedUserRoles";

const PRODUCT_PAGE_LIMIT = 20;

const ProductList = ({
  partner,
  products,
  productsMeta,
  getProducts,
  changeProductsOrder,
  user,
}) => {
  const { t } = useTranslation("product");
  const [productsData, setProductsData] = useState({ elements: [] });
  const [showLoader, setShowLoader] = useState(false);
  const [searchParams, setSearchParams] = useState({ offset: 0 });
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
  const ref = useRef();
  const isSuperadmin = user?.role === ExtendedUserRoles.SUPERADMIN;

  const toggleVisibility = useCallback(() => {
    const element = document.getElementById("scrollableWrapper");
    if (element.scrollTop > 400) {
      setScrollToTopVisible(true);
    } else {
      setScrollToTopVisible(false);
    }
  }, []);

  useEffect(() => {
    ref.current.scrollIntoView({ block: "end" });
  }, []);

  useEffect(() => {
    if (searchParams.offset === 0) {
      setShowLoader(true);
      setProductsData({ elements: [] });
    }

    if (user?.role === ExtendedUserRoles.SUPERADMIN && !partner) {
      return;
    }
    getProducts({ ...searchParams, limit: PRODUCT_PAGE_LIMIT }).finally(() => {
      setShowLoader(false);
    });
  }, [getProducts, searchParams, user, partner]);

  useEffect(() => {
    if (searchParams.offset === 0) {
      setProductsData({ elements: products });
      return;
    }
    setProductsData({ elements: productsData.elements.concat(products) });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const onPageChange = (offset) => {
    setSearchParams({
      ...searchParams,
      offset,
    });
  };

  const loadMoreData = () => {
    setSearchParams({
      ...searchParams,
      offset: searchParams.offset + PRODUCT_PAGE_LIMIT,
    });
  };

  useEffect(() => {
    setSearchParams({ offset: 0 });
  }, [partner]);

  const headers =
    partner && partner?.categories_visible
      ? [
          {
            label: t("thumbnail"),
          },
          {
            label: t("name"),
          },
          {
            label: "",
          },
          {
            label: t("category"),
          },
          {
            label: t("publishedVersions"),
            className: "list-narrow-column",
          },
          {
            label: t("actions"),
            className: "text-right",
          },
        ]
      : [
          {
            label: t("thumbnail"),
          },
          {
            label: t("name"),
          },
          {
            label: "",
          },
          {
            label: t("publishedVersions"),
            className: "list-narrow-column",
          },
          {
            label: t("actions"),
            className: "text-right",
          },
        ];

  return (
    <div className="content" ref={ref}>
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("title")}</h4>
              <Link to={RouterPaths.ADD_PRODUCT} className="btn btn-info">
                {t("common:button.add")}
              </Link>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <ProductSearch
              onSearchCallback={(params) =>
                setSearchParams({ ...searchParams, ...params })
              }
            />
            <CommonLoader show={showLoader} />

            {!showLoader && (
              <>
                <InfiniteScroll
                  dataLength={productsData?.elements?.length}
                  next={loadMoreData}
                  hasMore={
                    Number(productsMeta.count) >
                    Number(productsMeta.offset) + PRODUCT_PAGE_LIMIT
                  }
                  loader={
                    <div key="loader" className="loader">
                      <div className="dot" />
                    </div>
                  }
                  scrollableTarget="scrollableWrapper"
                  scrollThreshold={"50px"}
                  onScroll={toggleVisibility}
                  style={{ overflow: "hidden" }}
                >
                  {partner?.organizing_products ? (
                    <CommonDragableTable
                      headers={headers}
                      data={productsData}
                      rowComponent={ProductTableRow}
                      offset={searchParams.offset}
                      onOffsetChange={onPageChange}
                      total={Number(productsMeta.count) ?? 0}
                      pageSize={PRODUCT_PAGE_LIMIT}
                      onDrop={changeProductsOrder}
                      onDropCallback={(newData) =>
                        setProductsData({ elements: newData })
                      }
                      pagination={false}
                      rowComponentProps={{ isSuperadmin }}
                    />
                  ) : (
                    <CommonTable
                      headers={headers}
                      data={productsData}
                      rowComponent={ProductTableRow}
                      total={Number(productsMeta.count) ?? 0}
                      pagination={false}
                      rowComponentProps={{ isSuperadmin }}
                    />
                  )}
                </InfiniteScroll>
                {scrollToTopVisible && (
                  <Button
                    className="btn-icon btn-round scroll-to-top-button"
                    onClick={() =>
                      document.getElementById("scrollableWrapper").scrollTo({
                        top: 0,
                        behavior: "smooth",
                      })
                    }
                  >
                    <i className="nc-icon nc-minimal-up" />
                  </Button>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ products, partners, auth }) => {
  return {
    products: products.get("productList") ?? [],
    productsMeta: products.get("meta") ?? {},
    partner: partners.get("partner"),
    user: auth.get("user"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProducts, changeProductsOrder }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
