import React, { useEffect, useState } from "react";
import { Field, Form, FieldArray, getIn, setNestedObjectValues } from "formik";
import { Button, Col, Row, Collapse } from "reactstrap";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormInputField from "common/components/formFields/FormInputField";
import _ from "lodash";
import ActionButton from "common/components/ActionButton";
import FormSwitchField from "common/components/formFields/FormSwitchField";
import FormRangeField from "common/components/formFields/FormRangeField";
import { scrollToError } from "common/utils/FormUtils";
import Product2dVersionForm from "./Product2dVersionForm";
import { isMainPartner } from "common/utils/PartnerUtils";

const ProductForm = ({
  partner,
  form,
  deleteHandler,
  versionDeleteHandler,
  versionAssignHandler,
  showSaveLoader,
  showDeleteLoader,
  setFormSaving,
  formSaving,
  initialValues,
  showTryOn,
  fromMarketplace,
  fromOtherPartner,
}) => {
  const [versionsError, setVersionsError] = useState("");
  const [webSettingsOpen, setWebSettingsOpen] = useState(false);
  const { t } = useTranslation("product");

  const toggleWeb = () => setWebSettingsOpen(!webSettingsOpen);

  useEffect(() => {
    if (getIn(form.errors, "versions") || form.errors["versions"]) {
      const error = getIn(form.errors, "versions") || form.errors["versions"];
      if (typeof error === "string") {
        setVersionsError(
          getIn(form.errors, "versions") || form.errors["versions"]
        );
        return;
      }
    }
    setVersionsError("");
  }, [form.errors]);

  const onFileDrop = (fileFieldName, linkFieldName, files) => {
    const file = files && files.length > 0 ? files[0] : null;
    if (
      !_.isEmpty(_.get(form.values, linkFieldName)) ||
      _.get(form.values, `${fileFieldName}Uploaded`)
    ) {
      form.setFieldValue(`${fileFieldName}Deleted`, true);
    }
    form.setFieldValue(fileFieldName, file);
    form.setFieldValue(`${fileFieldName}New`, !!file);
    return;
  };

  const onProductSave = async () => {
    setFormSaving(true);
    const errors = await form.validateForm();
    if (!_.isEmpty(errors)) {
      form.setTouched(setNestedObjectValues(form.values, true));
      setFormSaving(false);
      scrollToError(errors);
    } else {
      form.submitForm();
    }
  };

  useEffect(() => {
    form.resetForm();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    return () => {
      const loadingScript = document.getElementById("load-arlab");
      const arlabScript = document.getElementById("arlab");
      if (arlabScript) arlabScript.parentElement.removeChild(arlabScript);
      if (loadingScript) loadingScript.parentElement.removeChild(loadingScript);
      if (window["arlab"]) window["arlab"].q = [];
    };
  }, []);

  const btnContainer = document
    .getElementsByClassName("header-button-container")
    .item(0);

  return (
    <Form noValidate>
      <div
        style={{
          position: "absolute",
          right: (btnContainer?.offsetWidth ?? 0) + 35,
          top: "45px",
          zIndex: "10",
        }}
      >
        <ActionButton
          type="button"
          color="info"
          className="p-0 actionButton"
          disabled={formSaving || form.isSubmitting}
          onClick={onProductSave}
          label={t("common:button.save")}
          showLoader={showSaveLoader}
        />
      </div>
      <Col>
        <Row>
          <Col md="12">
            {!fromMarketplace && !fromOtherPartner && (
              <Field
                id="published"
                name="published"
                label={
                  partner?.totem_active ? t("publishedApp") : t("published")
                }
                onText={t("common:button.yes").toUpperCase()}
                offText={t("common:button.no").toUpperCase()}
                component={FormSwitchField}
                onChangeHandler={(value) => {
                  if (!value && form?.values?.versions) {
                    form.values.versions.map((version, index) => {
                      if (version.published) {
                        form.setFieldValue(
                          `versions[${index}].published`,
                          false
                        );
                      }
                      return version;
                    });
                  }
                }}
                disabled={formSaving}
              />
            )}

            <Field
              required
              fullWidth
              id="name"
              type="text"
              name="name"
              label={t("name")}
              component={FormInputField}
              disabled={formSaving}
            />
            {!fromMarketplace && !fromOtherPartner && (
              <>
                <div>
                  <div
                    className={`inline-element nc-icon nc-minimal-${
                      webSettingsOpen ? "down" : "right"
                    }`}
                    onClick={toggleWeb}
                  ></div>
                  <h5 className="form-section-heading ml-2 pt-3 inline-element">
                    {t("modelSettingsWeb")}
                  </h5>
                </div>
                <Collapse isOpen={webSettingsOpen}>
                  <Field
                    id="aspectRatioWeb"
                    name="aspectRatioWeb"
                    label={t("aspectRatioWeb")}
                    min={0.5}
                    max={2}
                    step={0.025}
                    labelStep={0.1}
                    disabled={formSaving || form.isSubmitting}
                    component={FormRangeField}
                  />
                  <Field
                    id="positionYWeb"
                    name="positionYWeb"
                    label={t("positionYWeb")}
                    min={-1}
                    max={1}
                    step={0.05}
                    labelStep={0.1}
                    disabled={formSaving || form.isSubmitting}
                    component={FormRangeField}
                  />
                </Collapse>
              </>
            )}
          </Col>
          <Col md="12">
            {form.submitCount > 0 && versionsError && (
              <label style={{ color: "red" }}>{t(versionsError)}</label>
            )}
            <FieldArray
              name="versions"
              render={(arrayHelpers) => (
                <div className="mb-2">
                  {form.values?.versions && (
                    <>
                      {form.values.versions.map((version, index) => (
                        /* eslint-disable react/no-array-index-key */
                        <Product2dVersionForm
                          id={version.id}
                          key={index}
                          index={index}
                          arrayHelpers={arrayHelpers}
                          isAssigned={version.isAssigned}
                          assignHandler={versionAssignHandler}
                          deleteHandler={versionDeleteHandler}
                          onFileDrop={onFileDrop}
                          form={form}
                          formSaving={formSaving}
                          showTryOn={showTryOn && partner?.web_active}
                        />
                      ))}
                    </>
                  )}
                  {!fromMarketplace && !fromOtherPartner && (
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          name: "",
                          pageLink: "",
                          thumbFile: null,
                          albedoFile: null,
                          metallicFile: null,
                          normalFile: null,
                          published: false,
                          frontFile: null,
                          lensesfile: null,
                          leftFile: null,
                          rightFile: null,
                          sku: null,
                        })
                      }
                      color="info"
                      className="mr-3"
                      disabled={formSaving || form.isSubmitting}
                    >
                      <i className="nc-icon nc-simple-add button-add-icon" />
                      {t("addVersion")}
                    </Button>
                  )}
                </div>
              )}
            />
          </Col>
        </Row>
        <Row className="justify-content-end">
          <ActionButton
            type="button"
            onClick={deleteHandler}
            color="danger"
            className="mr-3 mt-5 p-0 actionButton"
            disabled={formSaving || form.isSubmitting}
            label={t("common:button.delete")}
            showLoader={showDeleteLoader}
          />
          <ActionButton
            type="button"
            color="info"
            className="mr-3 mt-5 p-0 actionButton"
            disabled={formSaving || form.isSubmitting}
            onClick={onProductSave}
            label={t("common:button.save")}
            showLoader={showSaveLoader}
          />
        </Row>
      </Col>
    </Form>
  );
};

const mapStateToProps = function ({ partners, products, auth }) {
  return {
    partner: partners.get("partner"),
    fromMarketplace:
      products.get("product")?.from_main_partner &&
      !isMainPartner(partners.get("partner")),
    fromOtherPartner: products.get("product")?.from_other_partner,
    user: auth.get("user"),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
