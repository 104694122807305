import React from "react";
import { REGISTER_URL } from "./CreateGoogleAccConstants";
import { useTranslation } from "react-i18next";

const CreateGoogleAcc = () => {
  const { t } = useTranslation("account");

  const pop = (url, w, h) => {
    const n = window.open(url, "_blank", "width=" + w + ",height=" + h);
    if (n == null) {
      return true;
    }
    return false;
  };

  return (
    <>
      <button
        className="create-account-link"
        onClick={() => pop(REGISTER_URL, 400, 650)}
        target="_blank"
        type="button"
      >
        {t("createGoogleAccount")}
      </button>
    </>
  );
};

export default CreateGoogleAcc;
