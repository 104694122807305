import React from "react";
import { Row, Col, CardSubtitle } from "reactstrap";
import { Field, useFormikContext } from "formik";
import FileExtension from "constants/FileExtension";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import TotemConfigurationWelcomeScreenForm from "./TotemConfigurationWelcomeScreenForm";
import FormInputField from "common/components/formFields/FormInputField";
import FormColorField from "common/components/formFields/FormColorField";
import FormDropzoneField from "common/components/formFields/FormDropzoneField";
import FormButtonGroupField from "common/components/formFields/FormButtonGroupField";
import FormSwitchField from "common/components/formFields/FormSwitchField";

const TotemConfigurationForm = () => {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation("totems");

  const onFileDrop = (fileFieldName, linkFieldName, files) => {
    const file = files && files.length > 0 ? files[0] : null;
    if (!_.isEmpty(_.get(values, linkFieldName))) {
      setFieldValue(`${fileFieldName}Deleted`, true);
    }
    setFieldValue(fileFieldName, file);
    if (file) {
      setFieldValue(`${linkFieldName}New`, URL.createObjectURL(file));
    } else {
      setFieldValue(`${linkFieldName}New`, null);
    }
    return;
  };

  return (
    <>
      <TotemConfigurationWelcomeScreenForm onFileDrop={onFileDrop} />
      <Col className="totem-config-section">
        <Row>
          <CardSubtitle>
            <h5 className="totem-config-subtitle-text">{t("colors.title")}</h5>
          </CardSubtitle>
        </Row>
        <Row>
          <Col sm="6">
            <FormColorField
              id={"fontColor"}
              name={"fontColor"}
              label={t("colors.fontColor")}
            />
          </Col>
          <Col sm="6">
            <FormColorField
              id={"backgroundColor"}
              name={"backgroundColor"}
              label={t("colors.backgroundColor")}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <FormColorField
              id={"progressBarBackground"}
              name={"progressBarBackground"}
              label={t("colors.progressBarBackground")}
            />
          </Col>
          <Col sm="6">
            <FormColorField
              id={"progressBarFront"}
              name={"progressBarFront"}
              label={t("colors.progressBarFront")}
            />
          </Col>
        </Row>
      </Col>
      <Col className="totem-config-section">
        <Row>
          <CardSubtitle>
            <h5 className="totem-config-subtitle-text">{t("tryOn.title")}</h5>
          </CardSubtitle>
        </Row>
        <Row>
          <Col>
            <FormDropzoneField
              id={"logoFile"}
              name={"logoFile"}
              label={t("tryOn.logoFile")}
              acceptedExtension={FileExtension.PNG}
              handleDrop={(file) => onFileDrop(`logoFile`, `logoLink`, file)}
              handleDelete={() => onFileDrop(`logoFile`, `logoLink`, null)}
              existingFile={
                !_.isEmpty(values.logoLink) && !values.logoFileDeleted
                  ? t("fileUploaded")
                  : ""
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6" md="4" className="product-name-style-container">
            <Field
              id="productNameFontReversed"
              name="productNameFontReversed"
              label={t("tryOn.productNameStyle")}
              onText={t("tryOn.reversed")}
              offText={t("tryOn.normal")}
              component={FormButtonGroupField}
            />
          </Col>
          {values.fontColor && (
            <Col sm="6" md="4" className="product-name-style-example-container">
              <div
                className="product-name-style-example"
                style={{
                  backgroundColor: values.productNameFontReversed
                    ? `rgb(${values.fontColor?.r}, ${values.fontColor?.g}, ${values.fontColor?.b})`
                    : "transparent",
                  color: values.productNameFontReversed
                    ? "white"
                    : `rgb(${values.fontColor?.r}, ${values.fontColor?.g}, ${values.fontColor?.b})`,
                }}
              >
                {t("tryOn.name")}
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col sm="3">
            <FormSwitchField
              id="qrCodes"
              name="qrCodes"
              label={t("tryOn.qrCodes")}
              onText={t("common:button.yes").toUpperCase()}
              offText={t("common:button.no").toUpperCase()}
            />
          </Col>
          <Col sm="9">
            <Field
              id={"qrCodesText"}
              name={"qrCodesText"}
              label={t("tryOn.qrCodesText")}
              component={FormInputField}
              type="textarea"
              disabled={!values.qrCodes}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default TotemConfigurationForm;
