import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "reactstrap/lib/Button";
import LogLookupPopup from "./LogLookupPopup";
import { useTranslation } from "react-i18next";
import moment from "moment";

const LogTableRow = ({ item }) => {
  const [popup, setPopup] = useState({
    log_id: null,
    show: false,
  });

  const { t } = useTranslation("product");

  const logLookupHandler = (log_id) => {
    setPopup({ log_id: log_id, show: !popup.show });
  };

  const formatTimestamp = (str) => {
    return moment(str).format("L, LT");
  };

  let changedBy = "-";
  if (item.changed_by) {
    changedBy = item.changed_by;
  } else if (item.changed_by_api) {
    changedBy = t("changedFromAPI");
  }

  return (
    <>
      <td style={{ width: "40%" }}>{changedBy}</td>
      <td style={{ width: "40%" }}>{formatTimestamp(item.changed_at)}</td>
      <td style={{ width: "20%" }}>
        <Button
          type="button"
          className="btn btn-info icon-button"
          onClick={() => logLookupHandler(item.log_id)}
        >
          <i className="nc-icon nc-zoom-split" />
        </Button>
      </td>
      {popup?.show && (
        <LogLookupPopup
          log_id={popup.log_id}
          cancelHandler={() => setPopup({ log_id: null, show: false })}
        />
      )}
    </>
  );
};

LogTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default LogTableRow;
