import React from "react";
import Loader from "react-loader-spinner";

const CommonLoader = ({ show = false, className = "p-5" }) => {
  return (
    <>
      {show && (
        <div className="text-center">
          <div className={className}>
            <Loader height={30} color="Blue" type="Oval" />
          </div>
        </div>
      )}
    </>
  );
};

export default CommonLoader;
