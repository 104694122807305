import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CardSubtitle, Row, Col, Button } from "reactstrap";
import { Field, FieldArray, getIn } from "formik";
import { useTranslation } from "react-i18next";
import { showAlert } from "common/components/alert/AlertActions";
import ReactBSAlert from "react-bootstrap-sweetalert";
import FormSwitchField from "common/components/formFields/FormSwitchField";
import FormInputField from "common/components/formFields/FormInputField";
import AlertVariant from "common/components/alert/AlertVariant";
import FormDropzoneField from "common/components/formFields/FormDropzoneField";
import FileExtension from "constants/FileExtension";
import _ from "lodash";
import { deleteEffect } from "./EffectsActions";

const Effects = ({ form, deleteEffect, showAlert }) => {
  const { t } = useTranslation("settings");
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    effect: null,
    idx: null,
  });

  const deleteHandler = () => {
    deleteEffect(deleteAlert.effect).then((formCorrect) => {
      setDeleteAlert({ show: false, effect: null, idx: null });
      if (formCorrect) {
        if (deleteField(deleteAlert.idx)) {
          showAlert(t("form:deleteSuccess"), AlertVariant.SUCCESS);
        }
      }
    });
  };

  const deleteField = (idx) => {
    const values = form.values.effects;
    values.splice(idx, 1);
    form.setFieldValue("effects", values);
    return true;
  };

  const addField = (name) => {
    const values = form.values[name] ?? [];
    values.push({
      id: null,
      name: "",
      fbxLink: "",
      active: false,
    });
    form.setFieldValue(name, values);
  };

  const onFileDrop = (form, fileFieldName, linkFieldName, files) => {
    const file = files && files.length > 0 ? files[0] : null;

    if (!_.isEmpty(_.get(form.values, linkFieldName))) {
      form.setFieldValue(`${fileFieldName}Deleted`, true);
    }
    form.setFieldValue(fileFieldName, file);
  };

  return (
    <>
      {deleteAlert.show && (
        <ReactBSAlert
          warning
          showCancel
          cancelBtnText={t("common:button.cancel")}
          confirmBtnText={t("common:button.delete")}
          confirmBtnBsStyle="danger"
          title={t("deleteEffectTitle")}
          onConfirm={deleteHandler}
          onCancel={() =>
            setDeleteAlert({ show: false, effect: null, idx: null })
          }
          focusCancelBtn
        />
      )}
      <Col className="settings-section" style={{ marginBottom: "10px" }}>
        <Row>
          <CardSubtitle>
            <h5 className="settings-subtitle-text">{t("effects")}</h5>
          </CardSubtitle>
        </Row>
        <Row>
          {(form.submitCount > 0 || getIn(form.touched, "effectsCheck")) &&
            getIn(form.errors, "effectsCheck") && (
              <label style={{ color: "red", width: "100%" }}>
                {t(getIn(form.errors, "effectsCheck"))}
              </label>
            )}
        </Row>
        <FieldArray name="effects">
          {({ name }) => (
            <div>
              {form.values.effects && form.values.effects.length > 0
                ? form.values.effects.map((e, idx) => (
                    <Row>
                      <Col md={4}>
                        <Field
                          id={`effects[${idx}].name`}
                          name={`effects[${idx}].name`}
                          component={FormInputField}
                          type="text"
                          label={t("name")}
                        />
                      </Col>
                      <Col md={5}>
                        <FormDropzoneField
                          id={`effects[${idx}].fbxFile`}
                          name={`effects[${idx}].fbxFile`}
                          label={t("fileLink")}
                          acceptedExtension={FileExtension.FBX}
                          handleDrop={(file) =>
                            onFileDrop(
                              form,
                              `effects[${idx}].fbxFile`,
                              `effects[${idx}].fbxLink`,
                              file
                            )
                          }
                          handleDelete={() => {
                            onFileDrop(
                              form,
                              `effects[${idx}].fbxFile`,
                              `effects[${idx}].fbxLink`,
                              null
                            );
                          }}
                          existingFile={
                            !_.isEmpty(form.values.effects[idx].fbxLink)
                              ? t("fileUploaded")
                              : ""
                          }
                        />
                      </Col>
                      <Col
                        md={2}
                        style={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "center",
                          marginTop: 30,
                        }}
                      >
                        <Field
                          id={`effects[${idx}].active`}
                          name={`effects[${idx}].active`}
                          onText={t("common:button.yes").toUpperCase()}
                          offText={t("common:button.no").toUpperCase()}
                          formGroupClass="settings-switch-group"
                          component={FormSwitchField}
                        />
                      </Col>
                      <Col
                        md={1}
                        style={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "center",
                          marginTop: 15,
                        }}
                      >
                        <Button
                          size="md"
                          color="neutral"
                          onClick={() =>
                            e.id
                              ? setDeleteAlert({
                                  show: true,
                                  effect: e.id,
                                  idx,
                                })
                              : deleteField(idx)
                          }
                        >
                          <i className="nc-icon nc-simple-remove" />
                        </Button>
                      </Col>
                    </Row>
                  ))
                : ""}
              <Row className="justify-content-end" style={{ marginTop: 10 }}>
                <Button className="btn btn-info" onClick={() => addField(name)}>
                  <i className="nc-icon nc-simple-add button-add-icon" />
                  {t("common:button.add")}
                </Button>
              </Row>{" "}
            </div>
          )}
        </FieldArray>
      </Col>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteEffect,
      showAlert,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(Effects));
