import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { Button } from "reactstrap";
import { assignProductFromMarketplace } from "./MarketplaceActions";
import { showAlert } from "common/components/alert/AlertActions";
import AlertVariant from "common/components/alert/AlertVariant";

const MarketplaceProductTableRow = ({
  item,
  assignProductFromMarketplace,
  showAlert,
  search,
}) => {
  const { t } = useTranslation("product");

  const addHandler = (id) => {
    assignProductFromMarketplace(id, search).then((res) => {
      if (res) {
        showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
      }
    });
  };

  return (
    <>
      <td style={{ width: 130, minWidth: 130 }}>
        {item.product_thumb_link ? (
          <img alt="thumb" src={item.product_thumb_link} height={40} />
        ) : (
          t("notAdded")
        )}
      </td>
      <td style={{ flex: 1.5, width: "60%" }}>{item.product_name}</td>
      <td style={{ width: 140, minWidth: 140 }}>{item.published_versions}</td>
      <td className="text-right" style={{ width: 70, minWidth: 70 }}>
        {item.assigned ? (
          <div style={{ margin: "0px 15px" }}>
            <i className="nc-icon nc-check-2 icon icon-success" />
          </div>
        ) : (
          <Button
            className="btn btn-info table-icon-button"
            onClick={() => addHandler(item.id)}
          >
            <i className="nc-icon nc-simple-add" />
          </Button>
        )}
      </td>
    </>
  );
};

MarketplaceProductTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ assignProductFromMarketplace, showAlert }, dispatch);

export default connect(null, mapDispatchToProps)(MarketplaceProductTableRow);
