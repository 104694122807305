import React from "react";
import { useTranslation } from "react-i18next";

const AutocutTableRow = ({ item }) => {
  const { t } = useTranslation("autocut");

  return (
    <>
      <td>{t(`importStatus.${item[0]}`)}</td>
      <td>{item[1]}</td>
    </>
  );
};

export default AutocutTableRow;
