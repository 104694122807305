export default {
  LOGIN: "/auth/",
  PRODUCTS: "/product/",
  PRODUCT_LOG: "/product/log/",
  ALL_PRODUCTS: "/product/all/",
  ORDER_PRODUCTS: "/product/order/",
  MOVE_PRODUCT_TO_MAIN_PARTNER: "/product/toMainPartner/",
  BRANDS: "/brand/",
  CATEGORIES: "/category/",
  EFFECTS: "/effects/",
  TYPES: "/type/",
  USERS: "/user/",
  PARTNERS: "/partner/",
  PROPERTY_GROUPS: "/propertygroup/",
  CATEGORY_PROPERTY_GROUPS: "/propertygroup/category/",
  LINKS: "/weblink/",
  NOTIFICATIONS: "/notification/",
  DASHBOARD: "/dashboard/",
  TOTEMS: "/totems/",
  TOTEM: "/totems/one/",
  TOTEM_COPY: "/totems/copy/",
  TOTEM_VERSIONS: "/totems/versions/",
  TOTEM_CONFIG: "/totems/config/",
  TOTEM_BANNERS: "/banners/",
  DOMAINS: "/domains/",
  APPLINKS: "/applinks/",
  MARKETPLACE_PRODUCTS: "/marketplace/",
  MARKETPLACE_PRODUCTS_ASSIGN: "/marketplace/assign",
  APPS: "/apps",
  APPS_MIN_VERSION: "/apps/minversion",
  AUTOCUT_REPORT: "/autocut/report",
  AUTOCUT_VISUAL_REPORT: "/autocut/visualreport",
  AUTOCUT_IMPORT_DATA: "/autocut",
  AUTOCUT_RUN_IMPORT: "/autocut/run",
  AUTOCUT_NOTIFY_ABOUT_IMPORT: "/autocut/notify",
  AUTOCUT_NOTIFY_ABOUT_IMPORT_COMPLETE: "/autocut/notifyclient",
};
