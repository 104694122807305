import { fromJS } from "immutable";

export const GET_TOTEMS_SUCCESS = "GET_TOTEMS_SUCCESS";
export const GET_TOTEM_SUCCESS = "GET_TOTEM_SUCCESS";
export const GET_TOTEM_VERSIONS_SUCCESS = "GET_TOTEM_VERSIONS_SUCCESS";
export const ADD_TOTEM_VERSION_SUCCESS = "ADD_TOTEM_VERSION_SUCCESS";
export const GET_TOTEM_VERSION_DELETED_SUCCESS =
  "GET_TOTEM_VERSION_DELETED_SUCCESS";
export const GET_TOTEM_BANNERS_SUCCESS = "GET_TOTEM_BANNERS_SUCCESS";
export const BANNER_DELETED_SUCCESS = "BANNER_DELETED_SUCCESS";
export const DELETE_TOTEM_SUCCESS = "DELETE_TOTEM_SUCCESS";

const initialData = fromJS({
  totemList: null,
  totemVersions: null,
  totem: null,
  banners: null,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_TOTEMS_SUCCESS:
      return state.merge({
        totemList: action.payload.totems,
      });
    case GET_TOTEM_VERSIONS_SUCCESS:
      return state.merge({
        totemVersions: action.payload.totemVersions,
      });
    case GET_TOTEM_SUCCESS:
      return state.merge({
        totem: action.payload.totem,
      });
    case GET_TOTEM_BANNERS_SUCCESS:
      return state.merge({
        banners: action.payload.banners,
      });
    case DELETE_TOTEM_SUCCESS:
      return state.merge({
        totem: null,
      });
    default:
      return state;
  }
};
