import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "index.css";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import history from "browserHistory";
import Dashboard from "layouts/Dashboard";
import { prepareStore } from "redux/Store";
import AlertContainer from "common/components/alert/AlertContainer";
import "./i18n/i18n";
import { setUserInSession } from "auth/AuthActions";
import { AUTH_TOKEN } from "auth/AuthConstants";

import RouterPaths from "constants/RouterPaths";
import AuthLayout from "layouts/Auth";
import ProtectedRoute from "common/components/ProtectedRoute";
import { setAuthorizationHeader } from "api/ApiClient";

const store = prepareStore();
setUserInSession(store.dispatch);

window.addEventListener("storage", () => {
  setAuthorizationHeader(localStorage.getItem(AUTH_TOKEN));
});

history.listen(() => {
  window.scrollTo(0, 0);
});

ReactDOM.render(
  <Provider store={store}>
    <div className="wrapper wrapper-full-page">
      <Router history={history}>
        <Switch>
          <Route
            path={RouterPaths.LOGIN}
            render={(props) => <AuthLayout {...props} />}
            exact
          />
          <ProtectedRoute path="/" component={Dashboard} />
        </Switch>
        <AlertContainer />
      </Router>
    </div>
  </Provider>,
  document.getElementById("root")
);
