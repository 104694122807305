import _ from "lodash";
const errorsTranslationNamespace = "common:backendErrors.validation.";

export const parseFormErrors = (errors) => {
  const formErrors = {};
  Object.keys(errors).forEach((name) => {
    if (errors.hasOwnProperty(name)) {
      const re = /\.([0-9]+)\./g;
      const formName = name.replace(re, "[$1].");
      formErrors[formName] = `${errorsTranslationNamespace}${errors[name][0]}`;
    }
  });
  return formErrors;
};

export const scrollToError = (errors) => {
  const keys = Object.keys(errors);
  let element = null;
  if (keys[0] !== "versions") {
    element = document.getElementsByName(keys[0]);
  } else if (typeof errors[keys[0]] === "object") {
    const versions = errors[keys[0]];
    const versionsKeys = Object.keys(versions);
    if (!_.isEmpty(versionsKeys)) {
      const number = versionsKeys[0];
      const field = Object.keys(versions[number])[0];
      element = document.getElementsByName(`versions[${number}].${field}`);
    }
  }
  if (!_.isEmpty(element)) {
    element[0].parentNode.scrollIntoView({ block: "center" });
  }
};
