const statusOptions = {
  ALL: "all",
  PUBLISHED: "published",
  NOTPUBLISHED: "notPublished",
};

const categoriesOptions = {
  ALL: "all",
};

const assignedOptions = {
  ALL: "all",
  ASSIGNED: "assigned",
  NOTASSIGNED: "notAssigned",
};

const ownershipOptions = {
  ALL: "all",
  MY: "myProducts",
  OTHER_PARTNER: "fromOtherPartner",
};

export { statusOptions, categoriesOptions, assignedOptions, ownershipOptions };
