import React, { useEffect } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Field, getIn } from "formik";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import _ from "lodash";

const FormSelectFieldInner = (props) => {
  const {
    label,
    placeholder,
    form,
    field,
    options,
    onChangeHandler,
    onInputChange,
    disabled,
    defaultValue,
    warningText,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (!getIn(form.values, field.name) && defaultValue) {
      form.setFieldValue(field.name, defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const valueFromForm = getIn(form.values, field.name);

  const value =
    valueFromForm && ![null, "", undefined].includes(valueFromForm.value)
      ? getIn(form.values, field.name)
      : defaultValue;

  const Option = (props) => {
    return (
      <components.Option {...props}>
        {props.data.label} {props.data.additionalLabel}
      </components.Option>
    );
  };

  return (
    <Row>
      <Col>
        <Label>{label}</Label>
        <FormGroup>
          <Select
            classNamePrefix="react-select"
            components={{ Option }}
            name={field.name}
            options={options}
            placeholder={placeholder}
            value={value}
            isDisabled={form.isSubmitting || disabled}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onInputChange={onInputChange}
            onChange={(option) => {
              form.setFieldValue(field.name, option);
              onChangeHandler(option);
            }}
            className={form.isSubmitting || disabled ? "selectDisabled" : ""}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
          {!_.isEmpty(warningText) && (
            <label style={{ color: "orange" }}>{warningText}</label>
          )}
          {(form.submitCount > 0 || touched) && error && error.value && (
            <label style={{ color: "red" }}>{t(error.value)}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormSelectField = (props) => {
  return <Field {...props} component={FormSelectFieldInner} />;
};

FormSelectField.defaultProps = {
  placeholder: "",
  options: [],
  onChangeHandler: () => {},
  onInputChange: () => {},
  disabled: false,
  defaultValue: { value: "", label: "" },
};

FormSelectField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.object,
  onInputChange: PropTypes.func,
};

export default FormSelectField;
