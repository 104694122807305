import ExtendedUserRoles from "constants/ExtendedUserRoles";

const checkPermission = (userRole, permissionLevel) => {
  if (userRole === ExtendedUserRoles.SUPERADMIN) {
    return true;
  } else if (userRole === ExtendedUserRoles.ADMIN) {
    if (
      permissionLevel === ExtendedUserRoles.USER ||
      permissionLevel === ExtendedUserRoles.ADMIN
    ) {
      return true;
    }
  } else if (userRole === ExtendedUserRoles.USER) {
    if (permissionLevel === ExtendedUserRoles.USER) {
      return true;
    }
  }
  return false;
};

export { checkPermission };
