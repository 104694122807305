import { fromJS } from "immutable";

export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const SET_CURRENT_PARTNER = "SET_CURRENT_PARTNER";

const initialData = fromJS({
  partnerList: null,
  partner: null,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_PARTNERS_SUCCESS:
      return state.merge({
        partnerList: action.payload.partnerList,
      });
    case SET_CURRENT_PARTNER:
      return state.merge({
        partner: action.payload.partner,
      });
    default:
      return state;
  }
};
