import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Select from "react-select";
import { isMainPartner } from "common/utils/PartnerUtils";
import { PARTNER } from "auth/AuthConstants";
import { useHistory } from "react-router-dom";
import RouterPaths from "constants/RouterPaths";
import { setCurrentPartner } from "./PartnerActions";

const PartnerSelect = ({ partners, partner, setCurrentPartner }) => {
  const [open, setOpen] = useState(false);
  const [partnerList, setPartnerList] = useState([]);
  const selectRef = useRef();

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    setPartnerList(partners);
    const partnerId = sessionStorage.getItem(PARTNER);

    if (partnerId && partners) {
      const lastPartner = partners.find((p) => p.id === partnerId);
      if (lastPartner) {
        setCurrentPartner(lastPartner);
        return;
      }
    }

    if (partners.length > 0) {
      selectPartner(partners[0], false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners, setCurrentPartner]);

  const selectPartner = (selectedPartner, redirect) => {
    if (partner === selectedPartner) return;
    setCurrentPartner(selectedPartner);
    if (redirect) {
      history.push(
        isMainPartner(selectedPartner)
          ? RouterPaths.USERS
          : RouterPaths.PRODUCTS
      );
    }
  };

  return (
    <Dropdown
      isOpen={open}
      toggle={() => {
        setOpen(!open);
        if (selectRef && !open) {
          setTimeout(() => {
            if (selectRef) selectRef.current.focus();
          }, 500);
        }
      }}
      className="dropdown-partner"
    >
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={open}
        className="dropdown-toggle-custom"
      >
        <div className="dropdown-title">{partner?.partner_name || ""}</div>
        <i className="nc-icon nc-minimal-down" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-partner">
        <Select
          className={`react-select-partner primary ${
            open ? "" : "react-select-hidden"
          }`}
          classNamePrefix="react-select-partner"
          name="partner-select"
          value={{
            value: partner || "",
            label: partner?.partner_name,
          }}
          ref={selectRef}
          menuIsOpen={open}
          noOptionsMessage={() => t("common:select.noOptions")}
          components={{ IndicatorsContainer: () => null }}
          onChange={(partner) => {
            selectPartner(partner.value, true);
            setOpen(false);
          }}
          options={partnerList.map((p) => {
            return {
              value: p,
              label: p.partner_name,
            };
          })}
          placeholder="Partner name"
        />
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = ({ partners, auth }) => {
  return {
    partners: auth.get("partners") ?? [],
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCurrentPartner }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSelect);
