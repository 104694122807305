import i18n from "i18n/i18n";
import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    duration: Yup.number()
      .min(1, i18n.t("common:validator.minValue", { x: 1 }))
      .required("common:validator.required"),
    displayed_time: Yup.number()
      .min(1, i18n.t("common:validator.minValue", { x: 1 }))
      .required("common:validator.required"),
    active: Yup.object().shape({
      value: Yup.string().required("common:validator.required"),
    }),
    banner_name: Yup.string(),
    banner_type: Yup.object().shape({
      value: Yup.string().required("common:validator.required"),
    }),
    file: Yup.mixed().when(
      ["banner_image_link"],
      (banner_image_link, schema) => {
        return banner_image_link
          ? schema
          : schema.required("common:validator.required");
      }
    ),
  });
};
