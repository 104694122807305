import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";

export const getDomains = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.DOMAINS)
    .then((res) => {
      return res?.data?.body;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const deleteDomain = (id) => (dispatch) => {
  return apiClient
    .delete(`${ApiEndpoints.DOMAINS}${id}`)
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};
