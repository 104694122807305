import React, { useState, useRef } from "react";
import Menu from "./Menu";
import ImagePreview from "./ImagePreview";
import { ReactComponent as TrashOutlined } from "./icons/trashOutlined.svg";
import { useTranslation } from "react-i18next";

const initialPhotoState = {
  crop: null,
  cropPercentage: null,
  scale: 1,
  position: {
    x: 0,
    y: 0,
  },
  rotation: 0,
};

const Wizard = ({ onSave, onBack }) => {
  const { t } = useTranslation("wizard");

  const [active, setActive] = useState(null);
  const [cropModeActive, setCropModeActive] = useState(null);
  const [photos, setPhotos] = useState({
    front: initialPhotoState,
    left: initialPhotoState,
    right: initialPhotoState,
  });
  const leftCanvasRef = useRef(null);
  const frontCanvasRef = useRef(null);
  const rightCanvasRef = useRef(null);
  const frontWithLensesCanvasRef = useRef(null);

  const handleCropClick = () => {
    setCropModeActive((c) => {
      if (c) handleCropSave();
      return !c;
    });
  };

  const handleCropSave = () => {
    setPhotos((photos) => {
      const newPhotos = { ...photos };
      const { cropPercentage, scale } = newPhotos[active];
      newPhotos[active].crop = null;
      newPhotos[active].cropPercentage = null;

      if (
        !cropPercentage ||
        cropPercentage.width === 0 ||
        cropPercentage.height === 0
      )
        return newPhotos;

      const width = 1024 / newPhotos[active].scale;
      const diffX =
        ((50 - cropPercentage.x - cropPercentage.width / 2) * width) / 100;
      const diffY =
        ((50 - cropPercentage.y - cropPercentage.height / 2) * width) / 100;
      const diffScale = (scale * 100) / cropPercentage.width - scale;

      handleMove(diffX, diffY);
      handleResize(diffScale);

      return newPhotos;
    });
  };

  const handleCropFinished = (crop, cropPercentage) => {
    setPhotos((photos) => {
      const newPhotos = { ...photos };
      newPhotos[active] = { ...newPhotos[active], crop, cropPercentage };
      return newPhotos;
    });
  };

  const handleResize = (diffScale) => {
    setPhotos((photos) => {
      const newPhotos = { ...photos };
      newPhotos[active] = {
        ...newPhotos[active],
        scale: newPhotos[active].scale + diffScale,
      };
      return newPhotos;
    });
  };

  const handleMove = (diffPositionX, diffPositionY) => {
    setPhotos((photos) => {
      const newPhotos = { ...photos };
      // clockwise rotation
      const radius = (newPhotos[active].rotation * Math.PI) / 180;
      const newY =
        newPhotos[active].position.y -
        Math.sin(radius) * diffPositionX +
        Math.cos(radius) * diffPositionY;
      const newX =
        newPhotos[active].position.x +
        Math.cos(radius) * diffPositionX +
        Math.sin(radius) * diffPositionY;
      newPhotos[active] = {
        ...newPhotos[active],
        position: {
          x: newX,
          y: newY,
        },
      };
      return newPhotos;
    });
  };

  const handleRotate = (diffRotation) => {
    setPhotos((photos) => {
      const newPhotos = { ...photos };
      newPhotos[active] = {
        ...newPhotos[active],
        rotation: newPhotos[active].rotation + diffRotation,
      };
      return newPhotos;
    });
  };

  const handleActiveChange = (id) => {
    setCropModeActive(false);
    setActive((act) => {
      if (act === id) return null;
      return id;
    });
  };

  const resetPhoto = (id) => {
    setPhotos((photos) => {
      const newPhotos = { ...photos };
      newPhotos[id] = initialPhotoState;
      return newPhotos;
    });

    setActive((act) => {
      if (act === id) {
        setCropModeActive(false);
        return null;
      }
      return act;
    });
  };

  const handleSave = async () => {
    const left = await fetch(leftCanvasRef.current?.toDataURL()).then((res) =>
      res.blob()
    );
    const front = await fetch(frontCanvasRef.current?.toDataURL()).then((res) =>
      res.blob()
    );
    const right = await fetch(rightCanvasRef.current?.toDataURL()).then((res) =>
      res.blob()
    );
    const frontFull = await fetch(
      frontWithLensesCanvasRef.current?.toDataURL()
    ).then((res) => res.blob());
    onSave(front, frontFull, left, right);
  };

  return (
    <div className="wizard-container">
      <div className="wizard">
        <div className="wizard-header">
          <h4 className="wizard-header-title">{t("title")}</h4>
          <div className="wizard-header-buttons-container">
            <button
              className="wizard-header-button-save"
              type="button"
              onClick={handleSave}
            >
              {t("common:button.save")}
            </button>
            <button
              className="wizard-header-button-exit"
              type="button"
              onClick={onBack}
            >
              <TrashOutlined color="white" />
            </button>
          </div>
        </div>
        <div className="wizard-body">
          <div className="wizard-images-container">
            <div className="wizard-fitting-line" />
            <div className="wizard-fitting-line-text">{t("fittingLine")}</div>
            <div className="wizard-images">
              <ImagePreview
                title={t("rightEarpiece")}
                id="right"
                active={active}
                changeActive={handleActiveChange}
                modifications={photos.right}
                cropModeActive={cropModeActive && active === "right"}
                setCrop={handleCropFinished}
                resetPhoto={resetPhoto}
                mainCanvasRef={rightCanvasRef}
              />
              <ImagePreview
                title={t("front")}
                id="front"
                active={active}
                changeActive={handleActiveChange}
                modifications={photos.front}
                cropModeActive={cropModeActive && active === "front"}
                setCrop={handleCropFinished}
                resetPhoto={resetPhoto}
                mainCanvasRef={frontCanvasRef}
                frontWithLensesCanvasRef={frontWithLensesCanvasRef}
              />
              <ImagePreview
                title={t("leftEarpiece")}
                id="left"
                active={active}
                changeActive={handleActiveChange}
                modifications={photos.left}
                cropModeActive={cropModeActive && active === "left"}
                setCrop={handleCropFinished}
                resetPhoto={resetPhoto}
                mainCanvasRef={leftCanvasRef}
              />
            </div>
            <div className="wizard-fitting-line-text">{t("fittingLine")}</div>
          </div>
          <Menu
            disabled={!active}
            cropActive={cropModeActive}
            moveValue={photos[active]?.position?.y || 0}
            resizeValue={photos[active]?.scale || 1}
            rotateValue={photos[active]?.rotation || 0}
            onCrop={handleCropClick}
            onMove={handleMove}
            onResize={handleResize}
            onRotate={handleRotate}
          />
        </div>
      </div>
    </div>
  );
};

export default Wizard;
