import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useRouteMatch, withRouter } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardBody, Col, Button } from "reactstrap";
import {
  getTotemVersions,
  getTotem,
  deleteTotemVersion,
  changeVersionsOrder,
  updateVersions,
} from "./TotemsActions";
import _ from "lodash";
import RouterPaths from "constants/RouterPaths";
import TotemVersionTableRow from "./versions/TotemVersionTableRow";
import AddTotemVersionPopup from "./versions/AddTotemVersionPopup";
import CopyTotemPopup from "./CopyTotemPopup";
import TotemDetails from "./details/TotemDetails";
import CommonLoader from "common/components/loader/CommonLoader";
import ReactBSAlert from "react-bootstrap-sweetalert";
import BackButton from "common/components/BackButton";
import CommonDragableTable from "common/components/CommonDragableTable";
import AlertVariant from "common/components/alert/AlertVariant";
import { showAlert } from "common/components/alert/AlertActions";

const EditTotem = ({
  partner,
  getTotemVersions,
  getTotem,
  deleteTotemVersion,
  totemVersions,
  totem,
  changeVersionsOrder,
  updateVersions,
}) => {
  const { t } = useTranslation("totems");
  const [versionsData, setVersionsData] = useState({ elements: [] });
  const [showLoader, setShowLoader] = useState({
    totem: true,
    versions: true,
    config: true,
  });
  const match = useRouteMatch({
    path: RouterPaths.TOTEMS_EDIT,
    strict: true,
    sensitive: true,
  });
  const totem_id = _.get(match, "params.id", null);
  useEffect(() => {
    getTotemVersions(totem_id);
    getTotem(totem_id).then(() => setShowLoader({ totem: false }));
    setVersionsData({ elements: [] });
  }, [partner, totem_id, getTotemVersions, getTotem]);

  useEffect(() => {
    if (totemVersions) {
      setVersionsData({ elements: totemVersions });
      setShowLoader({ versions: false });
    }
  }, [totemVersions]);

  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    totem: null,
  });

  const deleteVersionHandler = () => {
    deleteTotemVersion(totem_id, deleteAlert.totem.id).then(() => {
      setDeleteAlert({ show: false, totem: null });
      getTotemVersions(totem_id);
    });
  };

  const addVersionHandler = () => {
    setVersionPopup({ show: true, totem: totem_id });
  };

  const changeActiveVersion = (item) => {
    updateVersions(totem_id, item.id, !item.version_active).then((result) => {
      if (result) {
        getTotemVersions(totem_id);
      } else {
        showAlert(t("form:submitError"), AlertVariant.DANGER);
      }
    });
  };

  const [versionPopup, setVersionPopup] = useState({
    show: false,
    totem: null,
  });

  const [copyPopup, setCopyPopup] = useState({
    show: false,
    currentTotem: null,
  });

  const headers = [
    { label: t("thumbnail") },
    { label: t("versionName") },
    { label: t("productName") },
    { label: t("active") },
    { label: t("delete") },
  ];

  return (
    <div className="content">
      {deleteAlert.show && (
        <ReactBSAlert
          warning
          showCancel
          cancelBtnText={t("common:button.cancel")}
          confirmBtnText={t("common:button.delete")}
          confirmBtnBsStyle="danger"
          title={t("deleteAlertTitle")}
          onConfirm={deleteVersionHandler}
          onCancel={() => setDeleteAlert({ show: false, totem: null })}
          focusCancelBtn
        />
      )}
      {versionPopup?.show && (
        <AddTotemVersionPopup
          totem={versionPopup.totem}
          cancelHandler={() => setVersionPopup({ totem: null, show: false })}
        />
      )}
      {copyPopup?.show && (
        <CopyTotemPopup
          currentTotem={totem}
          cancelHandler={() => setCopyPopup({ show: false, totem: null })}
        />
      )}
      <Col md="12">
        <BackButton link={RouterPaths.TOTEMS} />
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("totem")}</h4>
              <Button
                type="button"
                color="info"
                onClick={() => setCopyPopup({ show: true, totem })}
              >
                {t("copySettings")}
              </Button>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CommonLoader show={showLoader.totem} />
            {!showLoader.totem && <TotemDetails totem={totem} />}
          </CardBody>
        </Card>
      </Col>
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <Button
              type="button"
              color="info"
              onClick={() => addVersionHandler()}
            >
              <i className="nc-icon nc-ruler-pencil" /> {t("add_version")}
            </Button>
          </CardHeader>
          <CardBody>
            <CommonLoader show={showLoader.versions} />
            {!showLoader.versions && (
              <CommonDragableTable
                headers={headers}
                data={versionsData || []}
                rowComponent={TotemVersionTableRow}
                total={Number(versionsData?.elements?.length) || 0}
                onDrop={(from_id, to_id, direction) =>
                  changeVersionsOrder(from_id, to_id, direction, totem_id)
                }
                onDropCallback={(newData) =>
                  setVersionsData({ elements: newData })
                }
                pagination={false}
                rowComponentProps={{
                  deleteVersionHandler: (item) =>
                    setDeleteAlert({ show: true, totem: item }),
                  changeActiveVersion: (item) => {
                    changeActiveVersion(item);
                  },
                }}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ totems, partners }) => {
  return {
    totemVersions: totems.get("totemVersions"),
    totem: totems.get("totem"),
    partner: partners.get("partner"),
    totemConfig: totems.get("totemConfig"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTotemVersions,
      getTotem,
      deleteTotemVersion,
      changeVersionsOrder,
      updateVersions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditTotem));
