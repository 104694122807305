import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import DashboardStatusType from "../../constants/DashboardStatusType";
import { GET_DATA_SUCCESS, GET_DATA_ERROR } from "./DashboardReducer";

export const getDashboardData = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.DASHBOARD)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type:
            res?.data?.body.status_progress === DashboardStatusType.DONE
              ? GET_DATA_SUCCESS
              : GET_DATA_ERROR,
          payload: res?.data?.body,
        });
      }
    })
    .catch((err) => dispatch(handleError(err)));
};
