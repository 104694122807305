import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    title: Yup.string()
      .required("common:validator.required")
      .max(25, "common:validator.max25Chars"),
    message: Yup.string()
      .required("common:validator.required")
      .max(100, "common:validator.max100Chars"),
    notification_timestamp: Yup.string()
      .required("common:validator.required")
      .max(100, "common:validator.max100Chars")
      .test(
        "is-future",
        "common:validator.pastDate",
        (value) => new Date(value) > new Date()
      ),
  });
};
