import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";
import RouterPaths from "constants/RouterPaths";
import { Card, CardHeader, CardBody, CardTitle, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BackButton from "common/components/BackButton";
import AlertVariant from "common/components/alert/AlertVariant";
import { showAlert } from "common/components/alert/AlertActions";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getUser, editUser, deleteUser } from "./UserActions";
import validationSchema from "./form/UserForm.schema";
import UserForm from "./form/UserForm";
import { isMainPartner } from "common/utils/PartnerUtils";

const EditUser = ({
  user,
  currentUser,
  getUser,
  editUser,
  deleteUser,
  showAlert,
  history,
  partner,
}) => {
  const match = useRouteMatch({
    path: RouterPaths.EDIT_USER,
    strict: true,
    sensitive: true,
  });
  const id = _.get(match, "params.id", null);
  const { t } = useTranslation("user");

  const [initialValues, setInitialValues] = useState({});
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selfEditMode, setSelfEditMode] = useState(false);

  useEffect(() => {
    if (id) {
      getUser(id);
    }
  }, [id, getUser, partner]);

  useEffect(() => {
    if (user) {
      setInitialValues({
        email: user.user_email,
        status: {
          value: user.user_active,
          label: t(`statusOptions.${user.user_active}`),
        },
        role: {
          value: user.user_role,
          label: t(`roleOptions.${user.user_role}`),
        },
      });
    }
  }, [user, t]);

  useEffect(() => {
    setSelfEditMode(user?.user_email === currentUser?.email);
  }, [user, currentUser]);

  const onSubmit = (values, form) => {
    const editedUser = {
      id,
      active: values.status.value,
      role: values.role.value,
    };

    editUser(editedUser, form).then((formCorrect) => {
      form.setSubmitting(false);
      if (!formCorrect) return;
      showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
      history.push(RouterPaths.USERS);
    });
  };

  const onDeleteConfirmed = () => {
    deleteUser(id).then(() => {
      setShowDeleteAlert(false);
      showAlert(t("form:deleteSuccess"), AlertVariant.SUCCESS);
      history.push(RouterPaths.USERS);
    });
  };

  return (
    <div className="content">
      <Col md="12">
        <BackButton />
        {showDeleteAlert && (
          <ReactBSAlert
            warning
            showCancel
            cancelBtnText={t("common:button.cancel")}
            confirmBtnText={t("common:button.delete")}
            confirmBtnBsStyle="danger"
            title={t("deleteAlertTitle")}
            onConfirm={onDeleteConfirmed}
            onCancel={() => setShowDeleteAlert(false)}
            focusCancelBtn
          />
        )}
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <Col>
                <h4>{t("editUserTitle")}</h4>
              </Col>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {(props) => (
                <UserForm
                  selfEditMode={selfEditMode}
                  deleteHandler={() => setShowDeleteAlert(true)}
                  disableRoleField={isMainPartner(partner)}
                  disableEmail={true}
                  {...props}
                />
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = function ({ users, auth, partners }) {
  return {
    user: users.get("user"),
    currentUser: auth.get("user"),
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUser, editUser, deleteUser, showAlert }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditUser));
