import { fromJS } from "immutable";

const initialData = fromJS({
  isAuthenticated: false,
  user: null,
  partners: [],
});

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const GET_USER_PARTNERS_SUCCESS = "GET_USER_PARTNERS_SUCCESS";
export const SET_USER_ROLE = "SET_USER_ROLE";

export default (state = initialData, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return state.merge({
        isAuthenticated: true,
        user: action.payload,
      });
    case GET_USER_PARTNERS_SUCCESS:
      return state.merge({
        partners: action.payload,
      });
    case LOGOUT:
    case LOGIN_FAILURE:
      return state.merge(initialData);
    case SET_USER_ROLE:
      return state.merge({
        user: action.payload,
      });
    default:
      return state;
  }
};
