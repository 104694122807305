import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    name: Yup.string().max(50).required("common:validator.required"),
    privacyPolicyLink: Yup.string().max(200, "common:validator.max200Chars"),
    privacyPolicyContents: Yup.string(),
    partnerAppKey: Yup.string(),
    partnerAppStoreLink: Yup.string().max(200, "common:validator.max200Chars"),
    partnerPlayStoreLink: Yup.string().max(200, "common:validator.max200Chars"),
    partnerAppPrefix: Yup.string()
      .matches("^[a-z0-9]+$")
      .required("common:validator.required"),
    panelLanguage: Yup.string()
      .max(50, "common:validator.max50Chars")
      .required("common:validator.required"),
    webActive: Yup.boolean().required("common:validator.required"),
    qrcodesActive: Yup.boolean().required("common:validator.required"),
    mobileActive: Yup.boolean().required("common:validator.required"),
    totemActive: Yup.boolean().required("common:validator.required"),
    autocutActive: Yup.boolean().required("common:validator.required"),
    categoriesVisible: Yup.boolean().required("common:validator.required"),
    organizingProducts: Yup.boolean().required("common:validator.required"),
    versionsGrouped: Yup.boolean().required("common:validator.required"),
    thumbFileWidth: Yup.number()
      .integer()
      .required("common:validator.required"),
    thumbFileHeight: Yup.number()
      .integer()
      .required("common:validator.required"),
    thumbObjectScale: Yup.number().required("common:validator.required"),
    rotationIOS: Yup.number().required("common:validator.required"),
    rotationAndroid: Yup.number().required("common:validator.required"),
    rotationWeb: Yup.number().required("common:validator.required"),
    aspectRatioIOS: Yup.number().required("common:validator.required"),
    aspectRatioAndroid: Yup.number().required("common:validator.required"),
    aspectRatioWeb: Yup.number().required("common:validator.required"),
    positionYIOS: Yup.number().required("common:validator.required"),
    positionYAndroid: Yup.number().required("common:validator.required"),
    positionYWeb: Yup.number().required("common:validator.required"),
    positionZIOS: Yup.number().required("common:validator.required"),
    positionZAndroid: Yup.number().required("common:validator.required"),
    positionZWeb: Yup.number().required("common:validator.required"),
    partnerCurrency: Yup.string().required("common:validator.required"),
    notificationsActive: Yup.string().required("common:validator.required"),
    notificationsLimit: Yup.string()
      .nullable()
      .matches("^[0-9]*$")
      .when("notificationsActive", {
        is: "true",
        then: Yup.string()
          .matches("^[0-9]*$")
          .required("common:validator.required"),
      }),
    notificationsAppId: Yup.string()
      .nullable()
      .max(50)
      .when("notificationsActive", {
        is: "true",
        then: Yup.string().max(50).required("common:validator.required"),
      }),
    notificationsKey: Yup.string()
      .nullable()
      .max(50)
      .when("notificationsActive", {
        is: "true",
        then: Yup.string().max(50).required("common:validator.required"),
      }),
    pdMeasurementActive: Yup.boolean().required("common:validator.required"),
    googleAnalyticsActive: Yup.string().required("common:validator.required"),
    googleAnalyticsKey: Yup.string()
      .nullable()
      .max(50)
      .when("googleAnalyticsActive", {
        is: "true",
        then: Yup.string().max(50).required("common:validator.required"),
      }),
    analyticsDashboardActive: Yup.string().required(
      "common:validator.required"
    ),
    numberOfTotems: Yup.number().required("common:validator.required"),
    partnerTypes: Yup.array(Yup.object())
      .min(1, "common:validator.min1Type")
      .required("common:validator.required"),
  });
};
