import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import AlertVariant from "common/components/alert/AlertVariant";
import { showAlert } from "common/components/alert/AlertActions";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { bindActionCreators } from "redux";
import { addEditBanner } from "../TotemsActions";
import connect from "react-redux/es/connect/connect";
import validationSchema from "./BannerForm.schema";
import { Formik, Form, Field } from "formik";
import FormSelectField from "common/components/formFields/FormSelectField";
import FormInputField from "common/components/formFields/FormInputField";
import FormDropzoneField from "common/components/formFields/FormDropzoneField";
import FileExtension from "constants/FileExtension";
import _ from "lodash";
import { bannerTypesToSelect } from "common/utils/BannerUtils";
import { bannerTypes } from "constants/BannerTypes";

const AddBannerPopup = ({
  totem,
  banner,
  cancelHandler,
  showAlert,
  addEditBanner,
}) => {
  const { t } = useTranslation("totems");
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [initialValues, setInitialValues] = useState({
    banner_name: "",
    duration: "",
    displayed_time: "",
    active: { label: "", value: "" },
    file: null,
    bannerImageLink: null,
    bannerType: { label: "", value: "" },
  });

  useEffect(() => {
    if (banner) {
      setInitialValues({
        banner_name: banner.banner_name,
        duration: banner.banner_duration,
        displayed_time: banner.banner_displayed_time,
        active: {
          label: t(`statusOptions.${banner.banner_active}`),
          value: banner.banner_active,
        },
        file: null,
        banner_image_link: banner.banner_image_link,
        banner_type: {
          label: t(`bannerTypes.${banner.banner_type}`),
          value: banner.banner_type,
        },
      });
      setBannerImageUrl(banner.banner_image_link);
    }
  }, [banner, t]);

  const onFileDrop = (form, fileFieldName, linkFieldName, file) => {
    if (
      !_.isEmpty(form.values[linkFieldName]) ||
      form.values[`${fileFieldName}Uploaded`]
    ) {
      form.setFieldValue(`${fileFieldName}Deleted`, true);
    }
    form.setFieldValue(fileFieldName, file);
  };

  const onSubmit = (values) => {
    const data = {
      totem_id: totem,
      banner_name: values.banner_name,
      active: values.active.value,
      duration: values.duration,
      displayed_time: values.displayed_time,
      file: values.file ? values.file[0] : null,
      banner_image_link: values.banner_image_link,
      banner_type: values.banner_type.value,
    };

    addEditBanner(data, banner ? banner.banner_id : null).then(
      (formCorrect) => {
        if (formCorrect) {
          showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
        } else {
          showAlert(t("form:submitError"), AlertVariant.DANGER);
        }
      }
    );
    cancelHandler();
  };

  const changeImage = (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setBannerImageUrl(reader.result);
    });
    reader.readAsDataURL(file[0]);
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {(form) => (
        <Form className="totem-banner-form">
          <ReactBSAlert
            style={{ display: "block", width: "500px" }}
            onCancel={cancelHandler}
            title={""}
            onConfirm={onSubmit}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            showCancel
            btnSize=""
            customButtons={
              <>
                <Button type="button" onClick={cancelHandler} color="danger">
                  {t("common:button.cancel")}
                </Button>
                <Button type="submit" color="success">
                  {t("common:button.save")}
                </Button>
              </>
            }
          >
            {bannerImageUrl ? (
              <img
                className="banner-image-file"
                src={bannerImageUrl}
                alt="Banner"
                width={460}
              />
            ) : (
              ""
            )}
            <Field
              required
              fullWidth
              id="banner_name"
              type="text"
              name="banner_name"
              label={t("bannerName")}
              component={FormInputField}
            />
            <Field
              required
              fullWidth
              id="banner_type"
              name="banner_type"
              label={t("bannerType")}
              options={bannerTypesToSelect(bannerTypes)}
              component={FormSelectField}
            />
            <Field
              required
              fullWidth
              id="duration"
              type="number"
              name="duration"
              label={t("duration")}
              min={1}
              component={FormInputField}
            />
            <Field
              required
              fullWidth
              id="displayed_time"
              type="number"
              name="displayed_time"
              label={t("displayedTime")}
              min={1}
              component={FormInputField}
            />
            <Field
              required
              fullWidth
              id="active"
              name="active"
              label={t("status")}
              options={[
                { label: t("statusOptions.true"), value: true },
                { label: t("statusOptions.false"), value: false },
              ]}
              component={FormSelectField}
            />

            <FormDropzoneField
              id={`file`}
              name={`file`}
              label={t("bannerFile")}
              acceptedExtension={[FileExtension.PNG, FileExtension.JPG]}
              handleDrop={(file) => {
                onFileDrop(form, "file", "banner_image_link", file);
                changeImage(file);
              }}
              existingFile={
                !_.isEmpty(form.values.banner_image_link)
                  ? t("fileUploaded")
                  : ""
              }
              removeButton={false}
            />
          </ReactBSAlert>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ partners }) => {
  return {
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAlert,
      addEditBanner,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddBannerPopup);
