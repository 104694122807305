import React, { useState, useEffect } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Row, Col, Button } from "reactstrap";
import { Form } from "formik";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import AlertVariant from "common/components/alert/AlertVariant";
import { showAlert } from "common/components/alert/AlertActions";
import { bindActionCreators } from "redux";
import { deleteTotem, updateTotem } from "../TotemsActions";
import connect from "react-redux/es/connect/connect";
import validationSchema from "./TotemsForm.schema";
import { getTotemStatus } from "common/utils/TotemUtils";
import { getTotemStatusTooltip } from "common/utils/TotemUtils";
import { checkIfTotemActive } from "common/utils/TotemUtils";
import TotemDetailsForm from "./TotemDetailsForm";
import TotemConfigurationForm from "./TotemConfigurationForm";
import ActionButton from "common/components/ActionButton";
import RouterPaths from "constants/RouterPaths";
import { withRouter } from "react-router-dom";

const TotemDetails = ({
  totem,
  updateTotem,
  deleteTotem,
  showAlert,
  history,
}) => {
  const { t } = useTranslation("totems");
  const [initialValues, setInitialValues] = useState({});
  const [totemCurrentlyActive, setTotemCurrentlyActive] = useState(false);
  const [statusTooltip, setStatusTooltip] = useState("");
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);

  useEffect(() => {
    if (totem) {
      const totemData = {
        totemId: totem.totem_id,
        totemName: totem.totem_name,
        totemActive: totem.totem_active,
        totemStatus: getTotemStatus(totem),
        topImageLink: totem.top_image_link,
        middleImageLink: totem.middle_image_link,
        bottomImageLink: totem.bottom_image_link,
        fontColor: totem.font_color,
        backgroundColor: totem.background_color,
        progressBarBackground: totem.progressbar_background,
        progressBarFront: totem.progressbar_front,
        logoLink: totem.logo_link,
        productNameFontReversed: totem.product_name_font_reversed,
        qrCodes: totem.qr_codes,
        qrCodesText: totem.qr_codes_text,
        changeProductSeconds: totem.change_product_seconds,
        showWelcomeScreen: totem.show_welcome_screen,
        faceDistanceLimit: totem.face_distance_limit,
        hideTryonSeconds: totem.hide_tryon_seconds,
      };

      setTotemCurrentlyActive(checkIfTotemActive(totem));
      setStatusTooltip(getTotemStatusTooltip(totem));
      setInitialValues(totemData);
    }
  }, [totem]);

  const onSubmit = (values, { setSubmitting }) => {
    setShowSaveLoader(true);
    updateTotem(values).then((ok) => {
      if (ok) {
        showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
        document
          .getElementById("scrollableWrapper")
          .scrollTo({ top: 0, behavior: "smooth" });
      }
      setSubmitting(false);
      setShowSaveLoader(false);
    });
  };

  const deleteTotemHandler = () => {
    if (totem?.totem_id) {
      deleteTotem(totem.totem_id).then((ok) => {
        if (ok) {
          showAlert(t("form:deleteSuccess"), AlertVariant.SUCCESS);
          history.push(RouterPaths.TOTEMS);
        }
        setDeleteAlert(false);
      });
    }
  };

  return (
    <>
      {deleteAlert && (
        <ReactBSAlert
          warning
          showCancel
          cancelBtnText={t("common:button.cancel")}
          confirmBtnText={t("common:button.delete")}
          confirmBtnBsStyle="danger"
          title={t("deleteAlertTitle")}
          onConfirm={deleteTotemHandler}
          onCancel={() => setDeleteAlert(false)}
          focusCancelBtn
        />
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(form) => (
          <Form noValidate className="category-form">
            <TotemDetailsForm statusTooltip={statusTooltip} />
            <TotemConfigurationForm />
            <Col>
              <Row className="justify-content-end">
                <Button
                  type="button"
                  onClick={() => {
                    setDeleteAlert(true);
                  }}
                  className="mr-3 mt-5"
                  disabled={totemCurrentlyActive}
                  hidden={totemCurrentlyActive}
                  color="info"
                >
                  {t("common:button.delete")}
                </Button>
                <ActionButton
                  type="submit"
                  color="info"
                  className="mr-3 mt-5 p-0 actionButton"
                  disabled={form.isSubmitting}
                  label={t("common:button.save")}
                  showLoader={showSaveLoader}
                />
              </Row>
            </Col>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateTotem,
      deleteTotem,
      showAlert,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(TotemDetails));
