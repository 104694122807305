import React from "react";
import { FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";
import { Field, getIn } from "formik";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormDatePickeInner = (props) => {
  const {
    label,
    placeholder,
    form,
    field,
    timeIntervals = 30,
    noStyling = false,
    disabled = false,
    past = true,
    className,
  } = props;

  const { t } = useTranslation();

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const error = getIn(form.errors, field.name) || form.errors[field.name];
  const touched = getIn(form.touched, field.name);
  const value = ![null, undefined].includes(getIn(form.values, field.name))
    ? getIn(form.values, field.name)
    : "";

  const element = (
    <>
      <ReactDatePicker
        showTimeSelect
        timeIntervals={timeIntervals}
        showTime={{ use12hours: true }}
        dateFormat="dd/MM/yyyy hh:mm a"
        className={className}
        name={field.name}
        placeholder={placeholder}
        selected={value}
        filterTime={!past ? filterPassedTime : null}
        minDate={!past ? new Date() : null}
        disabled={form.isSubmitting || disabled}
        onBlur={() => form.setFieldTouched(field.name, true)}
        onChange={(date) => {
          form.setFieldValue(field.name, date);
        }}
      />
      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: "red" }}>{t(error)}</label>
      )}
    </>
  );

  return noStyling ? (
    element
  ) : (
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }}>
        <Label>{label}</Label>
        <FormGroup>{element}</FormGroup>
      </div>
    </div>
  );
};

const FormDatePickerField = (props) => {
  return <Field {...props} component={FormDatePickeInner} />;
};

FormDatePickerField.defaultProps = {
  label: "",
  placeholder: "",
  disabled: false,
};

FormDatePickerField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormDatePickerField;
