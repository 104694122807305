import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { Card, CardHeader, CardBody, CardTitle, Col, Row } from "reactstrap";
import CommonLoader from "common/components/loader/CommonLoader";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useEffect } from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { prepareUrl } from "common/utils/PrepareUrl";
import RouterPaths from "constants/RouterPaths";
import { getVisualReport } from "./AutocutActions";
import BackButton from "common/components/BackButton";
import face from "assets/img/face.png";

const AutocutReport = ({ report, visualReport, getVisualReport }) => {
  const [showLoader, setShowLoader] = useState(true);
  const { t } = useTranslation("autocut");

  useEffect(() => {
    if (!report || !report["SUCCESS"]) return;
    const ids = report["SUCCESS"];
    getVisualReport(ids);
  }, [report]);

  useEffect(() => {
    if (visualReport) setShowLoader(false);
  }, [visualReport]);

  return (
    <div className="content">
      <Col md="12">
        <BackButton />
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("report.title")}</h4>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CommonLoader show={showLoader} />
            {visualReport && (
              <>
                <Row>
                  <Col md="12">
                    {visualReport.map((item) => (
                      <Row className="report-data">
                        <Col>
                          <div style={{ width: "100%", display: "flex", justifyContent: "space-between"}}>
                              <Table>
                                <tbody>
                                  <tr>
                                    <th>{t("report.product")}</th>
                                    <td>{item.product_name}</td>
                                  </tr>
                                  <tr>
                                    <th>{t("report.version")}</th>
                                    <td>{item.version_name}</td>
                                  </tr>
                                  <tr>
                                    <th>External ID</th>
                                    <td>{item.external_id}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <Link
                                        to={prepareUrl(
                                          RouterPaths.EDIT_PRODUCT,
                                          {
                                            id: item.partners_product_id,
                                          }
                                        )}
                                        className="btn btn-info table-icon-button"
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                      >
                                        {t("report.edit")}
                                      </Link>
                                    </th>
                                  </tr>
                                </tbody>
                              </Table>
                            {item.version_autocut_preview && (
                              <div style={{ minWidth: "250px" }}>
                                <div style={{ position: "relative" }}>
                                  <img src={face} width="250" height="250" />
                                  <div
                                    style={{
                                      overflow: "hidden",
                                      position: "absolute",
                                      height: "136px",
                                      width: "136px",
                                      left: "57px",
                                      top: "57px",
                                    }}
                                  >
                                    <img
                                      src={item.version_autocut_preview}
                                      alt={`version_${item.version_id}_black`}
                                      height="136"
                                      width="408"
                                      style={{
                                        maxWidth: "unset",
                                        position: "absolute",
                                        left: "-136px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {item.version_autocut_preview && (
                            <>
                              <img
                                src={item.version_autocut_preview}
                                alt={`version_${item.version_id}_white`}
                                height="auto"
                                width="100%"
                                style={{
                                  maxWidth: "700px",
                                  background: "white",
                                }}
                              />
                              <img
                                src={item.version_autocut_preview}
                                alt={`version_${item.version_id}_black`}
                                height="auto"
                                width="100%"
                                style={{
                                  maxWidth: "700px",
                                  background: "black",
                                }}
                              />
                            </>
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ autocut }) => {
  return {
    visualReport: autocut.get("visualReport") || null,
    report: autocut.get("report") ?? {},
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVisualReport,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AutocutReport);
