import React, { useEffect } from "react";
import { useState } from "react";
import CategoryItem from "./CategoryItem";
import { Card, CardHeader, CardBody, CardTitle, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  addCategory,
  editCategory,
  deleteCategory,
  getCategories,
} from "./CategoryActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CategoryPopup from "./CategoryPopup";
import _ from "lodash";

const CategoryList = ({
  addCategory,
  editCategory,
  getCategories,
  categories,
  partner,
}) => {
  const [categoryList, setCategoryList] = useState([]);
  const [categoryPopup, setCategoryPopup] = useState({
    category: null,
    show: false,
  });
  const { t } = useTranslation("category");

  useEffect(() => {
    getCategories();
  }, [getCategories, partner]);

  useEffect(() => {
    if (categories) setCategoryList(categories);
  }, [categories]);

  const addCategoryHandler = (category) => {
    addCategory(category).then((newCategory) => {
      if (!newCategory) return;
      setCategoryList((list) => {
        list.push(newCategory);
        return list;
      });
      setCategoryPopup({ category: null, show: false });
    });
  };

  const editCategoryHandler = (category) => {
    editCategory(category).then(() => {
      setCategoryList((list) => {
        let index = list.findIndex((c) => c.id === category.id);
        list[index] = {
          ...list[index],
          category_name: category.name,
          category_photo_link: category.photoLink,
        };
        return list;
      });
      setCategoryPopup({ category: null, show: false });
    });
  };

  const renderCategory = (category) => {
    return (
      <CategoryItem
        key={category.id}
        category={category}
        editHandler={() => setCategoryPopup({ category: category, show: true })}
      />
    );
  };

  return (
    <div className="content">
      {categoryPopup?.show && (
        <CategoryPopup
          category={categoryPopup.category}
          addHandler={addCategoryHandler}
          editHandler={editCategoryHandler}
          cancelHandler={() =>
            setCategoryPopup({ category: null, show: false })
          }
        />
      )}
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("title")}</h4>
              <Button
                className="btn btn-info"
                onClick={() => setCategoryPopup({ category: null, show: true })}
              >
                {t("common:button.add")}
              </Button>
            </CardTitle>
          </CardHeader>
          <CardBody>
            {!_.isEmpty(categoryList) ? (
              categoryList.map((category, i) => renderCategory(category, i))
            ) : (
              <div className="list-no-items">
                <span>{t("common:noData")}</span>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ categories, partners }) => {
  return {
    categories: categories.get("categoryList") ?? [],
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { addCategory, editCategory, deleteCategory, getCategories },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
