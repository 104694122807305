import React from "react";
import { CardSubtitle, Row, Col } from "reactstrap";
import { Form, Field, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { typesToSelect } from "common/utils/TypeUtils";
import ActionButton from "common/components/ActionButton";
import FormSwitchField from "common/components/formFields/FormSwitchField";
import FormSelectField from "common/components/formFields/FormSelectField";
import ExtendedUserRoles from "constants/ExtendedUserRoles";
import FormInputField from "common/components/formFields/FormInputField";
import FormCopyToClipboardField from "common/components/formFields/FormCopytoClipboardField";
import FormEditorField from "common/components/formFields/FormEditorField";
import FormCheckboxField from "common/components/formFields/FormCheckboxField";
import FormMultiselectField from "common/components/formFields/FormMultiselectField";
import _ from "lodash";
import FormButtonGroupField from "common/components/formFields/FormButtonGroupField";
import { scrollToError } from "common/utils/FormUtils";
import {
  integerRegex,
  decimalRegex,
  decimalNegativeRegex,
  currenciesToSelect,
} from "common/utils/SettingsUtils";

import { currencies } from "constants/SettingsCurrencies";
import FormDatePickerField from "common/components/formFields/FormDatePickerField";
import Domains from "./Domains";
import AppLinks from "./AppLinks";
import Effects from "./Effects";

const SettingsForm = ({
  currentUser,
  partner,
  selectTypeHandler,
  deselectTypeHandler,
  showSaveLoader,
}) => {
  const { t } = useTranslation(["settings", "types"]);
  const form = useFormikContext();
  const isSuperadmin = currentUser.role === ExtendedUserRoles.SUPERADMIN;
  const isAdmin = currentUser.role === ExtendedUserRoles.ADMIN;

  return (
    <Form noValidate className="category-form">
      <Col className="settings-section">
        <Row>
          <CardSubtitle>
            <h5 className="settings-subtitle-text">{t("data")}</h5>
          </CardSubtitle>
        </Row>
        {partner && (
          <>
            {form.values?.id && (
              <Row>
                <Col md="12">
                  <Field
                    fullWidth
                    disabled
                    id="name"
                    name="name"
                    label={t("partner")}
                    component={FormInputField}
                  />
                </Col>
              </Row>
            )}
            {!form.values?.id && (
              <Row>
                <Col md="12">
                  <Field
                    fullWidth
                    id="name"
                    name="name"
                    label={t("partner")}
                    component={FormInputField}
                  />
                </Col>
              </Row>
            )}
            {form.values?.id && (
              <>
                <Row>
                  {isSuperadmin && (
                    <Col md={isSuperadmin ? 6 : 12}>
                      <Field
                        fullWidth
                        disabled
                        id="partnerAPIKey"
                        name="partnerAPIKey"
                        label={t("APIkey")}
                        component={FormCopyToClipboardField}
                      />
                    </Col>
                  )}
                  {(partner?.web_active || isSuperadmin) && (
                    <Col md={isSuperadmin ? 6 : 12}>
                      <Field
                        fullWidth
                        disabled
                        id="partnerWebAppKey"
                        name="partnerWebAppKey"
                        label={t("WebAppKey")}
                        component={FormCopyToClipboardField}
                      />
                    </Col>
                  )}
                  {isSuperadmin && (
                    <>
                      <Col md="6">
                        <Field
                          fullWidth
                          disabled
                          id="partnerAppKey"
                          name="partnerAppKey"
                          label={t("APPkey")}
                          component={FormCopyToClipboardField}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          id="totemsApiKey"
                          name="totemsApiKey"
                          component={FormCopyToClipboardField}
                          label={t("totemsApiKey")}
                          disabled
                        />
                      </Col>
                    </>
                  )}
                  {isSuperadmin && (
                    <>
                      <Col md="6">
                        <Field
                          id="notificationsAppId"
                          name="notificationsAppId"
                          component={FormInputField}
                          label={t("notificationsAppId")}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          id="notificationsKey"
                          name="notificationsKey"
                          component={FormInputField}
                          label={t("notificationsKey")}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </>
            )}
            {form.values.googleAnalyticsActive && (
              <Row>
                <Col>
                  <Field
                    id="googleAnalyticsKey"
                    name="googleAnalyticsKey"
                    component={FormInputField}
                    label={t("googleAnalyticsKey")}
                    disabled={!form.values.googleAnalyticsActive}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Field
                  fullWidth
                  id="panelLanguage"
                  name="panelLanguage"
                  options={[
                    { label: "pl", value: "pl" },
                    { label: "en", value: "en" },
                  ]}
                  component={FormSelectField}
                  label={t("language")}
                />
              </Col>
            </Row>
            {isSuperadmin && (
              <>
                <Row>
                  <Col>
                    <Field
                      fullWidth
                      id="partnerCurrency"
                      name="partnerCurrency"
                      options={currenciesToSelect(currencies)}
                      component={FormSelectField}
                      label={t("currency")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      fullWidth
                      id="partnerAppStoreLink"
                      name="partnerAppStoreLink"
                      label={t("appStoreLink")}
                      component={FormInputField}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      fullWidth
                      id="partnerPlayStoreLink"
                      name="partnerPlayStoreLink"
                      label={t("playStoreLink")}
                      component={FormInputField}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      fullWidth
                      id="partnerAppPrefix"
                      name="partnerAppPrefix"
                      label={t("appPrefix")}
                      component={FormInputField}
                    />
                  </Col>
                </Row>
              </>
            )}
            {isSuperadmin && (
              <Row>
                <Col>
                  <Field
                    fullWidth
                    id="bundleId"
                    name="bundleId"
                    label={t("bundleId")}
                    component={FormInputField}
                  />
                </Col>
                <Col>
                  <Field
                    fullWidth
                    id="packageName"
                    name="packageName"
                    label={t("packageName")}
                    component={FormInputField}
                  />
                </Col>
              </Row>
            )}
            {isSuperadmin && (
              <>
                <Row>
                  <Col>
                    <Field
                      fullWidth
                      id="partnerTypes"
                      name="partnerTypes"
                      options={typesToSelect(partner.types)}
                      component={FormMultiselectField}
                      selectOptionHandler={selectTypeHandler}
                      deselectOptionHandler={deselectTypeHandler}
                      label={t("types")}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Field
              id="privacyPolicyFromEditor"
              name="privacyPolicyFromEditor"
              label={t("privacyPolicySource")}
              onText={t("editor")}
              offText={t("link")}
              component={FormButtonGroupField}
            />
            {!form.values?.privacyPolicyFromEditor && (
              <Row>
                <Col>
                  <Field
                    id="privacyPolicyLink"
                    name="privacyPolicyLink"
                    component={FormInputField}
                    label={t("privacyPolicyLink")}
                  />
                </Col>
              </Row>
            )}
            {form.values?.privacyPolicyFromEditor && (
              <Row>
                <Col>
                  <Field
                    id="privacyPolicyContents"
                    name="privacyPolicyContents"
                    component={FormEditorField}
                    label={t("privacyPolicyContents")}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </Col>
      {isSuperadmin && (
        <Col className="settings-section settings-section-dark">
          <Row>
            <CardSubtitle>
              <h5 className="settings-subtitle-text">{t("functionalities")}</h5>
            </CardSubtitle>
          </Row>
          <Row>
            <Col lg="3" md="6">
              <Field
                id="webActive"
                name="webActive"
                component={FormCheckboxField}
                label={t("webActive")}
                className="settings-section-dark"
              />
            </Col>
            <Col lg="3" md="6">
              <Field
                id="qrcodesActive"
                name="qrcodesActive"
                component={FormCheckboxField}
                label={t("qrcodesActive")}
                className="settings-section-dark"
              />
            </Col>
            <Col lg="3" md="6">
              <Field
                id="mobileActive"
                name="mobileActive"
                component={FormCheckboxField}
                label={t("mobileActive")}
              />
            </Col>
            <Col lg="3" md="6">
              <Field
                id="totemActive"
                name="totemActive"
                component={FormCheckboxField}
                label={t("totemActive")}
              />
            </Col>
            <Col lg="3" md="6">
              <Field
                id="pdMeasurementActive"
                name="pdMeasurementActive"
                component={FormCheckboxField}
                label={t("pdMeasurementActive")}
              />
            </Col>
            <Col lg="3" md="6">
              <Field
                id="autocutActive"
                name="autocutActive"
                component={FormCheckboxField}
                label={t("autocutActive")}
              />
            </Col>
            <Col lg="3" md="6">
              <Field
                id="categoriesVisible"
                name="categoriesVisible"
                component={FormCheckboxField}
                label={t("categoriesVisible")}
              />
            </Col>
            <Col lg="3" md="6">
              <Field
                id="organizingProducts"
                name="organizingProducts"
                component={FormCheckboxField}
                label={t("organizingProducts")}
              />
            </Col>
            <Col lg="3" md="6">
              <Field
                id="versionsGrouped"
                name="versionsGrouped"
                component={FormCheckboxField}
                label={t("versionsGrouped")}
              />
            </Col>
            <Col lg="3" md="6">
              <Field
                id="googleAnalyticsActive"
                name="googleAnalyticsActive"
                component={FormCheckboxField}
                label={t("googleAnalyticsActive")}
              />
            </Col>
            <Col lg="3" md="6">
              <Field
                id="analyticsDashboardActive"
                name="analyticsDashboardActive"
                component={FormCheckboxField}
                label={t("analyticsDashboardActive")}
              />
            </Col>
            <Col lg="3" md="6">
              <Field
                id="marketplaceVisible"
                name="marketplaceVisible"
                component={FormCheckboxField}
                label={t("marketplaceVisible")}
              />
            </Col>
          </Row>
        </Col>
      )}
      {isSuperadmin && (
        <>
          <Col className="settings-section">
            <Row>
              <CardSubtitle>
                <h5 className="settings-subtitle-text">
                  {t("productSettings")}
                </h5>
              </CardSubtitle>
            </Row>
            <Row>
              <Col sm="4">
                <Field
                  id="rotationIOS"
                  name="rotationIOS"
                  component={FormInputField}
                  label={t("rotationIOS")}
                  type="regex"
                  regex={decimalNegativeRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="rotationAndroid"
                  name="rotationAndroid"
                  component={FormInputField}
                  label={t("rotationAndroid")}
                  type="regex"
                  regex={decimalNegativeRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="rotationWeb"
                  name="rotationWeb"
                  component={FormInputField}
                  label={t("rotationWeb")}
                  type="regex"
                  regex={decimalNegativeRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="aspectRatioIOS"
                  name="aspectRatioIOS"
                  component={FormInputField}
                  label={t("aspectRatioIOS")}
                  type="regex"
                  regex={decimalRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="aspectRatioAndroid"
                  name="aspectRatioAndroid"
                  component={FormInputField}
                  label={t("aspectRatioAndroid")}
                  type="regex"
                  regex={decimalRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="aspectRatioWeb"
                  name="aspectRatioWeb"
                  component={FormInputField}
                  label={t("aspectRatioWeb")}
                  type="regex"
                  regex={decimalRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="positionYIOS"
                  name="positionYIOS"
                  component={FormInputField}
                  label={t("positionYIOS")}
                  type="regex"
                  regex={decimalNegativeRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="positionYAndroid"
                  name="positionYAndroid"
                  component={FormInputField}
                  label={t("positionYAndroid")}
                  type="regex"
                  regex={decimalNegativeRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="positionYWeb"
                  name="positionYWeb"
                  component={FormInputField}
                  label={t("positionYWeb")}
                  type="regex"
                  regex={decimalNegativeRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="positionZIOS"
                  name="positionZIOS"
                  component={FormInputField}
                  label={t("positionZIOS")}
                  type="regex"
                  regex={decimalNegativeRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="positionZAndroid"
                  name="positionZAndroid"
                  component={FormInputField}
                  label={t("positionZAndroid")}
                  type="regex"
                  regex={decimalNegativeRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="positionZWeb"
                  name="positionZWeb"
                  component={FormInputField}
                  label={t("positionZWeb")}
                  type="regex"
                  regex={decimalNegativeRegex}
                />
              </Col>
              <Col sm="4">
                <Field
                  id="thumbObjectScale"
                  name="thumbObjectScale"
                  component={FormInputField}
                  label={t("thumbObjectScale")}
                  type="regex"
                  regex={decimalRegex}
                />
              </Col>
            </Row>
          </Col>
          <Col className="settings-section settings-notifications-section">
            <Row>
              <CardSubtitle>
                <h5 className="settings-subtitle-text">{t("notifications")}</h5>
              </CardSubtitle>
            </Row>
            <Row>
              <Col>
                <Field
                  id="notificationsActive"
                  name="notificationsActive"
                  component={FormSwitchField}
                  onText={t("common:button.yes").toUpperCase()}
                  offText={t("common:button.no").toUpperCase()}
                  label={t("notificationsActive")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  id="notificationsLimit"
                  name="notificationsLimit"
                  component={FormInputField}
                  label={t("notificationsLimit")}
                  disabled={!form.values.notificationsActive}
                  type="regex"
                  regex={integerRegex}
                />
              </Col>
            </Row>
          </Col>
        </>
      )}
      {form.values?.id && (isSuperadmin || partner.totem_active) && (
        <Col className="settings-section">
          <Row>
            <CardSubtitle>
              <h5 className="settings-subtitle-text">{t("totems")}</h5>
            </CardSubtitle>
          </Row>
          <Row>
            <Col sm="12">
              <Field
                id="totemsLicenseKey"
                name="totemsLicenseKey"
                component={FormCopyToClipboardField}
                label={t("totemsLicenseKey")}
                disabled
              />
            </Col>
            {isSuperadmin && (
              <>
                <Col sm="12" md="4">
                  <Field
                    id="totemsLicenseExpirationDate"
                    name="totemsLicenseExpirationDate"
                    component={FormDatePickerField}
                    label={t("totemsLicenseExpirationDate")}
                  />
                </Col>
                <Col sm="12" md="4">
                  <Field
                    id="numberOfTotems"
                    name="numberOfTotems"
                    component={FormInputField}
                    label={t("numberOfTotems")}
                    type="regex"
                    regex={integerRegex}
                  />
                </Col>
                <Col sm="12" md="4">
                  <Field
                    id="numberOfTotemsActive"
                    name="numberOfTotemsActive"
                    component={FormInputField}
                    label={t("numberOfTotemsActive")}
                    type="regex"
                    regex={integerRegex}
                    disabled
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
      )}
      {isSuperadmin && form.values?.webActive && (
        <Col className="settings-section">
          <Row>
            <CardSubtitle>
              <h5 className="settings-subtitle-text">{t("web")}</h5>
            </CardSubtitle>
          </Row>
          <Row>
            <Field
              id="webUsageRemaindersActive"
              name="webUsageRemaindersActive"
              component={FormSwitchField}
              onText={t("common:button.yes").toUpperCase()}
              offText={t("common:button.no").toUpperCase()}
              label={t("webUsageRemaindersActive")}
            />
          </Row>
          <Row>
            <Field
              id="webRequestsLimit"
              name="webRequestsLimit"
              component={FormInputField}
              label={t("webRequestsLimit")}
              type="regex"
              regex={integerRegex}
            />
          </Row>
        </Col>
      )}
      {isSuperadmin && <Domains form={form} />}
      {partner?.mobile_active && (isSuperadmin || isAdmin) && (
        <AppLinks form={form} />
      )}
      {form.values?.id && partner?.mobile_active && <Effects form={form} />}
      <Row className="justify-content-end">
        <ActionButton
          type="submit"
          color="info"
          className="mr-3 mt-5 p-0 actionButton"
          disabled={form.isSubmitting}
          onClick={async () => {
            const errors = await form.validateForm();
            if (!_.isEmpty(errors)) {
              scrollToError(errors);
              form.submitForm();
            }
          }}
          label={t("common:button.save")}
          showLoader={showSaveLoader}
        />
      </Row>
    </Form>
  );
};

export default SettingsForm;
