import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CardSubtitle, Row, Col, Button } from "reactstrap";
import { Field, FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import { showAlert } from "common/components/alert/AlertActions";
import FormListField from "common/components/formFields/FormListField";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { deleteDomain } from "./DomainsActions";
import AlertVariant from "common/components/alert/AlertVariant";

const Domains = ({ form, deleteDomain, showAlert }) => {
  const { t } = useTranslation("settings");
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    domain: null,
    idx: null,
  });

  const deleteHandler = () => {
    deleteDomain(deleteAlert.domain).then((formCorrect) => {
      setDeleteAlert({ show: false, domain: null, idx: null });
      if (formCorrect) {
        if (deleteField(deleteAlert.idx)) {
          showAlert(t("form:deleteSuccess"), AlertVariant.SUCCESS);
        }
      }
    });
  };

  const buttons = (domain, idx) => {
    return [
      {
        onClick: () =>
          domain.domain_id
            ? setDeleteAlert({ show: true, domain: domain.domain_id, idx: idx })
            : deleteField(idx),
        icon: <i className="nc-icon nc-simple-remove" />,
      },
    ];
  };

  const deleteField = (idx) => {
    const values = form.values.domains;
    values.splice(idx, 1);
    form.setFieldValue("domains", values);
    return true;
  };

  const addField = (name) => {
    const values = form.values.domains ?? [];
    values.push({ domain_id: "", domain_url: "" });
    form.setFieldValue(name, values);
  };

  return (
    <>
      {deleteAlert.show && (
        <ReactBSAlert
          warning
          showCancel
          cancelBtnText={t("common:button.cancel")}
          confirmBtnText={t("common:button.delete")}
          confirmBtnBsStyle="danger"
          title={t("deleteDomainTitle")}
          onConfirm={deleteHandler}
          onCancel={() =>
            setDeleteAlert({ show: false, domain: null, idx: null })
          }
          focusCancelBtn
        />
      )}
      <Col className="settings-section" style={{ marginBottom: "10px" }}>
        <Row>
          <CardSubtitle>
            <h5 className="settings-subtitle-text">{t("domains")}</h5>
          </CardSubtitle>
        </Row>

        <FieldArray name="domains">
          {({ name }) => (
            <div>
              {form.values.domains && form.values.domains.length > 0
                ? form.values.domains.map((e, idx) => (
                    <Row key={idx}>
                      <Col>
                        <Field
                          key={idx}
                          id={`domains[${idx}].domain_id`}
                          name={`domains[${idx}].domain_url`}
                          component={FormListField}
                          buttons={buttons(e, idx)}
                        />
                      </Col>
                    </Row>
                  ))
                : ""}

              <Row className="justify-content-end" style={{ marginTop: 10 }}>
                <Button className="btn btn-info" onClick={() => addField(name)}>
                  <i className="nc-icon nc-simple-add button-add-icon" />
                  {t("common:button.add")}
                </Button>
              </Row>
            </div>
          )}
        </FieldArray>
      </Col>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteDomain,
      showAlert,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(Domains));
