import React from "react";

const brandsToSelect = (brandList, t) => {
  const result = [];
  brandList.forEach((brand) => {
    return result.push({
      label: brand.brand_name,
      value: brand.id,
      additionalLabel: brand.from_main_partner ? (
        <span className="global-label">({t("brands:global")})</span>
      ) : (
        ""
      ),
    });
  });
  return result;
};

export { brandsToSelect };
