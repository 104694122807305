import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

const LinkTableRow = ({ item, number, editHandler, deleteHandler }) => {
  return (
    <>
      <td>{number}</td>
      <td>{item.product_name}</td>
      <td>{item.version_name}</td>
      <td>{(item.domain_name || "") + item.web_link_url}</td>
      <td className="text-right list-action-column">
        <div>
          <Button
            className="btn btn-info icon-button"
            onClick={() => editHandler(item)}
          >
            <i className="nc-icon nc-ruler-pencil" />
          </Button>
          <Button
            className="btn btn-danger icon-button"
            onClick={() => deleteHandler(item)}
          >
            <i className="nc-icon nc-simple-remove" />
          </Button>
        </div>
      </td>
    </>
  );
};

LinkTableRow.propTypes = {
  number: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  editHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
};

export default LinkTableRow;
