import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { Formik, Form, Field } from "formik";
import FormInputField from "common/components/formFields/FormInputField";
import FormCheckboxField from "common/components/formFields/FormCheckboxField";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import { getProductLog } from "./ProductActions";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { setingsFields } from "../../constants/LogFieldsSettings";
import { propertyGroupsToSelect } from "common/utils/PropertyUtils";

const LogLookupPopup = ({
  log_id,
  getProductLog,
  log,
  cancelHandler,
  partner,
  brands,
  categories,
  types,
  productProperties,
}) => {
  const { t } = useTranslation("product");

  const [oldRowData, setOldRowData] = useState({});
  const [newRowData, setNewRowData] = useState({});
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (log_id) {
      getProductLog({ log_id });
    }
  }, [log_id, getProductLog]);

  useEffect(() => {
    if (log) {
      setOldRowData(log.old_row_data);
      setNewRowData(log.new_row_data);
    }
  }, [log]);

  useEffect(() => {
    if (
      (newRowData && newRowData?.property_id) ||
      (oldRowData && oldRowData?.property_id)
    ) {
      const properties = propertyGroupsToSelect(productProperties);
      const logValues = {
        insertDeleteValues: {
          property: {
            value:
              newRowData && newRowData?.property_id
                ? properties.find(
                    (p) => p.propertyId === newRowData?.property_id
                  )
                : properties.find(
                    (p) => p.propertyId === oldRowData?.property_id
                  ),
            type: newRowData && newRowData?.property_id ? "insert" : "delete",
          },
        },
      };
      setInitialValues(logValues);
    } else if (
      newRowData &&
      oldRowData &&
      (newRowData.product_name || typeof newRowData.brand_id !== "undefined")
    ) {
      const logValues = {
        oldRowValues: {
          productName: oldRowData.product_name,
          productAspectRatioAndroid: oldRowData.product_aspect_ratio_android,
          productAspectRatioIos: oldRowData.product_aspect_ratio_ios,
          productAspectRatioWeb: oldRowData.product_aspect_ratio_web,
          productPositionYAndroid: oldRowData.product_position_y_android,
          productPositionYIos: oldRowData.product_position_y_ios,
          productPositionYWeb: oldRowData.product_position_y_web,
          productPositionZAndroid: oldRowData.product_position_z_android,
          productPositionZIos: oldRowData.product_position_z_ios,
          productPositionZWeb: oldRowData.product_position_z_web,
          productRotationAndroid: oldRowData.product_rotation_android,
          productRotationIos: oldRowData.product_rotation_ios,
          productRotationWeb: oldRowData.product_rotation_web,
          productEarRotationWeb: oldRowData.product_ear_rotation_web,
          productModelFileFbxUploaded:
            oldRowData.product_model_file_fbx_uploaded,
          productModelFileUploaded: oldRowData.product_model_file_uploaded,
          productPublished: oldRowData.product_published,
          productUseMetallicGlass: oldRowData.product_use_metallic_glass,
          productFilesZipLink: oldRowData.product_files_zip_link,
          productPhotosZipLink: oldRowData.product_photos_zip_link,
          productBrand: brands.find(
            (brand) => brand.id === oldRowData.brand_id
          ),
          productCategory: categories.find(
            (category) => category.id === oldRowData.category_id
          ),
          productType: types.find((type) => type.id === oldRowData.type_id),
        },
        newRowValues: {
          productName: newRowData.product_name,
          productAspectRatioAndroid: newRowData.product_aspect_ratio_android,
          productAspectRatioIos: newRowData.product_aspect_ratio_ios,
          productAspectRatioWeb: newRowData.product_aspect_ratio_web,
          productPositionYAndroid: newRowData.product_position_y_android,
          productPositionYIos: newRowData.product_position_y_ios,
          productPositionYWeb: newRowData.product_position_y_web,
          productPositionZAndroid: newRowData.product_position_z_android,
          productPositionZIos: newRowData.product_position_z_ios,
          productPositionZWeb: newRowData.product_position_z_web,
          productRotationAndroid: newRowData.product_rotation_android,
          productRotationIos: newRowData.product_rotation_ios,
          productRotationWeb: newRowData.product_rotation_web,
          productEarRotationWeb: newRowData.product_ear_rotation_web,
          productModelFileFbxUploaded:
            newRowData.product_model_file_fbx_uploaded,
          productModelFileUploaded: newRowData.product_model_file_uploaded,
          productPublished: newRowData.product_published,
          productUseMetallicGlass: newRowData.product_use_metallic_glass,
          productFilesZipLink: newRowData.product_files_zip_link,
          productPhotosZipLink: newRowData.product_photos_zip_link,
          productBrand: brands.find(
            (brand) => brand.id === newRowData.brand_id
          ),
          productCategory: categories.find(
            (category) => category.id === newRowData.category_id
          ),
          productType: types.find((type) => type.id === newRowData.type_id),
        },
        messages: {
          filesZipLinkUpdateSuccess: t("productFilesZipLinkChanged"),
          photosZipLinkUpdateSuccess: t("productPhotosZipLinkChanged"),
        },
      };
      setInitialValues(logValues);
    } else if (
      newRowData &&
      oldRowData &&
      (newRowData.version_name ||
        typeof newRowData.version_code !== "undefined")
    ) {
      const logValues = {
        oldRowValues: {
          versionName: oldRowData.version_name,
          versionCode: oldRowData.version_code,
          versionOrder: oldRowData.version_order,
          versionPrice: oldRowData.version_price,
          versionPublished: oldRowData.version_published,
          versionShopUrl: oldRowData.version_shop_url,
          versionSku: oldRowData.version_sku,
          versionSpecialPrice: oldRowData.version_special_price,
          versionTextureAlbedoUploaded:
            oldRowData.version_texture_albedo_uploaded,
          versionTextureMetallicUploaded:
            oldRowData.version_texture_metallic_uploaded,
          versionTextureNormalUploaded:
            oldRowData.version_texture_normal_uploaded,
          versionThumbLink: oldRowData.version_thumb_link,
          versionFrontFile: oldRowData.version_front_uploaded,
          versionLensesFile: oldRowData.version_lenses_uploaded,
          versionLeftFile: oldRowData.version_left_uploaded,
          versionRightFile: oldRowData.version_right_uploaded,
        },
        newRowValues: {
          versionName: newRowData.version_name,
          versionCode: newRowData.version_code,
          versionOrder: newRowData.version_order,
          versionPrice: newRowData.version_price,
          versionPublished: newRowData.version_published,
          versionShopUrl: newRowData.version_shop_url,
          versionSku: newRowData.version_sku,
          versionSpecialPrice: newRowData.version_special_price,
          versionTextureAlbedoUploaded:
            newRowData.version_texture_albedo_uploaded,
          versionTextureMetallicUploaded:
            newRowData.version_texture_metallic_uploaded,
          versionTextureNormalUploaded:
            newRowData.version_texture_normal_uploaded,
          versionThumbLink: newRowData.version_thumb_link,
          versionFrontFile: newRowData.version_front_uploaded,
          versionLensesFile: newRowData.version_lenses_uploaded,
          versionLeftFile: newRowData.version_left_uploaded,
          versionRightFile: newRowData.version_right_uploaded,
        },
        messages: {
          filesZipLinkUpdateSuccess: t("productFilesZipLinkChanged"),
          photosZipLinkUpdateSuccess: t("productPhotosZipLinkChanged"),
        },
      };
      setInitialValues(logValues);
    }
  }, [newRowData, oldRowData, brands, categories, productProperties, types, t]);

  const oldVal = initialValues?.oldRowValues;
  const newVal = initialValues?.newRowValues;
  const insertDeleteVal = initialValues?.insertDeleteValues;

  return (
    <>
      <ReactBSAlert
        title={t("logTitle")}
        onCancel={cancelHandler}
        onConfirm={() => {}}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        showCancel
        customButtons={
          <React.Fragment>
            <Button type="button" onClick={cancelHandler} color="danger">
              {t("common:button.cancel")}
            </Button>
          </React.Fragment>
        }
        style={{ width: "600px" }}
      >
        <Formik initialValues={initialValues} enableReinitialize>
          <Form noValidate>
            <Col className="logs-section">
              <h6>{log?.title}</h6>
              {insertDeleteVal &&
                Object.entries(insertDeleteVal).map(([name, data]) => {
                  const setting = setingsFields[name];
                  return (
                    <Row style={{ margin: "10px 0 5px 0" }}>
                      <Col style={{ marginTop: "5px" }}>
                        <span>{t(`${setting?.label}${data?.type}`)}</span>
                      </Col>
                      <Col>
                        <Field
                          disabled
                          id={`insertDeleteValues.${setting?.id}`}
                          name={`insertDeleteValues.${setting?.id}`}
                          component={FormInputField}
                        />
                      </Col>
                    </Row>
                  );
                })}
              {oldVal && newVal ? (
                <>
                  {Object.entries(oldVal).map(([name, value]) => {
                    if (value !== newVal[name]) {
                      const setting = setingsFields[name];
                      const component =
                        setting?.component &&
                        setting?.component === "FormCheckboxField"
                          ? FormCheckboxField
                          : FormInputField;
                      const params = {};
                      if (
                        setting?.suffix &&
                        setting?.suffix === "partner_currency"
                      ) {
                        params.suffix = partner.partner_currency;
                      }
                      if (setting?.label) {
                        params.label = t(setting.label);
                      }
                      params.component = component;
                      return (
                        <Row style={{ margin: "10px 0 5px 0" }}>
                          <Col>
                            <Field
                              disabled
                              id={`oldRowValues.${setting?.id}`}
                              name={
                                setting?.name
                                  ? setting.name
                                  : `oldRowValues.${setting?.id}`
                              }
                              {...params}
                            />
                          </Col>
                          <Col>
                            <Field
                              disabled
                              id={`newRowValues.${setting?.id}`}
                              name={
                                setting?.name
                                  ? setting.name
                                  : `newRowValues.${setting?.id}`
                              }
                              {...params}
                            />
                          </Col>
                        </Row>
                      );
                    }
                    return "";
                  })}
                </>
              ) : (
                ""
              )}
            </Col>
          </Form>
        </Formik>
      </ReactBSAlert>
    </>
  );
};

const mapStateToProps = ({ products, partners, brands, types, properties }) => {
  return {
    log: products.get("productLog"),
    partner: partners.get("partner"),
    brands: brands.get("brandList") ?? [],
    categories: products.get("categoryList") ?? [],
    types: types.get("typeList") ?? [],
    productProperties: properties.get("propertyGroupList") ?? [],
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProductLog }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LogLookupPopup);
