import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    email: Yup.string()
      .required("common:validator.required")
      .email("common:validator.badEmail"),
    status: Yup.object().shape({
      value: Yup.string().required("common:validator.required"),
    }),
    role: Yup.object().shape({
      value: Yup.string().required("common:validator.required"),
    }),
  });
};
