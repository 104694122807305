import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import { Col, Row, Button, UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";
import { Field, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import FormInputField from "common/components/formFields/FormInputField";
import FormPriceInputField from "common/components/formFields/FormPriceInputField";
import FormMultiselectField from "common/components/formFields/FormMultiselectField";
import FileExtension from "constants/FileExtension";
import _ from "lodash";
import FormDropzoneField from "common/components/formFields/FormDropzoneField";
import { propertyGroupsToSelect } from "common/utils/PropertyUtils";
import ThumbnailMaker from "common/components/ThumbnailMaker";
import GlassesPreview from "common/components/GlassesPreview";
import FormSwitchField from "common/components/formFields/FormSwitchField";
import ExtendedUserRoles from "constants/ExtendedUserRoles";
import FormCopyToClipboardField from "common/components/formFields/FormCopytoClipboardField";
import { showAlert } from "common/components/alert/AlertActions";
import { bindActionCreators } from "redux";
import { AUTH_TOKEN } from "auth/AuthConstants";
import { isMainPartner } from "common/utils/PartnerUtils";
import { REACT_APP_API_URL } from "constants/env";
import ProductTypes from "constants/ProductTypes";

const ProductVersionForm = ({
  id,
  index,
  arrayHelpers,
  deleteHandler,
  assignHandler,
  onFileDrop,
  form,
  selectPropertyHandler,
  deselectPropertyHandler,
  propertyGroups,
  thumbSize,
  thumbObjectScale,
  formSaving,
  partner,
  user,
  showTryOn,
  generate,
  onThumbAssign,
  isAssigned,
  fromMarketplace,
  fromOtherPartner,
}) => {
  const { t } = useTranslation("product");
  const { values } = useFormikContext();
  const [propertyList, setPropertyList] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const [fileUrls, setFileUrls] = useState({
    model: null,
    modelFbx: null,
    albedo: null,
    metallic: null,
    normal: null,
  });
  const [thumbPreviewSize, setThumbPreviewSize] = useState({
    width: 0,
    height: 0,
  });
  const [globalWebSettings, setGlobalWebSettings] = useState({
    aspectRatioWeb: 1,
    positionYWeb: 0,
    positionZWeb: 0,
    rotationWeb: 0,
  });
  const [popup, setPopup] = useState({
    version_id: null,
    show: false,
  });

  useEffect(() => {
    if (partner) {
      setGlobalWebSettings({
        aspectRatioWeb: partner.aspect_ratio_web,
        positionYWeb: partner.position_y_web,
        positionZWeb: partner.position_z_web,
        rotationWeb: partner.rotation_web,
      });
    }
  }, [partner]);

  const versionValues = values.versions[index];
  const modelFile = values.modelFile;
  const modelFileFbx = values.modelFileFbx;
  const albedoFile = versionValues.albedoFile;
  const metallicFile = versionValues.metallicFile;
  const normalFile = versionValues.normalFile;

  useEffect(() => {
    const oldUrls = { ...fileUrls };
    setFileUrls({
      model: modelFile ? URL.createObjectURL(modelFile) : null,
      modelFbx: modelFileFbx ? URL.createObjectURL(modelFileFbx) : null,
      albedo: albedoFile ? URL.createObjectURL(albedoFile) : null,
      metallic: metallicFile ? URL.createObjectURL(metallicFile) : null,
      normal: normalFile ? URL.createObjectURL(normalFile) : null,
    });

    if (oldUrls.model) URL.revokeObjectURL(oldUrls.model);
    if (oldUrls.modelFbx) URL.revokeObjectURL(oldUrls.modelFbx);
    if (oldUrls.albedo) URL.revokeObjectURL(oldUrls.albedo);
    if (oldUrls.metallic) URL.revokeObjectURL(oldUrls.metallic);
    if (oldUrls.normal) URL.revokeObjectURL(oldUrls.normal);

    return () => {
      if (fileUrls.model) URL.revokeObjectURL(fileUrls.model);
      if (fileUrls.modelFbx) URL.revokeObjectURL(fileUrls.modelFbx);
      if (fileUrls.albedo) URL.revokeObjectURL(fileUrls.albedo);
      if (fileUrls.metallic) URL.revokeObjectURL(fileUrls.metallic);
      if (fileUrls.normal) URL.revokeObjectURL(fileUrls.normal);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelFile, modelFileFbx, albedoFile, metallicFile, normalFile]);

  useEffect(() => {
    if (!thumbSize.width || !thumbSize.height) {
      return;
    }
    if (thumbSize.height <= 150) {
      setThumbPreviewSize(thumbSize);
      return;
    }
    const height = 150;
    const width = (thumbSize.width * height) / thumbSize.height;
    setThumbPreviewSize({ width, height });
  }, [thumbSize]);

  useEffect(() => {
    if (_.isEmpty(propertyGroups)) return;
    const newPropertyList = propertyGroupsToSelect(propertyGroups);
    setPropertyList(newPropertyList);
    if (form.values?.versions[index]?.properties) {
      form.setFieldValue(
        `versions[${index}].properties`,
        form.values.versions[index].properties.filter((prop) => {
          const wasFound =
            newPropertyList.findIndex((p) => p.value === prop.value) > -1;
          if (!wasFound && deselectPropertyHandler) {
            deselectPropertyHandler(prop, id);
          }
          return wasFound;
        })
      );
    }
    // eslint-disable-next-line
  }, [deselectPropertyHandler, propertyGroups]);

  useEffect(() => {
    if (
      !_.isEmpty(values.versions[index].thumbLink) ||
      !_.isEmpty(thumbnailUrl)
    ) {
      setThumbnailUrl(values.versions[index].thumbLink);
    }
    if (values.versions.length > 1) {
      setDeleteError("");
    }
  }, [values, index, thumbnailUrl]);

  const onThumbGenerated = (file) => {
    form.setFieldValue(`versions[${index}].autoGeneratedThumbFile`, file);
    if (typeof onThumbAssign === "function") {
      onThumbAssign();
    }
  };

  const loadTryOn = () => {
    if (
      document.getElementById("load-arlab") &&
      document.getElementById("arlab")
    )
      return;

    if (window.arlab?.q) window.arlab.q = [];

    const script = document.createElement("script");
    script.id = "load-arlab";
    script.async = true;
    script.innerHTML = `
      (function (w,d,s,o,f,js,fjs) {
        w['arlab-site-widgets']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
        if (d.getElementById(o)) return;
        js = d.createElement(s); 
        fjs = d.getElementsByTagName(s)[0];
        js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
      }(window, document, 'script', 'arlab', 'https://connect.ar-labs.io/v2/widget.js'));
      arlab('init', { apiKey:'b5f30e56-6a08-4418-b08c-7bdef2d0dd80', language: 'en' })
      arlab('panel', { clickedIndex: ${index}})
    `;
    document.head.appendChild(script);
  };

  const deleteVersion = () => {
    if (
      arrayHelpers.form.values?.versions.length === 1 ||
      ((arrayHelpers.form.values?.versions?.filter(
        (v) => v.isAssigned === true
      )).length === 1 &&
        (fromMarketplace || fromOtherPartner))
    ) {
      setDeleteError(t("versionsRequired"));
    } else {
      if (!fromMarketplace && !fromOtherPartner) arrayHelpers.remove(index);
      if (deleteHandler && values.versions[index]?.id)
        deleteHandler(values.versions[index]);
    }
  };

  const token = localStorage.getItem(AUTH_TOKEN);

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${values.versions[index].name}_${Date.parse(
      values.versions[index].modificationTime
    )}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Row>
      <Col md="12">
        <Col xs="12">
          <Row>
            <Col>
              <h5
                className="pt-4 mb-3"
                style={{
                  color:
                    !isAssigned && (fromMarketplace || fromOtherPartner)
                      ? "#e1e1e1"
                      : "#252422",
                }}
              >{`${t("version.header")} ${index + 1}`}</h5>
            </Col>
          </Row>
          <Row>
            <ThumbnailMaker
              size={thumbSize}
              objectScale={thumbObjectScale}
              fileUrls={fileUrls}
              index={index}
              onThumbReady={onThumbGenerated}
              productType={values.type.code}
              values={{
                ...values.versions[index],
                earRotationWeb: values.earRotationWeb,
              }}
              generate={generate}
            />
            {user.role === ExtendedUserRoles.SUPERADMIN &&
              values.type.code === ProductTypes.GLASSES && (
                <>
                  <Button
                    type="button"
                    onClick={() =>
                      setPopup({
                        version_id: values.versions[index].id,
                        show: true,
                      })
                    }
                    color="info"
                  >
                    {t("preview")}
                  </Button>
                  {popup?.show && (
                    <GlassesPreview
                      previewSize={thumbPreviewSize}
                      size={thumbSize}
                      objectScale={thumbObjectScale}
                      fileUrls={fileUrls}
                      index={index}
                      productType={values.type.code}
                      values={{
                        ...values.versions[index],
                        earRotationWeb: values.earRotationWeb,
                      }}
                      show={user.role === ExtendedUserRoles.SUPERADMIN}
                      cancelHandler={() =>
                        setPopup({ version_id: null, show: false })
                      }
                    />
                  )}
                </>
              )}
          </Row>
        </Col>
        <Row>
          <Col md="6" xs="12" style={{ alignSelf: "end" }}>
            {!fromMarketplace && !fromOtherPartner && (
              <Field
                id={`versions[${index}].published`}
                name={`versions[${index}].published`}
                label={
                  partner?.totem_active
                    ? t("version.publishedApp")
                    : t("version.published")
                }
                onChangeHandler={(value) => {
                  if (value && !form.values.published) {
                    form.setFieldValue("published", true);
                  }
                }}
                onText={t("common:button.yes").toUpperCase()}
                offText={t("common:button.no").toUpperCase()}
                component={FormSwitchField}
                multiple={partner?.totem_active}
                disabled={formSaving}
              />
            )}
            {id && (
              <>
                <Field
                  id={`versions[${index}].partnersVersionId`}
                  name={`versions[${index}].partnersVersionId`}
                  label={t("version.id")}
                  component={FormCopyToClipboardField}
                />
              </>
            )}
            {values.versions[index].modificationTime && (
              <Field
                fullWidth
                disabled
                id={`versions[${index}].modificationTime`}
                type="text"
                name={`versions[${index}].modificationTime`}
                label={t("version.modificationTime")}
                component={FormInputField}
              />
            )}
          </Col>
          <Col md="6" className="align-bottom">
            {id && partner?.mobile_active && (modelFile || modelFileFbx) && (
              <div className="QR-container">
                <div>
                  <label>{t("tryOnApp")}</label>
                  <Button
                    className="try-on-app-tooltip-button btn-round btn-icon"
                    color="info"
                    id="top"
                  >
                    <i className="fa fa-info" />
                  </Button>
                  <UncontrolledTooltip
                    placement="top"
                    target="top"
                    delay={0}
                    className="try-on-app-tooltip"
                  >
                    {t("tryOnAppTooltip")}
                  </UncontrolledTooltip>
                </div>
                <div
                  style={{
                    width: "220px",
                    maxWidth: "220px",
                    flexDirection: "column",
                    alignItems: "baseline",
                  }}
                >
                  <QRCode
                    value={JSON.stringify({
                      versionId: id,
                      productId: form.values.partnerProductId,
                      token: token,
                    })}
                    size={220}
                    includeMargin={true}
                    renderAs={"svg"}
                    xs="12"
                  />
                  {(user.role === ExtendedUserRoles.SUPERADMIN ||
                    partner?.qrcodes_active) &&
                    form.values.published && (
                      <>
                        <Button
                          className="download-qr-tooltip-button btn btn-info small"
                          style={{ margin: "0 10px", width: "200px" }}
                          id="download_qr"
                          onClick={downloadQRCode}
                        >
                          {t("downloadQR")}
                        </Button>
                        <UncontrolledTooltip
                          placement="left"
                          target="download_qr"
                          delay={0}
                          className="download-qr-tooltip"
                        >
                          {`${t("downloadQRTooltip")} ${partner.partner_name}`}
                        </UncontrolledTooltip>
                        <div hidden>
                          <QRCode
                            id="qr-gen"
                            value={`${REACT_APP_API_URL}/qr/${id}/${partner.id}`}
                            size={700}
                            includeMargin={true}
                          />
                        </div>
                      </>
                    )}
                </div>
                <p></p>
              </div>
            )}
          </Col>
        </Row>
        <Row></Row>
        <div>
          <div className="product-thumb-file-container">
            <>
              {!_.isEmpty(thumbnailUrl) ? (
                <img
                  className="product-thumb-file"
                  src={thumbnailUrl}
                  alt="Version thumb"
                  width={thumbPreviewSize.width}
                  height={thumbPreviewSize.height}
                />
              ) : (
                <div
                  className="product-thumb-file-preview"
                  style={{
                    width: thumbPreviewSize.width,
                    height: thumbPreviewSize.height,
                    lineHeight: `${thumbPreviewSize.height}px`,
                  }}
                >
                  {t("version.thumbPreview")}
                </div>
              )}
            </>
            <div
              className={
                modelFileFbx &&
                (user.role === ExtendedUserRoles.SUPERADMIN || showTryOn)
                  ? "webfit-wrapper"
                  : "d-none"
              }
            >
              <div
                className="webfit"
                id={`webfit-${index}`}
                model-url={fileUrls.modelFbx || fileUrls.model}
                use-metallic-glass={values.useMetallicGlass ? "true" : null}
                albedo-url={fileUrls.albedo}
                metallic-url={fileUrls.metallic}
                normal-url={fileUrls.normal}
                scale={globalWebSettings.aspectRatioWeb * values.aspectRatioWeb}
                rotation={globalWebSettings.rotationWeb + values.rotationWeb}
                position-y={
                  globalWebSettings.positionYWeb + values.positionYWeb
                }
                position-z={
                  globalWebSettings.positionZWeb + values.positionZWeb
                }
                ear-rotation={values.earRotationWeb}
              />
              <div id={`webfit-${index}-modal`} />
              <Button onClick={loadTryOn}>TRY ON</Button>
              <div className="webfit-warning">{t("tryOnWarning")}</div>
            </div>
          </div>
          <Field
            required
            fullWidth
            id={`versions[${index}].name`}
            type="text"
            name={`versions[${index}].name`}
            label={t("version.name")}
            component={FormInputField}
            disabled={
              formSaving ||
              ((fromMarketplace || fromOtherPartner) && !isAssigned)
            }
          />
          {partner?.mobile_active && (
            <>
              <Field
                fullWidth
                id={`versions[${index}].code`}
                type="text"
                name={`versions[${index}].code`}
                label={t("version.code")}
                component={FormInputField}
                disabled={formSaving || fromMarketplace || fromOtherPartner}
              />
              <Field
                fullWidth
                id={`versions[${index}].sku`}
                type="text"
                name={`versions[${index}].sku`}
                label={t("version.sku")}
                component={FormInputField}
                disabled={formSaving || fromMarketplace || fromOtherPartner}
              />
              <Field
                fullWidth
                id={`versions[${index}].pageLink`}
                type="text"
                name={`versions[${index}].pageLink`}
                label={t("version.pageLink")}
                component={FormInputField}
                disabled={
                  formSaving ||
                  (!isAssigned && (fromMarketplace || fromOtherPartner))
                }
              />
              <Field
                fullWidth
                id={`versions[${index}].price`}
                name={`versions[${index}].price`}
                label={t("version.price")}
                suffix={partner.partner_currency}
                component={FormPriceInputField}
                disabled={
                  formSaving ||
                  (!isAssigned && (fromMarketplace || fromOtherPartner))
                }
              />
              <Field
                fullWidth
                id={`versions[${index}].specialPrice`}
                name={`versions[${index}].specialPrice`}
                label={t("version.specialPrice")}
                suffix={partner.partner_currency}
                component={FormPriceInputField}
                disabled={
                  formSaving ||
                  (!isAssigned && (fromMarketplace || fromOtherPartner))
                }
              />
            </>
          )}
          {!fromMarketplace && !fromOtherPartner && (
            <FormDropzoneField
              id={`versions[${index}].thumbFile`}
              name={`versions[${index}].thumbFile`}
              label={t("version.thumbFile", {
                x: thumbSize.width,
                y: thumbSize.height,
              })}
              acceptedExtension={FileExtension.PNG}
              handleDrop={(file) =>
                onFileDrop(
                  `versions[${index}].thumbFile`,
                  `versions[${index}].thumbLink`,
                  file
                )
              }
              handleDelete={() =>
                onFileDrop(
                  `versions[${index}].thumbFile`,
                  `versions[${index}].thumbLink`,
                  null
                )
              }
              existingFile={
                !_.isEmpty(values.versions[index].thumbLink) &&
                !values.versions[index].thumbFileDeleted
                  ? t("fileUploaded")
                  : ""
              }
              disabled={formSaving}
            />
          )}
          {!fromMarketplace && !fromOtherPartner && (
            <FormDropzoneField
              id={`versions[${index}].albedoFile`}
              name={`versions[${index}].albedoFile`}
              label={t("version.albedoFile")}
              acceptedExtension={FileExtension.PNG}
              handleDrop={(file) =>
                onFileDrop(
                  `versions[${index}].albedoFile`,
                  `versions[${index}].albedoLink`,
                  file
                )
              }
              handleDelete={() =>
                onFileDrop(
                  `versions[${index}].albedoFile`,
                  `versions[${index}].albedoLink`,
                  null
                )
              }
              existingFile={
                values.versions[index].albedoFileUploaded &&
                !values.versions[index].albedoFileDeleted
                  ? t("fileUploaded")
                  : ""
              }
              disabled={formSaving}
            />
          )}
          {!fromMarketplace && !fromOtherPartner && (
            <FormDropzoneField
              id={`versions[${index}].metallicFile`}
              name={`versions[${index}].metallicFile`}
              label={t("version.metallicFile")}
              acceptedExtension={FileExtension.PNG}
              handleDrop={(file) =>
                onFileDrop(
                  `versions[${index}].metallicFile`,
                  `versions[${index}].metallicLink`,
                  file
                )
              }
              handleDelete={() =>
                onFileDrop(
                  `versions[${index}].metallicFile`,
                  `versions[${index}].metallicLink`,
                  null
                )
              }
              existingFile={
                values.versions[index].metallicFileUploaded &&
                !values.versions[index].metallicFileDeleted
                  ? t("fileUploaded")
                  : ""
              }
              disabled={formSaving}
            />
          )}
          {!fromMarketplace && !fromOtherPartner && (
            <FormDropzoneField
              id={`versions[${index}].normalFile`}
              name={`versions[${index}].normalFile`}
              label={t("version.normalFile")}
              acceptedExtension={FileExtension.PNG}
              handleDrop={(file) =>
                onFileDrop(
                  `versions[${index}].normalFile`,
                  `versions[${index}].normalLink`,
                  file
                )
              }
              handleDelete={() =>
                onFileDrop(
                  `versions[${index}].normalFile`,
                  `versions[${index}].normalLink`,
                  null
                )
              }
              existingFile={
                values.versions[index].normalFileUploaded &&
                !values.versions[index].normalFileDeleted
                  ? t("fileUploaded")
                  : ""
              }
              disabled={formSaving}
            />
          )}
          {partner?.mobile_active && (
            <Field
              fullWidth
              id={`versions[${index}].properties`}
              name={`versions[${index}].properties`}
              label={t("properties")}
              newOptionLabel={t("property:newProperty")}
              component={FormMultiselectField}
              options={propertyList}
              newOptionHandler={() => {}}
              selectOptionHandler={
                selectPropertyHandler
                  ? (option) => selectPropertyHandler(option, id)
                  : null
              }
              deselectOptionHandler={
                deselectPropertyHandler
                  ? (option) => deselectPropertyHandler(option, id)
                  : null
              }
              // currently properties not related to categories
              // warningText={
              //   !form?.values?.category?.value && t("selectCategoryFirst")
              // }
              disabled={
                formSaving ||
                (!isAssigned && (fromMarketplace || fromOtherPartner))
              }
            />
          )}
        </div>
        {!fromOtherPartner && !fromMarketplace && (
          <Row>
            <Col>
              {!isAssigned && fromMarketplace ? (
                <Button
                  type="button"
                  onClick={() => {
                    if (assignHandler) assignHandler(values.versions[index]);
                  }}
                  color="info"
                  style={{ float: "right", marginTop: "0px" }}
                  disabled={formSaving || form.isSubmitting}
                >
                  {t("common:button.add")}
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={deleteVersion}
                  color="danger"
                  style={{ float: "right", marginTop: "0px" }}
                  disabled={formSaving || form.isSubmitting}
                >
                  {t("common:button.delete")}
                </Button>
              )}
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <label style={{ color: "red", float: "right" }}>
              {deleteError}
            </label>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = function ({ properties, partners, auth, products }) {
  return {
    propertyGroups: properties.get("propertyGroupList") ?? [],
    partner: partners.get("partner") ?? null,
    user: auth.get("user") ?? null,
    fromMarketplace:
      (products.get("product")?.from_main_partner &&
        !isMainPartner(partners.get("partner"))) ??
      false,
    fromOtherPartner: products.get("product")?.from_other_partner,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showAlert }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductVersionForm);
