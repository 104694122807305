import React from "react";
import { Button } from "reactstrap";
import validationSchema from "./CategoryForm.schema";
import { Formik, Form, Field } from "formik";
import FormInputField from "common/components/formFields/FormInputField";
import ReactBSAlert from "react-bootstrap-sweetalert";
import FileExtension from "constants/FileExtension";
import FormDropzoneField from "common/components/formFields/FormDropzoneField";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const CategoryPopup = ({
  category,
  addHandler,
  editHandler,
  cancelHandler,
}) => {
  const { t } = useTranslation("category");

  const onFileDrop = (form, fileFieldName, linkFieldName, file) => {
    if (
      !_.isEmpty(form.values[linkFieldName]) ||
      form.values[`${fileFieldName}Uploaded`]
    ) {
      form.setFieldValue(`${fileFieldName}Deleted`, true);
    }
    form.setFieldValue(fileFieldName, file);
  };

  const onSubmit = (values, form) => {
    if (category == null) {
      const newCategory = {
        name: values.name,
        photoFile: values.photoFile,
      };
      addHandler(newCategory);
    } else {
      const editedCategory = {
        ...category,
        name: values.name,
        photoFile: values.photoFile,
        photoFileDeleted: values.photoFileDeleted,
      };
      editHandler(editedCategory);
    }
    form.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        name: category?.category_name || "",
        photoLink: category?.category_photo_link || "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(form) => (
        <Form noValidate className="category-form">
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={category ? t("editCategoryTitle") : t("addCategoryTitle")}
            onConfirm={onSubmit}
            onCancel={cancelHandler}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            showCancel
            btnSize=""
            customButtons={
              <React.Fragment>
                <Button type="button" onClick={cancelHandler} color="danger">
                  {t("common:button.cancel")}
                </Button>
                <Button type="submit" color="success">
                  {t("common:button.save")}
                </Button>
              </React.Fragment>
            }
          >
            <Field
              required
              fullWidth
              id="name"
              type="text"
              name="name"
              label={t("name")}
              component={FormInputField}
            />
            <FormDropzoneField
              id="photoFile"
              name="photoFile"
              label={t("photoFile")}
              acceptedExtension={FileExtension.PNG}
              handleDrop={(file) =>
                onFileDrop(form, "photoFile", "photoLink", file)
              }
              existingFile={
                !_.isEmpty(form.values.photoLink) ? t("fileUploaded") : ""
              }
            />
          </ReactBSAlert>
        </Form>
      )}
    </Formik>
  );
};

export default CategoryPopup;
