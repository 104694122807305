import i18n from "i18n/i18n";

const bannerTypesToSelect = (typesArr) => {
  if (!typesArr) return [];
  let res = typesArr.map((type) => {
    return { label: i18n.t(`totems:bannerTypes.${type}`), value: type };
  });
  return res;
};

export { bannerTypesToSelect };
