import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CardSubtitle, Row, Col, Button, InputGroup } from "reactstrap";
import { Field, FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import { showAlert } from "common/components/alert/AlertActions";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { deleteAppLink } from "./AppLinksActions";
import AlertVariant from "common/components/alert/AlertVariant";
import FormInputField from "common/components/formFields/FormInputField";

const AppLinks = ({ form, deleteAppLink, showAlert }) => {
  const { t } = useTranslation("settings");
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    link: null,
    idx: null,
  });

  const deleteHandler = () => {
    deleteAppLink(deleteAlert.link).then((formCorrect) => {
      setDeleteAlert({ show: false, link: null, idx: null });
      if (formCorrect) {
        deleteField(deleteAlert.idx);
        showAlert(t("form:deleteSuccess"), AlertVariant.SUCCESS);
      }
    });
  };

  const deleteField = (idx) => {
    const values = form.values.appLinks;
    values.splice(idx, 1);
    form.setFieldValue("appLinks", values);
    return true;
  };

  const addField = (name) => {
    const values = form.values.appLinks ?? [];
    values.push({ id: "", url: "", text: "" });
    form.setFieldValue(name, values);
  };

  const getDeleteButton = (e, idx) => {
    const btns = [
      {
        onClick: () =>
          e.id
            ? setDeleteAlert({ show: true, link: e.id, idx: idx })
            : deleteField(idx),
        icon: <i className="nc-icon nc-simple-remove" />,
      },
    ];

    if (btns.length > 0) {
      return (
        <>
          {btns.map((button) => (
            <Button
              key={"button"}
              size="sm"
              className="form-list-field-icon"
              color="neutral"
              onClick={button.onClick ? button.onClick : () => {}}
            >
              {button?.icon}
            </Button>
          ))}
        </>
      );
    }
    return "";
  };

  return (
    <>
      {deleteAlert.show && (
        <ReactBSAlert
          warning
          showCancel
          cancelBtnText={t("common:button.cancel")}
          confirmBtnText={t("common:button.delete")}
          confirmBtnBsStyle="danger"
          title={t("deleteAppLinksTitle")}
          onConfirm={deleteHandler}
          onCancel={() =>
            setDeleteAlert({ show: false, link: null, idx: null })
          }
          focusCancelBtn
        />
      )}
      <Col className="settings-section" style={{ marginBottom: "10px" }}>
        <Row>
          <CardSubtitle>
            <h5 className="settings-subtitle-text">{t("app_links")}</h5>
          </CardSubtitle>
        </Row>

        <FieldArray name="appLinks">
          {({ name }) => (
            <div>
              {form.values.appLinks && form.values.appLinks.length > 0
                ? form.values.appLinks.map((e, idx) => (
                    <Row key={`row-${e?.id}`} className="settings-app-link-row">
                      <InputGroup key={`group-${e?.id}`}>
                        <Col sm={6} className="app-link-field">
                          <label id="first">{t("link")}</label>
                          <Field
                            id={`appLinks[${idx}].id`}
                            name={`appLinks[${idx}].url`}
                            component={FormInputField}
                            noStyling={true}
                            type="text"
                          />
                        </Col>
                        <Col sm={5} className="app-link-field">
                          <label>{t("text")}</label>
                          <Field
                            id={`appLinks[${idx}].id`}
                            name={`appLinks[${idx}].text`}
                            component={FormInputField}
                            noStyling={true}
                            type="text"
                          />
                        </Col>
                        <Col sm={1} className="app-link-actions">
                          {getDeleteButton(e, idx)}
                        </Col>
                      </InputGroup>
                    </Row>
                  ))
                : ""}

              <Row className="justify-content-end" style={{ marginTop: 10 }}>
                <Button className="btn btn-info" onClick={() => addField(name)}>
                  <i className="nc-icon nc-simple-add button-add-icon" />
                  {t("common:button.add")}
                </Button>
              </Row>
            </div>
          )}
        </FieldArray>
      </Col>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteAppLink,
      showAlert,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(AppLinks));
