const propertyGroupsToSelect = (groups) => {
  if (!groups) return null;

  const properties = [];
  groups.forEach((group) => {
    if (!group.properties) return;
    group.properties.forEach((prop) => {
      properties.push({
        value: prop.id,
        label: `${group.property_group_name}: ${prop.property_name}`,
        propertyId: prop.id,
        propertyGroupId: group.id,
      });
    });
  });

  return properties;
};

const propertyListToSelect = (propertyList) => {
  if (!propertyList) return null;

  return propertyList.map((p) => {
    return {
      value: p.id,
      label: `${p.property_group_name}: ${p.property_name}`,
      propertyId: p.id,
      propertyGroupId: p.property_group_id,
    };
  });
};

const propertySelectToList = (propertySelectOptions) => {
  if (!propertySelectOptions) return [];

  return propertySelectOptions.map((p) => {
    return {
      id: p.propertyId,
    };
  });
};
export { propertyListToSelect, propertySelectToList, propertyGroupsToSelect };
