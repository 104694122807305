import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import RouterPaths from "../../constants/RouterPaths";
import { Link } from "react-router-dom";
import { prepareUrl } from "common/utils/PrepareUrl";

const TotemTableRow = ({ item, number }) => {
  const formatTimestamp = (str) => {
    return moment(str).format("L, LT");
  };

  const status = (totem) => {
    if (
      new Date().toISOString() >
      new Date(totem.totem_expiration_date).toISOString()
    ) {
      return t("totemStatus.expired");
    } else if (!totem.totem_deactivation_date && totem.totem_active) {
      return t("totemStatus.active");
    } else if (
      totem.totem_deactivation_date &&
      new Date(totem.totem_last_activity).toISOString() <
        new Date(totem.totem_deactivation_date).toISOString()
    ) {
      return t("totemStatus.inactivePending");
    } else if (
      !totem.totem_active &&
      new Date(totem.totem_last_activity).toISOString() >
        new Date(totem.totem_deactivation_date).toISOString()
    ) {
      return t("totemStatus.inactiveConfirmed");
    }
  };

  const { t } = useTranslation("totems");
  return (
    <>
      <td>{number}</td>
      <td>{status(item)}</td>
      <td>{item.totem_name}</td>
      <td>{item.totem_device_name}</td>
      <td>{formatTimestamp(item.totem_expiration_date)}</td>
      <td>{formatTimestamp(item.totem_last_activity)}</td>
      <td className="text-center" style={{ width: 70, minWidth: 70 }}>
        <Link
          to={prepareUrl(RouterPaths.TOTEM_BANNERS, {
            id: item.totem_id,
          })}
          className="btn btn-info icon-button"
        >
          <i className="nc-icon nc-image" />
        </Link>
      </td>
      <td className="text-center" style={{ width: 70, minWidth: 70 }}>
        <Link
          to={prepareUrl(RouterPaths.TOTEMS_EDIT, { id: item.totem_id })}
          className="btn btn-info icon-button"
        >
          <i className="nc-icon nc-ruler-pencil" />
        </Link>
      </td>
    </>
  );
};

TotemTableRow.propTypes = {
  number: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
};

export default TotemTableRow;
