import React, { useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Form, useFormikContext } from "formik";
import FormInputField from "common/components/formFields/FormInputField";
import FormSelectField from "common/components/formFields/FormSelectField";
import { connect } from "react-redux";
import { assignedOptions } from "constants/SearchOptions";
import { constantToSelect } from "common/utils/ConstantToSelect";

const MarketplaceSearchForm = ({ partner }) => {
  const { t } = useTranslation("product");
  const form = useFormikContext();
  useEffect(() => {
    form.resetForm();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  return (
    <Form>
      <Row>
        <Col>
          <FormInputField name="productName" label={t("name")} />
        </Col>
        <Col xl>
          <FormSelectField
            name="status"
            label={t("search.status")}
            options={constantToSelect(assignedOptions, "product:search")}
          />
        </Col>
        <Col className="search-button-container">
          <Button color="info" type="submit">
            {t("common:button.search")}
          </Button>
          <Button
            color="danger"
            type="submit"
            onClick={() => {
              form.resetForm();
            }}
          >
            {t("common:button.clear")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = function ({ partners }) {
  return {
    partner: partners.get("partner"),
  };
};

export default connect(mapStateToProps)(MarketplaceSearchForm);
