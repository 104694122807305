import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import {
  GET_IMPORT_DATA_SUCCESS,
  GET_REPORT_SUCCESS,
  UPDATE_IMPORT_DATA_SUCCESS,
  GET_VISUAL_REPORT_SUCCESS,
} from "./AutocutReducer";

export const getAutocutReport = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.AUTOCUT_REPORT)
    .then((res) => {
      dispatch({
        type: GET_REPORT_SUCCESS,
        payload: {
          report: res?.data?.body,
        },
      });
    })
    .catch((err) => dispatch(handleError(err)));
};

export const getVisualReport = (ids) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.AUTOCUT_VISUAL_REPORT, { ids })
    .then((res) => {
      dispatch({
        type: GET_VISUAL_REPORT_SUCCESS,
        payload: {
          importData: res?.data?.body,
        },
      });
      return true;
    })
    .catch((err) => dispatch(handleError(err)));
};

export const getImportData = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.AUTOCUT_IMPORT_DATA)
    .then((res) => {
      dispatch({
        type: GET_IMPORT_DATA_SUCCESS,
        payload: {
          importData: res?.data?.body,
        },
      });
    })
    .catch((err) => dispatch(handleError(err)));
};

export const updateImportData = (csvUrl) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.AUTOCUT_IMPORT_DATA, { csvUrl })
    .then((res) => {
      dispatch({
        type: UPDATE_IMPORT_DATA_SUCCESS,
        payload: {
          importData: res?.data?.body,
        },
      });
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const startImport = (csvUrl) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.AUTOCUT_RUN_IMPORT, { csvUrl })
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const notifyAboutReport = (partnerName) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.AUTOCUT_NOTIFY_ABOUT_IMPORT, { partnerName })
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const notifyAboutImportComplete = (subject, text) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.AUTOCUT_NOTIFY_ABOUT_IMPORT_COMPLETE, { subject, text })
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};
