import React, { useState } from "react";

const ButtonWithHold = ({ icon, disabled, action, longAction }) => {
  const [actionInterval, setActionInterval] = useState(null);
  const start = () => {
    action();
    if (longAction) {
      setActionInterval(setInterval(longAction, 200));
    }
  };
  const end = () => {
    if (actionInterval) clearInterval(actionInterval);
  };

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <button
      type="button"
      disabled={disabled}
      onMouseDown={start}
      onMouseUp={end}
      onMouseOut={end}
    >
      {icon}
    </button>
  );
};

export default ButtonWithHold;
