import history from "browserHistory.js";
import ApiEndpoints from "constants/ApiEndpoints";
import jwt from "jsonwebtoken";
import {
  apiClient,
  handleError,
  setAuthorizationHeader,
  setPartnerHeader,
} from "api/ApiClient";
import RouterPaths from "constants/RouterPaths";
import ExtendedUserRoles from "constants/ExtendedUserRoles";

import { getUserPartners } from "components/partners/PartnerActions";
import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from "./AuthReducer";
import { AUTH_TOKEN, PARTNER } from "./AuthConstants";

export const login = (token) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.LOGIN, { token })
    .then((res) => {
      const { token } = res?.data?.body;
      if (res?.status === 200 && token) {
        localStorage.setItem(AUTH_TOKEN, token);
        setUserInSession(dispatch);
        if (jwt.decode(token).role === ExtendedUserRoles.SUPERADMIN) {
          history.push(RouterPaths.USERS);
        } else {
          history.push(RouterPaths.PRODUCTS);
        }
      } else {
        clearToken();
        dispatch({ type: LOGIN_FAILURE });
      }
    })
    .catch((err) => {
      clearToken();
      dispatch(handleError(err));
    });
};

export const setUserInSession = (dispatch) => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const decoded = jwt.decode(authToken);
  if (decoded) {
    dispatch(getUserPartners(decoded.id));
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        id: decoded.id,
        email: decoded.email,
      },
    });
    setAuthorizationHeader(authToken);
  } else {
    dispatch(logout());
  }
};

export const clearToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(PARTNER);
  setAuthorizationHeader(null, () => {});
  setPartnerHeader(null);
};

export const logout = () => (dispatch) => {
  clearToken();
  dispatch({ type: LOGOUT });
};
