import React from "react";

const AppsTableRow = ({ item }) => {
  return (
    <>
      <td>{item.partner_name}</td>
      <td>{item.app_ios_version}</td>
      <td>{item.app_ios_test_version}</td>
      <td>{item.app_android_version}</td>
      <td>{item.app_android_test_version}</td>
    </>
  );
};

export default AppsTableRow;
