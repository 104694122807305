import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import CommonTable from "common/components/CommonTable";
import ReactBSAlert from "react-bootstrap-sweetalert";
import CommonLoader from "common/components/loader/CommonLoader";
import { showAlert } from "common/components/alert/AlertActions";
import AlertVariant from "common/components/alert/AlertVariant";
import Select from "react-select";
import { constantToSelect } from "common/utils/ConstantToSelect";
import moment from "../../moment/moment";
import NotificationTableRow from "./NotificationTableRow";
import {
  getNotifications,
  sendNotification,
  deleteNotification,
} from "./NotificationActions";
import NotificationPopup from "./NotificationPopup";

const statusOptions = {
  PLANNED: "planned",
  SENT: "sent",
  ALL: "all",
};

const Notifications = ({
  partner,
  notifications,
  getNotifications,
  sendNotification,
  deleteNotification,
}) => {
  const { t, i18n } = useTranslation("notifications");
  const [selectOptions, setSelectOptions] = useState([]);
  const [notificationPopup, setNotificationsPopup] = useState({
    notification: null,
    show: false,
  });
  const [notificationList, setNotificationList] = useState([]);
  const [notificationListToDisplay, setNotificationListToDisplay] = useState(
    []
  );
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const [statusVal, setStatusVal] = useState(selectOptions[0]);

  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    id: null,
  });

  useEffect(() => {
    setShowLoader(true);
    if (partner) {
      getNotifications({ statusVal: statusOptions.ALL }).finally(() => {
        setShowLoader(false);
      });
    }
  }, [partner, getNotifications]);

  useEffect(() => {
    updateOptions();
    i18n.on("languageChanged", () => {
      updateOptions();
    });
    return () => {
      i18n.off("languageChanged", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNotificationList(notifications);
  }, [notifications]);

  useEffect(() => {
    switch (statusVal?.value) {
      case statusOptions.PLANNED:
        setNotificationListToDisplay(
          notificationList.filter(
            (x) => new Date(x.notification_timestamp).getTime() > Date.now()
          )
        );
        break;
      case statusOptions.SENT:
        setNotificationListToDisplay(
          notificationList.filter(
            (x) => new Date(x.notification_timestamp).getTime() < Date.now()
          )
        );
        break;
      default:
        setNotificationListToDisplay(notificationList);
    }
  }, [notificationList, statusVal]);

  const updateOptions = () => {
    const selectOptionsNew = constantToSelect(
      statusOptions,
      "notifications:status"
    );
    setSelectOptions(selectOptionsNew);
    setStatusVal(selectOptionsNew[0]);
  };

  const sendNotificationHandler = (form, values) => {
    sendNotification(values, form).then((id) => {
      form.setSubmitting(false);
      if (id) {
        setNotificationList((list) => [
          {
            notification_title: values.title,
            notification_message: values.message,
            notification_timestamp: values.notification_timestamp,
            id,
          },
          ...list,
        ]);
      }
      setNotificationsPopup({ notification: null, show: false });
    });
  };

  const deleteNotificationHandler = (id) => {
    setShowDeleteLoader(true);
    deleteNotification(id)
      .then((deleted) => {
        setDeleteAlert({ show: false, id: null });
        if (deleted) {
          showAlert(t("form:deleteSuccess"), AlertVariant.SUCCESS);
          setShowLoader(true);
          const status = statusVal?.value;
          getNotifications({ statusVal: status }).finally(() => {
            setShowLoader(false);
          });
        }
      })
      .finally(() => {
        setShowDeleteLoader(false);
      });
  };

  const checkNotificationsLimit = () => {
    const currentMonth = moment().format("M");
    const monthlyCount = notificationList.filter((n) => {
      const date = moment(n.notification_timestamp);
      const month = date.format("M");
      return month === currentMonth;
    }).length;

    return (
      partner?.notifications_active &&
      monthlyCount >= partner?.notifications_limit
    );
  };

  const getFailedSinceLastNotification = (notificationList) => {
    const sentNotificationList = notificationList.filter(
      (x) =>
        new Date(x.notification_timestamp).getTime() < Date.now() &&
        x.onesignal_data
    );

    return sentNotificationList?.length > 1
      ? Math.max(
          sentNotificationList[0].onesignal_data?.failed -
            sentNotificationList[1]?.onesignal_data?.failed,
          0
        )
      : "-";
  };

  const headers = [
    {
      label: t("sendDate"),
    },
    {
      label: t("titleField"),
    },
    {
      label: t("message"),
    },
    {
      label: t("sent"),
    },
    {
      label: t("failed"),
    },
    {
      label: t("actions"),
    },
  ];

  const now = new Date();
  const day = now.toISOString().substring(0, 10);
  const yesterday = new Date(now.getDate() - 1).toISOString().substring(0, 10);

  return (
    <div className="content">
      {deleteAlert.show && (
        <ReactBSAlert
          warning
          showCancel
          cancelBtnText={t("common:button.cancel")}
          confirmBtnText={t("common:button.delete")}
          confirmBtnBsStyle="danger"
          title={t("deleteAlertTitle")}
          onConfirm={() => deleteNotificationHandler(deleteAlert.id)}
          onCancel={() => setDeleteAlert({ show: false, id: null })}
          focusCancelBtn
        />
      )}
      {notificationPopup?.show && (
        <NotificationPopup
          sendHandler={sendNotificationHandler}
          cancelHandler={() =>
            setNotificationsPopup({ notification: null, show: false })
          }
        />
      )}
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <span>
                <h4>{t("title")}</h4>
                <div>
                  {t("canSend", {
                    x:
                      partner?.onesignal_data?.[day] ||
                      partner?.onesignal_data?.[yesterday] ||
                      "-",
                  })}
                </div>
                <div>
                  {t("failedSinceLast", {
                    x: getFailedSinceLastNotification(notificationList),
                  })}
                </div>
              </span>
              <Button
                className="btn btn-info"
                onClick={() =>
                  setNotificationsPopup({ notification: {}, show: true })
                }
                disabled={checkNotificationsLimit()}
              >
                {t("common:button.add")}
              </Button>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div style={{ marginBottom: "20px" }}>
              <Select
                className="select-notification-status"
                name="status"
                options={selectOptions}
                value={statusVal}
                onChange={(option) => {
                  setStatusVal(option);
                }}
              />
            </div>
            <CommonLoader show={showLoader} />
            <CommonTable
              headers={headers}
              data={{ elements: notificationListToDisplay }}
              rowComponent={NotificationTableRow}
              total={notificationListToDisplay?.length || 0}
              pagination={false}
              rowComponentProps={{
                deleteHandler: (id) => setDeleteAlert({ show: true, id }),
                showDeleteLoader,
              }}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ notifications, partners }) => {
  return {
    notifications: notifications.get("notificationList") ?? [],
    partner: partners.get("partner") ?? null,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNotifications,
      sendNotification,
      deleteNotification,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
