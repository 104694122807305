import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Col,
  Button,
  Row,
} from "reactstrap";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showAlert } from "common/components/alert/AlertActions";
import AlertVariant from "common/components/alert/AlertVariant";
import RouterPaths from "constants/RouterPaths";
import BackButton from "common/components/BackButton";
import { addProduct } from "./ProductActions";
import ValidationSchema2d from "./form/Product2dForm.schema";
import ValidationSchema from "./form/ProductForm.schema";
import ProductForm from "./form/ProductForm";
import { propertySelectToList } from "common/utils/PropertyUtils";
import { prepareUrl } from "common/utils/PrepareUrl";
import Product2dForm from "./form/Product2dForm";
import ProductTypes from "constants/ProductTypes";

const initialValues = {
  published: false,
  name: "",
  brand: "",
  category: "",
  type: "",
  aspectRatioIos: 1,
  aspectRatioAndroid: 1,
  aspectRatioWeb: 1,
  positionYIos: 0,
  positionYAndroid: 0,
  positionYWeb: 0,
  positionZIos: 0,
  positionZAndroid: 0,
  positionZWeb: 0,
  rotationIos: 0,
  rotationAndroid: 0,
  rotationWeb: 0,
  earRotationWeb: 0,
  useMetallicGlass: false,
  versions: [
    {
      published: false,
      name: "",
      pageLink: "",
      thumbFile: null,
      albedoFile: null,
      metallicFile: null,
      normalFile: null,
      frontFile: null,
      lensesFile: null,
      leftFile: null,
      rightFile: null,
      sku: null,
    },
  ],
};

const AddProduct = ({ addProduct, showAlert, history, currentUser, types }) => {
  const { t } = useTranslation("product");
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [formSaving, setFormSaving] = useState(false);
  const [is2d, setis2d] = useState(false);

  useEffect(() => {
    document.getElementById("scrollableWrapper").scrollTo({ top: 0 });
  }, []);

  const onSubmit = (values, form) => {
    form.setSubmitting(false);
    if (!formSaving) return;

    const newProduct = {
      ...values,
      is2d: is2d,
      productLastChangedBy: currentUser.id,
      brandId: values.brand.value,
      categoryId: values.category.value,
      typeId: is2d
        ? types.find((type) => type.type_name === ProductTypes.GLASSES)?.id
        : values.type.value,
      properties: propertySelectToList(values.properties),
    };

    delete newProduct.brand;
    delete newProduct.category;
    delete newProduct.type;

    if (newProduct.versions) {
      newProduct.versions.map((v) => {
        v.properties = propertySelectToList(v.properties);
        v.pageLink = v.pageLink?.trim();
        if (!v.thumbFile) {
          v.thumbFile = v.autoGeneratedThumbFile;
        }
        return v;
      });
    }

    addProduct(newProduct, form).then((res) => {
      setFormSaving(false);
      setShowSaveLoader(false);
      if (res) {
        showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
        history.push(prepareUrl(RouterPaths.EDIT_PRODUCT, { id: res }));
      }
    });
  };

  return (
    <div className="content">
      <Col md="12">
        <BackButton />
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <Col>
                <h4>{t("addProductTitle")}</h4>
              </Col>
              <div className="header-button-container">
                <Row>
                  <Button
                    className="btn header-button"
                    onClick={() => setis2d(!is2d)}
                    style={{ height: "40px" }}
                    hidden={
                      !types?.some(
                        (type) => type.type_name === ProductTypes.GLASSES
                      )
                    }
                  >
                    {!is2d ? t("2dform") : t("3dform")}
                  </Button>
                </Row>
              </div>
            </CardTitle>
          </CardHeader>
          <CardBody>
            {!is2d && (
              <Formik
                initialValues={{
                  ...initialValues,
                }}
                onSubmit={onSubmit}
                validationSchema={ValidationSchema}
                enableReinitialize
              >
                {(form) => (
                  <ProductForm
                    form={form}
                    showSaveLoader={showSaveLoader}
                    setFormSaving={(value) => {
                      setFormSaving(value);
                      setShowSaveLoader(value);
                    }}
                    formSaving={formSaving}
                  />
                )}
              </Formik>
            )}
            {is2d && (
              <Formik
                initialValues={{
                  ...initialValues,
                }}
                onSubmit={onSubmit}
                validationSchema={ValidationSchema2d}
                enableReinitialize
              >
                {(form) => (
                  <Product2dForm
                    form={form}
                    showSaveLoader={showSaveLoader}
                    setFormSaving={(value) => {
                      setFormSaving(value);
                      setShowSaveLoader(value);
                    }}
                    formSaving={formSaving}
                  />
                )}
              </Formik>
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = function ({ auth, types }) {
  return {
    currentUser: auth.get("user"),
    types: types.get("typeList"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addProduct, showAlert }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddProduct));
