import { fromJS } from "immutable";

export const GET_LINKS_SUCCESS = "GET_LINKS_SUCCESS";

const initialData = fromJS({
  linkList: null,
  meta: {
    offset: 0,
  },
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_LINKS_SUCCESS:
      return state.merge({
        linkList: action.payload.links,
        meta: action.payload.meta,
      });
    default:
      return state;
  }
};
