import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";

export const deleteAppLink = (id) => (dispatch) => {
  return apiClient
    .delete(`${ApiEndpoints.APPLINKS}${id}`)
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};
