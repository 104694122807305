import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { getAllProductsWithVersions } from "components/products/ProductActions";
import ProductList from "components/analyticsDashboard/ProductList";
import CommonLoader from "common/components/loader/CommonLoader";
import { getDashboardData } from "./DashboardActions";
import LineChart from "./LineChart";
import DashboardStatusType from "../../constants/DashboardStatusType";

const AnalyticsDashboard = ({
  partner,
  getDashboardData,
  dashboardData,
  getAllProductsWithVersions,
  products,
  dashboardDataStatus,
}) => {
  const { t } = useTranslation("analyticsDashboard");
  const [topVersionIds, setTopVersionIds] = useState("");
  const [topVersionNames, setTopVersionNames] = useState([]);
  const [countRefresh, setCountRefresh] = useState(0);
  const [chartData, setChartData] = useState(null);
  const [campaignIds, setCampaignIds] = useState([]);

  useEffect(() => {
    if (partner) {
      getDashboardData();
      getAllProductsWithVersions();
    }
  }, [partner, getDashboardData, getAllProductsWithVersions]);

  useEffect(() => {
    if (dashboardDataStatus === DashboardStatusType.IN_PROGRESS) {
      setTimeout(() => {
        getDashboardData().then(() => {
          setCountRefresh(countRefresh + 1);
        });
      }, 5000);
    }
  }, [dashboardDataStatus, countRefresh]);

  const isDayDate = (isoStr) => {
    return isoStr.split("-").length === 3;
  };

  useEffect(() => {
    if (dashboardData) {
      const onlyDaysData = Object.keys(dashboardData.data)
        .filter((key) => isDayDate(key))
        .sort()
        .reduce((obj, key) => {
          obj[key] = dashboardData.data[key];
          return obj;
        }, {});
      setChartData(onlyDaysData);

      const campaignIds = [
        ...new Set(
          (onlyDaysData
            ? [...Object.values(onlyDaysData)].map(
                (daysEvents) => daysEvents.campaigns
              )
            : []
          )
            .map((campaign) => (campaign ? Object.keys(campaign) : []))
            .flat()
        ),
      ];
      setCampaignIds(campaignIds);

      const keys = Object.keys(dashboardData.data);
      const data = dashboardData.data[keys[keys.length - 1]];
      if (data.top) {
        setTopVersionIds(Object.keys(data.top));
      }
    }
  }, [dashboardData]);

  useEffect(() => {
    if (topVersionIds && products) {
      products.forEach((product) =>
        product.versions.forEach((version) => {
          version.display_name = `${product.product_name} ${version.version_name}`;
        })
      );

      const versions = products.map((product) => product.versions).flat();
      const topVersions = versions
        .filter((v) => topVersionIds.includes(v.id))
        .map((v) => v.display_name);
      setTopVersionNames(topVersions);
    }
  }, [topVersionIds, products]);

  const openAppLineChartData = {
    labels: chartData ? Object.keys(chartData) : [],
    datasets: [
      {
        label: "Android",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.androidOpens
            )
          : [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "IOS",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.iosOpens
            )
          : [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const productARLineChartData = {
    labels: chartData ? Object.keys(chartData) : [],
    datasets: [
      {
        label: "Android",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.androidAR
            )
          : [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "IOS",
        data: chartData
          ? [...Object.values(chartData)].map((daysEvents) => daysEvents.iosAR)
          : [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const product3DLineChartData = {
    labels: chartData ? Object.keys(chartData) : [],
    datasets: [
      {
        label: "Android",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.android3D
            )
          : [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "IOS",
        data: chartData
          ? [...Object.values(chartData)].map((daysEvents) => daysEvents.ios3D)
          : [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const photoLineChartData = {
    labels: chartData ? Object.keys(chartData) : [],
    datasets: [
      {
        label: "Android",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.androidPhoto
            )
          : [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "IOS",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.iosPhoto
            )
          : [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const searchLineChartData = {
    labels: chartData ? Object.keys(chartData) : [],
    datasets: [
      {
        label: "Android",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.androidSearch
            )
          : [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "IOS",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.iosSearch
            )
          : [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const pushLineChartData = {
    labels: chartData ? Object.keys(chartData) : [],
    datasets: [
      {
        label: "Android",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.androidPush
            )
          : [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "IOS",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.iosPush
            )
          : [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const productsWebLineChartData = {
    labels: chartData ? Object.keys(chartData) : [],
    datasets: [
      {
        label: "Web",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.webOpens
            )
          : [],
        borderColor: "rgb(255, 69, 0)",
        backgroundColor: "rgba(255, 69, 0, 0.5)",
      },
    ],
  };

  const productBuysLineChartData = {
    labels: chartData ? Object.keys(chartData) : [],
    datasets: [
      {
        label: "Android",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.androidProductBuys
            )
          : [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "IOS",
        data: chartData
          ? [...Object.values(chartData)].map(
              (daysEvents) => daysEvents.iosProductBuys
            )
          : [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const getClicked = (id) => {
    const campaignsData = chartData
      ? [...Object.values(chartData)].map((daysEvents) => daysEvents.campaigns)
      : [];

    const clickedFromCampaignsData = {
      labels: chartData ? Object.keys(chartData) : [],
      datasets: [
        {
          label: "Android",
          data: campaignsData.map((item) =>
            item?.[id] ? item[id].clicked.android : 0
          ),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "IOS",
          data: campaignsData.map((item) =>
            item?.[id] ? item[id].clicked.iPhone + item[id].clicked.iPad : 0
          ),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };
    return clickedFromCampaignsData;
  };

  const getInstalled = (id) => {
    const campaignsData = chartData
      ? [...Object.values(chartData)].map((daysEvents) => daysEvents.campaigns)
      : [];

    const installedFromCampaignsData = {
      labels: chartData ? Object.keys(chartData) : [],
      datasets: [
        {
          label: "Android",
          data: campaignsData.map((item) =>
            item && item[id] ? item[id].installed.android : 0
          ),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "IOS",
          data: campaignsData.map((item) =>
            item && item[id]
              ? item[id].installed.iPhone + item[id].installed.iPad
              : 0
          ),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };
    return installedFromCampaignsData;
  };

  const now = new Date();
  const today = now.toISOString().substring(0, 10);
  const yesterday = new Date(now.getDate() - 1).toISOString().substring(0, 10);

  const onesignalPlayers =
    partner?.onesignal_players &&
    (partner.onesignal_players[today] || partner.onesignal_players[yesterday])
      ? Math.round(
          Number(
            partner.onesignal_players[today] ||
              partner.onesignal_players[yesterday]
          ) * 1.5
        )
      : null;

  return (
    <div className="content">
      <Col>
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("title")}</h4>
              <div style={{ paddingRight: "20px" }}>
                {onesignalPlayers &&
                  t("numberOfDownloads", { x: onesignalPlayers })}
              </div>
            </CardTitle>
          </CardHeader>
          <CardBody className="analytics-chart">
            {chartData && partner.mobile_active && (
              <>
                <Row>
                  <Col lg="12" xl="6">
                    <LineChart
                      data={openAppLineChartData}
                      title={t("openapp")}
                    />
                  </Col>
                  <Col lg="12" xl="6">
                    <LineChart
                      data={productARLineChartData}
                      title={t("productar")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" xl="6">
                    <LineChart
                      data={product3DLineChartData}
                      title={t("product3d")}
                    />
                  </Col>
                  <Col lg="12" xl="6">
                    <ProductList
                      productList={topVersionNames}
                      title={t("topProducts")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" xl="6">
                    <LineChart data={searchLineChartData} title={t("search")} />
                  </Col>
                  <Col lg="12" xl="6">
                    <LineChart data={photoLineChartData} title={t("photo")} />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              {chartData && partner.mobile_active && (
                <Col lg="12" xl="6">
                  <LineChart data={pushLineChartData} title={t("push")} />
                </Col>
              )}
              {chartData && partner.mobile_active && (
                <Col lg="12" xl="6">
                  <LineChart
                    data={productBuysLineChartData}
                    title={t("productBuys")}
                  />
                </Col>
              )}
            </Row>
            {campaignIds.map((campaignId) => (
              <Row key={campaignId}>
                <Col lg="12" xl="6">
                  <LineChart
                    data={getClicked(campaignId)}
                    title={t("clickedCampaign") + campaignId}
                  />
                </Col>
                <Col lg="12" xl="6">
                  <LineChart
                    data={getInstalled(campaignId)}
                    title={t("installedCampaign") + campaignId}
                  />
                </Col>
              </Row>
            ))}
            <Row>
              {chartData && partner.web_active && (
                <Col lg="12" xl="6">
                  <LineChart
                    data={productsWebLineChartData}
                    title={t("webByBrowser")}
                  />
                </Col>
              )}
            </Row>
            {!chartData &&
            dashboardDataStatus === DashboardStatusType.IN_PROGRESS ? (
              <>
                <h5>{t("noDataInProgress")}</h5>
                <CommonLoader
                  show={dashboardDataStatus === DashboardStatusType.IN_PROGRESS}
                />
              </>
            ) : (
              <>
                <CommonLoader show={!chartData} />
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = function ({ dashboard, partners, products }) {
  return {
    partner: partners.get("partner"),
    dashboardData: dashboard.get("data"),
    dashboardDataStatus: dashboard.get("status"),
    products: products.get("allProductList") ?? [],
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getDashboardData, getAllProductsWithVersions },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AnalyticsDashboard));
