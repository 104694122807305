import React from "react";
import ButtonWithHold from "./ButtonWithHold";
import NumberRangeInput from "./NumberRangeInput";
import { useTranslation } from "react-i18next";

const Menu = ({
  disabled,
  onCrop,
  onResize,
  onMove,
  onRotate,
  cropActive,
  resizeValue,
  rotateValue,
}) => {
  const { t } = useTranslation("wizard");

  return (
    <div className="wizard-menu">
      <p>{t("selectInfo")}</p>
      <div className="wizard-menu-controls">
        <div className="wizard-crop-button-container">
          <p className="label">{t("manualCrop")}</p>
          <div className="wizard-menu-crop-button-frame">
            <button disabled={disabled} type="button" onClick={onCrop}>
              {cropActive ? t("saveCrop") : t("crop")}
            </button>
          </div>
        </div>
        <NumberRangeInput
          label={t("resizeInfo")}
          iconStart={<i className="nc-icon nc-simple-delete" />}
          iconEnd={<i className="nc-icon nc-simple-add" />}
          value={`${Math.round(resizeValue * 100)}%`}
          startDisabled={disabled || cropActive}
          endDisabled={disabled || cropActive}
          actionStart={() => onResize(-0.01)}
          actionEnd={() => onResize(0.01)}
          longActionStart={() => onResize(-0.05)}
          longActionEnd={() => onResize(0.05)}
        />
        <NumberRangeInput
          label={t("rotateInfo")}
          iconStart={<i className="nc-icon nc-simple-delete" />}
          iconEnd={<i className="nc-icon nc-simple-add" />}
          value={`${Math.round(rotateValue * 10) / 10}`}
          startDisabled={disabled || cropActive}
          endDisabled={disabled || cropActive}
          actionStart={() => onRotate(-0.5)}
          actionEnd={() => onRotate(0.5)}
          longActionStart={() => onRotate(-3)}
          longActionEnd={() => onRotate(3)}
        />
        <div className="wizard-move-buttons-container">
          <ButtonWithHold
            icon={<i className="nc-icon nc-minimal-up" />}
            disabled={disabled || cropActive}
            action={() => {
              onMove(0, -1);
            }}
            longAction={() => {
              onMove(0, -5);
            }}
          />
          <ButtonWithHold
            icon={<i className="nc-icon nc-minimal-left" />}
            disabled={disabled || cropActive}
            action={() => {
              onMove(-1, 0);
            }}
            longAction={() => {
              onMove(-5, 0);
            }}
          />
          <ButtonWithHold
            icon={<i className="nc-icon nc-minimal-down" />}
            disabled={disabled || cropActive}
            action={() => {
              onMove(0, 1);
            }}
            longAction={() => {
              onMove(0, 5);
            }}
          />
          <ButtonWithHold
            icon={<i className="nc-icon nc-minimal-right" />}
            disabled={disabled || cropActive}
            action={() => {
              onMove(1, 0);
            }}
            longAction={() => {
              onMove(5, 0);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Menu;
