import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import { Field, getIn } from "formik";
import { useTranslation } from "react-i18next";

export const FormInputFieldType = {
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  TIME: "time",
  PASSWORD: "password",
  //allows only input that matches regex
  REGEX: "regex",
};

const FormInputFieldInner = (props) => {
  const {
    label,
    idValue,
    placeholder,
    type,
    step,
    min,
    form,
    field,
    noStyling = false,
    disabled = false,
    regex = null,
    className,
  } = props;

  const { t } = useTranslation();

  const error = getIn(form.errors, field.name) || form.errors[field.name];
  const touched = getIn(form.touched, field.name);
  const value = ![null, undefined].includes(getIn(form.values, field.name))
    ? getIn(form.values, field.name)
    : "";

  const id = ![null, undefined].includes(getIn(form.values, idValue))
    ? getIn(form.values, idValue)
    : "";

  const element = (
    <>
      <Input
        className={className}
        name={field.name}
        placeholder={placeholder}
        type={type === FormInputFieldType.REGEX ? "text" : type}
        step={step || 1}
        min={min || null}
        value={value}
        id={id}
        disabled={form.isSubmitting || disabled}
        onBlur={() => form.setFieldTouched(field.name, true)}
        onChange={({ target }) => {
          if (type !== FormInputFieldType.REGEX || regex?.test(target.value))
            form.setFieldValue(field.name, target.value);
        }}
      />
      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: "red" }}>{t(error)}</label>
      )}
    </>
  );

  return noStyling ? (
    element
  ) : (
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }}>
        {label && <Label>{label}</Label>}
        <FormGroup>{element}</FormGroup>
      </div>
    </div>
  );
};

const FormInputField = (props) => {
  return <Field {...props} component={FormInputFieldInner} />;
};

FormInputField.defaultProps = {
  label: "",
  type: FormInputFieldType.TEXT,
  placeholder: "",
  disabled: false,
};

FormInputField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormInputField;
