import { apiClient, handleError } from "api/ApiClient";
import { prepareTotemFormData } from "common/utils/PrepareFormData";
import { prepareBannerFormData } from "common/utils/PrepareFormData";
import { setCurrentPartnerById } from "components/partners/PartnerActions";
import ApiEndpoints from "constants/ApiEndpoints";
import {
  GET_TOTEMS_SUCCESS,
  GET_TOTEM_SUCCESS,
  GET_TOTEM_VERSIONS_SUCCESS,
  GET_TOTEM_VERSION_DELETED_SUCCESS,
  GET_TOTEM_BANNERS_SUCCESS,
  BANNER_DELETED_SUCCESS,
  DELETE_TOTEM_SUCCESS,
} from "./TotemsReducer";

export const getTotems = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.TOTEMS)
    .then((res) => {
      dispatch({
        type: GET_TOTEMS_SUCCESS,
        payload: {
          totems: res?.data?.body,
        },
      });
    })
    .catch((err) => dispatch(handleError(err)));
};
export const getTotem = (totem_id) => (dispatch) => {
  const params = { totem_id };
  return apiClient
    .get(ApiEndpoints.TOTEM, { params })
    .then((res) => {
      const totem = res?.data?.body;
      const partnerId = totem?.partner_id;
      if (partnerId) {
        dispatch(setCurrentPartnerById(partnerId));
      }
      dispatch({
        type: GET_TOTEM_SUCCESS,
        payload: {
          totem,
        },
      });
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};
export const getTotemVersions = (totem_id) => (dispatch) => {
  const params = { totem_id: totem_id };
  return apiClient
    .get(ApiEndpoints.TOTEM_VERSIONS, { params })
    .then((res) => {
      dispatch({
        type: GET_TOTEM_VERSIONS_SUCCESS,
        payload: {
          totemVersions: res?.data?.body,
        },
      });
    })
    .catch((err) => dispatch(handleError(err)));
};

export const addTotemVersion = (values, form) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.TOTEM_VERSIONS, values)
    .then(() => {
      return dispatch(getTotemVersions(values.totem_id)).then(() => {
        return true;
      });
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const deleteTotemVersion =
  (totemId, partnersVersionId) => (dispatch) => {
    const params = {
      partners_version_id: partnersVersionId,
      totem_id: totemId,
    };
    return apiClient
      .delete(ApiEndpoints.TOTEM_VERSIONS, { params })
      .then(() => {
        dispatch({ type: GET_TOTEM_VERSION_DELETED_SUCCESS });
        return true;
      })
      .catch((err) => {
        dispatch(handleError(err));
        return false;
      });
  };

export const updateTotem = (totem) => (dispatch) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const totemData = prepareTotemFormData(totem);
  return apiClient
    .put(ApiEndpoints.TOTEMS, totemData, config)
    .then(() => {
      return dispatch(getTotem(totem.totemId));
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const deleteTotem = (totemId) => (dispatch) => {
  const params = { totemId };
  return apiClient
    .delete(ApiEndpoints.TOTEMS, { params })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_TOTEM_SUCCESS,
        });
        return true;
      }
      return false;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const getBanners = (totem_id) => (dispatch) => {
  const params = { totem_id: totem_id };
  return apiClient
    .get(ApiEndpoints.TOTEM_BANNERS, { params })
    .then((res) => {
      dispatch({
        type: GET_TOTEM_BANNERS_SUCCESS,
        payload: {
          banners: res?.data?.body,
        },
      });
    })
    .catch((err) => dispatch(handleError(err)));
};

export const addEditBanner = (data, banner_id) => (dispatch) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const formData = prepareBannerFormData(data);

  if (banner_id) {
    return dispatch(editBanner(formData, banner_id, data.totem_id, config));
  } else {
    return dispatch(addBanner(formData, data.totem_id, config));
  }
};

export const addBanner = (formData, totem_id, config) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.TOTEM_BANNERS, formData, config)
    .then(() => {
      return dispatch(getBanners(totem_id)).then(() => {
        return true;
      });
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const editBanner =
  (formData, banner_id, totem_id, config) => (dispatch) => {
    return apiClient
      .put(`${ApiEndpoints.TOTEM_BANNERS}${banner_id}`, formData, config)
      .then(() => {
        return dispatch(getBanners(totem_id)).then(() => {
          return true;
        });
      })
      .catch((err) => {
        dispatch(handleError(err));
        return false;
      });
  };

export const deleteBanner =
  (totemId, bannerId, bannerImageLink) => (dispatch) => {
    const params = { banner_image_link: bannerImageLink };
    return apiClient
      .delete(`${ApiEndpoints.TOTEM_BANNERS}${bannerId}`, { params })
      .then(() => {
        return dispatch(getBanners(totemId)).then(() => {
          dispatch({ type: BANNER_DELETED_SUCCESS });
          return true;
        });
      })
      .catch((err) => {
        dispatch(handleError(err));
        return false;
      });
  };

export const copyTotem = (fromTotemId, toTotemId) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.TOTEM_COPY, { from: fromTotemId, to: toTotemId })
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const changeVersionsOrder =
  (from_id, to_id, direction, totem_id) => (dispatch) => {
    return apiClient
      .put(`${ApiEndpoints.TOTEMS}/order/`, {
        totem_id,
        from_id,
        to_id,
        direction,
      })
      .then((res) => {
        if (res.status === 200) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        dispatch(handleError(err));
        return false;
      });
  };

export const updateVersions =
  (totem_id, version_id, version_active) => (dispatch) => {
    return apiClient
      .put(`${ApiEndpoints.TOTEM_VERSIONS}`, {
        totem_id,
        version_id,
        version_active,
      })
      .then((res) => {
        if (res.status === 200) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        dispatch(handleError(err));
        return false;
      });
  };
