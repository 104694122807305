import React from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { assignedOptions } from "constants/SearchOptions";
import MarketplaceSearchForm from "./MarketplaceSearchForm";
import { useState } from "react";
import { useEffect } from "react";

const MarketplaceSearch = ({ onSearchCallback }) => {
  const { t, i18n } = useTranslation("product");
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({
      productName: "",
      status: { label: t("search.all"), value: assignedOptions.ALL },
    });
    i18n.on("languageChanged", () => {
      setInitialValues({
        productName: "",
        status: { label: t("search.all"), value: assignedOptions.ALL },
      });
    });
    return () => {
      i18n.off("languageChanged", () => {});
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values, { setSubmitting }) => {
    onSearchCallback({
      productNameSearch: values.productName,
      assignedSearch:
        values.status.value === assignedOptions.ALL
          ? null
          : values.status.value === assignedOptions.ASSIGNED,
      offset: 0,
    });
    setSubmitting(false);
  };

  return (
    <Row>
      <Col md="12">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => {
            return <MarketplaceSearchForm />;
          }}
        </Formik>
      </Col>
    </Row>
  );
};

export default MarketplaceSearch;
