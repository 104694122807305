import React from "react";
import { Button } from "reactstrap";
import CommonLoader from "./loader/CommonLoader";

const ActionButton = ({
  type,
  onClick,
  color,
  className,
  disabled,
  label,
  showLoader,
}) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      color={color}
      className={className}
      disabled={disabled}
      width={"50px"}
    >
      {showLoader ? <CommonLoader show={true} className="p-0" /> : label}
    </Button>
  );
};

export default ActionButton;
