import { fromJS } from "immutable";

export const GET_TYPES_SUCCESS = "GET_TYPES_SUCCESS";

const initialData = fromJS({
  typeList: null,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_TYPES_SUCCESS:
      return state.merge({
        typeList: action.payload.types,
      });
    default:
      return state;
  }
};
