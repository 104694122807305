import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as MagnifyingGlassPlus } from "../icons/magnifyingGlassPlus.svg";
import { ReactComponent as MagnifyingGlassMinus } from "../icons/magnifyingGlassMinus.svg";
import ButtonWithHold from "../ButtonWithHold";

const EditorBottomMenu = ({
  disabled,
  zoom,
  setZoom,
  previewOriginal,
  setPreviewOriginal,
  canvasRef,
  positionRef,
}) => {
  const { t } = useTranslation("wizard");

  const onMove = useCallback(
    (xTranslation, yTranslation) => {
      const xLimit = Math.round((canvasRef.current.width * (1 - 1 / zoom)) / 2);
      const yLimit = Math.round(
        (canvasRef.current.height * (1 - 1 / zoom)) / 2
      );
      positionRef.current.x += xTranslation;
      positionRef.current.y += yTranslation;
      positionRef.current.x = Math.max(
        Math.min(positionRef.current.x, xLimit),
        -xLimit
      );
      positionRef.current.y = Math.max(
        Math.min(positionRef.current.y, yLimit),
        -yLimit
      );
    },
    [canvasRef, positionRef, zoom]
  );

  useEffect(() => {
    if (zoom >= 1) onMove(0, 0);
  }, [onMove, zoom]);

  return (
    <>
      <div className="editor-canvas-zoom-container">
        <button
          disabled={disabled}
          type="button"
          onClick={() => {
            setZoom((z) => z + 0.1);
          }}
        >
          <MagnifyingGlassPlus color="#101828" />
        </button>
        <button
          disabled={disabled}
          type="button"
          onClick={() => {
            setZoom((z) => z - 0.1);
          }}
        >
          <MagnifyingGlassMinus color="#101828" />
        </button>
      </div>
      <button
        disabled={disabled}
        type="button"
        className="editor-canvas-preview-original-button"
        style={
          !disabled
            ? {
                border: previewOriginal
                  ? "1px solid #BA1176"
                  : "1px solid black",
              }
            : {}
        }
        onClick={() => setPreviewOriginal((po) => !po)}
      >
        {t("previewOriginal")}
      </button>
      {zoom > 1 && (
        <div className="editor-canvas-move-buttons-container">
          <ButtonWithHold
            disabled={disabled}
            icon={<i className="nc-icon nc-minimal-up" />}
            action={() => {
              onMove(0, -1);
            }}
            longAction={() => {
              onMove(0, -5);
            }}
          />
          <ButtonWithHold
            disabled={disabled}
            icon={<i className="nc-icon nc-minimal-left" />}
            action={() => {
              onMove(-1, 0);
            }}
            longAction={() => {
              onMove(-5, 0);
            }}
          />
          <ButtonWithHold
            disabled={disabled}
            icon={<i className="nc-icon nc-minimal-down" />}
            action={() => {
              onMove(0, 1);
            }}
            longAction={() => {
              onMove(0, 5);
            }}
          />
          <ButtonWithHold
            disabled={disabled}
            icon={<i className="nc-icon nc-minimal-right" />}
            action={() => {
              onMove(1, 0);
            }}
            longAction={() => {
              onMove(5, 0);
            }}
          />
        </div>
      )}
    </>
  );
};

export default EditorBottomMenu;
