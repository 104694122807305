import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import {
  GET_PROPERTY_GROUPS_SUCCESS,
  ADD_PROPERTY_GROUP_SUCCESS,
  EDIT_PROPERTY_GROUP_SUCCESS,
  DELETE_PROPERTY_GROUP_SUCCESS,
  GET_PROPERTY_CATEGORIES_SUCCESS,
} from "./PropertyGroupReducer";

export const getPropertyGroups = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.PROPERTY_GROUPS)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_PROPERTY_GROUPS_SUCCESS,
          payload: {
            propertyGroups: res?.data.propertyGroups,
          },
        });
      }
      return res?.data?.body;
    })
    .catch((err) => dispatch(handleError(err)));
};

export const getCategories = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.CATEGORIES)
    .then((res) => {
      dispatch({
        type: GET_PROPERTY_CATEGORIES_SUCCESS,
        payload: {
          categoryList: res?.data?.body,
        },
      });
      return res?.data?.body;
    })
    .catch((err) => dispatch(handleError(err)));
};

export const addPropertyGroup = (property, form) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.PROPERTY_GROUPS, property)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: ADD_PROPERTY_GROUP_SUCCESS });
      }
      return res?.data?.id;
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const editPropertyGroup = (property, form) => (dispatch) => {
  return apiClient
    .put(`${ApiEndpoints.PROPERTY_GROUPS}${property.id}`, property)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: EDIT_PROPERTY_GROUP_SUCCESS,
        });
      }
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const deletePropertyGroup = (id) => (dispatch) => {
  return apiClient
    .delete(`${ApiEndpoints.PROPERTY_GROUPS}${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_PROPERTY_GROUP_SUCCESS,
        });
      }
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};
