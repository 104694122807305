import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormGroup,
  Label,
  Input,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import { Field, getIn } from "formik";
import { SketchPicker } from "react-color";

const FormColorFieldInner = (props) => {
  const { label, form, field, disabled, id } = props;

  const [color, setColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
  });

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = getIn(form.values, field.name) || color;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ flexGrow: 1 }}>
        <Label>{label}</Label>
        <FormGroup>
          <Input
            disabled={disabled || form.isSubmitting}
            value={"r: " + value.r + " g: " + value.g + " b: " + value.b}
            type="text"
            id={`picker-${id}`}
            onChange={() => {}}
          />
          <UncontrolledPopover
            disabled={disabled || form.isSubmitting}
            trigger="legacy"
            placement="bottom-start"
            target={`picker-${id}`}
            offset={200}
            hideArrow
            popperClassName="color-picker-popover"
          >
            <PopoverBody>
              <SketchPicker
                style={{ tabIndex: 0, marginTop: 0 }}
                disableAlpha={true}
                color={value || color}
                name={field.name}
                onChange={(newColor) => {
                  setColor(newColor);
                  form.setFieldValue(
                    field.name,
                    (({ r, g, b }) => ({ r, g, b }))(newColor.rgb)
                  );
                }}
              />
            </PopoverBody>
          </UncontrolledPopover>
          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: "red " }}>{error}</label>
          )}
        </FormGroup>
      </div>
    </div>
  );
};

const FormColorField = (props) => {
  return (
    <>
      <Field {...props} component={FormColorFieldInner} />
    </>
  );
};

FormColorField.defaultProps = {
  label: "",
  disabled: false,
};

FormColorField.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormColorField;
