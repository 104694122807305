import React, { useEffect } from "react";
import { initThreeApp } from "../3D/ThreeApp";
import { reloadObject } from "../3D/Object3D";
import ThreeJSModes from "constants/ThreeJSModes";
import ProductTypes from "constants/ProductTypes";
import { useTranslation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";

const GlassesPreview = ({
  size,
  previewSize,
  objectScale,
  fileUrls,
  index,
  productType,
  show,
  values,
  cancelHandler,
}) => {
  let threeFrontApp;
  let threeSideApp;

  const { t } = useTranslation("product");

  const makeHelperLines = async () => {
    try {
      const canvasElementFront = document.getElementById(
        `thumbnail-canvas-front-${index}`
      );
      const canvasElementSide = document.getElementById(
        `thumbnail-canvas-side-${index}`
      );

      const modelObjLink = fileUrls.model;
      const modelFbxLink = fileUrls.modelFbx;
      const textureLinks = {
        albedo: fileUrls.albedo,
        metallic: fileUrls.metallic,
        normal: fileUrls.normal,
      };

      Object.keys(textureLinks).forEach(
        (k) => textureLinks[k] === null && delete textureLinks[k]
      );

      if (!threeFrontApp) {
        threeFrontApp = await initThreeApp(
          canvasElementFront,
          productType,
          ThreeJSModes.LINES_FRONT_MODE
        );
      }
      if (!threeSideApp) {
        threeSideApp = await initThreeApp(
          canvasElementSide,
          productType,
          ThreeJSModes.LINES_SIDE_MODE
        );
      }

      await reloadObject(
        threeFrontApp.scene,
        threeFrontApp.sceneTop,
        modelObjLink,
        modelFbxLink,
        values.earRotationWeb,
        textureLinks,
        objectScale,
        productType,
        ThreeJSModes.LINES_FRONT_MODE
      );

      await reloadObject(
        threeSideApp.scene,
        threeSideApp.sceneTop,
        modelObjLink,
        modelFbxLink,
        values.earRotationWeb,
        textureLinks,
        objectScale,
        productType,
        ThreeJSModes.LINES_SIDE_MODE
      );
      // eslint-disable-next-line
    } catch {}
  };

  useEffect(() => {
    if (
      (fileUrls.modelFbx || fileUrls.model) &&
      show &&
      productType === ProductTypes.GLASSES
    )
      makeHelperLines();
    // eslint-disable-next-line
  }, [
    fileUrls.model,
    fileUrls.modelFbx,
    fileUrls.albedo,
    fileUrls.metallic,
    fileUrls.normal,
    show,
    productType,
    size,
    previewSize,
  ]);

  if (!fileUrls.model && !fileUrls.modelFbx) {
    return null;
  }

  return (
    <ReactBSAlert
      title={""}
      onCancel={cancelHandler}
      onConfirm={() => {
        return;
      }}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      showCancel
      customButtons={
        <React.Fragment>
          <Button type="button" onClick={cancelHandler} color="danger">
            {t("common:button.back")}
          </Button>
        </React.Fragment>
      }
      style={{
        width: previewSize.width * 2 + 200,
        height: previewSize.height + 150,
      }}
    >
      <canvas
        id={`thumbnail-canvas-front-${index}`}
        className={
          show && productType === ProductTypes.GLASSES
            ? "thumbnail-canvas"
            : "thumbnail-canvas-hidden"
        }
        width={previewSize.width}
        height={previewSize.height}
      ></canvas>
      <canvas
        id={`thumbnail-canvas-side-${index}`}
        className={
          show && productType === ProductTypes.GLASSES
            ? "thumbnail-canvas"
            : "thumbnail-canvas-hidden"
        }
        width={previewSize.width}
        height={previewSize.height}
      ></canvas>
    </ReactBSAlert>
  );
};

export default GlassesPreview;
