import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RouterPaths from "constants/RouterPaths";
import { prepareUrl } from "common/utils/PrepareUrl";
import CommonLoader from "common/components/loader/CommonLoader";
import connect from "react-redux/es/connect/connect";
import { isMainPartner } from "common/utils/PartnerUtils";

const ProductTableRow = ({
  item,
  loading,
  categoriesVisible,
  partner,
  isSuperadmin,
}) => {
  const { t } = useTranslation("product");
  return (
    <>
      <td style={{ width: 130, minWidth: 130 }}>
        {item.product_thumb_link ? (
          <img alt="thumb" src={item.product_thumb_link} height={40} />
        ) : (
          t("notAdded")
        )}
      </td>
      <td style={{ flex: 1.5, minWidth: "20%", width: "auto" }}>
        {item.product_name}
      </td>
      <td style={{ flex: 1, width: "30%" }}>
        {item.from_main_partner && !isMainPartner(partner) && (
          <span className="label fmp">{t("marketplace")}</span>
        )}
        {item.from_other_partner && (
          <span className="label fop">{t("shared")}</span>
        )}
        {item.product_model_file_fbx_uploaded && isSuperadmin && (
          <span className="label fbx">{t("fbx")}</span>
        )}
        {item.is_2d && isSuperadmin && (
          <span className="label is2d">{t("2D")}</span>
        )}
      </td>
      {categoriesVisible && (
        <td style={{ flex: 1, width: "50%" }}>
          {item.category_name}
          <CommonLoader show={loading} className="list-element-loader" />
        </td>
      )}
      <td style={{ width: 160, minWidth: 160 }}>
        {item.from_main_partner && !isMainPartner(partner)
          ? `${item.assigned_versions}/${item.published_versions}`
          : `${item.published_versions}/${item.all_versions}`}
      </td>
      <td className="text-right" style={{ width: 70, minWidth: 70 }}>
        <Link
          to={prepareUrl(RouterPaths.EDIT_PRODUCT, { id: item.id })}
          className="btn btn-info table-icon-button"
        >
          <i className="nc-icon nc-ruler-pencil" />
        </Link>
      </td>
    </>
  );
};

ProductTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

const mapStateToProps = ({ partners }) => {
  return {
    categoriesVisible: partners.get("partner").categories_visible,
    partner: partners.get("partner"),
  };
};

export default connect(mapStateToProps)(ProductTableRow);
