import React from "react";
import PropTypes from "prop-types";
import Button from "reactstrap/lib/Button";
import { useTranslation } from "react-i18next";

const TotemBannersTableRow = ({
  item,
  deleteBannerHandler,
  addBannerHandler,
}) => {
  const { t } = useTranslation("totems");

  return (
    <>
      <td>{item.banner_name}</td>
      <td>{t(`totems:bannerTypes.${item.banner_type}`)}</td>
      <td>{item.banner_duration}</td>
      <td>{item.banner_displayed_time}</td>
      <td>{t(`statusOptions.${item.banner_active}`)}</td>
      <td className="text-center" style={{ width: 50, minWidth: 50 }}>
        <Button
          type="button"
          className="btn btn-info icon-button"
          onClick={() => addBannerHandler(item)}
        >
          <i className="nc-icon nc-ruler-pencil" />
        </Button>
      </td>
      <td className="text-center" style={{ width: 50, minWidth: 50 }}>
        <Button
          type="button"
          className="btn btn-danger icon-button"
          onClick={() => deleteBannerHandler(item)}
        >
          <i className="nc-icon nc-simple-remove" />
        </Button>
      </td>
    </>
  );
};

TotemBannersTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TotemBannersTableRow;
