import {
  apiClient,
  externalApiClient,
  handleError,
  setPartnerHeader,
} from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import { PARTNER } from "auth/AuthConstants";

import { prepareProductFormData } from "common/utils/PrepareFormData";
import {
  parseProductCategories,
  categoriesToSelect,
} from "common/utils/CategoryUtils";
import zip from "jszip";
import { setCurrentPartnerById } from "components/partners/PartnerActions";
import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_LOG_SUCCESS,
  GET_PRODUCT_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  EDIT_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_CATEGORY_PROPERTY_GROUPS_SUCCESS,
  GET_ALL_PRODUCT_SUCCESS,
  MOVE_PRODUCT_TO_MAIN_PARTNER_SUCCESS,
} from "./ProductReducer";

export const getProducts = (params) => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.PRODUCTS, { params })
    .then((res) => {
      const products = res?.data?.body;
      return dispatch(getCategories()).then((categories) => {
        const parsedCategories = categoriesToSelect(categories);
        const parsedProducts = parseProductCategories(
          products,
          parsedCategories
        );
        dispatch({
          type: GET_PRODUCTS_SUCCESS,
          payload: {
            products: parsedProducts,
            meta: res?.data?.meta,
          },
        });
        return res?.data?.body;
      });
    })
    .catch((err) => dispatch(handleError(err)));
};

export const getProductLog = (params) => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.PRODUCT_LOG, { params })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_PRODUCT_LOG_SUCCESS,
          payload: {
            productLog: res?.data?.body,
          },
        });
      }
    })
    .catch((err) => dispatch(handleError(err)));
};

export const changeProductsOrder =
  (from_id, to_id, direction) => (dispatch) => {
    return apiClient
      .put(`${ApiEndpoints.ORDER_PRODUCTS}`, {
        from_id,
        to_id,
        direction,
      })
      .then((res) => {
        if (res.status === 200) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        dispatch(handleError(err));
        return false;
      });
  };

export const getAllProductsWithVersions = () => (dispatch) => {
  return apiClient
    .get(`${ApiEndpoints.ALL_PRODUCTS}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_ALL_PRODUCT_SUCCESS,
          payload: {
            products: res?.data?.body,
          },
        });
      }
      return res?.data?.body;
    })
    .catch((err) => dispatch(handleError(err)));
};

export const getProduct = (id) => (dispatch) => {
  setPartnerHeader(sessionStorage.getItem(PARTNER));
  return apiClient
    .get(`${ApiEndpoints.PRODUCTS}${id}`)
    .then((res) => {
      if (res.status === 200) {
        const product = res?.data?.body;
        const partnerId = product?.partner_id;
        if (partnerId) {
          dispatch(setCurrentPartnerById(partnerId));
        }
        if (!product) return null;
        const linkSuffix = product.product_files_zip_link?.split("/").pop();
        if (!product.product_files_zip_link) {
          dispatch({
            type: GET_PRODUCT_SUCCESS,
            payload: {
              product: res?.data?.body,
            },
          });
          return product;
        }
        return new Promise((resolve, reject) => {
          externalApiClient
            .get(`https://files.ar-labs.io/${linkSuffix}`, {
              responseType: "blob",
            })
            .then((res) => {
              return res.data;
            })
            .then(zip.loadAsync)
            .then((zipData) => {
              const promArray = [];
              if (zipData.file("O.obj")) {
                promArray.push(
                  zipData
                    .file("O.obj")
                    .async("blob")
                    .then((file) => {
                      product.modelFile = file;
                    })
                );
              }
              if (zipData.file("F.fbx")) {
                promArray.push(
                  zipData
                    .file("F.fbx")
                    .async("blob")
                    .then((file) => {
                      product.modelFileFbx = file;
                    })
                );
              }
              Object.keys(zipData.files).forEach((file) => {
                const re = /_(A|M|N|front|lenses|left|right)\./;
                const [versionId, , ext] = file.split(re);
                switch (file) {
                  case `${versionId}_M.${ext}`:
                    promArray.push(
                      zipData
                        .file(`${versionId}_M.${ext}`)
                        .async("blob")
                        .then((file) => {
                          const versionIndex = product.versions.findIndex(
                            (v) => v.id === versionId
                          );
                          if (versionIndex !== -1) {
                            product.versions[versionIndex].metallicFile = file;
                          }
                        })
                    );
                    break;
                  case `${versionId}_A.${ext}`:
                    promArray.push(
                      zipData
                        .file(`${versionId}_A.${ext}`)
                        .async("blob")
                        .then((file) => {
                          const versionIndex = product.versions.findIndex(
                            (v) => v.id === versionId
                          );
                          if (versionIndex !== -1) {
                            product.versions[versionIndex].albedoFile = file;
                          }
                        })
                    );
                    break;
                  case `${versionId}_N.${ext}`:
                    promArray.push(
                      zipData
                        .file(`${versionId}_N.${ext}`)
                        .async("blob")
                        .then((file) => {
                          const versionIndex = product.versions.findIndex(
                            (v) => v.id === versionId
                          );
                          if (versionIndex !== -1) {
                            product.versions[versionIndex].normalFile = file;
                          }
                        })
                    );
                    break;
                  case `${versionId}_front.${ext}`:
                    promArray.push(
                      zipData
                        .file(`${versionId}_front.${ext}`)
                        .async("blob")
                        .then((file) => {
                          const versionIndex = product.versions.findIndex(
                            (v) => v.id === versionId
                          );
                          if (versionIndex !== -1) {
                            product.versions[versionIndex].frontFile = file;
                          }
                        })
                    );
                    break;
                  case `${versionId}_lenses.${ext}`:
                    promArray.push(
                      zipData
                        .file(`${versionId}_lenses.${ext}`)
                        .async("blob")
                        .then((file) => {
                          const versionIndex = product.versions.findIndex(
                            (v) => v.id === versionId
                          );
                          if (versionIndex !== -1) {
                            product.versions[versionIndex].lensesFile = file;
                          }
                        })
                    );
                    break;
                  case `${versionId}_left.${ext}`:
                    promArray.push(
                      zipData
                        .file(`${versionId}_left.${ext}`)
                        .async("blob")
                        .then((file) => {
                          const versionIndex = product.versions.findIndex(
                            (v) => v.id === versionId
                          );
                          if (versionIndex !== -1) {
                            product.versions[versionIndex].leftFile = file;
                          }
                        })
                    );
                    break;
                  case `${versionId}_right.${ext}`:
                    promArray.push(
                      zipData
                        .file(`${versionId}_right.${ext}`)
                        .async("blob")
                        .then((file) => {
                          const versionIndex = product.versions.findIndex(
                            (v) => v.id === versionId
                          );
                          if (versionIndex !== -1) {
                            product.versions[versionIndex].rightFile = file;
                          }
                        })
                    );
                    break;
                  default:
                    break;
                }
              });
              Promise.all(promArray)
                .then(() => {
                  dispatch({
                    type: GET_PRODUCT_SUCCESS,
                    payload: {
                      product: res?.data?.body,
                    },
                  });
                  return resolve(product);
                })
                .catch((err) => {
                  return reject(err);
                });
            })
            .catch((err) => {
              return reject(err);
            });
        });
      }
    })
    .catch((err) => dispatch(handleError(err)));
};

export const addProduct = (product, form) => (dispatch) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const formData = prepareProductFormData(product);

  return apiClient
    .post(ApiEndpoints.PRODUCTS, formData, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: ADD_PRODUCT_SUCCESS });
      }
      return res.data;
    })
    .catch((err) => {
      dispatch(handleError(err, form));
    });
};

export const getCategories = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.CATEGORIES)
    .then((res) => {
      dispatch({
        type: GET_PRODUCT_CATEGORIES_SUCCESS,
        payload: {
          categoryList: res?.data?.body,
        },
      });
      return res?.data?.body;
    })
    .catch((err) => dispatch(handleError(err)));
};

export const editProduct = (product, form) => (dispatch) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const formData = prepareProductFormData(product);

  return apiClient
    .put(`${ApiEndpoints.PRODUCTS}${product.id}`, formData, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: EDIT_PRODUCT_SUCCESS,
        });
        return true;
      }
      return false;
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const moveProductToMainPartner = (id) => (dispatch) => {
  return apiClient
    .put(`${ApiEndpoints.MOVE_PRODUCT_TO_MAIN_PARTNER}${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: MOVE_PRODUCT_TO_MAIN_PARTNER_SUCCESS,
        });
        return true;
      }
      return false;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const deleteProduct = (id) => (dispatch) => {
  return apiClient
    .delete(`${ApiEndpoints.PRODUCTS}${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_PRODUCT_SUCCESS,
        });
        return true;
      }
      return false;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const getCategoryPropertyGroups = (categoryId) => (dispatch) => {
  return apiClient
    .get(`${ApiEndpoints.CATEGORY_PROPERTY_GROUPS}${categoryId}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_CATEGORY_PROPERTY_GROUPS_SUCCESS,
          payload: {
            propertyGroups: res?.data.propertyGroups,
          },
        });
      }
      return res?.data?.body;
    })
    .catch((err) => dispatch(handleError(err)));
};
