import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useDropzone } from "react-dropzone";
import { ReactComponent as Magic } from "./icons/magic.svg";
import { ReactComponent as Trash } from "./icons/trash.svg";
import { toBase64, resizeImageToSquare, renderCanvas } from "./Utils";
import { EditMode } from "./Constants";
import Editor from "./editor/Editor";
import CropImage from "./CropImage";
import { useTranslation } from "react-i18next";

const useDebounceEffect = (fn, waitTime, deps) => {
  useEffect(() => {
    const t = setTimeout(() => {
      fn(...deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, [fn, waitTime, deps]);
};

const ImagePreview = ({
  title,
  id,
  active,
  changeActive,
  modifications,
  cropModeActive,
  setCrop,
  resetPhoto,
  mainCanvasRef,
  frontWithLensesCanvasRef,
}) => {
  const { t } = useTranslation("wizard");

  const [chosenFile, setChosenFile] = useState(null);
  const [editMode, setEditMode] = useState(EditMode.NONE);
  const imgRef = useRef(null);
  const frameMaskRef = useRef(null);
  const frontWithLensesMaskRef = useRef(null);
  const containerRef = useRef(null);

  useDebounceEffect(
    async () => {
      if (!cropModeActive) {
        renderCanvas(
          mainCanvasRef?.current,
          imgRef.current,
          imgRef.current?.naturalWidth,
          frameMaskRef.current,
          modifications
        );
        renderCanvas(
          frontWithLensesCanvasRef?.current,
          imgRef.current,
          imgRef.current?.naturalWidth,
          frontWithLensesMaskRef.current,
          modifications
        );
      }
    },
    100,
    [
      cropModeActive,
      chosenFile,
      modifications,
      imgRef.current,
      frameMaskRef.current,
      mainCanvasRef?.current,
      frontWithLensesCanvasRef?.current,
    ]
  );

  const onDrop = async (files) => {
    if (_.isEmpty(files)) return;
    const file = files[0];
    file.src = await toBase64(file);

    resizeImageToSquare(file, 1024, setChosenFile);
  };

  const onDelete = () => {
    setChosenFile(null);
    resetPhoto(id);
    frameMaskRef.current = null;
    frontWithLensesMaskRef.current = null;
  };

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    multiple: false,
    onDrop,
    noClick: true,
    getFilesFromEvent: async (event) => {
      const files = event.target.files || event.dataTransfer.files;

      const promises = [];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const promise = new Promise((resolve) => {
          const image = new Image();
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          const url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }
      // eslint-disable-next-line no-return-await
      return await Promise.all(promises);
    },
    validator: (file) => {
      if (file.type !== "image/png" && file.type !== "image/jpeg") {
        return {
          code: "wrong-format",
          message: t("common:validator.unsupportedExtension", {
            ext: ".png, .jpg, .jpeg",
          }),
        };
      }
      if (file.width < 1024 && file.height < 1024) {
        return {
          code: "wrong-size",
          message: t("common:validator.minImgSize", { minSize: 1024 }),
        };
      }
      return null;
    },
  });

  const fileRejectionError = fileRejections?.[0]?.errors ? (
    fileRejections[0].errors.map((e) => <p key={e.code}>{e.message}</p>)
  ) : (
    <p />
  );

  return (
    <div className="wizard-image-preview-container">
      <p className="wizard-image-preview-title">{title}</p>
      <div className="wizard-image-preview-error">{fileRejectionError}</div>
      <div
        {...getRootProps()}
        ref={containerRef}
        onClick={() => {
          if (!chosenFile) open();
          else if (!cropModeActive) changeActive(id);
        }}
        className="wizard-image-preview-dragndrop-container"
        style={{
          borderImageSource:
            active === id &&
            "linear-gradient(96.34deg, #BA1176 0%, #6B2BEC 100%)",
        }}
      >
        <div className="wizard-image-preview-dragndrop">
          <input {...getInputProps()} />
          {!chosenFile && (
            <>
              <div className="wizard-image-preview-dragndrop-info">
                {t("dragDropInfo")}
              </div>
            </>
          )}
          {chosenFile && (
            <>
              <CropImage
                src={chosenFile.src}
                modifications={modifications}
                onCropReady={setCrop}
                imgRef={imgRef}
                style={{
                  position: "absolute",
                  inset: 1,
                  visibility: cropModeActive ? "visible" : "hidden",
                }}
              />
              <canvas
                ref={mainCanvasRef}
                style={{
                  width: containerRef?.current?.offsetWidth - 4 || 0,
                  height: containerRef?.current?.offsetHeight - 4 || 0,
                  display: !cropModeActive ? "block" : "none",
                }}
              />
              {frameMaskRef.current && (
                <canvas
                  ref={frontWithLensesCanvasRef}
                  className="wizard-image-preview-hidden"
                  style={{
                    width: containerRef?.current?.offsetWidth - 4 || 0,
                    height: containerRef?.current?.offsetHeight - 4 || 0,
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div
        className="wizard-image-preview-buttons-container"
        style={{
          visibility: chosenFile ? "visible" : "hidden",
        }}
      >
        <>
          <button
            type="button"
            className="wizard-image-preview-button-edit"
            onClick={() => setEditMode(EditMode.FRAME)}
          >
            <Magic color="#101828" />
          </button>
          <button
            type="button"
            className="wizard-image-preview-button-delete"
            onClick={onDelete}
          >
            <Trash color="#BA1176" />
          </button>
        </>
      </div>

      {editMode === EditMode.FRAME && (
        <Editor
          inputFile={chosenFile}
          maskFile={frameMaskRef.current}
          onBack={() => setEditMode(EditMode.NONE)}
          onNext={(mask) => {
            const img = new Image();
            img.src = mask;
            frameMaskRef.current = img;
            if (!frontWithLensesMaskRef.current)
              frontWithLensesMaskRef.current = img;
            setEditMode(
              id === "front" ? EditMode.FRAME_WITH_LENSES : EditMode.NONE
            );
          }}
          nextText={
            id === "front" ? t("goToLensesButton") : t("common:button.save")
          }
          hintText={<>{t("backgroundAndLensesHint")}</>}
          photoTitle={t(`${id}Title`)}
        />
      )}
      {editMode === EditMode.FRAME_WITH_LENSES && (
        <Editor
          inputFile={chosenFile}
          maskFile={frontWithLensesMaskRef.current}
          onBack={() => setEditMode(EditMode.FRAME)}
          onNext={(mask) => {
            const img = new Image();
            img.src = mask;
            frontWithLensesMaskRef.current = img;
            setEditMode(EditMode.NONE);
          }}
          nextText={t("common:button.save")}
          hintText={<>{t("backgroundHint")}</>}
          photoTitle={t("frontWithLensesTitle")}
        />
      )}
    </div>
  );
};

export default ImagePreview;
