import React from "react";
import { Col, Label, Row, FormGroup } from "reactstrap";
import { Field, getIn } from "formik";
import { useTranslation } from "react-i18next";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const FormRangeFieldInner = (props) => {
  const {
    label,
    min = 0,
    max = 2,
    step = 1,
    labelStep = 1,
    form,
    field,
    defaultValue = 1,
    disabled = false,
  } = props;

  const { t } = useTranslation();

  const error = getIn(form.errors, field.name) || form.errors[field.name];
  const touched = getIn(form.touched, field.name);
  const value = getIn(form.values, field.name);

  const getLabels = () => {
    const labels = {};
    // Multiplication is required to avoid floating-point operation side effects
    for (let i = min * 100; i <= max * 100; i += labelStep * 100) {
      const item = i / 100;
      labels[item] = item;
    }
    return labels;
  };

  return (
    <Row>
      <Col>
        <Label>{label}</Label>
        <FormGroup className="form-slider">
          <Slider
            dots
            min={min}
            max={max}
            step={step}
            marks={getLabels()}
            value={value}
            defaultValue={defaultValue}
            onChange={(value) => {
              form.setFieldValue(field.name, value);
            }}
            disabled={disabled}
          />
          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: "red" }}>{t(error)}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormRangeField = (props) => {
  return <Field {...props} component={FormRangeFieldInner} />;
};

export default FormRangeField;
