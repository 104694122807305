import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import { GET_LINKS_SUCCESS } from "./LinkReducer";

export const getLinks = (params) => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.LINKS, { params })
    .then((res) => {
      const links = res?.data?.body;
      dispatch({
        type: GET_LINKS_SUCCESS,
        payload: {
          links: links,
          meta: res?.data?.meta,
        },
      });
      return links;
    })
    .catch((err) => dispatch(handleError(err)));
};

export const addWebLink = (link, form) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.LINKS, link)
    .then((res) => {
      return res?.data?.id;
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const editWebLink = (id, link, form) => (dispatch) => {
  return apiClient
    .put(`${ApiEndpoints.LINKS}${id}`, link)
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const deleteWebLink = (id) => (dispatch) => {
  return apiClient
    .delete(`${ApiEndpoints.LINKS}${id}`)
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};
