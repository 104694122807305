import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardTitle, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AlertVariant from "common/components/alert/AlertVariant";
import { showAlert } from "common/components/alert/AlertActions";
import ValidationSchema from "./AddPartnerForm.schema.js";
import SettingsForm from "components/settings/SettingsForm";
import { createPartner, setCurrentPartnerById } from "./PartnerActions";
import { typesToSelect } from "common/utils/TypeUtils.js";
import { createPrivacyLink } from "common/utils/PartnerUtils.js";
import RouterPaths from "constants/RouterPaths.js";
import { isPrefix } from "constants/SettingsCurrencies.js";

const AddPartner = ({
  history,
  currentUser,
  createPartner,
  setCurrentPartnerById,
  partner,
  showAlert,
}) => {
  const { t } = useTranslation("addPartner");
  const [showSaveLoader, setShowSaveLoader] = useState(false);

  useEffect(() => {
    document.getElementById("scrollableWrapper").scrollTo({ top: 0 });
  }, []);

  const initialValues = {
    name: "",
    privacyPolicyLink: "",
    privacyPolicyContents: "",
    privacyPolicyFromEditor: true,
    bundleId: "",
    packageName: "",
    panelLanguage: {
      label: "pl",
      value: "pl",
    },
    partnerCurrency: {
      label: "ZŁ",
      value: "ZŁ",
    },
    types: typesToSelect(partner?.types || []).map((type) => {
      return { ...type, show: false };
    }),
    partnerTypes: [],
    partnerAppStoreLink: "",
    partnerPlayStoreLink: "",
    partnerAppPrefix: "",
    webActive: false,
    qrcodesActive: false,
    mobileActive: false,
    totemActive: false,
    autocutActive: false,
    categoriesVisible: true,
    organizingProducts: false,
    versionsGrouped: false,
    thumbFileWidth: 800,
    thumbFileHeight: 400,
    thumbObjectScale: 1,
    rotationIOS: 0,
    rotationAndroid: 0,
    rotationWeb: 0,
    aspectRatioIOS: 1.0,
    aspectRatioAndroid: 1.0,
    aspectRatioWeb: 1.0,
    positionYIOS: 0,
    positionYAndroid: 0,
    positionYWeb: 0,
    positionZIOS: 0,
    positionZAndroid: 0,
    positionZWeb: 0,
    notificationsActive: false,
    notificationsLimit: 0,
    notificationsAppId: "",
    notificationsKey: "",
    pdMeasurementActive: false,
    googleAnalyticsActive: false,
    googleAnalyticsKey: "",
    analyticsDashboardActive: false,
    numberOfTotems: 0,
    marketplaceVisible: false,
  };

  const onSubmit = (values, form) => {
    setShowSaveLoader(true);

    const domains = [];
    if (values && values.domains && values.domains.length > 0) {
      values.domains.map((domain) => {
        if (domain.domain_url && domain.domain_url.length > 0) {
          domains.push(domain);
        }
        return true;
      });
    }

    const editedSettings = {
      name: values.name,
      privacyPolicyLink: values.privacyPolicyFromEditor
        ? createPrivacyLink(values.partnerAppPrefix)
        : values.privacyPolicyLink,
      privacyPolicyContents: values.privacyPolicyContents,
      partnerAppStoreLink: values.partnerAppStoreLink,
      partnerPlayStoreLink: values.partnerPlayStoreLink,
      partnerDefaultUrl: "",
      partnerAppPrefix: values.partnerAppPrefix,
      bundleId: values.bundleId || null,
      packageName: values.packageName || null,
      panelLanguage: values.panelLanguage.value,
      webActive: values.webActive,
      qrcodesActive: values.qrcodesActive,
      mobileActive: values.mobileActive,
      totemActive: values.totemActive,
      autocutActive: values.autocutActive,
      categoriesVisible: values.categoriesVisible,
      organizingProducts: values.organizingProducts,
      versionsGrouped: values.versionsGrouped,
      thumbFileWidth: values.thumbFileWidth,
      thumbFileHeight: values.thumbFileHeight,
      thumbObjectScale: values.thumbObjectScale,
      rotationIOS: values.rotationIOS,
      rotationAndroid: values.rotationAndroid,
      rotationWeb: values.rotationWeb,
      aspectRatioIOS: values.aspectRatioIOS,
      aspectRatioAndroid: values.aspectRatioAndroid,
      aspectRatioWeb: values.aspectRatioWeb,
      positionYIOS: values.positionYIOS,
      positionYAndroid: values.positionYAndroid,
      positionYWeb: values.positionYWeb,
      positionZIOS: values.positionZIOS,
      positionZAndroid: values.positionZAndroid,
      positionZWeb: values.positionZWeb,
      partnerCurrency: values.partnerCurrency.value,
      notificationsActive: values.notificationsActive,
      notificationsLimit: values.notificationsLimit,
      notificationsAppId: values.notificationsAppId,
      notificationsKey: values.notificationsKey,
      pdMeasurementActive: values.pdMeasurementActive,
      currencyAsPrefix: isPrefix[values.partnerCurrency.value],
      googleAnalyticsActive: values.googleAnalyticsActive,
      googleAnalyticsKey: values.googleAnalyticsKey,
      analyticsDashboardActive: values.analyticsDashboardActive,
      numberOfTotems: values.numberOfTotems,
      marketplaceVisible: values.marketplaceVisible,
      partnerTypes: values.partnerTypes.map((type) => type.value),
      domains: domains,
    };

    createPartner(editedSettings, form).then((formCorrect) => {
      form.setSubmitting(false);
      setShowSaveLoader(false);
      if (formCorrect) {
        showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
        setCurrentPartnerById(formCorrect);
        history.push(RouterPaths.PRODUCTS);
      }
    });
  };

  return (
    <div className="content">
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("title")}</h4>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={ValidationSchema}
              enableReinitialize
            >
              {() => (
                <SettingsForm
                  currentUser={currentUser}
                  partner={partner}
                  showSaveLoader={showSaveLoader}
                />
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = function ({ partners, auth }) {
  return {
    partner: partners.get("partner"),
    currentUser: auth.get("user"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { createPartner, showAlert, setCurrentPartnerById },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddPartner));
