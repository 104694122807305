import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardTitle, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import UserStatuses from "constants/UserStatuses";
import ExtendedUserRoles from "constants/ExtendedUserRoles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RouterPaths from "constants/RouterPaths";
import BackButton from "common/components/BackButton";
import AlertVariant from "common/components/alert/AlertVariant";
import { showAlert } from "common/components/alert/AlertActions";
import { addUser } from "./UserActions";
import validationSchema from "./form/UserForm.schema";
import UserForm from "./form/UserForm";
import { isMainPartner } from "common/utils/PartnerUtils";

const initialValues = {
  email: "",
};

const defaultStatus = UserStatuses.ACTIVE;

const AddUser = ({ addUser, showAlert, history, partner }) => {
  const { t } = useTranslation("user");
  const [defaultRole, setDefaultRole] = useState(ExtendedUserRoles.USER);

  useEffect(() => {
    setDefaultRole(
      isMainPartner(partner)
        ? ExtendedUserRoles.SUPERADMIN
        : ExtendedUserRoles.USER
    );
  }, [partner]);

  const onSubmit = (values, form) => {
    const newUser = {
      email: values.email,
      active: values.status.value,
      role: values.role.value,
    };
    addUser(newUser, form).then((formCorrect) => {
      form.setSubmitting(false);
      if (formCorrect) {
        showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
        history.push(RouterPaths.USERS);
      }
    });
  };
  return (
    <div className="content">
      <Col md="12">
        <BackButton />
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <Col>
                <h4>{t("addUserTitle")}</h4>
              </Col>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Formik
              initialValues={{
                ...initialValues,
                status: {
                  value: defaultStatus,
                  label: t(`statusOptions.${defaultStatus}`),
                },
                role: {
                  value: defaultRole,
                  label: t(`roleOptions.${defaultRole}`),
                },
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {(props) => (
                <UserForm
                  {...props}
                  disableRoleField={isMainPartner(partner)}
                />
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ partners }) => {
  return {
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addUser, showAlert }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddUser));
