import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import {
  GET_CATEGORIES_SUCCESS,
  ADD_CATEGORY_SUCCESS,
  EDIT_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
} from "./CategoryReducer";
import { prepareCategoryFormData } from "common/utils/PrepareFormData";

export const getCategories = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.CATEGORIES)
    .then((res) => {
      dispatch({
        type: GET_CATEGORIES_SUCCESS,
        payload: {
          categoryList: res?.data?.body,
        },
      });
      return res?.data?.body;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const addCategory = (category) => (dispatch) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const formData = prepareCategoryFormData(category);

  return apiClient
    .post(ApiEndpoints.CATEGORIES, formData, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: ADD_CATEGORY_SUCCESS });
      }
      return res.data.body;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const editCategory = (category) => (dispatch) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const formData = prepareCategoryFormData(category);

  return apiClient
    .put(`${ApiEndpoints.CATEGORIES}${category.id}`, formData, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: EDIT_CATEGORY_SUCCESS });
      }
      return res.data.body;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};

export const deleteCategory = (id) => (dispatch) => {
  return apiClient
    .delete(`${ApiEndpoints.CATEGORIES}${id}`)
    .then(() => {
      dispatch({ type: DELETE_CATEGORY_SUCCESS });
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};
