import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BackButton = ({ history, link = null }) => {
  const { t } = useTranslation("common");

  return (
    <button
      type="button"
      className="back-button mb-3"
      onClick={() => (link ? history.push(link) : history.goBack())}
    >
      <i className="nc-icon nc-minimal-left" />
      <div className="ml-1">{t("button.back")}</div>
    </button>
  );
};

export default withRouter(BackButton);
