import { fromJS } from "immutable";

export const GET_MARKETPLACE_PRODUCTS_SUCCESS =
  "GET_MARKETPLACE_PRODUCTS_SUCCESS";
export const GET_MARKETPLACE_PRODUCT_SUCCESS =
  "GET_MARKETPLACE_PRODUCT_SUCCESS";

const initialData = fromJS({
  productList: null,
  meta: null,
  product: null,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_MARKETPLACE_PRODUCTS_SUCCESS:
      return state.merge({
        productList: action.payload.products,
        meta: action.payload.meta,
        product: null,
      });
    case GET_MARKETPLACE_PRODUCT_SUCCESS:
      return state.merge({
        product: action.payload.product,
      });
    default:
      return state;
  }
};
