/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AdminNavbar from "common/components/Navbars/AdminNavbar.js";
import Footer from "common/components/Footer/Footer.js";
import Sidebar from "common/components/Sidebar/Sidebar.js";
import sidebarRoutes from "sidebarRoutes";
import AdminRoute from "AdminRoute";
import NotFound from "errors/NotFound";
import RouterPaths from "constants/RouterPaths";
import AddProduct from "components/products/AddProduct";
import ProductList from "components/products/ProductList";
import MarketplaceList from "components/marketplace/MarketplaceList";
import EditProduct from "components/products/EditProduct";
import UserList from "components/users/UserList";
import AddUser from "components/users/AddUser";
import EditUser from "components/users/EditUser";
import CategoryList from "components/categories/CategoryList";
import Totems from "components/totems/Totems";
import TotemsEdit from "components/totems/EditTotem";
import Banners from "components/totems/banners/Banners";
import { checkPermission } from "common/utils/UserUtils";
import { isMainPartner } from "common/utils/PartnerUtils";
import PropertyGroupList from "components/properties/PropertyGroupList";
import {
  checkRequiredSettings,
  checkDisabledSettings,
} from "common/utils/SettingsUtils";
import LinkList from "components/links/LinkList";
import BrandList from "components/brands/BrandList";
import i18n from "i18n/i18n";
import Settings from "components/settings/Settings";
import AddPartner from "components/partners/AddPartner";
import Notifications from "components/notifications/Notifications";
import AnalyticsDashboard from "components/analyticsDashboard/AnalyticsDashboard";
import Applications from "components/applications/Applications";
import Autocut from "components/autocut/Autocut";
import AutocutReport from "components/autocut/AutocutReport";

const Dashboard = ({ user, partner, ...props }) => {
  const [allowedSidebarRoutes, setAllowedSidebarRoutes] = useState([]);

  useEffect(() => {
    if (user && partner) {
      const language = partner.panel_language;
      if (language) {
        i18n.changeLanguage(language);
      }
      const routes = sidebarRoutes();
      if (isMainPartner(partner)) {
        setAllowedSidebarRoutes(
          routes.filter(
            (route) =>
              route.path === RouterPaths.USERS ||
              route.path === RouterPaths.ADD_PARTNER ||
              route.path === RouterPaths.SETTINGS ||
              route.path === RouterPaths.PRODUCTS ||
              route.path === RouterPaths.BRANDS ||
              route.path === RouterPaths.CATEGORIES ||
              route.path === RouterPaths.APPS
          )
        );
        return;
      }

      setAllowedSidebarRoutes(
        routes.filter(
          (route) =>
            checkPermission(user.role, route.permission) &&
            checkRequiredSettings(partner, route.requiredSettings) &&
            checkDisabledSettings(partner, route.disabledSettings) &&
            route.path !== RouterPaths.ADD_PARTNER &&
            route.path !== RouterPaths.APPS
        )
      );
    }
  }, [user, partner]);

  if (!partner) {
    return (
      <div className="wrapper" id="scrollableWrapper">
        <Sidebar routes={allowedSidebarRoutes} {...props} />
        <div className="main-panel">
          <AdminNavbar {...props} />
        </div>
      </div>
    );
  }
  return (
    <div className="wrapper" id="scrollableWrapper">
      <Sidebar routes={allowedSidebarRoutes} {...props} />
      <div className="main-panel">
        <AdminNavbar {...props} />
        <Switch>
          <AdminRoute exact path={RouterPaths.USERS} component={UserList} />
          <AdminRoute exact path={RouterPaths.ADD_USER} component={AddUser} />
          <AdminRoute exact path={RouterPaths.EDIT_USER} component={EditUser} />
          <Route
            exact
            path={RouterPaths.ANALYTICS_DASHBOARD}
            component={AnalyticsDashboard}
          />
          <Route exact path={RouterPaths.PRODUCTS} component={ProductList} />
          <Route exact path={RouterPaths.ADD_PRODUCT} component={AddProduct} />
          <Route
            exact
            path={RouterPaths.MARKETPLACE}
            component={MarketplaceList}
          />
          <Route
            exact
            path={RouterPaths.EDIT_PRODUCT}
            component={EditProduct}
          />
          {partner && (
            <Redirect
              exact
              from="/"
              to={
                isMainPartner(partner)
                  ? RouterPaths.USERS
                  : RouterPaths.PRODUCTS
              }
            />
          )}
          <Route exact path={RouterPaths.CATEGORIES} component={CategoryList} />
          <Route
            exact
            path={RouterPaths.PROPERTIES}
            component={PropertyGroupList}
          />
          <Route exact path={RouterPaths.LINKS} component={LinkList} />
          <Route exact path={RouterPaths.TOTEMS} component={Totems} />
          <Route exact path={RouterPaths.TOTEMS_EDIT} component={TotemsEdit} />
          <Route exact path={RouterPaths.TOTEM_BANNERS} component={Banners} />
          <Route exact path={RouterPaths.BRANDS} component={BrandList} />
          <Route
            exact
            path={RouterPaths.NOTIFICATIONS}
            component={Notifications}
          />
          <Route exact path={RouterPaths.SETTINGS} component={Settings} />
          <Route exact path={RouterPaths.ADD_PARTNER} component={AddPartner} />
          <Route exact path={RouterPaths.APPS} component={Applications} />
          <Route exact path={RouterPaths.AUTOCUT} component={Autocut} />
          <Route
            exact
            path={RouterPaths.AUTOCUT_REPORT}
            component={AutocutReport}
          />
          <Route component={NotFound} />
        </Switch>
        <Footer fluid default />
      </div>
    </div>
  );
};

const mapStateToProps = function ({ auth, partners }) {
  return {
    user: auth.get("user"),
    partner: partners.get("partner"),
  };
};

export default connect(mapStateToProps, null)(Dashboard);
