import React from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import PropTypes from "prop-types";
import { Field, getIn } from "formik";
import { useTranslation } from "react-i18next";

export const FormInputFieldType = {
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  TIME: "time",
  PASSWORD: "password",
  //allows only input that matches regex
  REGEX: "regex",
};

const FormInputFieldInner = (props) => {
  const {
    id,
    buttons = [],
    placeholder,
    type,
    step,
    form,
    field,
    noStyling = false,
    disabled = false,
    regex = null,
    className,
  } = props;

  const { t } = useTranslation();

  const error = getIn(form.errors, field.name) || form.errors[field.name];
  const touched = getIn(form.touched, field.name);
  const value = ![null, undefined].includes(getIn(form.values, field.name))
    ? getIn(form.values, field.name)
    : "";

  const idValue = ![null, undefined].includes(getIn(form.values, id))
    ? getIn(form.values, id)
    : "";

  const element = (
    <InputGroup key={id}>
      <Input
        className={className}
        name={field.name}
        placeholder={placeholder}
        type={type === FormInputFieldType.REGEX ? "text" : type}
        step={step || 1}
        value={value}
        id={idValue}
        disabled={form.isSubmitting || disabled}
        onBlur={() => form.setFieldTouched(field.name, true)}
        onChange={({ target }) => {
          if (type !== FormInputFieldType.REGEX || regex?.test(target.value))
            form.setFieldValue(field.name, target.value);
        }}
      />
      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: "red" }}>{t(error)}</label>
      )}
      {buttons.length > 0 ? (
        <InputGroupAddon addonType="append">
          {buttons.map((button, idx) => (
            <Button
              key={idx}
              size="sm"
              className="form-list-field-icon"
              color="neutral"
              onClick={button.onClick ? button.onClick : () => {}}
            >
              {button?.icon}
            </Button>
          ))}
        </InputGroupAddon>
      ) : (
        ""
      )}
    </InputGroup>
  );

  return noStyling ? (
    element
  ) : (
    <div style={{ display: "list" }}>
      <div style={{ flexGrow: 1 }}>
        <FormGroup style={{ minHeight: "40px", marginTop: "5px" }}>
          {element}
        </FormGroup>
      </div>
    </div>
  );
};

const FormListField = (props) => {
  return <Field {...props} component={FormInputFieldInner} />;
};

FormListField.defaultProps = {
  label: "",
  type: FormInputFieldType.TEXT,
  placeholder: "",
  disabled: false,
};

FormListField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormListField;
