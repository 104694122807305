import React from "react";
import { Row, Col, UncontrolledTooltip, Container } from "reactstrap";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import FormButtonGroupField from "common/components/formFields/FormButtonGroupField";
import FormInputField from "common/components/formFields/FormInputField";
import FormSwitchField from "common/components/formFields/FormSwitchField";

const TotemDetailsForm = ({ statusTooltip }) => {
  const { t } = useTranslation("totems");

  return (
    <Col className="totem-popup">
      <Row>
        <Col>
          <Container id="status-tooltip">
            <Field
              id={"totemStatus"}
              name={"totemStatus"}
              label={t("status")}
              onText={t("common:button.yes").toUpperCase()}
              offText={t("common:button.no").toUpperCase()}
              component={FormInputField}
              fullWidth
              disabled
            />
          </Container>
          <UncontrolledTooltip
            placement="left-end"
            target="status-tooltip"
            delay={0}
            className="totem-tooltip"
          >
            {statusTooltip}
          </UncontrolledTooltip>
        </Col>
        <Col lg="auto">
          <Field
            id={"totemActive"}
            name={"totemActive"}
            label={t("totemLicenseActive")}
            onText={t("common:button.yes").toUpperCase()}
            offText={t("common:button.no").toUpperCase()}
            component={FormButtonGroupField}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Field
            id={"totemName"}
            name={"totemName"}
            label={t("totemName")}
            component={FormInputField}
            fullWidth
          />
          <Field
            id={"changeProductSeconds"}
            name={"changeProductSeconds"}
            label={t("changeProductSeconds")}
            component={FormInputField}
            fullWidth
          />
          <Field
            id={"faceDistanceLimit"}
            name={"faceDistanceLimit"}
            label={t("faceDistanceLimit")}
            component={FormInputField}
            fullWidth
          />
          <Field
            id={"hideTryonSeconds"}
            name={"hideTryonSeconds"}
            label={t("hideTryonSeconds")}
            component={FormInputField}
            fullWidth
          />
        </Col>
      </Row>
      <FormSwitchField
        id="showWelcomeScreen"
        name="showWelcomeScreen"
        label={t("showWelcomeScreen")}
        onText={t("common:button.yes").toUpperCase()}
        offText={t("common:button.no").toUpperCase()}
      />
    </Col>
  );
};

export default TotemDetailsForm;
