import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { Card, CardHeader, CardBody, CardTitle, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import CommonLoader from "common/components/loader/CommonLoader";
import CommonTable from "common/components/CommonTable";
import { getAppsInfo } from "./ApplicationsActions";
import AppsTableRow from "./AppsTableRow";
import AppsMinVersionForm from "./AppsMinVersionForm";

const Applications = ({ getAppsInfo, apps, minVersion }) => {
  const { t } = useTranslation("apps");
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    getAppsInfo().then(() => {
      setShowLoader(false);
    });
  }, [getAppsInfo]);

  const headers = [
    {
      label: t("name"),
    },
    {
      label: t("iosVersion"),
    },
    {
      label: t("iosTestVersion"),
    },
    {
      label: t("androidVersion"),
    },
    {
      label: t("androidTestVersion"),
    },
  ];

  return (
    <div className="content">
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("title")}</h4>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CommonLoader show={showLoader} />
            <AppsMinVersionForm minVersion={minVersion} />
            <CommonTable
              headers={headers}
              data={{ elements: apps }}
              pagination={false}
              rowComponent={AppsTableRow}
              offset={0}
              total={apps?.length || 0}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ apps }) => {
  return {
    apps: apps.get("appsList") ?? [],
    minVersion: apps.get("minVersion") ?? [],
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppsInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
