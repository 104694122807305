import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle, Col, Button } from "reactstrap";
import { getMarketplaceProducts } from "./MarketplaceActions";
import MarketplaceProductTableRow from "./MarketplaceProductTableRow";
import MarketplaceSearch from "./search/MarketplaceSearch";
import CommonTable from "common/components/CommonTable";
import CommonLoader from "common/components/loader/CommonLoader";
import InfiniteScroll from "react-infinite-scroll-component";

const PRODUCT_PAGE_LIMIT = 20;

const MarketplaceList = ({
  partner,
  products,
  productsMeta,
  getMarketplaceProducts,
}) => {
  const { t } = useTranslation("product");
  const [productsData, setProductsData] = useState({ elements: [] });
  const [showLoader, setShowLoader] = useState(false);
  const [searchParams, setSearchParams] = useState({ offset: 0 });
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
  const ref = useRef();

  const toggleVisibility = useCallback(() => {
    const element = document.getElementById("scrollableWrapper");
    if (element.scrollTop > 400) {
      setScrollToTopVisible(true);
    } else {
      setScrollToTopVisible(false);
    }
  }, []);

  useEffect(() => {
    ref.current.scrollIntoView({ block: "end" });
  }, []);

  useEffect(() => {
    if (searchParams.offset === 0) {
      setShowLoader(true);
      setProductsData({ elements: [] });
    }
    getMarketplaceProducts({
      ...searchParams,
      limit: PRODUCT_PAGE_LIMIT,
    }).finally(() => {
      setShowLoader(false);
    });
  }, [getMarketplaceProducts, searchParams, partner]);

  useEffect(() => {
    if (searchParams.offset === 0) {
      setProductsData({ elements: products });
      return;
    }
    setProductsData({ elements: productsData.elements.concat(products) });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const loadMoreData = () => {
    setSearchParams({
      ...searchParams,
      offset: searchParams.offset + PRODUCT_PAGE_LIMIT,
    });
  };

  useEffect(() => {
    setSearchParams({ offset: 0 });
  }, [partner]);

  const headers = [
    {
      label: t("thumbnail"),
    },
    {
      label: t("name"),
    },
    {
      label: t("actions"),
      className: "text-right",
    },
  ];

  return (
    <div className="content" ref={ref}>
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("titleMarketplace")}</h4>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <MarketplaceSearch
              onSearchCallback={(params) =>
                setSearchParams({ ...searchParams, ...params })
              }
            />
            <CommonLoader show={showLoader} />

            {!showLoader && (
              <>
                <InfiniteScroll
                  dataLength={productsData?.elements?.length}
                  next={loadMoreData}
                  hasMore={
                    Number(productsMeta.count) >
                    Number(productsMeta.offset) + PRODUCT_PAGE_LIMIT
                  }
                  loader={
                    <div key="loader" className="loader">
                      <div className="dot" />
                    </div>
                  }
                  scrollableTarget="scrollableWrapper"
                  scrollThreshold={"50px"}
                  onScroll={toggleVisibility}
                  style={{ overflow: "hidden" }}
                >
                  <CommonTable
                    headers={headers}
                    data={productsData}
                    rowComponent={MarketplaceProductTableRow}
                    total={Number(productsMeta.count) ?? 0}
                    pagination={false}
                    rowComponentProps={{
                      search: {
                        ...searchParams,
                        limit: PRODUCT_PAGE_LIMIT,
                      },
                    }}
                  />
                </InfiniteScroll>
                {scrollToTopVisible && (
                  <Button
                    className="btn-icon btn-round scroll-to-top-button"
                    onClick={() =>
                      document.getElementById("scrollableWrapper").scrollTo({
                        top: 0,
                        behavior: "smooth",
                      })
                    }
                  >
                    <i className="nc-icon nc-minimal-up" />
                  </Button>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ marketplace, partners }) => {
  return {
    products: marketplace.get("productList") ?? [],
    productsMeta: marketplace.get("meta") ?? {},
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getMarketplaceProducts }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceList);
