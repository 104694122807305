import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CommonTable from "common/components/CommonTable";
import { Card, CardHeader, CardBody, CardTitle, Col, Button } from "reactstrap";
import LinkTableRow from "./LinkTableRow";
import {
  addWebLink,
  deleteWebLink,
  editWebLink,
  getLinks,
} from "./LinkActions";
import ReactBSAlert from "react-bootstrap-sweetalert";
import LinkPopup from "./LinkPopup";
import CommonLoader from "common/components/loader/CommonLoader";
import { getAllProductsWithVersions } from "components/products/ProductActions";
import LinkSearch from "./search/LinkSearch";
import { getDomains } from "components/settings/DomainsActions";
import { getDashboardData } from "../analyticsDashboard/DashboardActions";

const LINKS_PAGE_LIMIT = 20;

const LinkList = ({
  partner,
  links,
  linksMeta,
  products,
  getLinks,
  addWebLink,
  editWebLink,
  deleteWebLink,
  getAllProductsWithVersions,
  getDomains,
  getDashboardData,
  dashboardData,
}) => {
  const { t } = useTranslation("links");
  const [linksData, setLinksData] = useState({ elements: [] });
  const [showLoader, setShowLoader] = useState(false);
  const [searchParams, setSearchParams] = useState({ offset: 0 });
  const [domainOptions, setDomainOptions] = useState([]);
  const [requestCount, setRequestCount] = useState(0);
  const [lastUpdate, setLastUpdate] = useState("-");

  const ref = useRef();
  const [linkPopup, setLinkPopup] = useState({
    link: null,
    show: false,
    new: null,
  });
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    link: null,
  });

  useEffect(() => {
    ref.current.scrollIntoView({ block: "end" });
  }, []);

  useEffect(() => {
    const domains = [];
    getDomains().then((res) => {
      if (res) {
        res.map((domain) =>
          domains.push({ value: domain.domain_id, label: domain.domain_url })
        );
      }
    });
    setDomainOptions(domains);
  }, [getDomains, partner]);

  useEffect(() => {
    getAllProductsWithVersions();
  }, [getAllProductsWithVersions, partner]);

  useEffect(() => {
    if (partner) {
      getDashboardData();
    }
  }, [partner, getDashboardData]);

  useEffect(() => {
    if (dashboardData) {
      const thisMonth = new Date().toISOString().substring(0, 7);
      if (
        dashboardData.data[thisMonth] &&
        dashboardData.data[thisMonth].webOpens
      )
        setRequestCount(dashboardData.data[thisMonth].webOpens);

      const dates = Object.keys(dashboardData.data).sort();
      const lastDate = dates[dates.length - 1];
      setLastUpdate(lastDate);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (searchParams.offset === 0) {
      setShowLoader(true);
    }
    getLinks({ ...searchParams, limit: LINKS_PAGE_LIMIT }).finally(() => {
      setShowLoader(false);
    });
  }, [getLinks, searchParams, partner]);

  useEffect(() => {
    setLinksData({ elements: links });
  }, [links]);

  const onPageChange = (offset) => {
    setSearchParams({
      ...searchParams,
      offset,
    });
  };

  const addLinkHandler = (form, partnersVersionId, domainId, webLinkUrl) => {
    addWebLink({ partnersVersionId, domainId, webLinkUrl }, form).then((id) => {
      if (!id) return;
      getLinks({ ...searchParams, limit: LINKS_PAGE_LIMIT });
      setLinkPopup({ link: null, show: false, new: null });
    });
  };

  const editLinkHandler = (form, id, versionId, domainId, webLinkUrl) => {
    editWebLink(id, { versionId, domainId, webLinkUrl }, form).then((ok) => {
      if (!ok) return;
      getLinks({ ...searchParams, limit: LINKS_PAGE_LIMIT });
      setLinkPopup({ link: null, show: false, new: null });
    });
  };

  const onDeleteConfirmed = () => {
    const link = deleteAlert.link;
    deleteWebLink(link.web_link_id).then((ok) => {
      if (!ok) return;
      if (
        linksMeta.count - 1 >= searchParams.offset &&
        searchParams.offset >= LINKS_PAGE_LIMIT
      ) {
        setSearchParams({
          ...searchParams,
          offset: searchParams.offset - LINKS_PAGE_LIMIT,
        });
        return;
      }
      getLinks({ ...searchParams, limit: LINKS_PAGE_LIMIT });
    });
    setDeleteAlert({ show: false, link: null });
  };

  const headers = [
    {
      label: t("number"),
    },
    {
      label: t("productName"),
    },
    {
      label: t("versionName"),
    },
    {
      label: t("webLinkUrl"),
    },
    {
      label: t("actions"),
      className: "text-right",
    },
  ];

  return (
    <div className="content" ref={ref}>
      {linkPopup?.show && (
        <LinkPopup
          link={linkPopup.link}
          newLink={linkPopup.new}
          addHandler={addLinkHandler}
          editHandler={editLinkHandler}
          cancelHandler={() =>
            setLinkPopup({ link: null, show: false, new: null })
          }
          products={products}
          domainOptions={domainOptions}
        />
      )}
      {deleteAlert.show && (
        <ReactBSAlert
          warning
          showCancel
          cancelBtnText={t("common:button.cancel")}
          confirmBtnText={t("common:button.delete")}
          confirmBtnBsStyle="danger"
          title={t("deleteAlertTitle")}
          onConfirm={onDeleteConfirmed}
          onCancel={() => setDeleteAlert({ show: false, group: null })}
          focusCancelBtn
        />
      )}
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("title")}</h4>
              <Button
                className="btn btn-info"
                onClick={() =>
                  setLinkPopup({ link: {}, show: true, new: true })
                }
              >
                {t("common:button.add")}
              </Button>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div>{`${t("tryOnCount")}: ${requestCount}`}</div>
            <div className="last-update">{`${t(
              "lastUpdate"
            )}: ${lastUpdate}`}</div>

            <LinkSearch
              onSearchCallback={(params) =>
                setSearchParams({ ...searchParams, ...params })
              }
            />
            <CommonLoader show={showLoader} />
            {!showLoader && (
              <CommonTable
                headers={headers}
                data={linksData}
                rowComponent={LinkTableRow}
                offset={searchParams.offset}
                onOffsetChange={onPageChange}
                total={Number(linksMeta.count) ?? 0}
                pageSize={LINKS_PAGE_LIMIT}
                keyProperty="web_link_id"
                rowComponentProps={{
                  editHandler: (link) =>
                    setLinkPopup({ show: true, link: link, new: false }),
                  deleteHandler: (link) =>
                    setDeleteAlert({ show: true, link: link }),
                }}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ links, partners, products, dashboard }) => {
  return {
    links: links.get("linkList") ?? [],
    linksMeta: links.get("meta"),
    partner: partners.get("partner"),
    products: products.get("allProductList") ?? [],
    dashboardData: dashboard.get("data"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLinks,
      getAllProductsWithVersions,
      addWebLink,
      editWebLink,
      deleteWebLink,
      getDomains,
      getDashboardData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LinkList);
