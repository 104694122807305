import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import { GET_NOTIFICATIONS_SUCCESS } from "./NotificationReducer";

export const getNotifications = (params) => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.NOTIFICATIONS, { params })
    .then((res) => {
      let notifications = res?.data?.body;
      dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: {
          notifications,
        },
      });
      return notifications;
    })
    .catch((err) => dispatch(handleError(err)));
};

export const sendNotification = (notification, form) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.NOTIFICATIONS, notification)
    .then((res) => {
      return res?.data?.id;
    })
    .catch((err) => {
      dispatch(handleError(err, form));
      return false;
    });
};

export const deleteNotification = (id) => (dispatch) => {
  return apiClient
    .delete(`${ApiEndpoints.NOTIFICATIONS}${id}`)
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};
