const integerRegex = /^[0-9]*$/;
const decimalRegex = /^[0-9]*[\.]?[0-9]*$/; //eslint-disable-line
const decimalNegativeRegex = /^[-]?[0-9]*[\.]?[0-9]*$/; //eslint-disable-line

const checkRequiredSettings = (settings, requiredSettings) => {
  return requiredSettings.every((v) => v.some((x) => settings[x] === true));
};

const checkDisabledSettings = (settings, disabledSettings) => {
  return disabledSettings.every((v) => v.some((x) => settings[x] === false));
};

const currenciesToSelect = (currencies) =>
  Object.keys(currencies).map((key) => ({
    label: currencies[key],
    value: currencies[key],
  }));

export {
  checkRequiredSettings,
  checkDisabledSettings,
  integerRegex,
  decimalRegex,
  decimalNegativeRegex,
  currenciesToSelect,
};
