import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { REACT_APP_DEV_MODE } from "constants/env";
import languages from "./languages";

const options = {
  debug: REACT_APP_DEV_MODE,
  defaultNS: "common",
  fallbackLng: "pl",
  resources: {
    pl: languages.pl,
    en: languages.en,
  },
  whitelist: ["pl", "en"],
  detection: {
    order: ["navigator"],
  },
};

const locales = Object.keys(options.resources);
export function getCurrentLocale() {
  return i18n.languages.find((lng) => locales.indexOf(lng) !== -1);
}

i18n.use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;
