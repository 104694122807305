import React from "react";
import { FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";
import { Field, getIn } from "formik";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";

const FormPriceInputFieldInner = (props) => {
  const { label, placeholder, form, field, suffix, disabled = false } = props;

  const { t } = useTranslation();

  const error = getIn(form.errors, field.name) || form.errors[field.name];
  const touched = getIn(form.touched, field.name);
  const value = getIn(form.values, field.name) || "";

  const element = (
    <>
      <CurrencyInput
        name={field.name}
        value={value}
        step={1}
        suffix={" " + suffix}
        decimalSeparator="."
        placeholder={placeholder}
        className="form-control"
        onValueChange={(value) => form.setFieldValue(field.name, value)}
        disabled={form.isSubmitting || disabled}
        onBlur={() => form.setFieldTouched(field.name, true)}
      />
      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: "red" }}>{t(error)}</label>
      )}
    </>
  );

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }}>
        <Label>{label}</Label>
        <FormGroup>{element}</FormGroup>
      </div>
    </div>
  );
};

const FormPriceInputField = (props) => {
  return <Field {...props} component={FormPriceInputFieldInner} />;
};

FormPriceInputField.defaultProps = {
  placeholder: "",
  disabled: false,
};

FormPriceInputField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  suffix: PropTypes.string.isRequired,
};

export default FormPriceInputField;
