import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useRouteMatch, withRouter } from "react-router-dom";
import { Card, CardHeader, CardBody, Col, Button } from "reactstrap";
import { deleteBanner, getBanners } from "../TotemsActions";
import _ from "lodash";
import RouterPaths from "constants/RouterPaths";
import CommonTable from "common/components/CommonTable";
import CommonLoader from "common/components/loader/CommonLoader";
import ReactBSAlert from "react-bootstrap-sweetalert";
import TotemBannersTableRow from "./TotemBannersTableRow";
import { showAlert } from "common/components/alert/AlertActions";
import AlertVariant from "common/components/alert/AlertVariant";
import AddBannerPopup from "./AddBannerPopup";
import BackButton from "common/components/BackButton";

const Banners = ({ getBanners, deleteBanner, banners, partner, showAlert }) => {
  const { t } = useTranslation("totems");
  const [showLoader, setShowLoader] = useState(true);
  const match = useRouteMatch({
    path: RouterPaths.TOTEM_BANNERS,
    strict: true,
    sensitive: true,
  });
  const totem_id = _.get(match, "params.id", null);
  useEffect(() => {
    getBanners(totem_id).then(() => setShowLoader(false));
  }, [partner, totem_id, getBanners]);

  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    banner: null,
  });

  const deleteBannerHandler = () => {
    deleteBanner(
      totem_id,
      deleteAlert.banner,
      deleteAlert.bannerImageLink
    ).then((formCorrect) => {
      setDeleteAlert({ show: false, totem: null });
      if (formCorrect) {
        showAlert(t("form:deleteSuccess"), AlertVariant.SUCCESS);
      }
    });
  };

  const addVersionHandler = () => {
    setPopupEdit({ show: true, totem: totem_id, banner: null });
  };

  const [popupEdit, setPopupEdit] = useState({
    show: false,
    banner: null,
    totem: null,
  });

  const headers = [
    { label: t("bannerName") },
    { label: t("bannerType") },
    { label: t("duration") },
    { label: t("displayedTime") },
    { label: t("active") },
    { label: t("edit") },
    { label: t("delete") },
  ];

  return (
    <div className="content">
      {deleteAlert.show && (
        <ReactBSAlert
          warning
          showCancel
          cancelBtnText={t("common:button.cancel")}
          confirmBtnText={t("common:button.delete")}
          confirmBtnBsStyle="danger"
          title={t("deleteAlertTitle")}
          onConfirm={deleteBannerHandler}
          onCancel={() => setDeleteAlert({ show: false, totem: null })}
          focusCancelBtn
        />
      )}
      {popupEdit?.show && (
        <AddBannerPopup
          totem={popupEdit.totem}
          banner={popupEdit.banner}
          cancelHandler={() =>
            setPopupEdit({ totem: null, banner: null, show: false })
          }
        />
      )}
      <Col md="12">
        <BackButton link={RouterPaths.TOTEMS} />
        <Card>
          <CardHeader className="list-header">
            <Button
              type="button"
              color="info"
              onClick={() => addVersionHandler()}
            >
              <i className="nc-icon nc-ruler-pencil" /> {t("addBanners")}
            </Button>
          </CardHeader>
          <CardBody>
            <CommonLoader show={showLoader} />
            {!showLoader && (
              <CommonTable
                headers={headers}
                data={banners || []}
                rowComponent={TotemBannersTableRow}
                pagination={false}
                total={banners?.length || 0}
                rowComponentProps={{
                  deleteBannerHandler: (item) =>
                    setDeleteAlert({
                      show: true,
                      banner: item.banner_id,
                      bannerImageLink: item.banner_image_link,
                    }),
                  addBannerHandler: (item) =>
                    setPopupEdit({ show: true, banner: item, totem: totem_id }),
                }}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ totems, partners }) => {
  return {
    banners: { elements: totems.get("banners") },
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBanners,
      deleteBanner,
      showAlert,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Banners));
