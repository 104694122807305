import { Redirect, Route } from "react-router-dom";
import React from "react";
import connect from "react-redux/es/connect/connect";
import ExtendedUserRoles from "./constants/ExtendedUserRoles";
import RouterPaths from "./constants/RouterPaths";
import { checkPermission } from "common/utils/UserUtils";

const AdminRoute = ({ component: Component, user, ...rest }) => {
  return checkPermission(user.role, ExtendedUserRoles.ADMIN) ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    <Redirect to={RouterPaths.NOT_FOUND} />
  );
};

const mapStateToProps = function ({ auth }) {
  return {
    user: auth.get("user"),
  };
};

export default connect(mapStateToProps)(AdminRoute);
