import React, { useEffect, useState } from "react";
import { useRouteMatch, withRouter } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Col,
  Button,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import RouterPaths from "constants/RouterPaths";
import _ from "lodash";
import BackButton from "common/components/BackButton";
import AlertVariant from "common/components/alert/AlertVariant";
import { showAlert } from "common/components/alert/AlertActions";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  propertyListToSelect,
  propertySelectToList,
} from "common/utils/PropertyUtils";
import CommonLoader from "common/components/loader/CommonLoader";

import CommonTable from "common/components/CommonTable";
import ExtendedUserRoles from "constants/ExtendedUserRoles";
import { isMainPartner } from "common/utils/PartnerUtils";
import {
  getProduct,
  editProduct,
  deleteProduct,
  moveProductToMainPartner,
} from "./ProductActions";
import ValidationSchema2d from "./form/Product2dForm.schema";
import ValidationSchema from "./form/ProductForm.schema";
import ProductForm from "./form/ProductForm";
import moment from "../../moment/moment";
import LogTableRow from "./LogTableRow";
import Product2dForm from "./form/Product2dForm";

const numOfLogsIncrement = 5;

const EditProduct = ({
  product,
  partners,
  partner,
  user,
  getProduct,
  editProduct,
  deleteProduct,
  moveProductToMainPartner,
  showAlert,
  history,
  fromMarketplace,
  fromOtherPartner,
}) => {
  const { t } = useTranslation("product");
  const match = useRouteMatch({
    path: RouterPaths.EDIT_PRODUCT,
    strict: true,
    sensitive: true,
  });
  const partnerProductId = _.get(match, "params.id", null);
  const [initialValues, setInitialValues] = useState({});
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deletedVersions, setDeletedVersions] = useState([]);
  const [newProperties, setNewProperties] = useState([]);
  const [newVersionProperties, setNewVersionProperties] = useState({});
  const [deletedProperties, setDeletedProperties] = useState([]);
  const [deletedVersionProperties, setDeletedVersionProperties] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const [showMoveToMarketPlaceLoader, setShowMoveToMarketPlaceLoader] =
    useState(false);
  const [showMoveToMarketPlaceAlert, setShowMoveToMarketPlaceAlert] =
    useState(false);
  const [editForm, setEditForm] = useState({});
  const [formSaving, setFormSaving] = useState(false);
  const [logData, setLogData] = useState({ elements: [] });
  const [logsNum, setLogsNum] = useState(0);

  useEffect(() => {
    document.getElementById("scrollableWrapper").scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (partnerProductId && !_.isEmpty(partners)) {
      getProduct(partnerProductId).finally(() => {
        setShowLoader(false);
      });
    }
  }, [partnerProductId, getProduct, partners, user]);

  const isSuperadmin = user.role === ExtendedUserRoles.SUPERADMIN;

  useEffect(() => {
    if (product) {
      const productValues = {
        id: product.id,
        partnerProductId: product.partners_product_id,
        is2d: product.is_2d,
        published: product.product_published,
        name: product.product_name,
        brand: {
          label: product.brand_name,
          value: product.brand_id,
        },
        category: {
          label: product.category_name,
          value: product.category_id || null,
        },
        type: {
          code: product.type_name,
          label: t(`types:${product.type_name}`),
          value: product.type_id,
        },
        filesZipLink: product.product_files_zip_link,
        photosZipLink: product.product_photos_zip_link,
        modelFile: product.modelFile,
        modelFileFbx: product.modelFileFbx,
        modelFileUploaded: product.product_model_file_uploaded,
        modelFileFbxUploaded: product.product_model_file_fbx_uploaded,
        aspectRatioIos: product.product_aspect_ratio_ios,
        aspectRatioAndroid: product.product_aspect_ratio_android,
        aspectRatioWeb: product.product_aspect_ratio_web,
        positionYIos: product.product_position_y_ios,
        positionYAndroid: product.product_position_y_android,
        positionYWeb: product.product_position_y_web,
        positionZIos: product.product_position_z_ios,
        positionZAndroid: product.product_position_z_android,
        positionZWeb: product.product_position_z_web,
        rotationIos: product.product_rotation_ios,
        rotationAndroid: product.product_rotation_android,
        rotationWeb: product.product_rotation_web,
        earRotationWeb: product.product_ear_rotation_web,
        useMetallicGlass: product.product_use_metallic_glass,
        productDimensions: product.product_dimensions,
        properties: propertyListToSelect(product.properties) || [],
        versions: product.versions?.map((v) => {
          return {
            id: v.id,
            isAssigned: !!v.partners_version_id,
            partnersVersionId: v.partners_version_id,
            isNewAssign: false,
            published: v.version_published,
            modificationTime: v.updated_at
              ? moment(v.updated_at).format("L, LT")
              : null,
            name: v.version_name,
            code: v.version_code,
            sku: v.version_sku,
            pageLink: v.version_shop_url,
            price: v.version_price ? v.version_price.toString() : null,
            specialPrice: v.version_special_price
              ? v.version_special_price.toString()
              : null,
            thumbLink: v.version_thumb_link,
            smallThumbLink: v.version_thumb_small_link,
            albedoFile: v.albedoFile,
            metallicFile: v.metallicFile,
            normalFile: v.normalFile,
            frontFile: v.frontFile,
            lensesFile: v.lensesFile,
            rightFile: v.rightFile,
            leftFile: v.leftFile,
            albedoFileUploaded: v.version_texture_albedo_uploaded,
            metallicFileUploaded: v.version_texture_metallic_uploaded,
            normalFileUploaded: v.version_texture_normal_uploaded,
            frontFileUploaded: v.version_front_uploaded,
            lensesFileUploaded: v.version_lenses_uploaded,
            leftFileUploaded: v.version_left_uploaded,
            rightFileUploaded: v.version_right_uploaded,
            properties: propertyListToSelect(v.properties) || [],
          };
        }) || [
          {
            published: false,
            name: "",
            pageLink: "",
            thumbFile: null,
            albedoFile: null,
            metallicFile: null,
            normalFile: null,
            frontFile: null,
            lensesFile: null,
            leftFile: null,
            rightFile: null,
            sku: null,
          },
        ],
      };
      setInitialValues(productValues);
      if (product?.logs) {
        setLogData({ elements: product.logs });
      }
    }
  }, [product, t]);

  useEffect(() => {
    if (logData) {
      setLogsNum(
        logData.elements.length < numOfLogsIncrement
          ? logData.elements.length
          : numOfLogsIncrement
      );
    }
  }, [logData]);

  const onVersionDelete = (version) => {
    if (!version) return;
    setDeletedVersions((versions) => [...versions, version]);

    if (fromMarketplace || fromOtherPartner) {
      setInitialValues({
        ...initialValues,
        versions: [
          ...initialValues.versions.map((v) => {
            if (v.id === version.id) {
              return {
                ...v,
                price: null,
                specialPrice: null,
                pageLink: null,
                isAssigned: false,
                isNewAssign: false,
              };
            }
            return v;
          }),
        ],
      });
    }
  };

  const onVersionAssign = (version) => {
    if (!version) return;
    if (deletedVersions.find((v) => v.id === version.id)) {
      setDeletedVersions(deletedVersions.filter((v) => v.id !== version.id));
    }
    setInitialValues({
      ...initialValues,
      versions: [
        ...initialValues.versions.map((v) => {
          if (v.id === version.id) {
            return {
              ...v,
              isAssigned: true,
              isNewAssign: !v.partnersVersionId,
            };
          }
          return v;
        }),
      ],
    });
  };

  const onSubmit = (values, form) => {
    form.setSubmitting(false);
    if (!product) return;

    if (!formSaving) return;

    const editedProduct = {
      ...values,
      brandId: values.brand.value,
      categoryId: values.category.value,
      typeId: values.type.value,
      deletedVersions,
      id: values.id,
      newProperties,
      deletedProperties,
      productLastChangedBy: user.id,
      ownerId: product.owner_id,
    };

    const elementLoad = document.getElementById("load-arlab");
    if (elementLoad) elementLoad.remove();

    const elementArlab = document.getElementById("arlab");
    if (elementArlab) elementArlab.remove();

    if (editedProduct.versions) {
      for (let i = 0; i < editedProduct.versions.length; i++) {
        const element = document.getElementById(`webfit-${i}`);
        if (element) element.innerHTML = "";
        const elementModal = document.getElementById(`webfit-${i}-modal`);
        if (elementModal) elementModal.innerHTML = "";
      }
    }

    if (editedProduct.versions) {
      editedProduct.versions.map((v) => {
        v.pageLink = v.pageLink?.trim();
        if (v.id) {
          v.newProperties = newVersionProperties[v.id];
          v.deletedProperties = deletedVersionProperties[v.id];
          delete v.properties;
        } else {
          v.properties = propertySelectToList(v.properties);
        }

        if ((_.isEmpty(v.thumbLink) || v.thumbFileDeleted) && !v.thumbFile) {
          v.thumbFile = v.autoGeneratedThumbFile;
        }
        return v;
      });
    }

    delete editedProduct.brand;
    delete editedProduct.category;
    delete editedProduct.type;
    delete editedProduct.properties;

    editProduct(editedProduct, form).then((formCorrect) => {
      if (formCorrect) {
        showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
        getProduct(partnerProductId).finally(() => {
          setFormSaving(false);
          setShowSaveLoader(false);
          setNewProperties([]);
          setDeletedProperties([]);
          setDeletedVersionProperties({});
          setDeletedVersions([]);
          setNewVersionProperties({});
          document
            .getElementById("scrollableWrapper")
            .scrollTo({ top: 0, behavior: "smooth" });
        });
      } else {
        setFormSaving(false);
        setShowSaveLoader(false);
      }
    });
  };

  const onDeleteConfirmed = () => {
    if (editForm) editForm.setSubmitting(true);
    setShowDeleteLoader(true);
    setShowDeleteAlert(false);
    deleteProduct(partnerProductId).then((deleted) => {
      setShowDeleteLoader(false);
      if (editForm) editForm.setSubmitting(false);
      if (deleted) {
        showAlert(t("form:deleteSuccess"), AlertVariant.SUCCESS);
        history.push(RouterPaths.PRODUCTS);
      }
    });
  };

  const onMoveToMarketPlaceConfirmed = (id) => {
    setShowMoveToMarketPlaceLoader(true);
    setShowMoveToMarketPlaceAlert(false);
    moveProductToMainPartner(id).then((productMoved) => {
      setShowMoveToMarketPlaceLoader(false);
      if (productMoved) {
        showAlert(t("form:moveToMarketPlaceSuccess"), AlertVariant.SUCCESS);
        history.push(RouterPaths.PRODUCTS);
      }
    });
  };

  const moveToMarketPlaceHandler = () => {
    setShowMoveToMarketPlaceAlert(true);
  };

  const onPropertySelected = (option) => {
    if (!option || !product) return;

    const property = { id: option.propertyId };

    if (!product.properties) {
      setNewProperties((newProperties) => [...newProperties, property]);
      return;
    }

    const existingProperty = product.properties.find(
      (p) => p.id === property.id
    );
    if (existingProperty)
      setDeletedProperties((deletedProperties) =>
        deletedProperties.filter((p) => p.id !== property.id)
      );
    else setNewProperties((newProperties) => [...newProperties, property]);
  };

  const onPropertyVersionSelected = (option, versionId) => {
    if (!option || !versionId || !product) return;

    const property = { id: option.propertyId };

    const version = product.versions?.find((v) => v.id === versionId);

    if (!version.properties) {
      setNewVersionProperties((newVersionProperties) => {
        return {
          ...newVersionProperties,
          [versionId]: newVersionProperties[versionId]
            ? [...newVersionProperties[versionId], property]
            : [property],
        };
      });
      return;
    }

    const existingProperty = version.properties.find(
      (p) => p.id === property.id
    );

    if (existingProperty)
      setDeletedVersionProperties((deletedVersionProperties) => {
        return {
          ...deletedVersionProperties,
          [versionId]: deletedVersionProperties[versionId].filter(
            (p) => p.id !== property.id
          ),
        };
      });
    else
      setNewVersionProperties((newVersionProperties) => {
        return {
          ...newVersionProperties,
          [versionId]: newVersionProperties[versionId]
            ? [...newVersionProperties[versionId], property]
            : [property],
        };
      });
  };

  const onPropertyDeselected = (option) => {
    if (!option || !product) return;

    const property = { id: option.propertyId };

    if (!product.properties) {
      setNewProperties((newProperties) =>
        newProperties.filter((p) => p.id !== property.id)
      );
      return;
    }

    const existingProperty = product.properties.find(
      (p) => p.id === property.id
    );
    if (existingProperty)
      setDeletedProperties((deletedProperties) => [
        ...deletedProperties,
        property,
      ]);
    else
      setNewProperties((newProperties) =>
        newProperties.filter((p) => p.id !== property.id)
      );
  };

  const onPropertyVersionDeselected = (option, versionId) => {
    if (!option || !versionId || !product) return;

    const property = { id: option.propertyId };

    const version = product.versions?.find((v) => v.id === versionId);
    if (!version.properties) {
      setNewVersionProperties((newVersionProperties) => {
        return {
          ...newVersionProperties,
          [versionId]: newVersionProperties[versionId].filter(
            (p) => p.id !== property.id
          ),
        };
      });
      return;
    }
    const existingProperty = version.properties.find(
      (p) => p.id === property.id
    );
    if (existingProperty)
      setDeletedVersionProperties((deletedVersionProperties) => {
        return {
          ...deletedVersionProperties,
          [versionId]: deletedVersionProperties[versionId]
            ? [...deletedVersionProperties[versionId], property]
            : [property],
        };
      });
    else
      setNewVersionProperties((newVersionProperties) => {
        return {
          ...newVersionProperties,
          [versionId]: newVersionProperties[versionId].filter(
            (p) => p.id !== property.id
          ),
        };
      });
  };

  const headers = [
    {
      label: t("user"),
    },
    {
      label: t("timestamp"),
    },
    {
      label: t("details"),
    },
  ];

  return (
    <div className="content">
      <Col md="12">
        <BackButton link={RouterPaths.PRODUCTS} />
        {showDeleteAlert && (
          <ReactBSAlert
            warning
            showCancel
            cancelBtnText={t("common:button.cancel")}
            confirmBtnText={t("common:button.delete")}
            confirmBtnBsStyle="danger"
            title={t("deleteAlertTitle")}
            onConfirm={onDeleteConfirmed}
            onCancel={() => setShowDeleteAlert(false)}
            focusCancelBtn
          />
        )}
        {showMoveToMarketPlaceAlert && (
          <ReactBSAlert
            warning
            showCancel
            cancelBtnText={t("common:button.cancel")}
            confirmBtnText={t("common:button.moveToMarketPlace")}
            confirmBtnBsStyle="danger"
            title={t("moveToMarketPlaceAlertTitle")}
            onConfirm={() => onMoveToMarketPlaceConfirmed(product.id)}
            onCancel={() => setShowMoveToMarketPlaceAlert(false)}
            focusCancelBtn
          />
        )}
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("editProductTitle")}</h4>
              <div className="header-button-container">
                <Row>
                  {isSuperadmin &&
                    !fromMarketplace &&
                    !fromOtherPartner &&
                    !isMainPartner(partner) &&
                    initialValues?.published && (
                      <Button
                        type="button"
                        disabled={formSaving}
                        className="btn header-button"
                        onClick={moveToMarketPlaceHandler}
                        showLoader={showMoveToMarketPlaceLoader}
                        style={{ height: "40px" }}
                      >
                        {t("common:button.moveToMarketPlace")}
                      </Button>
                    )}
                  {isSuperadmin &&
                    !fromMarketplace &&
                    !fromOtherPartner &&
                    !showLoader && (
                      <Button
                        disabled={
                          showLoader ||
                          showDeleteLoader ||
                          showMoveToMarketPlaceLoader ||
                          showSaveLoader ||
                          !product?.product_files_zip_link
                        }
                        className="btn header-button"
                        href={product?.product_files_zip_link}
                        style={{ height: "40px" }}
                      >
                        {t("common:button.download")}
                      </Button>
                    )}
                  {isSuperadmin &&
                    product?.product_initial_files_zip_link &&
                    !fromMarketplace &&
                    !fromOtherPartner &&
                    !showLoader && (
                      <Button
                        disabled={
                          showLoader ||
                          showDeleteLoader ||
                          showSaveLoader ||
                          showMoveToMarketPlaceLoader
                        }
                        className="btn header-button"
                        href={product?.product_initial_files_zip_link}
                        style={{ height: "40px" }}
                      >
                        {t("common:button.downloadInitial")}
                      </Button>
                    )}
                </Row>
              </div>
            </CardTitle>
          </CardHeader>
          <CommonLoader show={showLoader} />
          {!showLoader && (
            <CardBody>
              {!initialValues.is2d && (
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={ValidationSchema}
                  enableReinitialize
                >
                  {(form) => (
                    <ProductForm
                      deleteHandler={() => {
                        setShowDeleteAlert(true);
                        setEditForm(form);
                      }}
                      versionDeleteHandler={onVersionDelete}
                      versionAssignHandler={onVersionAssign}
                      form={form}
                      selectPropertyHandler={onPropertySelected}
                      deselectPropertyHandler={onPropertyDeselected}
                      selectVersionPropertyHandler={onPropertyVersionSelected}
                      deselectVersionPropertyHandler={
                        onPropertyVersionDeselected
                      }
                      showSaveLoader={showSaveLoader}
                      showDeleteLoader={showDeleteLoader}
                      showMoveToMarketPlaceLoader={showMoveToMarketPlaceLoader}
                      setFormSaving={(value) => {
                        setFormSaving(value);
                        setShowSaveLoader(value);
                      }}
                      formSaving={formSaving}
                      showTryOn={true}
                      initialValues={initialValues}
                    />
                  )}
                </Formik>
              )}
              {initialValues.is2d && (
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={ValidationSchema2d}
                  enableReinitialize
                >
                  {(form) => (
                    <Product2dForm
                      form={form}
                      versionAssignHandler={onVersionAssign}
                      showSaveLoader={showSaveLoader}
                      setFormSaving={(value) => {
                        setFormSaving(value);
                        setShowSaveLoader(value);
                      }}
                      formSaving={formSaving}
                      deleteHandler={() => {
                        setShowDeleteAlert(true);
                        setEditForm(form);
                      }}
                      versionDeleteHandler={onVersionDelete}
                      showDeleteLoader={showDeleteLoader}
                      showMoveToMarketPlaceLoader={showMoveToMarketPlaceLoader}
                      showTryOn={true}
                    />
                  )}
                </Formik>
              )}
              {product &&
                !_.isEmpty(logData.elements) &&
                user.role === ExtendedUserRoles.SUPERADMIN && (
                  <>
                    <h4>{t("configurationHistory")}</h4>
                    <CommonTable
                      headers={headers}
                      data={{ elements: logData.elements.slice(0, logsNum) }}
                      rowComponent={LogTableRow}
                      total={logsNum}
                      pagination={false}
                    />
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="button"
                        className="btn btn-info icon-button"
                        onClick={() => {
                          return logData.elements.length >
                            logsNum + numOfLogsIncrement
                            ? setLogsNum(logsNum + numOfLogsIncrement)
                            : setLogsNum(logData.elements.length);
                        }}
                        style={{
                          display:
                            logData.elements.length > logsNum
                              ? "visible"
                              : "none",
                        }}
                      >
                        <i className="nc-icon nc-minimal-down" />
                      </Button>
                    </Row>
                  </>
                )}
            </CardBody>
          )}
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = function ({ products, auth, partners }) {
  return {
    product: products.get("product"),
    partners: auth.get("partners") ?? [],
    partner: partners.get("partner"),
    user: auth.get("user"),
    fromMarketplace:
      products.get("product")?.from_main_partner &&
      !isMainPartner(partners.get("partner")),
    fromOtherPartner: products.get("product")?.from_other_partner,
    isMainPartner: isMainPartner(partners.get("partner")),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProduct,
      editProduct,
      deleteProduct,
      moveProductToMainPartner,
      showAlert,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditProduct));
