import { fromJS } from "immutable";

export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";

const initialData = fromJS({
  notificationList: null,
});

export default (state = initialData, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return state.merge({
        notificationList: action.payload.notifications.map((notification) => ({
          ...notification,
          onesignal_data: notification.onesignal_data
            ? {
                ...notification.onesignal_data,
                failed:
                  (notification.onesignal_data?.stats?.android?.failed ?? 0) +
                  (notification.onesignal_data?.stats?.ios?.failed ?? 0),
              }
            : null,
        })),
      });
    default:
      return state;
  }
};
