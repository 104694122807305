/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import logo from "assets/img/logo.png";
var ps;

class Sidebar extends React.Component {
  // this function creates the links that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((route, key) => {
      return (
        <li className={this.activeRoute(route.path)} key={key}>
          <NavLink to={route.path} activeClassName="">
            {route.icon !== undefined ? (
              <>
                <i className={route.icon} />
                <p>{route.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{route.mini}</span>
                <span className="sidebar-normal">{route.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    return (
      <div className="sidebar" data-active-color={"white"}>
        <img src={logo} alt="logo" className="sidebar-logo" />
        <div className="sidebar-wrapper" ref="sidebar">
          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
