import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardBody, Col, Button } from "reactstrap";
import { getTotems } from "./TotemsActions";
import { showAlert } from "common/components/alert/AlertActions";
import TotemTableRow from "./TotemTableRow";
import CommonTable from "common/components/CommonTable";
import { REACT_APP_TOTEM_INSTALLER_LINK } from "constants/env";

const Totems = ({ partner, getTotems, totems }) => {
  const { t } = useTranslation("totems");
  const [totemsData, setTotemsData] = useState({ elements: [] });

  useEffect(() => {
    getTotems();
  }, [partner, getTotems]);

  useEffect(() => {
    setTotemsData({ elements: totems });
  }, [totems]);

  const headers = [
    { label: t("number") },
    { label: t("status") },
    { label: t("totemName") },
    { label: t("totemDeviceName") },
    { label: t("totemExpirationDate") },
    { label: t("totemLastActivity") },
    { label: t("banners") },
    { label: t("edit") },
  ];

  return (
    <div className="content">
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("title")}</h4>
            </CardTitle>
            <Button
              className="btn btn-info btn-download"
              href={REACT_APP_TOTEM_INSTALLER_LINK}
              style={{ height: "40px" }}
            >
              {t("common:button.download")}
            </Button>
          </CardHeader>
          <CardBody>
            <CommonTable
              headers={headers}
              data={totemsData || []}
              rowComponent={TotemTableRow}
              pagination={false}
              total={totemsData?.length || 0}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ partners, totems }) => {
  return {
    partner: partners.get("partner"),
    totems: totems.get("totemList"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTotems,
      showAlert,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Totems));
