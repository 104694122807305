import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Form, useFormikContext, Field } from "formik";
import FormInputField from "common/components/formFields/FormInputField";
import FormSelectField from "common/components/formFields/FormSelectField";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { productsToSelect } from "common/utils/ProductUtils";

const LinkSearchForm = ({ products, partner }) => {
  const { t } = useTranslation("links");
  const form = useFormikContext();
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    setProductOptions(productsToSelect(products));
  }, [products]);

  useEffect(() => {
    form.resetForm();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  return (
    <Form>
      <Row>
        <Col>
          <Field
            required
            fullWidth
            id="product"
            name="product"
            label={t("product")}
            options={productOptions}
            component={FormSelectField}
          />
        </Col>
        <Col xl>
          <FormInputField name="linkSearch" label={t("webLinkUrl")} />
        </Col>
        <Col className="search-button-container">
          <Button color="info" type="submit">
            {t("common:button.search")}
          </Button>
          <Button
            color="danger"
            type="submit"
            onClick={() => {
              form.resetForm();
            }}
          >
            {t("common:button.clear")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = function ({ products, partners }) {
  return {
    products: products.get("allProductList") ?? [],
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LinkSearchForm);
