import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { Card, CardHeader, CardBody, CardTitle, Col, Button } from "reactstrap";
import { Formik, Form, Field } from "formik";
import validationSchema from "./AutocutForm.schema";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import CommonLoader from "common/components/loader/CommonLoader";
import {
  getAutocutReport,
  getImportData,
  updateImportData,
  startImport,
  notifyAboutReport,
  notifyAboutImportComplete,
} from "./AutocutActions";
import { useEffect } from "react";
import _ from "lodash";
import AutocutTableRow from "./AutocutTableRow";
import CommonTable from "common/components/CommonTable";
import FormInputField from "common/components/formFields/FormInputField";
import Row from "reactstrap/lib/Row";
import { showAlert } from "common/components/alert/AlertActions";
import AlertVariant from "common/components/alert/AlertVariant";
import ExtendedUserRoles from "constants/ExtendedUserRoles";
import ReactBSAlert from "react-bootstrap-sweetalert";
import RouterPaths from "constants/RouterPaths";
import { Link } from "react-router-dom";

const Autocut = ({
  getAutocutReport,
  getImportData,
  updateImportData,
  notifyAboutReport,
  notifyAboutImportComplete,
  report,
  importData,
  showAlert,
  startImport,
  user,
  partner,
}) => {
  const { t } = useTranslation("autocut");
  const [showLoader, setShowLoader] = useState(true);
  const [reportData, setReportData] = useState({});
  const [showNotifyClientAlert, setShowNotifyClientAlert] = useState(false);

  useEffect(() => {
    getImportData().then(() => {
      getAutocutReport().then(() => {
        setShowLoader(false);
      });
    });
  }, [getAutocutReport, getImportData]);

  useEffect(() => {
    setReportData(Object.entries(_.mapValues(report, (v) => v?.length || 0)));
  }, [report]);

  const onSubmit = (values, form) => {
    updateImportData(values?.csvUrl).then((ok) => {
      if (ok) {
        showAlert(t("form:submitSuccess"), AlertVariant.SUCCESS);
      }
      form.setSubmitting(false);
    });
  };

  const onImportStart = () => {
    setShowLoader(true);
    startImport(importData?.url).then(() => {
      setShowLoader(false);
    });
  };

  const onClientImportStart = () => {
    notifyAboutReport(partner.partner_name);
  };

  const onNotifyClientConfirmed = () => {
    notifyAboutImportComplete(t("emailSubject"), t("emailText")).then(() =>
      setShowNotifyClientAlert(false)
    );
  };

  const headers = [
    {
      label: t("status"),
    },
    {
      label: t("count"),
    },
  ];

  return (
    <div className="content">
      {showNotifyClientAlert && (
        <ReactBSAlert
          warning
          showCancel
          cancelBtnText={t("common:button.cancel")}
          confirmBtnText={t("common:button.send")}
          confirmBtnBsStyle="danger"
          title={t("notifyClient")}
          onConfirm={onNotifyClientConfirmed}
          onCancel={() => setShowNotifyClientAlert(false)}
          focusCancelBtn
        />
      )}
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("newImport")}</h4>
              {user.role === ExtendedUserRoles.SUPERADMIN && (
                <Button
                  disabled={showLoader || !importData?.url}
                  className="btn btn-info btn-download header-button"
                  onClick={onImportStart}
                  style={{ height: "40px" }}
                >
                  {t("common:button.import")}
                </Button>
              )}
              {user.role !== ExtendedUserRoles.SUPERADMIN && (
                <Button
                  disabled={showLoader || !importData?.url}
                  className="btn btn-info btn-download header-button"
                  onClick={onClientImportStart}
                  style={{ height: "40px" }}
                >
                  {t("common:button.import")}
                </Button>
              )}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CommonLoader show={showLoader} />
            {!showLoader && (
              <>
                <Formik
                  initialValues={{
                    csvUrl: importData?.url,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {() => (
                    <Form noValidate>
                      <Row>
                        <Col md="6" xs="12">
                          <Field
                            required
                            fullWidth
                            id="csvUrl"
                            type="text"
                            name="csvUrl"
                            label={t("csvUrl")}
                            component={FormInputField}
                          />
                        </Col>
                        <Col
                          md="6"
                          xs="12"
                          style={{
                            justifyContent: "end",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <Button type="submit">
                            {t("common:button.save")}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p style={{ color: "green" }}>
                            {importData?.status === 0
                              ? t("newImportWaiting")
                              : ""}
                          </p>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
                <h4>{t("previousImport")}</h4>
                <CommonTable
                  headers={headers}
                  data={{
                    elements: reportData,
                  }}
                  pagination={false}
                  rowComponent={AutocutTableRow}
                  offset={0}
                  total={reportData?.length || 0}
                />
                {user.role === ExtendedUserRoles.SUPERADMIN && (
                  <Col>
                    <Row>
                      <Link
                        to={RouterPaths.AUTOCUT_REPORT}
                        className="btn btn-info"
                      >
                        {t("openReport")}
                      </Link>
                    </Row>
                  </Col>
                )}
                {user.role === ExtendedUserRoles.SUPERADMIN && (
                  <Button
                    disabled={showLoader || !importData?.url}
                    className="btn btn-info btn-download header-button"
                    onClick={() => setShowNotifyClientAlert(true)}
                    style={{ height: "40px" }}
                  >
                    {t("common:button.notifyClient")}
                  </Button>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ autocut, auth, partners }) => {
  return {
    partner: partners.get("partner"),
    user: auth.get("user"),
    report: autocut.get("report") ?? {},
    visualReport: autocut.get("visualReport") || null,
    importData: autocut.get("importData") ?? {},
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      notifyAboutImportComplete,
      notifyAboutReport,
      getAutocutReport,
      getImportData,
      updateImportData,
      showAlert,
      startImport,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Autocut);
