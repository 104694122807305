import { Redirect, Route } from "react-router-dom";
import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import RouterPaths from "../../constants/RouterPaths";

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={RouterPaths.LOGIN} />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = function ({ auth }) {
  return {
    isAuthenticated: auth.get("isAuthenticated"),
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
