import React from "react";
import { Field, Form } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "reactstrap";
import FormInputField from "common/components/formFields/FormInputField";
import FormSelectField from "common/components/formFields/FormSelectField";
import UserStatuses from "constants/UserStatuses";
import UserRoles from "constants/UserRoles";
import ExtendedUserRoles from "constants/ExtendedUserRoles";
import { constantToSelect } from "common/utils/ConstantToSelect";
import { connect } from "react-redux";
import { isMainPartner } from "common/utils/PartnerUtils";

const UserForm = ({
  currentPartner,
  deleteHandler,
  selfEditMode,
  disableRoleField,
  disableEmail = false,
}) => {
  const { t } = useTranslation("user");

  const userStatuses = currentPartner
    ? constantToSelect(UserStatuses, "user:statusOptions")
    : null;
  const userRoles = currentPartner
    ? constantToSelect(UserRoles, "user:roleOptions")
    : null;
  const extendedUserRoles = currentPartner
    ? constantToSelect(ExtendedUserRoles, "user:roleOptions")
    : null;

  return (
    <Form noValidate>
      <Col>
        <Row>
          <Col md="12">
            <Field
              required
              fullWidth
              id="email"
              type="text"
              name="email"
              label={t("email")}
              component={FormInputField}
              disabled={selfEditMode || disableEmail}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Field
              fullWidth
              id="role"
              name="role"
              label={t("role")}
              options={
                isMainPartner(currentPartner) ? extendedUserRoles : userRoles
              }
              component={FormSelectField}
              disabled={selfEditMode || disableRoleField}
            />
          </Col>
          <Col md="6">
            <Field
              fullWidth
              id="status"
              name="status"
              label={t("status")}
              options={userStatuses}
              component={FormSelectField}
              disabled={selfEditMode}
            />
          </Col>
        </Row>
        <Row className="justify-content-end">
          {deleteHandler && !selfEditMode && (
            <Button
              type="button"
              onClick={deleteHandler}
              color="danger"
              className="mr-3 mt-5"
            >
              {t("common:button.delete")}
            </Button>
          )}
          <Button type="submit" color="info" className="mr-3 mt-5">
            {t("common:button.save")}
          </Button>
        </Row>
      </Col>
    </Form>
  );
};

const mapStateToProps = function ({ partners }) {
  return {
    currentPartner: partners.get("partner"),
  };
};

export default connect(mapStateToProps, null)(UserForm);
