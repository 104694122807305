import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle, Col } from "reactstrap";
import {
  getPropertyGroups,
  addPropertyGroup,
  editPropertyGroup,
  getCategories,
  deletePropertyGroup,
} from "./PropertyGroupActions";
import PropertyGroupItem from "./PropertyGroupItem";
import PropertyGroupPopup from "./PropertyGroupPopup";
import { Button } from "reactstrap";
import { categoriesToSelect } from "common/utils/CategoryUtils";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from "lodash";

const PropertyGroupList = ({
  getPropertyGroups,
  getCategories,
  addPropertyGroup,
  editPropertyGroup,
  deletePropertyGroup,
  propertyGroups,
  partner,
  categories,
}) => {
  const { t } = useTranslation("property");
  const [propertyGroupList, setPropertyGroupList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [propertyPopup, setPropertyPopup] = useState({
    group: null,
    index: null,
    show: false,
  });
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    group: null,
  });

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    getPropertyGroups();
  }, [getPropertyGroups, partner]);

  useEffect(() => {
    setPropertyGroupList(
      propertyGroups.map((group) => ({
        id: group.id,
        name: group.property_group_name,
        categoryId: group.category_id,
        properties: group?.properties?.map((prop) => ({
          id: prop.id,
          name: prop.property_name,
        })),
      }))
    );
  }, [propertyGroups]);

  useEffect(() => {
    if (_.isEmpty(categories)) return;
    setCategoryList([
      { label: t("all"), value: null },
      ...categoriesToSelect(categories),
    ]);
  }, [t, categories]);

  useEffect(() => {
    if (_.isEmpty(propertyGroups) || _.isEmpty(categoryList)) return;
    setPropertyGroupList((list) =>
      list.map((group) => ({
        ...group,
        category: categoryList.find((c) => c.value === group.categoryId),
      }))
    );
  }, [propertyGroups, categoryList]);

  const addPropertyGroupHandler = (group) => {
    addPropertyGroup(group).then((id) => {
      if (!id) return;
      getPropertyGroups();
      setPropertyPopup({ group: null, index: null, show: false });
    });
  };

  const editPropertyGroupHandler = (group) => {
    editPropertyGroup(group).then((ok) => {
      if (!ok) return;
      getPropertyGroups();
      setPropertyPopup({ group: null, index: null, show: false });
    });
  };

  const deletePropertyGroupHandler = (group) => {
    setDeleteAlert({ show: true, group: group });
  };

  const onDeleteConfirmed = () => {
    const group = deleteAlert.group;
    deletePropertyGroup(group.id).then((ok) => {
      if (!ok) return;
      setPropertyGroupList((list) => list.filter((g) => g.id !== group.id));
    });
    setDeleteAlert({ show: false, group: null });
  };

  return (
    <div className="content">
      {propertyPopup?.show && (
        <PropertyGroupPopup
          group={propertyPopup.group}
          index={propertyPopup.index}
          addHandler={addPropertyGroupHandler}
          editHandler={editPropertyGroupHandler}
          cancelHandler={() =>
            setPropertyPopup({ group: null, index: null, show: false })
          }
          categories={categoryList}
        />
      )}
      {deleteAlert.show && (
        <ReactBSAlert
          warning
          showCancel
          cancelBtnText={t("common:button.cancel")}
          confirmBtnText={t("common:button.delete")}
          confirmBtnBsStyle="danger"
          title={t("deleteAlertTitle")}
          onConfirm={onDeleteConfirmed}
          onCancel={() => setDeleteAlert({ show: false, group: null })}
          focusCancelBtn
        />
      )}
      <Col md="12">
        <Card>
          <CardHeader className="list-header">
            <CardTitle>
              <h4>{t("title")}</h4>
              <Button
                className="btn btn-info"
                onClick={() =>
                  setPropertyPopup({ group: null, show: true, index: null })
                }
              >
                {t("common:button.add")}
              </Button>
            </CardTitle>
          </CardHeader>
          <CardBody>
            {_.isEmpty(propertyGroupList) ? (
              <div className="list-no-items">
                <span>{t("common:noData")}</span>
              </div>
            ) : (
              propertyGroupList.map((group, index) => (
                <PropertyGroupItem
                  key={group.id}
                  group={group}
                  index={index}
                  editHandler={() =>
                    setPropertyPopup({ group: group, show: true, index: index })
                  }
                  deleteHandler={() => deletePropertyGroupHandler(group)}
                />
              ))
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = ({ properties, partners }) => {
  return {
    propertyGroups: properties.get("propertyGroupList") ?? [],
    categories: properties.get("categoryList") ?? [],
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPropertyGroups,
      addPropertyGroup,
      editPropertyGroup,
      deletePropertyGroup,
      getCategories,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PropertyGroupList);
