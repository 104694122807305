import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    published: Yup.bool()
      .required("common:validator.required")
      .test(
        "published_version",
        "common:validator.cannotPublish",
        function (value) {
          const publishedVersions = this.parent?.versions?.filter(
            (v) => v.published === true
          );
          if (value && publishedVersions.length === 0) return false;
          return true;
        }
      ),
    name: Yup.string()
      .required("common:validator.required")
      .max(200, "common:validator.max200Chars"),
    versions: Yup.array()
      .of(
        Yup.object().shape({
          published: Yup.bool().required("common:validator.required"),
          name: Yup.string()
            .required("common:validator.required")
            .max(200, "common:validator.max200Chars"),
          pageLink: Yup.string()
            .max(200, "common:validator.max200Chars")
            .nullable(),
          frontFile: Yup.mixed()
            .nullable()
            .test(
              "required_other_files",
              "common:validator.required",
              function () {
                if (
                  !this.parent?.frontFileNew &&
                  (this.parent?.lensesFileNew ||
                    this.parent?.leftFileNew ||
                    this.parent?.rightFileNew)
                )
                  return false;
                return true;
              }
            ),
          lensesFile: Yup.mixed()
            .nullable()
            .test(
              "required_other_files",
              "common:validator.required",
              function () {
                if (
                  !this.parent?.lensesFileNew &&
                  (this.parent?.frontFileNew ||
                    this.parent?.leftFileNew ||
                    this.parent?.rightFileNew)
                )
                  return false;
                return true;
              }
            ),
          leftFile: Yup.mixed()
            .nullable()
            .test(
              "required_other_files",
              "common:validator.required",
              function () {
                if (
                  !this.parent?.leftFileNew &&
                  (this.parent?.lensesFileNew ||
                    this.parent?.frontFileNew ||
                    this.parent?.rightFileNew)
                )
                  return false;
                return true;
              }
            ),
          rightFile: Yup.mixed()
            .nullable()
            .test(
              "required_other_files",
              "common:validator.required",
              function () {
                if (
                  !this.parent?.rightFileNew &&
                  (this.parent?.lensesFileNew ||
                    this.parent?.leftFileNew ||
                    this.parent?.frontFileNew)
                )
                  return false;
                return true;
              }
            ),
        })
      )
      .required("product:versionsRequired"),
  });
};
