import React, { useEffect, useState } from "react";
import { Field, Form, FieldArray, getIn } from "formik";
import { Button, Col, Row, Collapse } from "reactstrap";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormInputField from "common/components/formFields/FormInputField";
import FormSelectField from "common/components/formFields/FormSelectField";
import FormMultiselectField from "common/components/formFields/FormMultiselectField";
import { getBrands } from "components/brands/BrandActions";
import { getCategories } from "../ProductActions";
import { categoriesToSelect } from "common/utils/CategoryUtils";
import { brandsToSelect } from "common/utils/BrandUtils";
import ProductVersionForm from "./ProductVersionForm";
import FileExtension from "constants/FileExtension";
import FormDropzoneField from "common/components/formFields/FormDropzoneField";
import _ from "lodash";
import FormRangeField from "common/components/formFields/FormRangeField";
import { propertyGroupsToSelect } from "common/utils/PropertyUtils";
import { getPropertyGroups } from "components/properties/PropertyGroupActions";
import ActionButton from "common/components/ActionButton";
import { getTypes } from "components/types/TypeActions";
import { typesToSelect } from "common/utils/TypeUtils";
import FormSwitchField from "common/components/formFields/FormSwitchField";
import { scrollToError } from "common/utils/FormUtils";
import ExtendedUserRoles from "constants/ExtendedUserRoles";
import { isMainPartner } from "common/utils/PartnerUtils";

const ProductForm = ({
  getBrands,
  getCategories,
  getTypes,
  // currently properties not related to categories
  //getCategoryPropertyGroups,
  getPropertyGroups,
  brands,
  categories,
  types,
  propertyGroups,
  partner,
  form,
  deleteHandler,
  versionDeleteHandler,
  versionAssignHandler,
  selectPropertyHandler,
  deselectPropertyHandler,
  selectVersionPropertyHandler,
  deselectVersionPropertyHandler,
  showSaveLoader,
  showDeleteLoader,
  setFormSaving,
  formSaving,
  showTryOn,
  initialValues,
  user,
  fromMarketplace,
  fromOtherPartner,
}) => {
  const [brandList, setBrandList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [iosSettingsOpen, setIosSettingsOpen] = useState(false);
  const [androidSettingsOpen, setAndroidSettingsOpen] = useState(false);
  const [webSettingsOpen, setWebSettingsOpen] = useState(false);
  const [thumbSize, setThumbSize] = useState({ width: 0, height: 0 });
  const [thumbObjectScale, setThumbObjectScale] = useState(1);
  const [versionsError, setVersionsError] = useState("");
  const [generate, setGenerate] = useState({
    generate: false,
    done: 0,
  });

  const { t } = useTranslation("product");

  const toggleIos = () => setIosSettingsOpen(!iosSettingsOpen);
  const toggleAndroid = () => setAndroidSettingsOpen(!androidSettingsOpen);
  const toggleWeb = () => setWebSettingsOpen(!webSettingsOpen);

  useEffect(() => {
    return () => {
      const loadingScript = document.getElementById("load-arlab");
      const arlabScript = document.getElementById("arlab");
      if (arlabScript) arlabScript.parentElement.removeChild(arlabScript);
      if (loadingScript) loadingScript.parentElement.removeChild(loadingScript);
      if (window["arlab"]) window["arlab"].q = [];
    };
  }, []);

  useEffect(() => {
    if (!partner) return;

    const thumbWidthSetting = partner.thumb_file_width;
    const thumbHeightSetting = partner.thumb_file_height;
    setThumbSize({
      width: thumbWidthSetting || 0,
      height: thumbHeightSetting || 0,
    });

    const thumbObjectScaleSetting = partner.thumb_object_scale;
    setThumbObjectScale(thumbObjectScaleSetting);

    return;
  }, [partner]);

  useEffect(() => {
    getBrands();
  }, [getBrands, partner]);

  useEffect(() => {
    if (partner?.categories_visible) getCategories();
  }, [getCategories, partner]);

  useEffect(() => {
    getTypes();
  }, [getTypes, partner]);

  // currently properties not related to categories
  useEffect(() => {
    getPropertyGroups();
  }, [getPropertyGroups]);

  useEffect(() => {
    const brandsOptions = brandsToSelect(brands, t) || [];
    setBrandList(brandsOptions);
    if (brandsOptions.length === 1) {
      form.setFieldValue("brand", brandsOptions[0]);
    }
    // eslint-disable-next-line
  }, [brands]);

  useEffect(() => {
    const categoriesOptions = categoriesToSelect(categories) || [];
    setCategoryList(categoriesOptions);
    if (categoriesOptions.length === 1) {
      form.setFieldValue("category", categoriesOptions[0]);
    }
    // eslint-disable-next-line
  }, [categories]);

  useEffect(() => {
    const typesOptions = typesToSelect(types) || [];
    setTypeList(typesOptions);

    if (
      typesOptions.length >= 1 &&
      (_.isEmpty(form.values.type) || !form.values.type?.value)
    ) {
      form.setFieldValue("type", typesOptions[0]);
    }
    // eslint-disable-next-line
  }, [types]);

  useEffect(() => {
    if (_.isEmpty(propertyGroups)) return;
    const newPropertyList = propertyGroupsToSelect(propertyGroups);
    setPropertyList(newPropertyList);
    if (form.values.properties) {
      form.setFieldValue(
        "properties",
        form.values.properties.filter((prop) => {
          const wasFound =
            newPropertyList.findIndex((p) => p.value === prop.value) > -1;
          if (!wasFound && deselectPropertyHandler) {
            deselectPropertyHandler(prop);
          }
          return wasFound;
        })
      );
    }
    // eslint-disable-next-line
  }, [deselectPropertyHandler, propertyGroups]);

  useEffect(() => {
    if (getIn(form.errors, "versions") || form.errors["versions"]) {
      const error = getIn(form.errors, "versions") || form.errors["versions"];
      if (typeof error === "string") {
        setVersionsError(
          getIn(form.errors, "versions") || form.errors["versions"]
        );
        return;
      }
    }
    setVersionsError("");
  }, [form.errors]);

  useEffect(() => {
    if (form.values.brand && typeof form.values.brand === "number") {
      const selectedOption = brandList.find(
        (opt) => opt.value === form.values.brand
      );
      form.setFieldValue("brand", selectedOption);
    }
    // eslint-disable-next-line
  }, [form.values.brand, brandList]);

  useEffect(() => {
    if (form.values.category && typeof form.values.category === "number") {
      const selectedOption = categoryList.find(
        (opt) => opt.value === form.values.category
      );
      form.setFieldValue("category", selectedOption);
    }
    // currently properties not related to categories
    // if (form?.values?.category?.value) {
    //   getCategoryPropertyGroups(form.values.category.value);
    // }
    // eslint-disable-next-line
  }, [form.values.category, categoryList]);

  const onFileDrop = (fileFieldName, linkFieldName, files, isMulti = false) => {
    if (!isMulti) {
      const file = files && files.length > 0 ? files[0] : null;
      if (
        !_.isEmpty(_.get(form.values, linkFieldName)) ||
        _.get(form.values, `${fileFieldName}Uploaded`)
      ) {
        form.setFieldValue(`${fileFieldName}Deleted`, true);
      }
      form.setFieldValue(fileFieldName, file);
      form.setFieldValue(`${fileFieldName}New`, !!file);
      return;
    }

    if (!files) {
      if (
        !_.isEmpty(_.get(form.values, linkFieldName)) ||
        _.get(form.values, `${fileFieldName}Uploaded`)
      ) {
        form.setFieldValue(`${fileFieldName}Deleted`, true);
      }
      form.setFieldValue(fileFieldName, null);
    } else {
      const uploadedFiles = form.values[fileFieldName] || [];
      form.setFieldValue(fileFieldName, [...uploadedFiles, ...files]);
    }
  };

  useEffect(() => {
    form.resetForm();
    // eslint-disable-next-line
  }, [initialValues]);

  useEffect(() => {
    if (generate.generate === false || generate.done > 0) return;
    setGenerate({
      generate: false,
      done: 0,
    });
    form.submitForm();
    // eslint-disable-next-line
  }, [generate]);

  const onProductSave = async () => {
    setFormSaving(true);
    const errors = await form.validateForm();
    if (!_.isEmpty(errors)) {
      setFormSaving(false);
      scrollToError(errors);
      form.submitForm();
      return;
    }
    setGenerate({
      generate: true,
      done: form.values.versions.length,
    });
  };

  const buttonDisabled = formSaving || form.isSubmitting;

  const btn = document.getElementsByClassName("header-button").item(0);

  return (
    <Form noValidate>
      <div
        style={{
          position: "absolute",
          right:
            (btn?.offsetWidth ?? 0) +
            (!fromMarketplace || !fromOtherPartner ? 195 : 35),
          top: "45px",
          zIndex: "10",
        }}
      >
        <ActionButton
          type="button"
          color="info"
          className="p-0 actionButton"
          disabled={buttonDisabled}
          onClick={onProductSave}
          label={t("common:button.save")}
          showLoader={showSaveLoader}
        />
      </div>
      <Col>
        <Row>
          <Col md="12">
            {!fromMarketplace && !fromOtherPartner && (
              <Field
                id="published"
                name="published"
                label={
                  partner?.totem_active ? t("publishedApp") : t("published")
                }
                onText={t("common:button.yes").toUpperCase()}
                offText={t("common:button.no").toUpperCase()}
                component={FormSwitchField}
                onChangeHandler={(value) => {
                  if (!value && form?.values?.versions) {
                    form.values.versions.map((version, index) => {
                      if (version.published) {
                        form.setFieldValue(
                          `versions[${index}].published`,
                          false
                        );
                      }
                      return version;
                    });
                  }
                }}
                disabled={formSaving}
              />
            )}
            <Field
              required
              fullWidth
              id="name"
              type="text"
              name="name"
              label={t("name")}
              component={FormInputField}
              disabled={formSaving}
            />
            {partner?.mobile_active && (
              <Field
                fullWidth
                id="brand"
                name="brand"
                label={t("brand")}
                options={brandList}
                component={FormSelectField}
                disabled={formSaving || fromMarketplace || fromOtherPartner}
              />
            )}
            {partner?.mobile_active && partner?.categories_visible && (
              <Field
                fullWidth
                id="category"
                name="category"
                label={t("category")}
                options={categoryList}
                component={FormSelectField}
                // currently properties not related to categories
                // warningText={
                //   !_.isEmpty(form?.values?.properties) && t("propertiesMayChange")
                // }
                disabled={formSaving}
              />
            )}
            {typeList.length > 1 && (
              <Field
                fullWidth
                id="type"
                name="type"
                label={t("type")}
                options={typeList}
                component={FormSelectField}
                disabled={formSaving || fromMarketplace || fromOtherPartner}
              />
            )}
            {!fromMarketplace && !fromOtherPartner && (
              <FormDropzoneField
                id="modelFile"
                name="modelFile"
                label={t("modelFileObj")}
                acceptedExtension={FileExtension.OBJ}
                handleDrop={(file) =>
                  onFileDrop("modelFile", "modelLink", file)
                }
                handleDelete={() => {
                  onFileDrop("modelFile", "modelLink", null);
                  onFileDrop("modelFileFbx", "modelLinkFbx", null);
                }}
                existingFile={
                  form.values.modelFileUploaded && !form.values.modelFileDeleted
                    ? t("fileUploaded")
                    : ""
                }
                disabled={formSaving}
              />
            )}
            {!fromMarketplace && !fromOtherPartner && (
              <FormDropzoneField
                id="modelFileFbx"
                name="modelFileFbx"
                label={t("modelFileFbx")}
                acceptedExtension={FileExtension.FBX}
                handleDrop={(file) =>
                  onFileDrop("modelFileFbx", "modelLinkFbx", file)
                }
                handleDelete={() => {
                  onFileDrop("modelFile", "modelLink", null);
                  onFileDrop("modelFileFbx", "modelLinkFbx", null);
                }}
                existingFile={
                  form.values.modelFileFbxUploaded &&
                  !form.values.modelFileFbxDeleted
                    ? t("fileUploaded")
                    : ""
                }
                disabled={formSaving}
              />
            )}
            {user.role === ExtendedUserRoles.SUPERADMIN &&
              form?.values?.type?.code === "GLASSES" &&
              !fromMarketplace &&
              !fromOtherPartner && (
                <FormSwitchField
                  id="useMetallicGlass"
                  name="useMetallicGlass"
                  label={t("useMetallicGlass")}
                  disabled={formSaving}
                  onText={t("common:button.yes").toUpperCase()}
                  offText={t("common:button.no").toUpperCase()}
                />
              )}
            {partner?.photos_active &&
              !fromMarketplace &&
              !fromOtherPartner && (
                <FormDropzoneField
                  id="photoFiles"
                  name="photoFiles"
                  label={t("photos")}
                  acceptedExtension={[FileExtension.PNG, FileExtension.JPG]}
                  handleDrop={(file) =>
                    onFileDrop("photoFiles", "photosZipLink", file, true)
                  }
                  handleDelete={() =>
                    onFileDrop("photoFiles", "photosZipLink", null, true)
                  }
                  existingFile={
                    !_.isEmpty(form.values.photosZipLink)
                      ? t("fileUploaded")
                      : ""
                  }
                  multi
                  disabled={formSaving}
                />
              )}
            {partner?.mobile_active && (
              <Field
                fullWidth
                id="properties"
                name="properties"
                label={t("properties")}
                newOptionLabel={t("property:newProperty")}
                component={FormMultiselectField}
                options={propertyList}
                newOptionHandler={() => {}}
                selectOptionHandler={selectPropertyHandler}
                deselectOptionHandler={deselectPropertyHandler}
                // currently properties not related to categories
                // warningText={
                //   !form?.values?.category?.value && t("selectCategoryFirst")
                // }
                disabled={formSaving}
              />
            )}
            {partner?.mobile_active &&
              !fromMarketplace &&
              !fromOtherPartner && (
                <>
                  <div>
                    <div
                      className={`inline-element nc-icon nc-minimal-${
                        iosSettingsOpen ? "down" : "right"
                      }`}
                      onClick={toggleIos}
                    ></div>
                    <h5 className="form-section-heading ml-2 pt-3 inline-element">
                      {t("modelSettingsIos")}
                    </h5>
                  </div>
                  <Collapse isOpen={iosSettingsOpen} disabled>
                    <Field
                      id="aspectRatioIos"
                      name="aspectRatioIos"
                      label={t("aspectRatioIos")}
                      min={0.5}
                      max={2}
                      step={0.05}
                      labelStep={0.1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                    <Field
                      id="positionYIos"
                      name="positionYIos"
                      label={t("positionYIos")}
                      min={-1}
                      max={1}
                      step={0.05}
                      labelStep={0.1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                    <Field
                      id="positionZIos"
                      name="positionZIos"
                      label={t("positionZIos")}
                      min={-1}
                      max={1}
                      step={0.05}
                      labelStep={0.1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                    <Field
                      id="rotationIos"
                      name="rotationIos"
                      label={t("rotationIos")}
                      min={-10}
                      max={10}
                      step={0.5}
                      labelStep={1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                  </Collapse>
                  <div>
                    <div
                      className={`inline-element nc-icon nc-minimal-${
                        androidSettingsOpen ? "down" : "right"
                      }`}
                      onClick={toggleAndroid}
                    ></div>
                    <h5 className="form-section-heading ml-2 pt-3 inline-element">
                      {t("modelSettingsAndroid")}
                    </h5>
                  </div>
                  <Collapse isOpen={androidSettingsOpen}>
                    <Field
                      id="aspectRatioAndroid"
                      name="aspectRatioAndroid"
                      label={t("aspectRatioAndroid")}
                      min={0.5}
                      max={2}
                      step={0.05}
                      labelStep={0.1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                    <Field
                      id="positionYAndroid"
                      name="positionYAndroid"
                      label={t("positionYAndroid")}
                      min={-1}
                      max={1}
                      step={0.05}
                      labelStep={0.1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                    <Field
                      id="positionZAndroid"
                      name="positionZAndroid"
                      label={t("positionZAndroid")}
                      min={-1}
                      max={1}
                      step={0.05}
                      labelStep={0.1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                    <Field
                      id="rotationAndroid"
                      name="rotationAndroid"
                      label={t("rotationAndroid")}
                      min={-10}
                      max={10}
                      step={0.5}
                      labelStep={1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                  </Collapse>
                </>
              )}
            {!fromMarketplace &&
              !fromOtherPartner &&
              (partner?.web_active ||
                user.role === ExtendedUserRoles.SUPERADMIN) && (
                <>
                  <div>
                    <div
                      className={`inline-element nc-icon nc-minimal-${
                        webSettingsOpen ? "down" : "right"
                      }`}
                      onClick={toggleWeb}
                    ></div>
                    <h5 className="form-section-heading ml-2 pt-3 inline-element">
                      {t("modelSettingsWeb")}
                    </h5>
                  </div>
                  <Collapse isOpen={webSettingsOpen}>
                    <Field
                      id="aspectRatioWeb"
                      name="aspectRatioWeb"
                      label={t("aspectRatioWeb")}
                      min={0.5}
                      max={2}
                      step={0.025}
                      labelStep={0.1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                    <Field
                      id="positionYWeb"
                      name="positionYWeb"
                      label={t("positionYWeb")}
                      min={-1}
                      max={1}
                      step={0.05}
                      labelStep={0.1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                    <Field
                      id="positionZWeb"
                      name="positionZWeb"
                      label={t("positionZWeb")}
                      min={-1}
                      max={1}
                      step={0.05}
                      labelStep={0.1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                    <Field
                      id="rotationWeb"
                      name="rotationWeb"
                      label={t("rotationWeb")}
                      min={-10}
                      max={10}
                      step={0.5}
                      labelStep={1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                    <Field
                      id="earRotationWeb"
                      name="earRotationWeb"
                      label={t("earRotationWeb")}
                      min={-5}
                      max={5}
                      step={0.5}
                      labelStep={1}
                      disabled={formSaving || form.isSubmitting}
                      component={FormRangeField}
                    />
                  </Collapse>
                </>
              )}
          </Col>
          <Col md="12">
            {form.submitCount > 0 && versionsError && (
              <label style={{ color: "red" }}>{t(versionsError)}</label>
            )}
            <FieldArray
              name="versions"
              render={(arrayHelpers) => (
                <div className="mb-2">
                  {form.values?.versions && (
                    <>
                      {form.values.versions.map((version, index) => (
                        /* eslint-disable react/no-array-index-key */
                        <ProductVersionForm
                          id={version.id}
                          isAssigned={version.isAssigned}
                          key={index}
                          index={index}
                          arrayHelpers={arrayHelpers}
                          deleteHandler={versionDeleteHandler}
                          assignHandler={versionAssignHandler}
                          onFileDrop={onFileDrop}
                          form={form}
                          selectPropertyHandler={selectVersionPropertyHandler}
                          deselectPropertyHandler={
                            deselectVersionPropertyHandler
                          }
                          thumbSize={thumbSize}
                          thumbObjectScale={thumbObjectScale}
                          formSaving={formSaving}
                          showTryOn={showTryOn && partner?.web_active}
                          generate={generate.generate}
                          onThumbAssign={() =>
                            setGenerate((old) => {
                              return { ...old, done: old.done - 1 };
                            })
                          }
                        />
                      ))}
                    </>
                  )}
                  {!fromMarketplace && !fromOtherPartner && (
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          name: "",
                          pageLink: "",
                          thumbFile: null,
                          albedoFile: null,
                          metallicFile: null,
                          normalFile: null,
                          published: false,
                          publishedTotem: false,
                          frontFile: null,
                          lensesFile: null,
                          leftFile: null,
                          rightFile: null,
                          sku: null,
                        })
                      }
                      color="info"
                      className="mr-3"
                      disabled={formSaving || form.isSubmitting}
                    >
                      <i className="nc-icon nc-simple-add button-add-icon" />
                      {t("addVersion")}
                    </Button>
                  )}
                </div>
              )}
            />
          </Col>
        </Row>
        <Row className="justify-content-end">
          <ActionButton
            type="button"
            onClick={deleteHandler}
            color="danger"
            className="mr-3 mt-5 p-0 actionButton"
            disabled={buttonDisabled}
            label={t("common:button.delete")}
            showLoader={showDeleteLoader}
          />
          <ActionButton
            type="button"
            color="info"
            className="mr-3 mt-5 p-0 actionButton"
            disabled={buttonDisabled}
            onClick={onProductSave}
            label={t("common:button.save")}
            showLoader={showSaveLoader}
          />
        </Row>
      </Col>
    </Form>
  );
};

const mapStateToProps = function ({
  brands,
  products,
  partners,
  properties,
  types,
  auth,
}) {
  return {
    brands: brands.get("brandList") ?? [],
    categories: products.get("categoryList") ?? [],
    fromMarketplace:
      products.get("product")?.from_main_partner &&
      !isMainPartner(partners.get("partner")),
    fromOtherPartner: products.get("product")?.from_other_partner,
    // currently properties not related to categories
    // propertyGroups: products.get("propertyGroupList") ?? [],
    propertyGroups: properties.get("propertyGroupList") ?? [],
    partner: partners.get("partner"),
    types: types.get("typeList") ?? [],
    user: auth.get("user"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBrands,
      getCategories,
      getPropertyGroups,
      //getCategoryPropertyGroups,
      getTypes,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
