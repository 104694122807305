import CommonLoader from "common/components/loader/CommonLoader";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { EditorTools, EditorToolBrushes } from "../Constants";
import background from "./checkboardBackground.png";
import { ReactComponent as Magic } from "../icons/magic.svg";
import { ReactComponent as Plus } from "../icons/plus.svg";
import { ReactComponent as Minus } from "../icons/minus.svg";
import { ReactComponent as Undo } from "../icons/undo.svg";

import EditorBottomMenu from "./EditorBottomMenu";
import EditorCanvasTool from "./EditorCanvasTool";
import { renderCanvas } from "../Utils";

const EditorCanvas = ({
  onEdit,
  onUndo,
  isUndoPossible,
  backgroundImg,
  maskImg,
  loading,
  hintText,
  photoTitle,
}) => {
  const { t } = useTranslation("wizard");

  const [zoom, setZoom] = useState(1);
  const [tool, setTool] = useState(EditorTools.SMART_MINUS);
  const [previewOriginal, setPreviewOriginal] = useState(false);

  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const bgImgRef = useRef(null);
  const maskImgRef = useRef(null);
  const cursorRef = useRef({
    x: -100,
    y: -100,
    pressed: false,
    visible: false,
  });
  const positionRef = useRef({ x: 0, y: 0 });
  const brushRadius = 10;

  useEffect(() => {
    bgImgRef.current = new Image();
    bgImgRef.current.src = backgroundImg;
  }, [backgroundImg]);

  useEffect(() => {
    maskImgRef.current = new Image();
    maskImgRef.current.src = maskImg;
  }, [maskImg]);

  const render = useCallback(() => {
    const ctx = contextRef.current;
    if (!ctx || !bgImgRef.current.naturalWidth) return;

    const { canvas } = ctx;

    renderCanvas(
      canvas,
      bgImgRef.current,
      560,
      maskImgRef.current.naturalWidth && !previewOriginal
        ? maskImgRef.current
        : null,
      {
        scale: zoom,
        position: positionRef.current,
      }
    );

    if (!loading && cursorRef.current.visible) {
      const pointer = cursorRef.current;
      ctx.globalCompositeOperation = "source-over";
      ctx.beginPath();
      ctx.fillStyle = EditorToolBrushes[tool].color;
      ctx.arc(pointer.x, pointer.y, brushRadius, 0, Math.PI * 2, true);
      ctx.fill();
    }

    window.requestAnimationFrame(render);
  }, [loading, previewOriginal, zoom, tool]);

  const applyCurrentTransforms = useCallback(
    (point) => {
      let newX = point.x;
      let newY = point.y;
      newX -= canvasRef.current.width / 2;
      newY -= canvasRef.current.height / 2;
      newX /= zoom;
      newY /= zoom;
      newX += canvasRef.current.width / 2 - positionRef.current.x;
      newY += canvasRef.current.height / 2 - positionRef.current.y;
      newX /= canvasRef.current.width / bgImgRef.current.width;
      newY /= canvasRef.current.height / bgImgRef.current.height;
      newX = Math.round(newX);
      newY = Math.round(newY);
      return { x: newX, y: newY };
    },
    [zoom]
  );

  const handleMouseMove = useCallback(
    (e) => {
      const diffX = e.offsetX - cursorRef.current.x;
      const diffY = e.offsetY - cursorRef.current.y;

      if (
        Math.abs(diffX) < brushRadius / 2 &&
        Math.abs(diffY) < brushRadius / 2
      )
        return;

      cursorRef.current.x = e.offsetX;
      cursorRef.current.y = e.offsetY;
      if (!cursorRef.current.pressed) return;

      const newPoint = applyCurrentTransforms(cursorRef.current);
      const scale = bgImgRef.current.width / canvasRef.current.width / zoom;
      const newBrushRadius = Math.round(brushRadius * scale);

      onEdit(newPoint.x, newPoint.y, newBrushRadius, tool);
    },
    [applyCurrentTransforms, onEdit, zoom, tool]
  );

  const handleMouseDown = useCallback(() => {
    cursorRef.current.pressed = true;
  }, []);

  const handleMouseUp = useCallback(
    (e) => {
      cursorRef.current.pressed = false;
      cursorRef.current.x = e.offsetX;
      cursorRef.current.y = e.offsetY;

      const newPoint = applyCurrentTransforms(cursorRef.current);

      const scale = bgImgRef.current.width / canvasRef.current.width / zoom;
      const newBrushRadius = Math.round(brushRadius * scale);

      onEdit(newPoint.x, newPoint.y, newBrushRadius, tool, true);
    },
    [applyCurrentTransforms, onEdit, zoom, tool]
  );

  const handleMouseOut = useCallback(() => {
    cursorRef.current.pressed = false;
    cursorRef.current.visible = false;
  }, []);

  const handleMouseEnter = () => {
    cursorRef.current.visible = true;
  };

  const onKeyDown = useCallback(
    (event) => {
      if (event.ctrlKey && event.key === "z") {
        onUndo();
      }
    },
    [onUndo]
  );

  useEffect(() => {
    const canvas = canvasRef.current;

    contextRef.current = canvas.getContext("2d");
    if (!contextRef.current) return;

    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mouseout", handleMouseOut);
    canvas.addEventListener("mouseenter", handleMouseEnter);
    document.addEventListener("keydown", onKeyDown);

    contextRef.current.lineJoin = "round";
    contextRef.current.lineCap = "round";
    contextRef.current.lineWidth = brushRadius;

    render();

    return () => {
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mouseout", handleMouseOut);
      canvas.removeEventListener("mouseenter", handleMouseEnter);
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [
    handleMouseMove,
    handleMouseDown,
    handleMouseUp,
    handleMouseOut,
    render,
    onKeyDown,
  ]);

  return (
    <Col>
      <Row>
        <div className="editor-canvas-side-menu-container">
          <div className="editor-canvas-hint">{hintText}</div>
          <div className="editor-canvas-tools-title">{t("editMask")}</div>
          <div className="editor-canvas-tools-container">
            <EditorCanvasTool
              onClick={() => setTool(EditorTools.SMART_PLUS)}
              outlined
              text={t("intelligentKeep")}
              color="#0D8F84"
              icon={<Magic color="#0D8F84" />}
              selected={tool === EditorTools.SMART_PLUS}
            />
            <EditorCanvasTool
              onClick={() => setTool(EditorTools.SMART_MINUS)}
              outlined
              text={t("intelligentRemove")}
              color="#BA1176"
              icon={<Magic color="#BA1176" />}
              selected={tool === EditorTools.SMART_MINUS}
            />
            <EditorCanvasTool
              onClick={() => setTool(EditorTools.PLUS)}
              text={t("manualKeep")}
              color="#0D8F84"
              icon={<Plus />}
              selected={tool === EditorTools.PLUS}
            />
            <EditorCanvasTool
              onClick={() => setTool(EditorTools.MINUS)}
              text={t("manualRemove")}
              color="#BA1176"
              icon={<Minus />}
              selected={tool === EditorTools.MINUS}
            />
          </div>
        </div>
        <div>
          <div className="editor-canvas-photo-title">{photoTitle}</div>
          <div className="editor-canvas-container" type="button">
            <CommonLoader show={loading} className="editor-loader" />
            <button
              type="button"
              className="editor-canvas-undo-button"
              onClick={onUndo}
              disabled={!isUndoPossible}
            >
              <Undo color="#101828" />
            </button>
            <img src={background} alt="img" />
            <canvas ref={canvasRef} className="editor-canvas" />
            <EditorBottomMenu
              zoom={zoom}
              setZoom={setZoom}
              previewOriginal={previewOriginal}
              setPreviewOriginal={setPreviewOriginal}
              canvasRef={canvasRef}
              positionRef={positionRef}
              disabled={loading}
            />
          </div>
        </div>
      </Row>
    </Col>
  );
};

export default EditorCanvas;
