const categoriesToSelect = (categoryArr) => {
  if (!categoryArr) return [];
  let res = categoryArr.map((c) => {
    return { label: c.category_name, value: c.id };
  });
  return res;
};

const parseProductCategories = (products, categories) => {
  if (products.length > 0 && categories.length > 0) {
    const newProducts = products.map((product) => {
      if (!product.categoryId) return product;
      const categoryPath = categories
        .filter((category) => category.value === product.category_id)
        .shift().label;
      return {
        ...product,
        category_name: categoryPath,
      };
    });
    return newProducts;
  }
  return products;
};
export { categoriesToSelect, parseProductCategories };
