import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Form, useFormikContext } from "formik";
import FormInputField from "common/components/formFields/FormInputField";
import FormSelectField from "common/components/formFields/FormSelectField";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCategories } from "../ProductActions";
import { categoriesToSelect } from "common/utils/CategoryUtils";
import {
  categoriesOptions,
  ownershipOptions,
  statusOptions,
} from "constants/SearchOptions";
import { constantToSelect } from "common/utils/ConstantToSelect";

const ProductSearchForm = ({ getCategories, categories, partner }) => {
  const [categoryList, setCategoryList] = useState([]);
  const { t } = useTranslation("product");
  const form = useFormikContext();

  useEffect(() => {
    getCategories();
    form.resetForm();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCategories, partner]);

  useEffect(() => {
    setCategoryList(categoriesToSelect(categories));
  }, [categories]);

  return (
    <Form>
      <Row>
        <Col>
          <FormInputField name="productName" label={t("name")} />
        </Col>
        {partner?.categories_visible && categoryList.length > 0 && (
          <Col xl>
            <FormSelectField
              name="category"
              label={t("category")}
              options={
                partner
                  ? [
                      ...constantToSelect(
                        categoriesOptions,
                        "product:search",
                        partner
                      ),
                      ...categoryList,
                    ]
                  : []
              }
            />
          </Col>
        )}
        <Col xl>
          <FormSelectField
            name="status"
            label={t("search.status")}
            options={
              partner ? constantToSelect(statusOptions, "product:search") : []
            }
          />
        </Col>
        <Col xl>
          <FormSelectField
            name="owner"
            label={t("search.owner")}
            options={
              partner
                ? constantToSelect(ownershipOptions, "product:search")
                : []
            }
          />
        </Col>
        <Col className="search-button-container">
          <Button color="info" type="submit">
            {t("common:button.search")}
          </Button>
          <Button
            color="danger"
            type="submit"
            onClick={() => {
              form.resetForm();
            }}
          >
            {t("common:button.clear")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = function ({ products, partners }) {
  return {
    categories: products.get("categoryList") ?? [],
    partner: partners.get("partner"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getCategories }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearchForm);
