import { apiClient, handleError } from "api/ApiClient";
import ApiEndpoints from "constants/ApiEndpoints";
import { GET_APPS_SUCCESS } from "./ApplicationsReducer";

export const getAppsInfo = () => (dispatch) => {
  return apiClient
    .get(ApiEndpoints.APPS)
    .then((res) => {
      dispatch({
        type: GET_APPS_SUCCESS,
        payload: {
          apps: res?.data?.body?.partners,
          minVersion: res?.data?.body?.oneapp_min_version,
        },
      });
    })
    .catch((err) => dispatch(handleError(err)));
};

export const updateMinVersion = (minVersion) => (dispatch) => {
  return apiClient
    .post(ApiEndpoints.APPS_MIN_VERSION, { minVersion })
    .then(() => {
      return true;
    })
    .catch((err) => {
      dispatch(handleError(err));
      return false;
    });
};
