import React, { useState, useEffect, useRef } from "react";
import { Table } from "reactstrap";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TablePagination from "./TablePagination";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useDraggableScroll from "use-draggable-scroll";

const CommonDragableTable = ({
  data,
  headers,
  rowComponent: RowComponent,
  rowComponentProps = {},
  pagination = true,
  offset,
  pageSize,
  onOffsetChange,
  total,
  keyProperty = "id",
  onDrop,
  onDropCallback,
}) => {
  const { t } = useTranslation("common");
  const [loadingId, setLoadingId] = useState(null);
  const [dataVisible, setDataVisible] = useState({ elements: [] });
  const [isDragging, setIsDragging] = useState(false);
  const onDragEnd = async (result) => {
    if (!result.destination) {
      setIsDragging(false);
      return;
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    if (startIndex === endIndex) {
      setIsDragging(false);
      return;
    }
    const dataOld = data.elements;
    const dataNew = Array.from(data.elements);
    const [removed] = dataNew.splice(startIndex, 1);
    dataNew.splice(endIndex, 0, removed);
    onDropCallback(dataNew);
    setDataVisible({ elements: dataNew });
    setLoadingId(data.elements[startIndex].id);
    setIsDragging(false);
    const res = await onDrop(
      data.elements[startIndex].id,
      data.elements[endIndex].id,
      endIndex < startIndex
    );
    setLoadingId(null);
    if (!res) {
      onDropCallback(dataOld);
      setDataVisible({ elements: dataOld });
    }
  };

  useEffect(() => {
    if (isDragging) return;
    setDataVisible(data);
  }, [data, isDragging]);

  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref, { direction: "horizontal" });

  return (
    <div ref={ref} onMouseDown={onMouseDown} style={{ overflow: "auto" }}>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={() => setIsDragging(true)}
      >
        <Table>
          <thead className="text-primary">
            {!isEmpty(data.elements) && (
              <tr>
                {headers.map((header) => (
                  <th key={header.label} className={header.className}>
                    {header.label}
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <Droppable droppableId="droppable">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {!isEmpty(data.elements) &&
                  dataVisible.elements.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={!!loadingId}
                      style={{ overflowX: "auto" }}
                    >
                      {(provided, snapshot) => (
                        <>
                          <tr
                            key={item[keyProperty]}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              display: snapshot.isDragging && "flex",
                            }}
                            className="list-products-tr"
                          >
                            <RowComponent
                              number={offset + index + 1}
                              item={item}
                              index={index}
                              isFirst={offset + index === 0}
                              isLast={offset + index + 1 === total}
                              loading={loadingId === item.id}
                              {...rowComponentProps}
                            />
                          </tr>
                        </>
                      )}
                    </Draggable>
                  ))}
                {isEmpty(data.elements) && (
                  <tr>
                    <td colSpan={headers.length}>
                      <span>{t("noData")}</span>
                    </td>
                  </tr>
                )}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
      {pagination && (
        <div className="mt-2">
          {!isEmpty(data.elements) && (
            <TablePagination
              total={total}
              offset={offset}
              pageSize={pageSize}
              onOffsetChange={(offset) => onOffsetChange(offset)}
            />
          )}
        </div>
      )}
    </div>
  );
};

CommonDragableTable.defaultProps = {
  offset: 0,
  onOffsetChange: () => {},
  pageSize: 10,
  onDrop: () => {},
  onDropCallback: () => {},
};

CommonDragableTable.propTypes = {
  data: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  rowComponent: PropTypes.any.isRequired,
  offset: PropTypes.number,
  onOffsetChange: PropTypes.func,
  pageSize: PropTypes.number,
  total: PropTypes.number.isRequired,
  onDrop: PropTypes.func,
  onDropCallback: PropTypes.func,
};

export default CommonDragableTable;
